import { format } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

export const MISSING_DATE_PLACEHOLDER = '-'

export enum DateFormat {
	MonthDay = 'MM.dd',
	Date = 'MM/dd/yyyy',
	DateForSelect = 'yyyy-MM-dd',
	DateAndTime = 'MM/dd/yyyy, h:mm aa',
	DateAndTimeGraph = 'yyyy-MM-dd HH:mm',
	DateAndTimeWithTimeZone = 'MM/dd/yyyy, h:mm aa OOOO',
	TimeWithTimeZone = 'h:mm aa',
	DayMonth = 'd MMM',
}

export const formatDate = (
	date: string | number | Date,
	dateFormat: DateFormat,
	timeZone?: string,
) => {
	if (timeZone) {
		return formatInTimeZone(typeof date === 'string' ? new Date(date) : date, timeZone, dateFormat)
	}

	const parsedDate = typeof date === 'string' ? new Date(date) : date
	return format(parsedDate, dateFormat)
}

export const formatAndPrintOptionalDate = (
	date: string | number | Date | undefined,
	dateFormat: DateFormat,
	timeZone?: string,
) => {
	if (!date) {
		return MISSING_DATE_PLACEHOLDER
	}

	return formatDate(date, dateFormat, timeZone)
}
