import { NotificationType } from 'blue-project-types'

const sr = {
	common: {
		title: 'NOTIFIKACIJE',
		markAll: 'Oznaci sve kao pročitane',
		loadMore: 'Učitaj jos notifikacija',
		type: {
			[NotificationType.VulnerabilityCreated]: 'Ranjivost',
			[NotificationType.TargetFailed]: 'Phishing simulacije',
			[NotificationType.VulnerabilityActivityCreated]: 'Aktivnost za ranjivost',
			[NotificationType.CredentialLeaked]: 'Procureli kredencijali',
			[NotificationType.LearnerFailedCourse]: 'Kursevi',
		},
		text: {
			[NotificationType.VulnerabilityCreated]: '{{severity}} ranjivost je otkrivena',
			[NotificationType.VulnerabilityActivityCreated]:
				'Kreirana je nova aktivnost sa statusom : {{vulnerabilityActivityStatus}} za ranjivost: {{vulnerabilityTitle}}',
			[NotificationType.TargetFailed]:
				'{{personFirstName}} {{personLastName}} je pao/la na simulaciji {{simulationName}}',
			[`${NotificationType.CredentialLeaked}_one`]:
				'Nova procurela lozinka je otkrivena za {{username}}',
			[`${NotificationType.CredentialLeaked}_multiple`]:
				'Nove procurele lozinke su otkrivene za {{domain}}',
			[`${NotificationType.LearnerFailedCourse}`]:
				'{{courseName}}: {{learnerName}} nije položio/la kurs',
		},
	},
}

export default sr
