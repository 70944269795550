import { Avatar } from 'antd'
import { FC } from 'react'
import styled from 'styled-components'
import { IUser } from 'blue-project-types'
import { Colors } from '../styles/colors'

interface IProps {
	user: IUser
	onClick?(): void
}

const UserAvatar: FC<IProps> = ({ user, ...props }) => {
	const credentials = `${user.firstName[0]}${user.lastName[0]}`

	return (
		<StyledAvatar {...props} shape="square" isClickable={!!props.onClick}>
			{credentials}
		</StyledAvatar>
	)
}

const StyledAvatar = styled(({ isClickable, ...rest }) => <Avatar {...rest} />)`
	background-color: ${Colors.Orange};
	&:hover {
		cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'initial')};
	}
`

export default UserAvatar
