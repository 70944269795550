import { IExportRequest } from 'blue-project-types' // PDF export types
import { createContext } from 'react'

export enum PdfDownloadStatus {
	PendingToBeCreated = 'pendingToBeCreated',
	ReadyToBeDownloaded = 'readyToBeDownloaded',
	Downloading = 'downloading',
	Completed = 'completed',
	Error = 'error',
}

export interface IPdfUrls {
	requestExportUrl: string
	requestDownloadableUrl: string
	downloadableUrl?: string
}

export interface IPdfExportsData {
	urls: IPdfUrls
	exportRequest?: IExportRequest
	fileName: string
	downloadStatus: PdfDownloadStatus
}

// Context data
export interface IDownloadablePDFsContext {
	onRequestDownload(urls: IPdfUrls, fileName: string): void
}

// Context
export const DownloadablePDFsContext = createContext<IDownloadablePDFsContext>({
	onRequestDownload: () => ({}),
} as IDownloadablePDFsContext)

DownloadablePDFsContext.displayName = 'DownloadablePDFsContext'
