const sr = {
	orgMenu: {
		orgSettings: 'Podešsavenje organizacije',
		manageOrg: 'Upravljanje organizacijama',
		userSettings: 'Korisnička podešavanja',
		signOut: 'Odjava',
		createNewOrganization: 'Kreiranje nove organizacije',
	},
	manageOrgModal: {
		title: 'Upravljanje organizacijama',
		subtitle: 'Odabir aktivne organizacije',
		smallExplanationAddNewOrg: 'Želite da kreirate novu organizaciju? Počnite ovde',
		addOrgButton: 'Kreirajte novu Organizaciju',
	},
	sidebar: {
		overview: 'Pregled',
		inventory: 'Inventar',
		people: 'Zaposleni',
		assets: 'Resursi',
		awareness: 'Podizanje svesti',
		library: 'Biblioteka',
		organizationLessons: 'Lekcije',
		organizationNanoPages: 'Nano Stranice',
		courses: 'Kursevi',
		simulations: 'Phishing Simulacije',
		continuesMonitoring: 'Monitoring',
		vulnerabilities: 'Propusti',
		reporting: 'Izveštavanje',
		vulnerabilityEnrichers: 'Opisi propusta',
		drafts: 'Nacrti',
		admin: 'Admin',
		users: 'Korisnici',
		organizations: 'Organizacije',
		templates: 'Phishing Šabloni',
		organizationTemplates: 'Phishing Šabloni',
		'3rdParty': 'Procena trecih lica',
		allAssets: 'Svi resursi',
		learningContent: 'Sadržaj za učenje',
		dataLeaks: 'Procureli podaci',
		waf: 'Waf',
		organizationLearningContent: 'Sadržaj za učenje org.',
		blockedIps: 'Blokirane IP Adrese',
		predefinedCourses: 'Predefinisani kursevi',
		groupAdministration: 'Administracija grupe',
		inventoryAdministration: 'Administracija Inventara',
		awarenessAdministration: 'Administracija Awernesa',
		continuesMonitoringAdministration: 'Administracija Monitoringa',
		theme: 'Tema',
		nanoLearn: 'Seif Nano učenje',
		leaksAdministration: 'Procurele inf. Administration',
		credentialsAdministration: 'Kredencijali',
		dataLeaksCredentials: 'Kredencijali',
		extensionReports: 'Prijave ekstenzije',
		courseIndustryStandards: 'Industrijski standardi',
		peopleOverview: 'Pregled zaposlenih',
	},
}

export default sr
