import { FC } from 'react'
import { Form } from 'formik-antd'
import { Formik } from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { Button, FormikInput } from '../../../components'
import { ITwoFactorAuthToken } from '../utils/types'

interface IProps {
	formSize?: 'small' | 'middle' | 'large'
	submitText?: string
	submitBlock?: boolean
	isSubmitting: boolean
	onSubmit(data: ITwoFactorAuthToken): void
}

const TwoFactorAuthTokenForm: FC<IProps> = ({
	onSubmit,
	isSubmitting,
	formSize,
	submitText,
	submitBlock,
}) => {
	const { t } = useTranslation()

	return (
		<Formik
			initialValues={{ token: '' }}
			onSubmit={onSubmit}
			validationSchema={TwoFactorAuthTokenSchema}
		>
			{() => (
				<Form layout="vertical">
					<Form.Item name="token" label={t('auth.fields.twoFactor.label')}>
						<FormikInput
							name="token"
							placeholder={t('auth.fields.twoFactor.placeholder')}
							size={formSize}
						/>
					</Form.Item>
					<ButtonWrapper>
						<Button
							type="primary"
							htmlType="submit"
							size={formSize}
							block={submitBlock}
							loading={isSubmitting}
						>
							{submitText}
						</Button>
					</ButtonWrapper>
				</Form>
			)}
		</Formik>
	)
}

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`

const TwoFactorAuthTokenSchema = Yup.object().shape({
	token: Yup.string().required('Required'),
})

TwoFactorAuthTokenForm.defaultProps = {
	submitText: 'Connect phone',
	submitBlock: false,
}

export default TwoFactorAuthTokenForm
