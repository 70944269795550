import styled from 'styled-components'

import { Colors } from '../../../../styles/colors'
import { Text } from '../../../../components'
import { Description } from '../people/StyledComponents'

interface IProps {
	title: string
	subtitle: string
	smallerFontTitle?: boolean
	titleColor?: string
}

const TitleSubtitleWrapper: React.FC<IProps> = ({
	title,
	subtitle,
	smallerFontTitle,
	titleColor,
}) => {
	return (
		<Wrapper>
			<Text
				zeroMargin
				style={{
					fontSize: smallerFontTitle ? 18 : 21,
					fontWeight: 500,
					lineHeight: smallerFontTitle ? '22px' : '28px',
				}}
				color={titleColor ?? Colors.Dark}
			>
				{title}
			</Text>
			<Description zeroMargin style={{ width: smallerFontTitle ? '100%' : '70%' }}>
				{subtitle}
			</Description>
		</Wrapper>
	)
}

export default TitleSubtitleWrapper

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`
