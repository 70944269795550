import axios from 'axios'
import { API_USERS_URL } from '../../../config/urls'
import {
	ILoginRequest,
	IRegisterRequest,
	IResetEmailRequest,
	IResetPasswordSaveRequest,
	IVerifyEmailRequest,
} from './types'

export const LOGIN_KEY = 'loginUser'
export const login = async (data: ILoginRequest) => {
	const response = await axios.post(`${API_USERS_URL}/auth/login`, data)

	return response.data
}

export const LOGIN_2_FA_KEY = 'loginUser2FA'
export const login2FA = async (data: ILoginRequest) => {
	const response = await axios.post(`${API_USERS_URL}/auth/login/two-factor`, data)

	return response.data
}

export const LOGIN_2_FA_RECOVERY_KEY = 'loginUser2FARecovery'
export const login2FARecoveryKey = async (data: ILoginRequest) => {
	const response = await axios.post(`${API_USERS_URL}/auth/login/two-factor-recovery-key`, data)

	return response.data
}

export const REGISTER_KEY = 'registerUser'
export const register = async (data: IRegisterRequest) => {
	// todo remove
	const data2 = { ...data, fullName: `${data.firstName} ${data.lastName}` }
	const response = await axios.post(`${API_USERS_URL}/auth/register`, data2)

	return response.data
}

export const GET_AUTH_USER_KEY = 'getAuthUser'
export const getAuthUser = async () => {
	const response = await axios.get(`${API_USERS_URL}/users/me`)

	return response.data
}

export const GET_AUTH_USER_PERMISSIONS_KEY = 'getAuthUserPermissions'
export const getAuthUserPermissions = async () => {
	const response = await axios.get(`${API_USERS_URL}/acl/permissions/my-permissions`)

	return response.data
}

export const VERIFY_EMAIL_KEY = 'verifyEmail'
export const verifyEmail = async (data: IVerifyEmailRequest) => {
	const response = await axios.post(`${API_USERS_URL}/auth/verify-email`, data)

	return response.data
}

export const RESET_PASSWORD_SAVE_KEY = 'resetPasswordSave'
export const resetPasswordSave = async (data: IResetPasswordSaveRequest) => {
	const response = await axios.post(`${API_USERS_URL}/auth/reset-password/save`, data)

	return response.data
}

export const RESET_PASSWORD_REQUEST_KEY = 'resetPasswordRequest'
export const resetPasswordRequest = async (data: IResetEmailRequest) => {
	const response = await axios.post(`${API_USERS_URL}/auth/reset-password/request`, data)

	return response.data
}

export const RESEND_VERIFICATION_KEY = 'resendVerification'
export const resendVerification = async () => {
	const response = await axios.post(`${API_USERS_URL}/auth/resend-verification-token`)

	return response.data
}

export const LOGOUT_USER_KEY = 'logoutUser'
export const logoutUser = async () => {
	const response = await axios.post(`${API_USERS_URL}/auth/logout`)

	return response.data
}
