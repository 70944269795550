import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from './en'
import sr from './sr'

const resources = {
	en: { translation: en },
	sr: { translation: sr },
}

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		resources,
		fallbackLng: 'en',
		supportedLngs: ['en', 'sr'],
		interpolation: {
			escapeValue: false,
		},
	})

export default i18n
