import { message, notification } from 'antd'
import { NotificationPlacement } from 'antd/lib/notification'
import axios from 'axios'
import { ExportRequestStatus } from 'blue-project-types'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { cloneDeep } from 'lodash'
import { DownloadablePDFsContext, IPdfExportsData, IPdfUrls, PdfDownloadStatus } from './context'
import DownloadPdfMessage from './DownloadPdfMessage'

const MAP_URL_RESPONSE_STATUS_TO_DOWNLOADABLE_STATUS = {
	[ExportRequestStatus.Completed]: PdfDownloadStatus.ReadyToBeDownloaded,
	[ExportRequestStatus.Failed]: PdfDownloadStatus.Error,
	[ExportRequestStatus.Pending]: PdfDownloadStatus.PendingToBeCreated,
}

const MESSAGE_KEY = 'download-pdf'

const NOTIFICATION_BASE_SETTINGS = {
	duration: 0,
	key: MESSAGE_KEY,
	placement: 'bottomRight' as NotificationPlacement,
}

const DownloadablePDFsProvider = ({ children }) => {
	const [downloadablePDFs, setDownloadablePDFs] = useState<IPdfExportsData[]>([])
	const [pooling, setPooling] = useState(false)

	const { t } = useTranslation()

	const handleRequestDownload = async (urls: IPdfUrls, fileName: string) => {
		const isAlreadyInPool = !!downloadablePDFs.find(
			(pdf) => pdf.urls.requestExportUrl === urls.requestExportUrl,
		)

		// If url already in the pool skip new request
		if (isAlreadyInPool) {
			return
		}

		try {
			// Make request to async create pdf file
			const response = await axios.get(urls.requestExportUrl)

			// Pdf file is in status pending to be created
			setDownloadablePDFs((current) => [
				...current,
				{
					urls,
					exportRequest: response.data,
					fileName,
					downloadStatus: PdfDownloadStatus.PendingToBeCreated,
				},
			])

			// If pooling is off change to on
			if (!pooling) {
				setPooling(true)
			}
		} catch (_: any) {
			message.error(t('common.errors.generic'))
		}
	}

	const cleanDiscoveringData = useCallback(() => {
		setDownloadablePDFs([])
		setPooling(false)
	}, [])

	// Open notification message from where user can download
	const openMessage = useCallback(
		(message: string | React.ReactNode) => {
			notification.open({
				...NOTIFICATION_BASE_SETTINGS,
				message,
				onClose: cleanDiscoveringData,
				className: 'download-pdf-notification',
			})
		},
		[cleanDiscoveringData],
	)

	// Fetch downloadable url for pdf
	const fetchNewPdfData = async (PDFs: IPdfExportsData[]) => {
		try {
			const getPdfUrlResponses = await Promise.all(
				PDFs.map((pdf) =>
					axios.get(pdf.urls.requestDownloadableUrl, {
						params: { exportRequestId: pdf.exportRequest?.id },
					}),
				),
			)

			// Update state with urls
			setDownloadablePDFs((items) =>
				items.map((pdf) => {
					const apiResponse = getPdfUrlResponses.find(
						(response) => response.data.id === pdf.exportRequest?.id,
					)

					if (apiResponse) {
						const newPdf = cloneDeep(pdf)
						newPdf.urls.downloadableUrl = `${apiResponse.data.url}&name=${pdf.fileName}`
						newPdf.exportRequest!.status = apiResponse.data.status
						newPdf.downloadStatus =
							MAP_URL_RESPONSE_STATUS_TO_DOWNLOADABLE_STATUS[apiResponse.data.status] ||
							PdfDownloadStatus.Error

						return newPdf
					}

					return pdf
				}),
			)
		} catch (_: any) {
			message.error(t('common.errors.generic'))
		} finally {
			setPooling(false)
		}
	}

	useEffect(() => {
		// Find PDFs that should be downloaded
		const pdfDataToBeLoaded = downloadablePDFs.filter(
			(pdf) => pdf.downloadStatus === PdfDownloadStatus.PendingToBeCreated,
		)

		// If pooling is on and there are some PDFs to be loaded fetch that data
		if (pooling && pdfDataToBeLoaded.length) {
			setTimeout(async () => {
				fetchNewPdfData(pdfDataToBeLoaded.map((item) => item))
			}, 3000)

			return
		}

		// If pooling is off and there are data to be checked set pooling on
		if (!pooling && pdfDataToBeLoaded.length) {
			setPooling(true)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pooling])

	// Every time something happened with downloadablePDFs (if array length is more then 0)
	// array update message
	useEffect(() => {
		if (downloadablePDFs.length) {
			openMessage(<DownloadPdfMessage pdfData={downloadablePDFs} />)
		}
	}, [downloadablePDFs, openMessage])

	// Every time something happened with downloadablePDFs check if any pdf is ready
	// to be downloaded
	useEffect(() => {
		const downloadFile = async (pdf: IPdfExportsData) => {
			const response = await axios.get(pdf.urls.downloadableUrl!, { responseType: 'blob' })
			const contentDisposition = response.headers['content-disposition']

			const fileNameMatch = contentDisposition?.match(/filename=(.+)/)

			const file = URL.createObjectURL(response.data)

			const link = document.createElement('a')
			link.href = file
			link.download = fileNameMatch[1]
			document.body.appendChild(link)

			// Download file
			link.click()
			document.body.removeChild(link)

			return pdf
		}

		const toBeDownloaded = downloadablePDFs.filter(
			(pdf) => pdf.downloadStatus === PdfDownloadStatus.ReadyToBeDownloaded,
		)

		if (!toBeDownloaded.length) {
			return
		}

		// change status to downloading
		setDownloadablePDFs((current) =>
			current.map((pdf) => {
				if (pdf.downloadStatus === PdfDownloadStatus.ReadyToBeDownloaded) {
					return { ...pdf, downloadStatus: PdfDownloadStatus.Downloading }
				}
				return pdf
			}),
		)

		// trigger download
		Promise.all(toBeDownloaded.map(downloadFile))
			.then((completed: IPdfExportsData[]) => {
				setDownloadablePDFs((current) =>
					current.map((pdf) => {
						if (completed.find((item) => item.exportRequest?.id === pdf.exportRequest?.id)) {
							return { ...pdf, downloadStatus: PdfDownloadStatus.Completed }
						}
						return pdf
					}),
				)
			})
			.catch((e) => console.log(e))
	}, [downloadablePDFs])

	return (
		<DownloadablePDFsContext.Provider value={{ onRequestDownload: handleRequestDownload }}>
			{children}
		</DownloadablePDFsContext.Provider>
	)
}

export default DownloadablePDFsProvider
