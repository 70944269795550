import * as React from 'react'
import { Breadcrumb as AntBreadcrumb } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { SPACES } from '../../styles/spaces'
import { Routes } from '../../routes/config'

export interface IBreadcrumbItem {
	path: string
	name: string
}

interface IBreadcrumb {
	items: IBreadcrumbItem[]
}

const Breadcrumb: React.FC<IBreadcrumb> = ({ items }) => {
	return (
		<CustomBreadcrumb>
			<AntBreadcrumb.Item>
				<Link to={Routes.Dashboard}>Dashboard</Link>
			</AntBreadcrumb.Item>
			{items.map((item) => (
				<AntBreadcrumb.Item key={item.name}>
					<Link to={item.path}>{item.name}</Link>
				</AntBreadcrumb.Item>
			))}
		</CustomBreadcrumb>
	)
}

const CustomBreadcrumb = styled(AntBreadcrumb)`
	padding: ${SPACES.SMALL} ${SPACES.LARGE};
	transition: margin 0.2s ease-out;
`

export default Breadcrumb
