import { useCallback, useState } from 'react'

const useToggleVisibility = (isDefaultVisible?: boolean) => {
	const [isVisible, setIsVisible] = useState(!!isDefaultVisible)

	const show = useCallback(() => {
		setIsVisible(true)
	}, [])

	const hide = useCallback(() => {
		setIsVisible(false)
	}, [])

	const toggle = useCallback(() => {
		setIsVisible((v) => !v)
	}, [])

	return {
		isVisible,
		show,
		hide,
		toggle,
	}
}

export default useToggleVisibility
