import { getApiError } from '../utils/errors'
import Alert from './Alert'

interface IProps {
	error: any
}

const ApiError: React.FC<IProps> = ({ error }) => {
	const apiError = getApiError(error)
	const message = typeof apiError.message === 'string' ? apiError.message : apiError.error

	return <Alert title="Error happened" subtitle={message} type="error" />
}

export default ApiError
