import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

const RESOLVING_ASSETS_TRANSLATE_KEY = 'inventory.assets.common.resolvingAssets'

interface IProps {
	newlyAddedAssets: string[]
	refetchAssets(): void
}

const NewlyCreatedAssets: React.FC<IProps> = ({ newlyAddedAssets, refetchAssets }) => {
	const { t } = useTranslation()

	const numberOfAssets = newlyAddedAssets.length

	const translateKey = numberOfAssets
		? numberOfAssets === 1
			? 'completedNotificationMessageSingular'
			: 'completedNotificationMessagePlural'
		: 'noNewAssetsNotificationMessage'

	return (
		<div>
			{t(`${RESOLVING_ASSETS_TRANSLATE_KEY}.${translateKey}`, {
				numberOfAssets,
			})}
			{numberOfAssets > 0 && (
				<Button style={{ margin: 0 }} type="link" onClick={refetchAssets}>
					{t(`${RESOLVING_ASSETS_TRANSLATE_KEY}.refetchButton`)}
				</Button>
			)}
		</div>
	)
}

export default NewlyCreatedAssets
