import { useQueryParam, StringParam } from 'use-query-params'

const useRedirectBack = () => {
	const [from] = useQueryParam('from', StringParam)

	if (from) {
		return {
			redirectPath: from,
		}
	}

	return {}
}

export default useRedirectBack
