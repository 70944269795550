import { useMutation, useQueryClient } from 'react-query'

import {
	FETCH_NOTIFICATIONS_KEY,
	FETCH_NUMBER_OF_UNREAD_NOTIFICATIONS,
	updateNotificationsMarkAllRead as updateNotificationsMarkAllReadApi,
	UPDATE_NOTIFICATIONS_MARK_ALL_READ_KEY,
} from '../utils/api'

const useUpdateNotificationsMarkAllRead = () => {
	const queryClient = useQueryClient()

	const { mutate: updateNotificationsMarkAllRead, isLoading: isUpdatingNotificationsMarkAllRead } =
		useMutation(updateNotificationsMarkAllReadApi, {
			mutationKey: UPDATE_NOTIFICATIONS_MARK_ALL_READ_KEY,
			onSuccess: () => {
				queryClient.invalidateQueries([FETCH_NOTIFICATIONS_KEY])
				queryClient.invalidateQueries([FETCH_NUMBER_OF_UNREAD_NOTIFICATIONS])
			},
		})

	return {
		isUpdatingNotificationsMarkAllRead,
		updateNotificationsMarkAllRead,
	}
}

export default useUpdateNotificationsMarkAllRead
