import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import PeopleRiskScoreNew from '../../inventory/components/people/risk-score/PeopleRiskScoreNew'
import useFetchPeople from '../../inventory/hooks/useFetchPeople'
import { GlobalLoader } from '../../../components'
import Title from '../../../components/Title'
import KeyActionTaken from '../../inventory/components/people/risk-score/KeyActionTaken'
import RecommendedActions from '../../pdf-exports/components/RecommendedActions'
import CampaignsOverview from '../../pdf-exports/components/CampaignsOverview'
import { Colors } from '../../../styles/colors'
import useFetchPeopleSummary from '../../inventory/hooks/api/useFetchPeopleSummary'
import CredentialLeakMonitoring from '../../pdf-exports/components/CredentialLeakMonitoring'
import { SPACES } from '../../../styles/spaces'

const DASHBOARD_BASE_KEY = 'dashboard.overview'

const OverviewNew = () => {
	const { t } = useTranslation()

	const { people, isFetchingPeople } = useFetchPeople()

	const { isFetchingPeopleSummary, peopleSummary } = useFetchPeopleSummary()

	if (isFetchingPeople || isFetchingPeopleSummary) {
		return <GlobalLoader />
	}

	return (
		<Wrapper>
			<Title level={1} color={Colors.Dark} style={{ marginBottom: SPACES.DEFAULT }}>
				{t(`${DASHBOARD_BASE_KEY}.overviewTitle`)}
			</Title>
			<PeopleRiskScoreNew peopleSummary={peopleSummary} people={people} />
			<RecommendedActions peopleSummary={peopleSummary} people={people} />
			<CampaignsOverview />
			<CredentialLeakMonitoring />
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	background: ${Colors.BackgroundGray};
`

export default OverviewNew
