import React from 'react'
import styled from 'styled-components'
import dompurify from 'dompurify'

interface IProps {
	emailBody?: string
}

const HTMLPreview: React.FC<IProps> = ({ emailBody }) => {
	return <Wrapper dangerouslySetInnerHTML={{ __html: `${dompurify.sanitize(emailBody)}` }} />
}

const Wrapper = styled.div`
	pointer-events: none;
`

export default HTMLPreview
