import { rgba } from 'polished'
import styled from 'styled-components'
import { Colors } from '../styles/colors'
import { SPACES } from '../styles/spaces'

const SuccessBigIcon = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: ${SPACES.EXTRA_LARGE} auto;
	width: 55px;
	height: 55px;
	background-color: ${Colors.Green};
	border-radius: 100%;
	.anticon {
		z-index: 2;
		svg {
			width: 18px;
			height: 18px;
			color: ${Colors.White};
		}
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: ${rgba(`${Colors.Green}`, 0.24)};
		border-radius: 100%;
		transform: scale(1.35);
		z-index: 1;
	}
`

export default SuccessBigIcon
