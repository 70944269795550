import { EllipsisOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import { rgba } from 'polished'
import styled from 'styled-components'

import { DropdownContent } from '.'
import { useToggleVisibility } from '../hooks'
import { Colors } from '../styles/colors'
import { SPACES } from '../styles/spaces'

interface IProps {
	children: React.ReactNode | React.ReactNode[]
}

const ActionMenu: React.FC<IProps> = ({ children }) => {
	const { isVisible, show, hide } = useToggleVisibility()

	const stopEvent = (e: any) => {
		e.preventDefault()
		e.stopPropagation()
	}

	return (
		<Dropdown
			dropdownRender={() => (
				<StyledContent
					onClick={(e) => {
						stopEvent(e)
						hide()
					}}
				>
					{children}
				</StyledContent>
			)}
			overlayClassName="z-index-5"
			trigger={['click']}
			open={isVisible}
			onOpenChange={(visible) => {
				visible ? show() : hide()
			}}
			placement="bottomRight"
		>
			<Icon
				onClick={(e) => {
					stopEvent(e)
					show()
				}}
			>
				<EllipsisOutlined />
			</Icon>
		</Dropdown>
	)
}

export const ActionMenuItem = styled.div`
	font-size: 12px;
	padding: ${SPACES.EXTRA_SMALL} ${SPACES.DEFAULT};
	color: ${Colors.Dark};
	border-radius: 4px;
	&:hover {
		cursor: pointer;
		background: ${rgba(Colors.Blue, 0.05)};
	}
`

const StyledContent = styled(DropdownContent)`
	padding: ${SPACES.EXTRA_SMALL};
`

const Icon = styled.span`
	width: 32px;
	height: 32px;
	padding: 7px;
	display: inline-block;

	.anticon-ellipsis {
		font-size: 16px;
		transform: rotate(90deg);
	}
	&:hover {
		cursor: pointer;
	}
`

export default ActionMenu
