import { IAsset, AssetDiscoveryStatus, IAssetDiscovery, AssetType } from 'blue-project-types'
import React, { useCallback, useEffect, useState } from 'react'
import { message, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { stringify } from 'query-string'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { NotificationPlacement } from 'antd/lib/notification'

import { fetchAssetDiscoveryForAsset } from '../utils/api'
import { AssetDiscoveryContext, IAssetDiscoveryData } from './context'
import NewlyCreatedAssets from '../components/assets/common/NewlyCreatedAssetsMessage'
import { Routes as RoutesConfig } from '../../../routes/config'
import { FETCH_ASSETS_KEY } from '../utils/api'
import { NEW_ADDED_ASSETS_QUERY_KEY } from '../utils/types'

const MESSAGE_KEY = 'asset-discovery'
const RESOLVING_ASSETS_TRANSLATE_KEY = 'inventory.assets.common.resolvingAssets'

const NOTIFICATION_BASE_SETTINGS = {
	duration: 0,
	key: MESSAGE_KEY,
	placement: 'bottomRight' as NotificationPlacement,
}

const findNewlyAddedAssets = (completedAssets: IAssetDiscoveryData[]) => {
	return completedAssets.reduce((prev, curr) => {
		if (curr.newlyDiscoveredAssets) {
			return [...prev, ...curr.newlyDiscoveredAssets]
		}

		return prev
	}, [] as string[])
}

const AssetDiscoveryProvider = ({ children }) => {
	const [discoveringAssetsData, setDiscoveringAssetsData] = useState<IAssetDiscoveryData[]>([])
	const [pooling, setPooling] = useState(false)

	const queryClient = useQueryClient()

	const history = useHistory()

	const { t } = useTranslation()

	const handleNewAssetsAdded = async (assets: IAsset[]) => {
		const assetsForDiscovery = assets.filter((asset) =>
			[AssetType.DOMAIN, AssetType.SUBDOMAIN].includes(asset.assetType),
		)

		if (!assetsForDiscovery.length) {
			return
		}

		setDiscoveringAssetsData((currentItems) => [
			...currentItems,
			...assetsForDiscovery.map((asset) => ({
				asset,
				status: AssetDiscoveryStatus.Requested,
				newlyDiscoveredAssets: [],
			})),
		])

		// Start pooling data
		setPooling(true)
		openMessage(t(`${RESOLVING_ASSETS_TRANSLATE_KEY}.notificationMessage`))
	}

	const openMessage = (message: string | React.ReactNode) => {
		notification.open({
			...NOTIFICATION_BASE_SETTINGS,
			message,
			onClose: cleanDiscoveringData,
			className: 'asset-discovery-notification',
		})
	}

	const cleanDiscoveringData = () => {
		setDiscoveringAssetsData([])
	}

	const fetchNewAssetDiscovery = async (assets: IAsset[]) => {
		try {
			// Fetch data from api
			const newlyDiscoveredAssets = await Promise.all(
				assets.map((asset) => fetchAssetDiscoveryForAsset(asset.id)),
			)

			checkForFailedResolving(newlyDiscoveredAssets)

			// Sync status with local state
			setDiscoveringAssetsData((currentDiscoveringAssetsData) =>
				currentDiscoveringAssetsData.map((currentDiscoveredItem) => {
					const newDiscoveredItem = newlyDiscoveredAssets.find(
						(newItem) => newItem.assetId === currentDiscoveredItem.asset.id,
					)

					if (newDiscoveredItem) {
						return {
							...currentDiscoveredItem,
							status: newDiscoveredItem.status,
							newlyDiscoveredAssets: newDiscoveredItem.discoveryData?.newlyDiscoveredAssets || [],
						}
					}

					return currentDiscoveredItem
				}),
			)
		} catch (e) {
		} finally {
			// Once fetch is done set data should be pooled to false
			setPooling(false)
		}
	}

	const findAssetsPerStatus = useCallback(
		(status: AssetDiscoveryStatus) => {
			return discoveringAssetsData.filter((asset) => asset.status === status)
		},
		[discoveringAssetsData],
	)

	const refetchAssets = () => {
		const completedAssets = findAssetsPerStatus(AssetDiscoveryStatus.Completed)

		// Find newly added asset ids from local state
		const newlyAddedAssets = findNewlyAddedAssets(completedAssets)

		history.push(
			`${RoutesConfig.Assets}?${stringify({
				[NEW_ADDED_ASSETS_QUERY_KEY]: newlyAddedAssets,
			})}`,
		)
		cleanDiscoveringData()
		notification.close(MESSAGE_KEY)
		queryClient.invalidateQueries(FETCH_ASSETS_KEY)
	}

	const checkForFailedResolving = (newlyDiscoveredAssets: IAssetDiscovery[]) => {
		const failedDiscovery = newlyDiscoveredAssets.filter(
			(item) => item.status === AssetDiscoveryStatus.Failed,
		)

		if (failedDiscovery.length) {
			failedDiscovery.forEach((failedDiscoveryItem) => {
				const asset = discoveringAssetsData.find(
					(assetDataItem) => assetDataItem.asset.id === failedDiscoveryItem.assetId,
				)?.asset

				if (asset) {
					message.error(
						t(`${RESOLVING_ASSETS_TRANSLATE_KEY}.errorMessage`, {
							asset: asset?.name,
						}),
					)
				}
			})
		}
	}

	useEffect(() => {
		const completedAssets = findAssetsPerStatus(AssetDiscoveryStatus.Completed)
		const loadingAssets = findAssetsPerStatus(AssetDiscoveryStatus.Requested)

		// Find newly added asset ids from local state
		const newlyAddedAssets = findNewlyAddedAssets(completedAssets)

		// If number of loading assets is zero and completed assets are more then zero
		// we finished with loading and can change message to summary
		if (loadingAssets.length === 0 && completedAssets.length > 0) {
			openMessage(
				<NewlyCreatedAssets refetchAssets={refetchAssets} newlyAddedAssets={newlyAddedAssets} />,
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [findAssetsPerStatus])

	useEffect(() => {
		const assetsToBeLoaded = findAssetsPerStatus(AssetDiscoveryStatus.Requested)
		// If pooling is on and there are some asset to be checked fetch that data
		if (pooling && assetsToBeLoaded.length) {
			setTimeout(() => {
				fetchNewAssetDiscovery(assetsToBeLoaded.map((item) => item.asset))
			}, 3000)
		}

		// If pooling is off and there are data to be checked set pooling on
		if (!pooling && assetsToBeLoaded.length) {
			setPooling(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pooling])

	return (
		<AssetDiscoveryContext.Provider
			value={{ discoveringAssetsData, onAssetAdded: handleNewAssetsAdded }}
		>
			{children}
		</AssetDiscoveryContext.Provider>
	)
}

export default AssetDiscoveryProvider
