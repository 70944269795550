import { createContext } from 'react'
import { ISidebarRoute } from '../../layout/utils/sidebarRoutes'

// Context data
export interface IUIContext {
	isVerifyModalVisible: boolean
	isSidebarOpen: boolean
	currentSidebarRoute?: ISidebarRoute
	isAdminRoute: boolean
	toggleIsSidebarOpen(): void
	toggleIsVerifyModalVisible(): void
	showIsVerifyModalVisible(): void
}

// Context
export const UIContext = createContext<IUIContext>({
	isSidebarOpen: true,
	isVerifyModalVisible: false,
	isAdminRoute: false,
} as IUIContext)

UIContext.displayName = 'UIContext'
