import { Spin, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ApiError, Button, GlobalLoader } from '../../../components'
import { Routes } from '../../../routes/config'
import { Colors } from '../../../styles/colors'
import { SPACES } from '../../../styles/spaces'
import useFetchNotifications from '../hooks/useFetchNotifications'
import useUpdateNotificationsMarkAllRead from '../hooks/useUpdateNotificationsMarkAllRead'
import NotificationDetails from './NotificationDetails'
import NotificationIcon from './NotificationIcon'

const TRANSLATE_COMMON_NOTIFICATION_BASE_KEY = 'notifications.common'

interface IProps {
	onHide(): void
}

const NotificationsBar: React.FC<IProps> = ({ onHide }) => {
	const history = useHistory()
	const { t } = useTranslation()

	const {
		isFetchingNotifications,
		errorFetchingNotifications,
		notificationsData,
		hasMoreNotifications,
		fetchMoreNotifications,
		isFetchingNotificationsNextPage,
	} = useFetchNotifications()

	const { updateNotificationsMarkAllRead } = useUpdateNotificationsMarkAllRead()

	const handleNotificationLink = (notificationId: string) => {
		history.push(Routes.Notification.replace(':id', notificationId))
		onHide()
	}

	if (errorFetchingNotifications) {
		return <ApiError error={errorFetchingNotifications} />
	}

	return (
		<NotificationWrapper>
			<NotificationHeader>
				<Typography.Text
					style={{
						color: Colors.Gray,
						textTransform: 'uppercase',
						fontSize: '12px',
					}}
				>
					{t(`${TRANSLATE_COMMON_NOTIFICATION_BASE_KEY}.title`)}
				</Typography.Text>
				<Button type="link" onClick={() => updateNotificationsMarkAllRead()}>
					{t(`${TRANSLATE_COMMON_NOTIFICATION_BASE_KEY}.markAll`)}
				</Button>
			</NotificationHeader>
			<NotificationList>
				{isFetchingNotifications ? (
					<GlobalLoader />
				) : (
					<>
						{notificationsData?.pages?.map((page, index) => (
							<React.Fragment key={index}>
								{page.items.map((notification) => (
									<NotificationItem
										key={notification.id}
										isRead={notification?.isRead}
										onClick={() => handleNotificationLink(notification.id)}
									>
										<NotificationIconWrapper>
											<NotificationIcon notification={notification} />
										</NotificationIconWrapper>
										<NotificationDetails notification={notification} />
									</NotificationItem>
								))}
							</React.Fragment>
						))}
					</>
				)}
				{isFetchingNotificationsNextPage && (
					<Spin size="large" tip={'Loading...'} style={{ margin: 'auto', width: '100%' }} />
				)}
				{hasMoreNotifications && (
					<NotificationListLoadMore>
						<ActionButton type="link" onClick={() => fetchMoreNotifications()}>
							{t(`${TRANSLATE_COMMON_NOTIFICATION_BASE_KEY}.loadMore`)}
						</ActionButton>
					</NotificationListLoadMore>
				)}
			</NotificationList>
		</NotificationWrapper>
	)
}

const NotificationWrapper = styled.div`
	width: 350px;
	max-width: 350px;
	background-color: ${Colors.White};
	border-radius: ${SPACES.EXTRA_SMALL};
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	button {
		font-weight: 400;
		font-size: 12px;
		padding: 0;
		height: auto;
	}
`
const NotificationHeader = styled.div`
	display: flex;
	justify-content: space-between;
	padding: ${SPACES.SMALL} ${SPACES.SMALL} ${SPACES.EXTRA_SMALL} ${SPACES.SMALL};
`
const NotificationList = styled.div`
	min-height: 300px;
	max-height: 495px;
	overflow-y: auto;
`

const NotificationItem = styled.div<{ isRead: boolean }>`
	cursor: pointer;
	display: flex;
	padding: 12px ${SPACES.SMALL};
	background-color: ${(props) => (props.isRead ? `${Colors.White}` : `#F4FAFF`)};
	transition: background-color 0.3s ease-in-out;
	span {
		&.ant-typography {
			color: ${Colors.Gray};
			font-size: 12px;
			margin-bottom: 0;
		}
	}
	div .ant-typography {
		margin-bottom: ${SPACES.XXS};
	}
	&:hover {
		background-color: ${(props) => (props.isRead ? `#f7f8fb` : `#EDF6FF`)};
	}
	&:active,
	&:focus {
		background-color: ${(props) => (props.isRead ? `#ebecee` : `#E1EEFB`)};
	}
`

const NotificationIconWrapper = styled.div`
	margin-right: 12px;
	img {
		position: relative;
		top: ${SPACES.EXTRA_SMALL};
		width: 32px;
	}
`
const NotificationListLoadMore = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: ${SPACES.EXTRA_SMALL} ${SPACES.SMALL} ${SPACES.SMALL} ${SPACES.SMALL};
`

const ActionButton = styled(Button)`
	font-weight: 400;
	font-size: 12px;
	padding: 0;
	height: auto;
`

export default NotificationsBar
