import { FC, ReactNode } from 'react'

import useToggleVisibility from '../../hooks/useToggleVisibility'
import { UIContext } from './context'
import { useMatchSidebarRoute } from '../../layout/utils/sidebarRoutes'
import { isAdminRoute as isAdminRouteMather } from '../../utils/app-routing'

interface IProps {
	children: ReactNode | ReactNode[]
}

const UIProvider: FC<IProps> = ({ children }) => {
	const { isVisible: isSidebarOpen, toggle: toggleIsSidebarOpen } = useToggleVisibility(true)
	const {
		isVisible: isVerifyModalVisible,
		toggle: toggleIsVerifyModalVisible,
		show: showIsVerifyModalVisible,
	} = useToggleVisibility(false)

	const currentSidebarRoute = useMatchSidebarRoute()

	const isAdminRoute = isAdminRouteMather(currentSidebarRoute?.route)

	return (
		<UIContext.Provider
			value={{
				isSidebarOpen,
				toggleIsSidebarOpen,
				isVerifyModalVisible,
				toggleIsVerifyModalVisible,
				showIsVerifyModalVisible,
				currentSidebarRoute,
				isAdminRoute,
			}}
		>
			{children}
		</UIContext.Provider>
	)
}

export default UIProvider
