import { FC, useMemo } from 'react'
import { Form, Input } from 'formik-antd'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import { useTranslation } from 'react-i18next'

import { Button, Title } from '../../../components'
import { useAuth } from '../context'
import { FormWrapper } from './StyledComponents'

interface IProps {}

interface IResetPasswordSaveForm {
	password: string
}

const SET_PASSWORD_BASE_KEY = 'auth.setPassword'
const FIELDS_BASE_KEY = 'auth.fields'
const ERRORS_BASE_KEY = 'common.validation.errors'

const ResetPasswordSaveForm: FC<IProps> = () => {
	const { t } = useTranslation()
	const location = useLocation()
	const { resetPasswordSave, isSubmittingResetPasswordSave } = useAuth()

	const handleSetNewPassword = (data: IResetPasswordSaveForm) => {
		const query = parse(location.search)
		resetPasswordSave({
			token: query.token as string,
			password: data.password,
		})
	}

	const schema = useMemo(() => {
		return Yup.object().shape({
			password: Yup.string()
				.required(t(`${ERRORS_BASE_KEY}.required`))
				.matches(/^(?=.*[0-9])(?=.{6,})/, t(`${ERRORS_BASE_KEY}.password`)),
		})
	}, [t])

	return (
		<FormWrapper>
			<Title level={1}>{t(`${SET_PASSWORD_BASE_KEY}.title`)}</Title>
			<Formik
				initialValues={{ password: '' }}
				onSubmit={handleSetNewPassword}
				validationSchema={schema}
			>
				{() => (
					<Form layout="vertical">
						<Form.Item name="password" label={t(`${FIELDS_BASE_KEY}.password.label`)}>
							<Input.Password
								name="password"
								placeholder={t(`${FIELDS_BASE_KEY}.password.placeholder`)}
								size="large"
							/>
						</Form.Item>
						<Button type="primary" htmlType="submit" block loading={isSubmittingResetPasswordSave}>
							{t(`${SET_PASSWORD_BASE_KEY}.button`)}
						</Button>
					</Form>
				)}
			</Formik>
		</FormWrapper>
	)
}

export default ResetPasswordSaveForm
