import { INotification, NotificationType, Severity } from 'blue-project-types'
import React, { useMemo } from 'react'

interface IProps {
	notification: INotification
}

const VULNERABILITY_NOTIFICATIONS = [NotificationType.VulnerabilityCreated]

const SEVERITY_TO_ICON_SRC = {
	[Severity.Critical]: '/images/vul-critical.png',
	[Severity.High]: '/images/vul-high.png',
	[Severity.Medium]: '/images/vul-medium.png',
}

const NOTIFICATION_TYPE_TO_ICON = {
	[NotificationType.TargetFailed]: '/images/phishing-target-failed.png',
	[NotificationType.CredentialLeaked]: '/images/credentials-leaked-notification.png',
	[NotificationType.LearnerFailedCourse]:
		'https://cdn.seif.ai/images/64546525296032771846406278916116.png',
	[NotificationType.VulnerabilityActivityCreated]:
		'https://cdn.seif.ai/images/72252069258885775025230974274306.png',
}

const NotificationIcon: React.FC<IProps> = ({ notification }) => {
	const { isVulnerabilityNotification } = useMemo(() => {
		const isVulnerabilityNotification = VULNERABILITY_NOTIFICATIONS.includes(notification?.type)

		return {
			isVulnerabilityNotification,
		}
	}, [notification])

	if (isVulnerabilityNotification) {
		return (
			<img
				src={SEVERITY_TO_ICON_SRC[notification.meta.vulnerabilitySeverity]}
				alt="Notification Icon"
			/>
		)
	}

	const iconUrl = NOTIFICATION_TYPE_TO_ICON[notification?.type]
	if (iconUrl) {
		return <img src={iconUrl} alt="Notification Icon" />
	}

	return null
}

export default NotificationIcon
