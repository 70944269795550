const sr = {
	accountSettings: {
		title: 'Podešavanja naloga',
		userInfo: {
			editButton: 'Uredi',
			editModal: {
				title: 'Izmenite detalje naloga',
				fields: {
					firstName: {
						label: 'Ime',
						placeholder: 'Unesite ime',
					},
					lastName: {
						label: 'Prezime',
						placeholder: 'Unesite prezime',
					},
				},
				messages: {
					success: 'Profil je uspešno ažuriran',
					error: 'Došlo je do greške!',
				},
				confirmButton: 'Sačuvajte izmene',
			},
		},
		updatePassword: {
			title: 'Promenite lozinku',
			messages: {
				success: 'Lozinka je uspešno ažurirana',
				error: 'Došlo je do greške!',
			},
			fields: {
				oldPassword: {
					label: 'Stara lozinka',
					placeholder: 'Unesite staru lozinku',
				},
				newPassword: {
					label: 'Nova lozinka',
					placeholder: 'Unesite novu lozinku',
				},
			},
			confirmButton: 'Sačuvajte izmene',
		},
		twoStepAuth: {
			title: 'Postavite dvo-faktorsku autentifikaciju',
			description:
				'Zaštitite vaš nalog dodavanjem dodatnog obruča bezbednosti. Drugi login korak obezbediće vaš nalog čak i u slučaju da vam je lozinka kompromitovana. Da biste je omogućili, potrebno je da imate pametni telefon.',
			button: 'Postavite dvo-faktorsku autentifikaciju',
		},
	},
	twoFactorAuth: {
		title: 'Dvo-faktorska autentifikacija',
		steps: {
			confirmIdentity: 'Potvrdite identitet',
			getApp: 'Nabavite aplikaciju',
			connectPhone: 'Povežite telefon',
			saveKey: 'Sačuvajte ključ za povraćaj',
		},
		verifyPassword: {
			title: 'Unesite svoju lozinku',
			subtitle:
				'Otpočnite unošenjem svoje lozinke, kako bismo znali da ste to vi. Zatim ćemo zajedno proći kroz tri preostala koraka.',
			fields: {
				password: {
					label: 'Lozinka',
					placeholder: 'Unesite lozinku',
				},
			},
			submit: 'Hajdemo',
		},
		getApp: {
			title: 'Instalirajte aplikaciju za verifikaciju na vašem telefonu',
			subtitle:
				'Koristićete neku od aplikacija za verifikaciju kao što su Google Authenticator, Authy ili Duo. Instalirajte preko vašeg app store-a.',
			question: 'Već imate aplikaciju?',
			scan: ' Da, spreman sam da skeniram kod',
		},
		connectPhone: {
			section1: {
				title: 'Skenirajte ovaj QR kod',
				subtitlePart1:
					'Kada vaša aplikacija očita QR, šestocifreni kod biće poslat na vaš telefon.',
				subtitlePart2: 'ili iskoristite ovaj kod:',
			},
			section2: {
				title: 'Unesite šestocifreni kod ovde',
				subtitle:
					'Jednom kada se povežete, zapamtićemo vaš telefon, kako biste mogli da ga koristite svaki put kad se ulogujete.',
			},
		},
		recoveryKeys: {
			title: 'Sačuvajte vaš ključ za povraćaj za hitne slučajeve',
			subtitle:
				'Ukoliko izgubite telefon, nećete moći da se ulogujete bez ovog ključa. Otštampajte ga, kopirajte ili zapišite, ali se postarajte da ga niko drugi ne vidi sem vas.',
			saveButton: 'Sačuvajte!',
			savedButton: 'Sačuvao sam, hajde da završimo',
			copyToClipboard: 'Kopirajte u clipboard',
		},
	},
	activeTwoFactorAuth: {
		createNewKeyTitle: 'Kreirajte novi ključ za povraćaj za hitne slučajeve',
		createNewKeySubtitle: 'Kada kreirate novi ključ, vaš stari ključ postaje nevažeći.',
		createNewKeyRoute: 'Kreirajte novi ključ',
		twoFactorAuth: 'Dvo-faktorska autentifikacija',
		enabled: 'omogućena',
		yourAccountIsProtected: 'Vaš nalog je obezbeđen sa dva faktora verifikacije od ',
		ifYouDisable:
			'Ukoliko onemogućite 2FA, više nećete imati drugi obruč bezbednosti logovanju u nalog',
		disableButton: 'Onemogućite 2FA',
		disableModal: {
			title: 'Onemogućite dvo-faktorsku autentifikaciju',
			subtitle:
				'Ukoliko nastavite, više nećete imati drugi obruč bezbednosti pri logovanju u nalog.',
			fields: {
				password: {
					label: 'Lozinka',
					placeholder: 'Unesite lozinku',
				},
			},
			proceed: 'Nastavite',
		},
		messages: {
			successfullyDisabled: 'Uspešno onemogućena',
		},
	},
	newRecoveryKey: {
		title: 'Dvo-faktorska autentifikacija',
		steps: {
			createNewKey: 'Kreirajte novi ključ',
			saveNewKey: 'Sačuvajte novi ključ',
		},
		createNewKey: {
			title: 'Kreirajte novi ključ za povraćaj za hitne slučajeve',
			subtitle:
				'Kada kreirate novi ključ, on postaje važeći istog trenutka, vaš stari ključ postaje nevažeći.',
			create: 'Kreirajte novi ključ',
			fields: {
				password: {
					label: 'Lozinka',
					placeholder: 'Unesite lozinku',
				},
			},
		},
	},
	mySessions: {
		table: {
			title: 'Moje sesije',
			columns: {
				ip: 'IP',
				userAgent: 'Korisnički agent',
				createdAt: 'Datum kreiranja',
				validUntil: 'Važi do',
			},
			action: {
				logout: 'Odjava',
			},
			loggedOut: {
				title: 'Odjavi ovu sesiju?',
				subtitle: 'Da li se sigurni da želite da odjavite ovu sesiju?',
			},
		},
		useLogoutSession: {
			success: 'Sesija uspešno odjavljena',
			error: 'Desila se greška',
		},
	},
}

export default sr
