import { IApiError } from 'blue-project-types'
import { useInfiniteQuery } from 'react-query'
import { fetchNotifications, FETCH_NOTIFICATIONS_KEY } from '../utils/api'

const SHOW_NOTIFICATIONS_PER_PAGE = 5

const useFetchNotifications = () => {
	const {
		data: notificationsData,
		error: errorFetchingNotifications,
		hasNextPage: hasMoreNotifications,
		isLoading: isFetchingNotifications,
		fetchNextPage: fetchMoreNotifications,
		isFetchingNextPage: isFetchingNotificationsNextPage,
	} = useInfiniteQuery<any, IApiError>(
		FETCH_NOTIFICATIONS_KEY,
		({ pageParam = 0 }) =>
			fetchNotifications({
				skip: pageParam,
				limit: SHOW_NOTIFICATIONS_PER_PAGE,
			}),
		{
			getNextPageParam: (lastPage, pages) => {
				const fetchedPagesItems = pages.map((page) => page.items).flat().length
				if (fetchedPagesItems < lastPage?.total) {
					return fetchedPagesItems
				}
			},
		},
	)

	return {
		isFetchingNotifications,
		errorFetchingNotifications,
		notificationsData,
		hasMoreNotifications,
		isFetchingNotificationsNextPage,
		fetchMoreNotifications,
	}
}

export default useFetchNotifications
