import { Dropdown } from 'antd'
import styled from 'styled-components'

import { DropdownContent } from '../../components'
import { SPACES } from '../../styles/spaces'
import { AdministrationMenuIcon, MenuChangerIcon, PlatformMenuIcon } from '../../components/Icons'
import { DropDownItem } from './StyledComponents'
import { Routes } from '../../routes/config'
import { useHistory } from 'react-router-dom'
import { useCan, useToggleVisibility } from '../../hooks'
import { useUI } from '../../context/ui'
import { useTranslation } from 'react-i18next'
import { SuperAdminPermissions } from 'blue-project-types'

enum Menu {
	Platform = 'platform',
	Administration = 'administration',
}

const TRANSLATE_BASE_KEY = 'layout.menuChanger'

const MenuChanger = () => {
	const { isAdminRoute } = useUI()

	const canManageOrganization = useCan(SuperAdminPermissions.ManageTenants)
	const canManageLessons = useCan(SuperAdminPermissions.ManageLearningContent)
	const canManageAssets = useCan(SuperAdminPermissions.ManageInventory)
	const canManageTemplates = useCan(SuperAdminPermissions.ManageTemplatesLibrary)
	const canManageIndustryStandard = useCan(SuperAdminPermissions.ManageLearning)
	const canManageMonitoring = useCan(SuperAdminPermissions.ManageVulnerabilities)
	const canManageLeaks = useCan(SuperAdminPermissions.ManageLeaks)
	const canManageUsers = useCan(SuperAdminPermissions.ManageUsers)

	/**
	 * Apart for can manage organization check,
	 * we respect the order of items in sidebar
	 * (top to bottom) when checking permissions
	 */
	const administrationRoute = canManageOrganization
		? Routes.AdminOrganizations
		: canManageLessons
		? Routes.Lessons
		: canManageAssets
		? Routes.AdminAllAssets
		: canManageTemplates
		? Routes.AdminTemplates
		: canManageIndustryStandard
		? Routes.CourseIndustryStandards
		: canManageMonitoring
		? Routes.AdminVulnerabilities
		: canManageLeaks
		? Routes.AdminLeaks
		: canManageUsers
		? Routes.AdminUsers
		: Routes.AdministrationOverview

	const MAP_MENU_TO_URL = {
		[Menu.Administration]: administrationRoute,
		[Menu.Platform]: Routes.Dashboard,
	}
	const history = useHistory()

	const { t } = useTranslation()

	const {
		isVisible: isDropdownVisible,
		hide: hideDropdown,
		show: showDropdown,
	} = useToggleVisibility()

	const onChange = (newMenu: Menu) => {
		history.push(MAP_MENU_TO_URL[newMenu])
		hideDropdown()
	}

	return (
		<Dropdown
			open={isDropdownVisible}
			onOpenChange={(visible: boolean) => (!visible ? hideDropdown() : showDropdown())}
			dropdownRender={() => (
				<DropdownContent>
					<DropDownItem align="left" onClick={() => onChange(Menu.Platform)}>
						<PlatformMenuIcon style={{ marginRight: SPACES.EXTRA_SMALL }} />
						{t(`${TRANSLATE_BASE_KEY}.platform`)}
					</DropDownItem>
					<DropDownItem align="left" onClick={() => onChange(Menu.Administration)}>
						<AdministrationMenuIcon style={{ marginRight: SPACES.EXTRA_SMALL }} />
						{t(`${TRANSLATE_BASE_KEY}.admin`)}
					</DropDownItem>
				</DropdownContent>
			)}
			trigger={['click']}
			placement="bottomLeft"
		>
			<CurrentLanguage>
				<MenuChangerIcon />{' '}
				<span style={{ marginLeft: 8, display: 'inline-block' }}>
					{isAdminRoute ? 'Administration' : 'Platform'}
				</span>
			</CurrentLanguage>
		</Dropdown>
	)
}

const CurrentLanguage = styled.div`
	margin: 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	&:hover {
		cursor: pointer;
	}
`

export default MenuChanger
