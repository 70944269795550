import styled from 'styled-components'
import LoginForm from '../components/LoginForm'
import { useQueryParam, StringParam } from 'use-query-params'
import { useTranslation } from 'react-i18next'

import RegisterForm from '../components/RegisterForm'
import ResetPasswordForm from '../components/ResetPasswordForm'
import { Colors } from '../../../styles/colors'
import { StyledList } from '../../../components'
import { SPACES } from '../../../styles/spaces'
import { respondTo } from '../../../styles/breakpoints'

export enum AuthType {
	Register = 'register',
	Login = 'login',
	PasswordRecover = 'passwordRecover',
}

const DESCRIPTION_BASE_KEY = 'auth.description'

const Auth = () => {
	const [authType, setAuthType] = useQueryParam('authType', StringParam)
	const { t } = useTranslation()

	return (
		<Wrapper>
			<WrapperContent>
				<LogoBrand href="/">
					<img
						src="https://seif-ai.ams3.digitaloceanspaces.com/tenants/images/3544491671708062172.png"
						alt="Logo Seif"
					/>
				</LogoBrand>
				<Info>
					<h1>
						{t(`${DESCRIPTION_BASE_KEY}.title`)} <strong>Seif</strong>
					</h1>
					<p>
						{t(`${DESCRIPTION_BASE_KEY}.subtitle1`)}
						<br />
						{t(`${DESCRIPTION_BASE_KEY}.subtitle2`)}
					</p>
					<StyledList>
						<li>{t(`${DESCRIPTION_BASE_KEY}.item1`)}</li>
						<li>{t(`${DESCRIPTION_BASE_KEY}.item2`)}</li>
						<li>{t(`${DESCRIPTION_BASE_KEY}.item3`)}</li>
						<li>{t(`${DESCRIPTION_BASE_KEY}.item4`)}</li>
					</StyledList>
				</Info>
				<Item>
					<img src="/auth-footer.png" alt="Abstract" />
				</Item>
			</WrapperContent>
			<WrapperForm>
				<LogoBrandMob href="/">
					<img
						src="https://seif-ai.ams3.digitaloceanspaces.com/tenants/images/3544491671708062172.png"
						alt="Logo Seif"
					/>
				</LogoBrandMob>
				{(authType === AuthType.Login || !authType) && (
					<LoginForm
						goToRegister={() => setAuthType(AuthType.Register)}
						goToPassRecover={() => setAuthType(AuthType.PasswordRecover)}
					/>
				)}
				{authType === AuthType.Register && (
					<RegisterForm goToLogin={() => setAuthType(AuthType.Login)} />
				)}
				{authType === AuthType.PasswordRecover && (
					<ResetPasswordForm goToLogin={() => setAuthType(AuthType.Login)} />
				)}
			</WrapperForm>
		</Wrapper>
	)
}

const Wrapper = styled.main`
	display: flex;
	justify-content: center;
	${respondTo.xs`
		flex-wrap: wrap;
		flex-direction: column-reverse;
	`}
`

const WrapperContent = styled.div`
	position: relative;
	width: 33.75%;
	min-height: 100vh;
	background-image: linear-gradient(153deg, #fdfdfd 0%, #ebedf4 100%);
	padding: 85px 100px 288px 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	${respondTo.lg`
		padding: 85px 80px 288px 80px;
	`}
	${respondTo.xl`
		padding: 85px 40px 288px 40px;
	`}
	${respondTo.md`
		width: 50%;
	`}
	${respondTo.xs`
		width: 100%;
		padding: 20px 20px 250px 20px;
	`}
	${respondTo.xss`
		padding: 20px 20px 200px 20px;
	`}
`

const WrapperForm = styled.div`
	width: 66.25%;
	display: flex;
	justify-content: center;
	${respondTo.md`
		width: 50%;
	`}
	${respondTo.xs`
		width: 100%;
		display: block;
	`}
	> div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: ${SPACES.EXTRA_LARGE} 0;
		${respondTo.md`
			min-width: 100%;
			padding: 20px;
		`}
		${respondTo.xs`
			padding: 20px 20px 50px 20px;
		`}
		h1 {
			${respondTo.xs`
				font-size: 28px;
			`}
		}
	}
`

const LogoBrand = styled.a`
	display: block;
	margin-bottom: 60px;
	img {
		width: 150px;
	}
	${respondTo.xs`
		display: none;
	`}
`
const LogoBrandMob = styled.a`
	display: none;
	img {
		width: 140px;
	}
	${respondTo.xs`
		display: block;
		padding: 20px;
	`}
`

const Info = styled.div`
	h1 {
		font-size: 24px;
		font-weight: 700;
		line-height: 28px;
		strong {
			color: ${Colors.Blue};
		}
	}
	p {
		max-width: 380px;
	}
	> ul {
		margin: ${SPACES.EXTRA_LARGE} 0 0 0;
	}
`
const Item = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	img {
		width: 100%;
		max-width: 100%;
		height: auto;
	}
`

export default Auth
