import styled from 'styled-components'
import ResetPasswordSaveForm from '../components/ResetPasswordSaveForm'

const ResetPasswordSave = () => {
	return (
		<WrapperMain>
			<ResetPasswordSaveForm />
		</WrapperMain>
	)
}

const WrapperMain = styled.main`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
`

export default ResetPasswordSave
