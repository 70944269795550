import Icon from '@ant-design/icons'

import { Colors } from '../styles/colors'

/* 
How to Icons add 
1 - Go to site svgomg - https://jakearchibald.github.io
2 - Optimize svg icon
3 - Copy svg path and paste bellow in the function of Icon.
4 - Svg path must have viewBox property. Example viewBox="0 0 width height" - viewBox="0 0 1024 1024"
*/

const ExampleIconSvg = () => (
	<svg width="20" height="20" fill="currentColor" viewBox="0 0 1024 1024">
		<path d="M923 283.6c-13.4-31.1-32.6-58.9-56.9-82.8-24.3-23.8-52.5-42.4-84-55.5-32.5-13.5-66.9-20.3-102.4-20.3-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5-24.4 23.9-43.5 51.7-56.9 82.8-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3 0.1-35.3-7-69.6-20.9-101.9z" />
	</svg>
)

const GoogleSvg = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
		<path
			data-name="Path 838"
			d="M139.536 108.675a8.022 8.022 0 0 0-.2-1.914h-8.786v3.474h5.158a4.574 4.574 0 0 1-1.914 3.037l-.018.116 2.779 2.153.193.019a9.152 9.152 0 0 0 2.787-6.885"
			transform="translate(-121.19 -99.106)"
			style={{ fill: '#4285f4' }}
		/>
		<path
			data-name="Path 839"
			d="M22.287 163.879a8.922 8.922 0 0 0 6.2-2.267l-2.954-2.288a5.54 5.54 0 0 1-3.245.936 5.635 5.635 0 0 1-5.325-3.89l-.11.009-2.889 2.236-.038.105a9.353 9.353 0 0 0 8.362 5.159"
			transform="translate(-12.927 -145.159)"
			style={{ fill: '#34a853' }}
		/>
		<path
			data-name="Path 840"
			d="M4.035 77.41a5.762 5.762 0 0 1-.312-1.851 6.055 6.055 0 0 1 .3-1.851l-.005-.124-2.924-2.272-.1.046a9.34 9.34 0 0 0 0 8.4l3.037-2.35"
			transform="translate(0 -66.199)"
			style={{ fill: '#fbbc05' }}
		/>
		<path
			data-name="Path 841"
			d="M22.287 3.619a5.188 5.188 0 0 1 3.619 1.394l2.642-2.579A8.993 8.993 0 0 0 22.287 0a9.353 9.353 0 0 0-8.362 5.158l3.026 2.35a5.658 5.658 0 0 1 5.335-3.89"
			transform="translate(-12.927)"
			style={{ fill: '#eb4335' }}
		/>
	</svg>
)

const MicrosoftAzureSvg = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
		<path data-name="Path 1099" d="M8.4 8.4H0V0h8.4z" style={{ fill: '#f1511b' }} />
		<path
			data-name="Path 1100"
			d="M1320 8.4h-8.4V0h8.4v8.4z"
			transform="translate(-1301.999)"
			style={{ fill: '#80cc28' }}
		/>
		<path
			data-name="Path 1101"
			d="M8.4 1320.5H0v-8.4h8.4z"
			transform="translate(0 -1302.495)"
			style={{ fill: '#00adef' }}
		/>
		<path
			data-name="Path 1102"
			d="M1320 1320.5h-8.4v-8.4h8.4v8.4z"
			transform="translate(-1301.999 -1302.495)"
			style={{ fill: '#fbbc09' }}
		/>
	</svg>
)

const VisibleSvg = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="28.372" height="18.501">
		<path
			d="M27.895 10.435A16.362 16.362 0 0 0 14.237 2.95 16.362 16.362 0 0 0 .579 10.435a3.251 3.251 0 0 0 0 3.53 16.362 16.362 0 0 0 13.658 7.485 16.362 16.362 0 0 0 13.658-7.485 3.174 3.174 0 0 0 0-3.53zm-13.649 3.7a1.848 1.848 0 1 1 1.876-1.848 1.879 1.879 0 0 1-.554 1.341 1.753 1.753 0 0 1-1.322.503zM2.795 12.394a.38.38 0 0 1 0-.388 13.931 13.931 0 0 1 8.093-5.858 7.068 7.068 0 0 0 .107 12.123 13.925 13.925 0 0 1-8.2-5.877zm22.9 0a13.978 13.978 0 0 1-8.182 5.858A7.088 7.088 0 0 0 17.6 6.129a13.982 13.982 0 0 1 8.093 5.84.457.457 0 0 1 0 .444z"
			transform="translate(-.058 -2.95)"
			style={{ fill: '#23c7b6' }}
		/>
	</svg>
)

const PeopleSvg = () => (
	<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
		<path
			style={{ fill: 'currentcolor' }}
			d="M9.67 8.22a2.72 2.72 0 1 0-3.34 0 3.7 3.7 0 0 0-2 3.3.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5 2.72 2.72 0 0 1 5.44 0 .5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5 3.7 3.7 0 0 0-2.1-3.3ZM6.28 6.09A1.72 1.72 0 1 1 8 7.81a1.72 1.72 0 0 1-1.72-1.72Z"
		/>
		<path
			style={{ fill: 'currentcolor' }}
			d="M14.65 7.44a2.11 2.11 0 0 0 .68-1.55 2.14 2.14 0 0 0-4.28 0 2.11 2.11 0 0 0 .68 1.56 2.89 2.89 0 0 0-.8.68.5.5 0 0 0 .78.62 1.88 1.88 0 0 1 3.36 1.16.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5 2.88 2.88 0 0 0-1.42-2.47Zm-2.6-1.55A1.14 1.14 0 1 1 13.19 7a1.14 1.14 0 0 1-1.14-1.11ZM5 8.83a.49.49 0 0 0 .08-.7 2.89 2.89 0 0 0-.8-.68A2.11 2.11 0 0 0 5 5.89a2.14 2.14 0 1 0-3.6 1.56A2.85 2.85 0 0 0-.07 9.91a.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5 1.88 1.88 0 0 1 3.36-1.16.49.49 0 0 0 .71.08ZM1.67 5.89A1.14 1.14 0 1 1 2.81 7a1.14 1.14 0 0 1-1.14-1.11Z"
		/>
	</svg>
)

const AssetsSvg = () => (
	<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
		<path
			style={{ fill: 'currentcolor' }}
			d="M11.8 3.84H.47a.47.47 0 0 0-.47.47v11.22a.47.47 0 0 0 .47.47H11.8a.47.47 0 0 0 .47-.47V4.31a.47.47 0 0 0-.47-.47Zm-.47 11.22H.94V4.78h10.39Z"
		/>
		<path
			style={{ fill: 'currentcolor' }}
			d="M2.37 6.58a.48.48 0 0 0 .47-.48.47.47 0 0 0-.47-.47.47.47 0 0 0-.47.47.48.48 0 0 0 .47.48ZM4.25 6.58a.48.48 0 0 0 .47-.48.47.47 0 0 0-.47-.47.47.47 0 0 0-.47.47.48.48 0 0 0 .47.48ZM6.13 6.58a.48.48 0 0 0 .47-.48.47.47 0 0 0-.47-.47.47.47 0 0 0-.47.47.48.48 0 0 0 .47.48ZM2.36 10.34h3.77a.48.48 0 0 0 .47-.48.47.47 0 0 0-.47-.47H2.36a.47.47 0 0 0-.47.47.48.48 0 0 0 .47.48Z"
		/>
		<path
			style={{ fill: 'currentcolor' }}
			d="M13.61 1.89H2.36a.47.47 0 0 0 0 .94h10.78v10.7a.47.47 0 1 0 .94 0V2.36a.47.47 0 0 0-.47-.47Z"
		/>
		<path
			style={{ fill: 'currentcolor' }}
			d="M15.53 0H4.18a.47.47 0 0 0 0 .94h10.88v10.71a.47.47 0 0 0 .94 0V.48a.47.47 0 0 0-.47-.48ZM2.36 12.2h5.73a.47.47 0 1 0 0-.94H2.36a.47.47 0 0 0 0 .94Z"
		/>
	</svg>
)

const DataLeaksSvg = () => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.75 9.75C7.57843 9.75 8.25 9.07843 8.25 8.25C8.25 7.42157 7.57843 6.75 6.75 6.75C5.92157 6.75 5.25 7.42157 5.25 8.25C5.25 9.07843 5.92157 9.75 6.75 9.75Z"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4.5 13.5C4.5 12.6716 5.50736 12 6.75 12C7.99264 12 9 12.6716 9 13.5"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M13.5 8.25L11.25 8.25" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M13.5 10.5L11.25 10.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M2.25 5.25C2.25 4.42157 2.92157 3.75 3.75 3.75H14.25C15.0784 3.75 15.75 4.42157 15.75 5.25V12.75C15.75 13.5784 15.0784 14.25 14.25 14.25H3.75C2.92157 14.25 2.25 13.5784 2.25 12.75V5.25Z"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

const AssignmentSvg = () => (
	<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
		<path
			style={{ fill: 'currentcolor' }}
			d="M15.55.32H5a.5.5 0 0 0-.5.5v5a.5.5 0 1 0 1 0v-4.5h9.57v11.56h-4.54a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5V.82a.5.5 0 0 0-.48-.5Z"
		/>
		<path
			style={{ fill: 'currentcolor' }}
			d="M8.61 9.71.75 5.17a.5.5 0 0 0-.5 0 .52.52 0 0 0-.25.44v9.07a.52.52 0 0 0 .25.44.57.57 0 0 0 .25.06.51.51 0 0 0 .25-.06l7.86-4.54a.5.5 0 0 0 0-.87ZM1 13.82V6.47l6.36 3.68ZM9.56 3.9h3.57a.5.5 0 0 0 0-1H9.56a.5.5 0 0 0 0 1ZM7.5 3.86a.53.53 0 0 0 .18 0 .71.71 0 0 0 .2 0L8 3.75a.39.39 0 0 0 .1-.16.43.43 0 0 0 0-.19.45.45 0 0 0 0-.2A.39.39 0 0 0 8 3l-.16-.11a.52.52 0 0 0-.51.11.91.91 0 0 0-.11.16.45.45 0 0 0 0 .2.49.49 0 0 0 .15.35.5.5 0 0 0 .13.15ZM13.63 5.61a.5.5 0 0 0-.5-.5H9.56a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5h3.57a.5.5 0 0 0 .5-.5ZM7.33 6a.52.52 0 0 0 .35.14.69.69 0 0 0 .2 0L8 6a.52.52 0 0 0 .14-.36.43.43 0 0 0 0-.19.39.39 0 0 0-.14-.2.51.51 0 0 0-.71 0 .91.91 0 0 0-.11.16.43.43 0 0 0 0 .19.49.49 0 0 0 .15.4ZM12.06 7.87a1.81 1.81 0 1 0 1.81 1.81 1.81 1.81 0 0 0-1.81-1.81Zm0 2.62a.81.81 0 1 1 .81-.81.81.81 0 0 1-.81.81Z"
		/>
	</svg>
)

const SimulationSvg = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
		<rect x="1.39746" y="6.7207" width="13.2054" height="9.90408" rx="1.5" stroke="white" />
		<path d="M14.6029 7.95898L8.00018 12.911L1.39746 7.95898" stroke="white" />
		<path
			d="M8.00026 2.59399H7.50026C7.50026 2.87013 7.72412 3.09399 8.00026 3.09399V2.59399ZM10.2593 6.26141C10.1693 6.52248 10.308 6.80707 10.5691 6.89704C10.8302 6.98702 11.1147 6.84832 11.2047 6.58724L10.2593 6.26141ZM5.2312 4.65734L5.58475 4.30379C5.46017 4.17921 5.27922 4.12939 5.10843 4.17265C4.93764 4.2159 4.80223 4.34585 4.75197 4.51472L5.2312 4.65734ZM8.00026 7.87137C6.68103 7.87137 5.61157 6.80192 5.61157 5.48268H4.61157C4.61157 7.3542 6.12874 8.87137 8.00026 8.87137V7.87137ZM8.00026 3.09399C9.3195 3.09399 10.389 4.16344 10.389 5.48268H11.389C11.389 3.61116 9.87179 2.09399 8.00026 2.09399V3.09399ZM10.389 5.48268C10.389 5.7562 10.3432 6.01798 10.2593 6.26141L11.2047 6.58724C11.3243 6.24038 11.389 5.86856 11.389 5.48268H10.389ZM5.61157 5.48268C5.61157 5.24469 5.64622 5.01568 5.71042 4.79997L4.75197 4.51472C4.66051 4.82203 4.61157 5.14708 4.61157 5.48268H5.61157ZM8.50026 2.59399V0.47168H7.50026V2.59399H8.50026ZM4.87764 5.0109L5.99603 6.12928L6.70314 5.42217L5.58475 4.30379L4.87764 5.0109Z"
			fill="white"
		/>
		<circle cx="8.00015" cy="0.943262" r="0.943262" fill="white" />
	</svg>
)

const OverviewSvg = () => (
	<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
		<path
			style={{ fill: 'currentcolor' }}
			d="M14.4 10.16A6.8 6.8 0 0 0 8 5.73a6.89 6.89 0 0 0-6.4 4.43l-.07.18.07.18A6.89 6.89 0 0 0 8 15a6.8 6.8 0 0 0 6.4-4.43l.07-.18ZM8 7.29a2.39 2.39 0 1 1-2.39 2.38A2.39 2.39 0 0 1 8 7.29ZM8 14a5.8 5.8 0 0 1-5.39-3.61 5.84 5.84 0 0 1 3.07-3.18 3.39 3.39 0 1 0 4.64 0 5.84 5.84 0 0 1 3.07 3.13A5.8 5.8 0 0 1 8 14ZM8 4a.5.5 0 0 0 .5-.5V1.55a.5.5 0 0 0-1 0v1.92A.5.5 0 0 0 8 4ZM12.93 5.24a.51.51 0 0 0 .36-.15l.4-.4A.5.5 0 1 0 13 4l-.4.41a.5.5 0 0 0 .35.85ZM2.91 5.29a.49.49 0 0 0 .35.15.51.51 0 0 0 .36-.15.51.51 0 0 0 0-.71L3 4a.5.5 0 0 0-.71 0 .5.5 0 0 0 0 .7Z"
		/>
	</svg>
)

const StatisticSvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="15.927"
		height="15.927"
		viewBox="0 0 15.927 15.927"
	>
		<g data-name="Group 5380" transform="translate(-229.085 108.984)">
			<path
				data-name="Line 2930"
				transform="translate(242.543 -98.869)"
				style={{
					strokeMiterlimit: 10,
					fill: 'none',
					stroke: '#fff',
					strokeLinecap: 'round',
				}}
				d="M0 0h0"
			/>
			<path
				data-name="Line 2931"
				transform="translate(238.548 -101.916)"
				style={{
					strokeMiterlimit: 10,
					fill: 'none',
					stroke: '#fff',
					strokeLinecap: 'round',
				}}
				d="M0 0h0"
			/>
			<path
				data-name="Line 2932"
				transform="translate(232.82 -99.817)"
				style={{
					strokeMiterlimit: 10,
					fill: 'none',
					stroke: '#fff',
					strokeLinecap: 'round',
				}}
				d="M0 0h0"
			/>
			<circle
				data-name="Ellipse 1830"
				cx="1.311"
				cy="1.311"
				r="1.311"
				transform="translate(236.471 -98.477)"
				style={{
					strokeLinejoin: 'round',
					fill: 'none',
					stroke: '#fff',
					strokeLinecap: 'round',
				}}
			/>
			<circle
				data-name="Ellipse 1831"
				cx="1.133"
				cy="1.133"
				r="1.133"
				transform="translate(239.943 -106.29)"
				style={{
					strokeLinejoin: 'round',
					fill: 'none',
					stroke: '#fff',
					strokeLinecap: 'round',
				}}
			/>
			<circle
				data-name="Ellipse 1832"
				cx=".927"
				cy=".927"
				r=".927"
				transform="translate(233.335 -103.962)"
				style={{
					strokeLinejoin: 'round',
					fill: 'none',
					stroke: '#fff',
					strokeLinecap: 'round',
				}}
			/>
			<path
				data-name="Path 1460"
				d="M229.585-108.484v14.927h14.927"
				style={{
					strokeLinejoin: 'round',
					fill: 'none',
					stroke: '#fff',
					strokeLinecap: 'round',
				}}
			/>
		</g>
	</svg>
)

const DomainSvg = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
			stroke="#808FAD"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M2 12H22"
			stroke="#808FAD"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z"
			stroke="#808FAD"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

const CopySvg = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
		<path
			d="M20 9h-9a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-9a2 2 0 0 0-2-2Z"
			stroke="#111"
			stroke-width="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"
			stroke="#111"
			stroke-width="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

const NotificationSvg = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" fill="none">
		<g clipPath="url(#a)">
			<path
				d="M5.993 16a1.125 1.125 0 0 1-1.123-1.125v-.751H.562a.562.562 0 1 1 0-1.124H1V6a4.991 4.991 0 0 1 4.431-4.969V.563a.562.562 0 1 1 1.124 0v.469A4.992 4.992 0 0 1 10.988 6v7h.437a.562.562 0 1 1 0 1.124H7.117v.751A1.126 1.126 0 0 1 5.993 16Zm0-13.875A3.877 3.877 0 0 0 2.123 6v7h7.741V6a3.878 3.878 0 0 0-3.871-3.875Z"
				fill="#808FAD"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h11.987v16H0z" />
			</clipPath>
		</defs>
	</svg>
)

const DragAndDropSvg = () => (
	<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M15.2 12.5H3.80005M15.2 7.5H3.80005"
			stroke="#808FAD"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M9.15359 2.6C9.30755 2.33333 9.69245 2.33333 9.84641 2.6L10.8856 4.4C11.0396 4.66667 10.8472 5 10.5392 5H8.46077C8.15285 5 7.9604 4.66667 8.11436 4.4L9.15359 2.6Z"
			fill="#808FAD"
		/>
		<path
			d="M9.84641 17.4C9.69245 17.6667 9.30755 17.6667 9.15359 17.4L8.11436 15.6C7.9604 15.3333 8.15285 15 8.46077 15L10.5392 15C10.8472 15 11.0396 15.3333 10.8856 15.6L9.84641 17.4Z"
			fill="#808FAD"
		/>
	</svg>
)

const NanoLearnSvg = () => (
	<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.3503 9.91699C3.83639 9.07277 3.54077 8.08365 3.54077 7.02618C3.54077 3.92831 6.07782 1.41699 9.20744 1.41699C12.3371 1.41699 14.8741 3.92831 14.8741 7.02618C14.8741 8.08365 14.5785 9.07277 14.0646 9.91699"
			stroke="url(#paint0_linear_5934_2935)"
			strokeLinecap="round"
		/>
		<path
			d="M5.93689 12.1119C5.87182 11.916 5.83928 11.818 5.84307 11.7386C5.85109 11.5708 5.95691 11.4234 6.11305 11.3627C6.18687 11.334 6.28977 11.334 6.49555 11.334H11.9192C12.1249 11.334 12.2278 11.334 12.3017 11.3627C12.4578 11.4234 12.5636 11.5708 12.5716 11.7386C12.5754 11.818 12.5429 11.916 12.4778 12.1119C12.3576 12.474 12.2974 12.6551 12.2047 12.8017C12.0104 13.1088 11.7058 13.3292 11.354 13.417C11.1861 13.459 10.9959 13.459 10.6156 13.459H7.79909C7.41878 13.459 7.22863 13.459 7.06071 13.417C6.70894 13.3292 6.40435 13.1088 6.21004 12.8017C6.11729 12.6551 6.05716 12.474 5.93689 12.1119Z"
			stroke="url(#paint1_linear_5934_2935)"
		/>
		<path
			d="M11.3325 13.459L11.2409 13.917C11.1408 14.4178 11.0907 14.6682 10.9784 14.8661C10.8049 15.1721 10.5238 15.4026 10.1897 15.5127C9.97364 15.584 9.71827 15.584 9.20752 15.584C8.69677 15.584 8.4414 15.584 8.22533 15.5127C7.89126 15.4026 7.61011 15.1721 7.4366 14.8661C7.32437 14.6682 7.27429 14.4178 7.17412 13.917L7.08252 13.459"
			stroke="url(#paint2_linear_5934_2935)"
		/>
		<path
			d="M6.70752 6.43612H6.80864C7.31574 6.43612 7.56929 6.43612 7.76287 6.54063C7.95645 6.64514 8.04347 6.82902 8.21752 7.19677L8.42782 7.64111C8.5422 7.88278 8.59938 8.00361 8.69166 7.99986C8.78393 7.9961 8.82434 7.87129 8.90517 7.62168L9.62829 5.38839C9.71145 5.13156 9.75303 5.00314 9.84622 5C9.93941 4.99686 9.99533 5.12199 10.1072 5.37226L10.2338 5.65568C10.4022 6.03258 10.4864 6.22104 10.6818 6.32858C10.8772 6.43612 11.1354 6.43612 11.6518 6.43612H11.7075"
			stroke="url(#paint3_linear_5934_2935)"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_5934_2935"
				x1="1.70752"
				y1="0.5"
				x2="15.7075"
				y2="10"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#F39CAE" />
				<stop offset="1" stopColor="#C867E0" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_5934_2935"
				x1="4.70752"
				y1="9"
				x2="13.2075"
				y2="13.5"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#F39CAE" />
				<stop offset="1" stopColor="#C867E0" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_5934_2935"
				x1="6.70752"
				y1="13.5"
				x2="11.7075"
				y2="16"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#F39CAE" />
				<stop offset="1" stopColor="#C867E0" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_5934_2935"
				x1="6.70752"
				y1="6.5"
				x2="11.7075"
				y2="6.5"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#F39CAE" />
				<stop offset="1" stopColor="#C867E0" />
			</linearGradient>
		</defs>
	</svg>
)

const AdministrationMenuSvg = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="12" cy="12" r="3" stroke="#808FAD" stroke-width="2" />
		<path
			d="M5.05816 5.56344C4.08048 6.61721 3.37055 7.86775 2.95718 9.20753L4.79366 10.2678C6.127 11.0376 6.127 12.9621 4.79366 13.7319L2.95538 14.7933C3.16066 15.4558 3.44244 16.1059 3.80386 16.7319C4.16528 17.3579 4.5874 17.927 5.05851 18.436L6.89683 17.3747C8.23016 16.6049 9.89683 17.5671 9.89683 19.1067V21.2274C11.2638 21.5393 12.7018 21.5497 14.1032 21.2299L14.1032 19.1068C14.1032 17.5672 15.7699 16.6049 17.1032 17.3747L18.9419 18.4363C19.9196 17.3825 20.6295 16.132 21.0429 14.7922L19.2063 13.7319C17.873 12.9621 17.873 11.0376 19.2063 10.2678L21.0446 9.20641C20.8394 8.54391 20.5576 7.8938 20.1962 7.26781C19.8348 6.64181 19.4126 6.07271 18.9415 5.56368L17.1032 6.62502C15.7699 7.39482 14.1032 6.43257 14.1032 4.89297V2.77237C12.7362 2.46046 11.2983 2.45002 9.89683 2.76983L9.89683 4.89294C9.89683 6.43254 8.23016 7.39479 6.89683 6.62499L5.05816 5.56344Z"
			stroke="#808FAD"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
	</svg>
)

const PlatformMenuSvg = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4 8L20 8"
			stroke="#B651F6"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8 3V8"
			stroke="#B651F6"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
			stroke="#B651F6"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

const MenuChangerSvg = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14 15C14 14.4477 14.4477 14 15 14H19C19.5523 14 20 14.4477 20 15V19C20 19.5523 19.5523 20 19 20H15C14.4477 20 14 19.5523 14 19V15Z"
			stroke="#00206D"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4 5C4 4.44772 4.44772 4 5 4H9C9.55228 4 10 4.44772 10 5V9C10 9.55228 9.55228 10 9 10H5C4.44772 10 4 9.55228 4 9V5Z"
			stroke="#00206D"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14 5C14 4.44772 14.4477 4 15 4H19C19.5523 4 20 4.44772 20 5V9C20 9.55228 19.5523 10 19 10H15C14.4477 10 14 9.55228 14 9V5Z"
			stroke="#00206D"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4 15C4 14.4477 4.44772 14 5 14H9C9.55228 14 10 14.4477 10 15V19C10 19.5523 9.55228 20 9 20H5C4.44772 20 4 19.5523 4 19V15Z"
			stroke="#00206D"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

const AverageCourseScoreSvg = (color: Colors) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.16953 2.30586C7.64808 1.89805 8.35192 1.89805 8.83047 2.30586L9.64445 2.99953C9.84952 3.17429 10.1045 3.2799 10.3731 3.30133L11.4391 3.3864C12.0659 3.43642 12.5636 3.93411 12.6136 4.56087L12.6987 5.62693C12.7201 5.89551 12.8257 6.15048 13.0005 6.35555L13.6941 7.16953C14.102 7.64808 14.102 8.35192 13.6941 8.83047L13.0005 9.64445C12.8257 9.84952 12.7201 10.1045 12.6987 10.3731L12.6136 11.4391C12.5636 12.0659 12.0659 12.5636 11.4391 12.6136L10.3731 12.6987C10.1045 12.7201 9.84952 12.8257 9.64445 13.0005L8.83047 13.6941C8.35192 14.102 7.64808 14.102 7.16953 13.6941L6.35555 13.0005C6.15048 12.8257 5.89551 12.7201 5.62693 12.6987L4.56087 12.6136C3.93411 12.5636 3.43642 12.0659 3.3864 11.4391L3.30133 10.3731C3.2799 10.1045 3.17429 9.84952 2.99953 9.64445L2.30586 8.83047C1.89805 8.35192 1.89805 7.64808 2.30586 7.16953L2.99953 6.35555C3.17429 6.15048 3.2799 5.89551 3.30133 5.62693L3.3864 4.56087C3.43642 3.93411 3.93411 3.43642 4.56087 3.3864L5.62693 3.30133C5.89551 3.2799 6.15048 3.17429 6.35555 2.99953L7.16953 2.30586Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9 10.6666C9 11.2189 8.55228 11.6666 8 11.6666C7.44772 11.6666 7 11.2189 7 10.6666C7 10.1143 7.44772 9.66663 8 9.66663C8.55228 9.66663 9 10.1143 9 10.6666Z"
			fill={color}
		/>
		<path
			d="M8 8L8 5.33333"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

const AverageIndustryScoreSvg = (color: Colors) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.6667 2H3.33333C2.96514 2 2.66667 2.29848 2.66667 2.66667V6.77657C2.66667 11.6976 6.84944 13.5677 7.80654 13.9312C7.93336 13.9794 8.06665 13.9794 8.19346 13.9312C9.15057 13.5677 13.3333 11.6976 13.3333 6.77657V2.66667C13.3333 2.29848 13.0349 2 12.6667 2Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9 9.99994C9 10.5522 8.55228 10.9999 8 10.9999C7.44772 10.9999 7 10.5522 7 9.99994C7 9.44765 7.44772 8.99994 8 8.99994C8.55228 8.99994 9 9.44765 9 9.99994Z"
			fill={color}
		/>
		<path
			d="M8 7.33331L8 4.66665"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

const ShieldError = () => (
	<svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.6667 1H2.33333C1.96514 1 1.66666 1.29848 1.66666 1.66667V5.77657C1.66666 10.6976 5.84943 12.5677 6.80654 12.9312C6.93335 12.9794 7.06664 12.9794 7.19346 12.9312C8.15056 12.5677 12.3333 10.6976 12.3333 5.77657V1.66667C12.3333 1.29848 12.0349 1 11.6667 1Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8 8.99994C8 9.55222 7.55228 9.99994 7 9.99994C6.44772 9.99994 6 9.55222 6 8.99994C6 8.44765 6.44772 7.99994 7 7.99994C7.55228 7.99994 8 8.44765 8 8.99994Z"
			fill="currentColor"
		/>
		<path
			d="M7 6.33331L7 3.66665"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

const ShieldWarning = () => (
	<svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.6667 1H2.33334C1.96515 1 1.66667 1.29848 1.66667 1.66667V5.77657C1.66667 10.6976 5.84944 12.5677 6.80654 12.9312C6.93336 12.9794 7.06665 12.9794 7.19347 12.9312C8.15057 12.5677 12.3333 10.6976 12.3333 5.77657V1.66667C12.3333 1.29848 12.0349 1 11.6667 1Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4.99998 6.33331H8.99998"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

const ShieldSuccess = () => (
	<svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.6667 1H2.33334C1.96515 1 1.66667 1.29848 1.66667 1.66667V5.77657C1.66667 10.6976 5.84944 12.5677 6.80654 12.9312C6.93336 12.9794 7.06665 12.9794 7.19347 12.9312C8.15057 12.5677 12.3333 10.6976 12.3333 5.77657V1.66667C12.3333 1.29848 12.0349 1 11.6667 1Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.66666 4.33331L6.11111 8.33331L4.33333 6.33331"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

const Stopwatch = () => (
	<svg width="1em" height="1em" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.0003 4.58333L14.8337 3.75M3.16699 3.75L4.00033 4.58333"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<ellipse
			cx="9"
			cy="10.834"
			rx="7.5"
			ry="7.5"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M9 7.91699V11.2503L10.6667 12.917"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9 2.91699V1.66699"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.33301 1.66699H10.6663"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

const Languages = () => (
	<svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_5817_40180)">
			<path
				d="M1.66699 10C1.66699 10.8766 1.81738 11.7181 2.09376 12.5M10.845 7.5H17.9183M9.16699 12.5H2.09376M17.9183 7.5C16.8887 4.58702 14.1106 2.5 10.845 2.5C12.1791 2.5 13.2692 5.635 13.3412 9.58333M17.9183 7.5C18.1506 8.15723 18.2939 8.8565 18.3337 9.58333M2.09376 12.5C3.12335 15.413 5.90145 17.5 9.16699 17.5C7.88017 17.5 6.82043 14.5833 6.68225 10.8333"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.66699 4.41478C1.66699 3.49947 1.66699 3.04182 1.82315 2.6869C1.98874 2.31051 2.27417 2.008 2.62929 1.83249C2.96417 1.66699 3.39597 1.66699 4.25959 1.66699H5.00033C6.57167 1.66699 7.35735 1.66699 7.8455 2.18437C8.33366 2.70174 8.33366 3.53445 8.33366 5.19986V7.08242C8.33366 7.80887 8.33366 8.1721 8.1136 8.29692C7.89354 8.42174 7.60839 8.22026 7.03808 7.8173L6.95119 7.7559C6.53438 7.4614 6.32598 7.31415 6.08978 7.23835C5.85359 7.16256 5.60312 7.16256 5.10218 7.16256H4.25959C3.39597 7.16256 2.96417 7.16256 2.62929 6.99706C2.27417 6.82155 1.98874 6.51904 1.82315 6.14265C1.66699 5.78774 1.66699 5.33008 1.66699 4.41478Z"
				stroke="currentColor"
				strokeWidth="1.5"
			/>
			<path
				d="M18.3337 14.4148C18.3337 13.4995 18.3337 13.0418 18.1775 12.6869C18.0119 12.3105 17.7265 12.008 17.3714 11.8325C17.0365 11.667 16.6047 11.667 15.7411 11.667H15.0003C13.429 11.667 12.6433 11.667 12.1551 12.1844C11.667 12.7017 11.667 13.5344 11.667 15.1999V17.0824C11.667 17.8089 11.667 18.1721 11.8871 18.2969C12.1071 18.4217 12.3923 18.2203 12.9626 17.8173L13.0495 17.7559C13.4663 17.4614 13.6747 17.3141 13.9109 17.2384C14.1471 17.1626 14.3975 17.1626 14.8985 17.1626H15.7411C16.6047 17.1626 17.0365 17.1626 17.3714 16.9971C17.7265 16.8215 18.0119 16.519 18.1775 16.1427C18.3337 15.7877 18.3337 15.3301 18.3337 14.4148Z"
				stroke="currentColor"
				strokeWidth="1.5"
			/>
		</g>
		<defs>
			<clipPath id="clip0_5817_40180">
				<rect width="20" height="20" fill="white" />
			</clipPath>
		</defs>
	</svg>
)

export const ExampleIcon = ({ ...props }) => {
	return <Icon component={ExampleIconSvg} {...props} />
}

export const GoogleIcon = ({ ...props }) => {
	return <Icon component={GoogleSvg} {...props} />
}

export const MicrosoftAzureIcon = ({ ...props }) => {
	return <Icon component={MicrosoftAzureSvg} {...props} />
}

export const VisibleIcon = ({ ...props }) => {
	return <Icon component={VisibleSvg} {...props} />
}

export const PeopleIcon = ({ ...props }) => <Icon component={PeopleSvg} {...props} />

export const AssetsIcon = ({ ...props }) => <Icon component={AssetsSvg} {...props} />

export const DataLeaksIcon = ({ ...props }) => <Icon component={DataLeaksSvg} {...props} />

export const AssignmentsIcon = ({ ...props }) => <Icon component={AssignmentSvg} {...props} />

export const SimulationIcon = ({ ...props }) => <Icon component={SimulationSvg} {...props} />

export const OverviewIcon = ({ ...props }) => <Icon component={OverviewSvg} {...props} />

export const StatisticIcon = ({ ...props }) => <Icon component={StatisticSvg} {...props} />

export const DomainIcon = ({ ...props }) => <Icon component={DomainSvg} {...props} />

export const CopyIcon = ({ ...props }) => <Icon component={CopySvg} {...props} />

export const NotificationIcon = ({ ...props }) => <Icon component={NotificationSvg} {...props} />

export const DragAndDropIcon = ({ ...props }) => <Icon component={DragAndDropSvg} {...props} />

export const NanoLearnIcon = ({ ...props }) => <Icon component={NanoLearnSvg} {...props} />

export const MenuChangerIcon = ({ ...props }) => <Icon component={MenuChangerSvg} {...props} />

export const PlatformMenuIcon = ({ ...props }) => <Icon component={PlatformMenuSvg} {...props} />

export const AdministrationMenuIcon = ({ ...props }) => (
	<Icon component={AdministrationMenuSvg} {...props} />
)

export const AverageCourseScoreIcon = ({ ...props }) => (
	<Icon component={() => AverageCourseScoreSvg(props.color)} {...props} />
)

export const AverageIndustryScoreIcon = ({ ...props }) => (
	<Icon component={() => AverageIndustryScoreSvg(props.color)} {...props} />
)

export const ShieldErrorIcon = ({ ...props }) => <Icon component={ShieldError} {...props} />

export const ShieldWarningIcon = ({ ...props }) => <Icon component={ShieldWarning} {...props} />

export const ShieldSuccessIcon = ({ ...props }) => <Icon component={ShieldSuccess} {...props} />

export const StopwatchIcon = ({ ...props }) => <Icon component={Stopwatch} {...props} />

export const LanguagesIcon = ({ ...props }) => <Icon component={Languages} {...props} />
