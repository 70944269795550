const sr = {
	phishingExport: {
		title: 'Phishing pdf izvestaj - {{simulation}}',
		overview: {
			overview: 'Pregled',
			templates: 'Predlozi',
			simulationFunnel: 'Simulacija levka',
		},
		targets: {
			title: 'Ciljevi',
			failedSimulations: 'Neuspešne simulacije',
			failedDesc:
				'Ljudi koji nisu uspeli adekvatno da reaguju na e-poštu sa zlonamernim linkovima ili unosom osetljivih podataka.',
			reported: 'Prijavljivanje phishing-a',
			reportedDesc: 'Ljudi koji su uspešno prepoznali i prijavili phishing e-poruke iz simulacije.',
			notReportedDesc:
				'Ljudi koji nisu prijavili phishing e-poruke iz simulacije nakon što su se susreli s njima.',
			notReported: 'Nije prijavljeno',
			table: {
				firstName: 'Ime',
				lastName: 'Prezime',
				simulationsSent: 'Poslate simulacije',
				simulationsFailed: 'Neuspešne simulacije',
				simulationsPassed: 'Uspesne simulacije',
			},
		},
	},
	courseOverviewExport: {
		title: 'Pdf izvestaj zadatka - {{courseName}}',
		overview: 'Pregled',
		lessons: 'Lekcije',
		learners: 'Polaznici',
		nonCompleted: 'Polaznici koji nisu zavrsili kurs',
		nonCompletedDescription: `Polaznici koji su pozvani na kurs, ali ga nisu završili u određenom roku.`,
		passed: 'Polaznici koji su prošli kurs',
		passedDescription: 'Polaznici koji su uspešno završili i položili kurs.',
		failed: 'Polaznici koji su pali kurs',
		failedDescription: `Polaznici koji su pozvani na kurs, ali ga nisu uspešno završili.`,
	},
	exportLanguageSelectionModal: {
		title: 'Izvezi u pdf',
		emailLanguageSelectLabel: 'Jezik korišćen u pdf-u',
		emailLanguageSelectPlaceholder: 'Izaberi jezik koji će biti korišćen u pdf-u',
		emailLanguageOptions: {
			en: 'Engleski',
			sr: 'Srpski',
		},
	},
}

export default sr
