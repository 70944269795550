const en = {
	common: {
		title: 'Extension reports',
		properties: {
			fullName: { title: 'Full name' },
			email: { title: 'Email' },
			createdAt: { title: 'Reported at' },
		},
		actions: {
			previewContent: 'Preview content',
			previewHTML: 'Preview HTML',
		},
		modal: {
			previewContent: {
				title: 'Preview content',
			},
			previewHTML: {
				title: 'Preview HTML',
			},
		},
	},
}

export default en
