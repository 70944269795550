const en = {
	libraryPage: {
		title: 'Library',
		subtitle:
			'Here you can browse between different educational formats, each with its distinct purpose of enhancing the information security awareness of your learners. You can use our pre-arranged content, or create your own. ',
	},
	cards: {
		lessons: {
			title: 'Lessons',
			description:
				'Use ready-made educational content and apply it to quickly train learners on the basics of information security. ',
			btnLabel: 'Manage lessons',
			featureDisabledTooltip: `This feature is not enabled for your organization. To launch your first Security Awareness Course, please contact Seif sales team!`,
		},
		nanoPages: {
			title: 'Nano Pages',
			description:
				'Browse hundreds of nano-learning materials and use them to help learners stay alert and remember important lessons about information security.',
			btnLabel: 'Manage Nano Pages',
			featureDisabledTooltip:
				'This feature is not enabled for your organization. To launch your first Nano Learning Campaign, please contact Seif sales team!',
		},
		phishingTemplates: {
			title: 'Phishing Templates',
			description:
				'Discover phishing email templates to suit your needs. Use them to test the ability of your learners to identify and avoid phishing threats.',
			btnLabel: 'Manage Phishing Templates',
			featureDisabledTooltip: `This feature is not enabled for your organization. To launch your first Security Phishing Simulations, please contact Seif sales team!`,
		},
		customContent: {
			title: 'Custom Content',
			description: `Choose between Lessons, Nano Pages, and Phishing Templates to build your own company-specific content.`,
			btnLabel: 'Manage Custom Content',
			featureDisabledTooltip: 'This feature is not enabled for your organization.',
		},
	},
	customModal: {
		title: 'Custom Content',
		subtitle: 'Select custom content type:',
		radioGroup: {
			radio1: 'Lessons',
			radio2: 'Nano Pages',
			radio3: 'Phishing Templates',
		},
	},
}

export default en
