import { Editor } from '@tiptap/react'
import { rgba } from 'polished'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import { useState } from 'react'
import { MenuOutlined, SendOutlined } from '@ant-design/icons'

import { Colors } from '../styles/colors'
import { SPACES } from '../styles/spaces'
import Modal from './Modal'
import Button from './Button'
import AddLinkForm from './AddLinkForm'
import { EditorToolbarPosition } from './Editor'
import { useToggleVisibility } from '../hooks'
import AddImageForm, { IUploadResponse } from './AddImageForm'
import { ReactComponent as Bold } from '../assets/bold.svg'
import { ReactComponent as Italic } from '../assets/italic.svg'
import { ReactComponent as Underline } from '../assets/underline.svg'
import { ReactComponent as Numbered } from '../assets/numbered.svg'
import { ReactComponent as Bullets } from '../assets/bullets.svg'
import { ReactComponent as Divider } from '../assets/divider.svg'
import { ReactComponent as Link } from '../assets/link.svg'
import { ReactComponent as Image } from '../assets/image.svg'
import { ReactComponent as Code } from '../assets/code.svg'
import { ReactComponent as Unlink } from '../assets/unlink.svg'

export enum ToolbarOptions {
	HeadingOne = 'HeadingOne',
	HeadingTwo = 'HeadingTwo',
	HeadingThree = 'HeadingThree',
	Bold = 'Bold',
	Italic = 'Italic',
	Underline = 'Underline',
	BulletList = 'BulletList',
	OrderedList = 'OrderedList',
	Image = 'Image',
	HorizontalRule = 'HorizontalRule',
	Link = 'Link',
	CodeBlock = 'CodeBlock',
	ClearFormatting = 'ClearFormatting',
}

interface IProps {
	onSubmit?(editor: Editor): void
	isSubmitting?: boolean
	withSubmitButton: boolean
	editor: Editor | null
	position: EditorToolbarPosition
	uploadImageUrl?: string
	optionsHidden?: string[]
}

const EditorToolbar: React.FC<IProps> = ({
	editor,
	position,
	withSubmitButton,
	onSubmit,
	isSubmitting,
	uploadImageUrl,
	optionsHidden,
}) => {
	const [createEditLink, setCreateEditLink] = useState<string | null>(null)
	const {
		isVisible: isImageModalVisible,
		show: showImageModal,
		hide: hideImageModal,
	} = useToggleVisibility()

	const openEditorSetter = () => {
		if (!editor) return

		const previousUrl = editor.getAttributes('link').href
		const url = previousUrl || ''
		setCreateEditLink(url)
	}

	const saveLink = (link: string) => {
		setCreateEditLink(null)

		if (!editor) return

		editor.chain().focus().extendMarkRange('link').setLink({ href: link }).run()
	}

	const handleAddImages = (imagesUrlData: IUploadResponse[]) => {
		if (!editor) return

		const imagesHtml = imagesUrlData
			.map((imageData) => `<img src="${imageData.src}" alt="${imageData.title || ''}">`)
			.join('')

		editor.chain().focus().insertContent(imagesHtml).run()

		hideImageModal()
	}

	if (!editor) return null

	return (
		<Toolbar position={position}>
			<LeftPart>
				<Tooltip placement="top" title="Heading 1">
					<EditorButton
						type="button"
						isActive={editor.isActive('heading', { level: 1 })}
						onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
						disabled={optionsHidden?.includes(ToolbarOptions.HeadingOne)}
					>
						<Header>H1</Header>
					</EditorButton>
				</Tooltip>
				<Tooltip placement="top" title="Heading 2">
					<EditorButton
						type="button"
						isActive={editor.isActive('heading', { level: 2 })}
						onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
						disabled={optionsHidden?.includes(ToolbarOptions.HeadingTwo)}
					>
						<Header>H2</Header>
					</EditorButton>
				</Tooltip>
				<Tooltip placement="top" title="Heading 3">
					<EditorButton
						type="button"
						isActive={editor.isActive('heading', { level: 3 })}
						onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
						disabled={optionsHidden?.includes(ToolbarOptions.HeadingThree)}
					>
						<Header>H3</Header>
					</EditorButton>
				</Tooltip>
				<Tooltip placement="top" title="Bold">
					<EditorButton
						type="button"
						isActive={editor.isActive('bold')}
						onClick={() => editor.chain().focus().toggleBold().run()}
						disabled={optionsHidden?.includes(ToolbarOptions.Bold)}
					>
						<Bold />
					</EditorButton>
				</Tooltip>
				<Tooltip placement="top" title="Italic">
					<EditorButton
						type="button"
						isActive={editor.isActive('italic')}
						onClick={() => editor.chain().focus().toggleItalic().run()}
						disabled={optionsHidden?.includes(ToolbarOptions.Italic)}
					>
						<Italic />
					</EditorButton>
				</Tooltip>
				<Tooltip placement="top" title="Underline">
					<EditorButton
						type="button"
						isActive={editor.isActive('underline')}
						onClick={() => editor.commands.toggleUnderline()}
						disabled={optionsHidden?.includes(ToolbarOptions.Underline)}
					>
						<Underline />
					</EditorButton>
				</Tooltip>
				<Tooltip placement="top" title="Bullet list">
					<EditorButton
						type="button"
						isActive={editor.isActive('bulletList')}
						onClick={() => editor.chain().focus().toggleBulletList().run()}
						disabled={optionsHidden?.includes(ToolbarOptions.BulletList)}
					>
						<Bullets />
					</EditorButton>
				</Tooltip>
				<Tooltip placement="top" title="Ordered list">
					<EditorButton
						type="button"
						isActive={editor.isActive('orderedList')}
						onClick={() => editor.chain().focus().toggleOrderedList().run()}
						disabled={optionsHidden?.includes(ToolbarOptions.OrderedList)}
					>
						<Numbered />
					</EditorButton>
				</Tooltip>
				<Tooltip placement="top" title="Image">
					<EditorButton type="button" isActive={false} onClick={showImageModal}>
						<Image />
					</EditorButton>
				</Tooltip>
				<Tooltip placement="top" title="Horizontal rule">
					<EditorButton
						type="button"
						isActive={false}
						onClick={() => editor.chain().focus().setHorizontalRule().run()}
						disabled={optionsHidden?.includes(ToolbarOptions.HorizontalRule)}
					>
						<Divider />
					</EditorButton>
				</Tooltip>
				<Tooltip placement="top" title="Set link">
					<EditorButton
						type="button"
						isActive={false}
						onClick={openEditorSetter}
						disabled={optionsHidden?.includes(ToolbarOptions.Link)}
					>
						<Link />
					</EditorButton>
				</Tooltip>
				<Tooltip placement="top" title="Unset link">
					<EditorButton
						type="button"
						isActive={false}
						onClick={() => editor.chain().focus().extendMarkRange('link').unsetLink().run()}
						disabled={optionsHidden?.includes(ToolbarOptions.Link)}
					>
						<Unlink />
					</EditorButton>
				</Tooltip>
				<Tooltip placement="top" title="Code block">
					<EditorButton
						type="button"
						isActive={editor.isActive('codeBlock')}
						onClick={() => editor.chain().focus().toggleCodeBlock().run()}
						disabled={optionsHidden?.includes(ToolbarOptions.CodeBlock)}
					>
						<Code />
					</EditorButton>
				</Tooltip>
				<Tooltip placement="top" title="Insert Custom Block">
					<EditorButton
						type="button"
						isActive={editor.isActive('customBlock')}
						onClick={() => {
							editor
								.chain()
								.focus()
								.insertContent({
									type: 'customBlock',
									content: [
										{
											type: 'heading',
											attrs: { level: 3 },
											content: [{ type: 'text', text: 'This is a heading' }],
										},
										{
											type: 'bulletList',
											content: [
												{
													type: 'listItem',
													content: [
														{
															type: 'paragraph',
															content: [{ type: 'text', text: 'First bullet point' }],
														},
													],
												},
												{
													type: 'listItem',
													content: [
														{
															type: 'paragraph',
															content: [{ type: 'text', text: 'Second bullet point' }],
														},
													],
												},
											],
										},
									],
								})
								.run()
						}}
					>
						<MenuOutlined />
					</EditorButton>
				</Tooltip>
				<Tooltip placement="top" title="Clear formatting">
					<ClearAllButton
						type="button"
						isActive={false}
						onClick={() => editor.chain().focus().clearNodes().unsetAllMarks().run()}
						disabled={optionsHidden?.includes(ToolbarOptions.ClearFormatting)}
					>
						Clear formatting
					</ClearAllButton>
				</Tooltip>
			</LeftPart>
			{withSubmitButton && (
				<RightPart>
					<SubmitButton
						htmlType="button"
						type="primary"
						size="small"
						onClick={() => {
							onSubmit && onSubmit(editor)
						}}
						loading={isSubmitting}
					>
						<SendOutlined />
					</SubmitButton>
				</RightPart>
			)}
			<Modal
				open={createEditLink !== null}
				onCancel={() => setCreateEditLink(null)}
				title="Add link"
			>
				<AddLinkForm
					initialLink={createEditLink as string}
					onCancel={() => setCreateEditLink(null)}
					onSubmit={saveLink}
				/>
			</Modal>
			<Modal
				open={isImageModalVisible}
				onCancel={hideImageModal}
				title="Add image"
				subtitle={
					uploadImageUrl ? 'Save image via link or upload new image' : 'Save image via link '
				}
			>
				<AddImageForm
					onCancel={hideImageModal}
					onSave={handleAddImages}
					uploadImageUrl={uploadImageUrl}
				/>
			</Modal>
		</Toolbar>
	)
}

const EditorButton = styled.button<{ isActive: boolean; disabled?: boolean }>`
	border: none;
	border-radius: 2px;
	background: ${({ isActive }) => (isActive ? rgba(94, 106, 255, 0.06) : 'transparent')};
	display: ${({ disabled }) => (disabled ? 'none' : 'inline-flex')};
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	color: ${Colors.VioletLight};
	transition: all 0.3s ease-in-out;
	&:hover {
		background-color: rgba(94, 106, 255, 0.03);
		cursor: pointer;
	}
`

const ClearAllButton = styled(EditorButton)`
	font-size: 10px;
	width: auto;
`

const Header = styled.span`
	font-size: 14px;
`

const Toolbar = styled.div<{ position: EditorToolbarPosition }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: ${SPACES.XXS} ${SPACES.SMALL};
	${({ position }) =>
		position === EditorToolbarPosition.Bottom &&
		`
		border-top: 1px solid ${Colors.Light};
	`}
	${({ position }) =>
		position === EditorToolbarPosition.Top &&
		`
	border-bottom: 1px solid ${Colors.Light};
	`}
`

const ToolbarPart = styled.div`
	height: 32px;
	display: flex;
	align-items: center;
`

const LeftPart = styled(ToolbarPart)``

const RightPart = styled(ToolbarPart)``

const SubmitButton = styled(Button)`
	width: 32px;
	height: 32px;
	padding: 0;
	border-radius: 4px;
`

export default EditorToolbar
