const sr = {
	common: {
		title: 'Pregled',
		subtitle: 'Dogadjaji zaštitnog zida',
		eventsSummary: {
			title: 'Rezime događaja',
			placeholder: 'Izaberite filter',
			total: 'Ukupuno',
			timeFilters: {
				0: 'Prethodnih 24h',
				1: 'Prethodna 3 dana',
				2: 'Prethodnih 5 dana',
			},
			tabs: {
				tab1: 'Postupak',
				tab2: 'Domaćin',
				tab3: 'Država',
				tab4: 'ASN',
				tab5: 'IP',
				tab6: 'Putanja',
			},
			chart: {
				title: 'Broj dogadjaja',
			},
		},
		activityLog: {
			title: 'Aktivnosti',
			table: {
				date: 'Datum',
				actionTaken: 'Preduzete mere',
				country: 'Država',
				ip: 'IP adrese',
				service: 'Usluga',
			},
			log: {
				rayId: 'Ray ID',
				methods: 'Metode',
				http: 'HTTP Verzija',
				host: 'Host',
				path: 'Putanja',
				queryString: 'Prazni upiti stringa',
				userAgent: 'Korisnički agent',
				ipAddress: 'IP adrese',
				asn: 'ASN',
				country: 'Država',
				service: 'Usluga',
				ruleId: 'ID pravila',
				ruleMessage: 'Poruka pravila',
				ruleGroup: 'Grupa pravila',
				actionTaken: 'Preduzete mere',
			},
			actionTaken: {
				deny: 'Blokirano',
			},
			search: {
				placeholder: 'Pretraži aktivnosti',
			},
		},
		eventsServices: {
			title: 'Dogadjaji po službi',
			subtitle: 'Upravljana pravila',
		},
		eventsTopSource: {
			title: 'Najbolji događaji prema izvoru',
			log: {
				rayId: 'Ray ID',
				paths: 'Putanje',
				hosts: 'Hosts',
				firewallRules: 'Pravila zaštitnog zida',
				httpDdoS: 'HTTP DdoS pravila',
				httpMethods: 'HTTP Metode',
				service: 'Usluga',
				countries: 'Zemlje',
				asns: 'ASN',
				managedRules: 'Upravljana pravila',
				rateLimiting: 'Pravila ograničavanja',
			},
		},
	},
	blockedIps: {
		title: 'Blokirane IP adrese',
		addNewBlockedIp: 'Blokirajte IP Adresu',
		table: {
			columns: {
				ip: 'IP Adresa',
				country: 'Država',
				blockedTimes: 'Broj blokiranih pokusaja',
				expireTime: 'Blokiran do',
			},
		},
		blockIpModal: {
			title: 'Blokirajte IP Adresu',
			properties: {
				ip: {
					placeholder: 'Unesite IP Adresu',
					label: 'IP Address',
				},
			},
		},
	},
}

export default sr
