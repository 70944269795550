const en = {
	description: {
		title: 'Welcome to',
		subtitle1: 'An interactive information security awareness',
		subtitle2: ' learning platform that will help you:',
		item1: 'Reduce human error to near-zero',
		item2: 'Analyse your employees responsiveness to cyber threats',
		item3: 'Track their progress',
		item4: 'Make data-driven decisions',
	},
	fields: {
		firstName: {
			label: 'First Name',
			placeholder: 'Enter First Name',
		},
		lastName: {
			label: 'Last Name',
			placeholder: 'Enter Last Name',
		},
		email: {
			label: 'Email',
			placeholder: 'Enter Email',
		},
		password: {
			label: 'Password',
			placeholder: 'Enter Password',
		},
		twoFactor: {
			label: '6-digit code',
			placeholder: 'Enter 6-digit code',
		},
		recoveryKey: {
			label: 'Recovery Key',
			placeholder: 'Enter Recovery Key',
		},
	},
	signIn: {
		title: 'Sign In',
		subtitle: 'Not a member yet?',
		rememberMe: 'Remember me',
		forgetPassword: 'Forget Password',
		button: 'Sign In',
		dontHaveAccessToPhone: "Don't have access to phone?",
		useRecoveryKey: 'Use recovery key',
		backTo: 'Back to',
		code: 'code',
		submit: 'Submit',
	},
	signUp: {
		title: 'Create an Account',
		subtitle: 'Already have an account?',
		button: 'Sign Up',
		policy:
			'I have read and approved Abstract’s <1>Terms of Use</1> and <1>Privacy Policy</1>. I accept processing of my personal information according to the Privacy Policy.',
	},
	forgetPassword: {
		title: 'Reset password',
		subtitle: 'Changed your mind?',
		goBack: 'Go back',
		button: 'Reset password',
	},
	setPassword: {
		title: 'Set Password',
		button: 'Set Password',
	},
}

export default en
