import { FC } from 'react'
import { Form } from 'formik-antd'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { Button, FormikInput } from '../../../components'
import { ITwoFactorRecoveryKey } from '..'

interface IProps {
	isSubmitting: boolean
	onSubmit(data: ITwoFactorRecoveryKey): void
}

const TwoFactorAuthRecoveryKeyForm: FC<IProps> = ({ onSubmit, isSubmitting }) => {
	const { t } = useTranslation()

	return (
		<Formik
			initialValues={{ recoveryKey: '' }}
			onSubmit={onSubmit}
			validationSchema={TwoFactorAuthRecoveryKeySchema}
		>
			{() => (
				<Form layout="vertical">
					<Form.Item name="recoveryKey" label={t('auth.fields.recoveryKey.label')}>
						<FormikInput
							name="recoveryKey"
							placeholder={t('auth.fields.recoveryKey.placeholder')}
							size="large"
						/>
					</Form.Item>
					<Button type="primary" htmlType="submit" block loading={isSubmitting}>
						{t('auth.signIn.submit')}
					</Button>
				</Form>
			)}
		</Formik>
	)
}

const TwoFactorAuthRecoveryKeySchema = Yup.object().shape({
	recoveryKey: Yup.string().required('Required').length(24),
})

export default TwoFactorAuthRecoveryKeyForm
