import { TagProps, Tag as AntTag } from 'antd'
import { rgba } from 'polished'
import styled from 'styled-components'
import { SPACES } from '../styles/spaces'

interface IProps extends TagProps {
	color: string
}

const Tag: React.FC<IProps> = ({ color, ...props }) => {
	return <CustomTag {...props} customColor={color} />
}

const CustomTag = styled(({ customColor, ...rest }) => <AntTag {...rest} />)`
	background: ${({ customColor }) => rgba(customColor, 0.3)};
	color: ${({ customColor }) => customColor};
	font-size: 11px;
	line-height: normal;
	border-radius: 12px;
	border: none;
	padding: ${SPACES.XXS} ${SPACES.EXTRA_SMALL};
	margin: ${SPACES.XXS} ${SPACES.EXTRA_SMALL} ${SPACES.XXS} auto;
`

export default Tag
