import { BusinessDays, NanoLearnerEventName } from 'blue-project-types'

const sr = {
	title: 'Nano kampanje',
	headerData: {
		createBtn: 'Kreiraj kampanju',
	},
	createWizardNano: {
		title: 'Pokrenite novu nano kampanju',
		subtitle:
			'Ovde možete zakazati, dodati polaznike i konfigurisati postavke za vašu kampanju za nano učenje.',
		step1: {
			title: 'Zakaži kampanju',
			subtitle: 'Izaberite grupe sadržaja i vreme',
		},
		step2: {
			title: 'Mete',
			subtitle: 'Upravljajte učenicima',
		},
		step3: {
			title: 'Podešavanja',
			subtitle: 'Konfigurišite podešavanja',
		},
		error: 'Unesite aktuelne podatke',
	},
	scheduleContent: {
		title: 'Raspored sadržaja',
		subtitle: 'Izaberite grupe sadržaja i vreme',
		btnAddContent: '+ Dodaj sadržaj',
		columns: {
			name: 'Naziv',
			time: 'Vreme',
			actions: 'Radnje',
		},
		modalContent: {
			title: 'Izaberi sadržaj',
			selectLang: 'Jezik',
			selType: 'Tip',
			selectGroups: 'Grupa',
			select: 'Odaberi vreme',
			selectAll: 'Svi',
			fixedTime: 'Fiksno vreme',
			startDate: 'Početak',
			endDate: 'Kraj',
			submitBtn: 'Zakaži',
			cancel: 'Otkaži',
			contentRequiredMsg:
				'* Molimo Vas selektujte i prebacite minimum jedan sadržaj u desnu kolonu',
			endDateBeforeStartError: 'Kranji datum mora biti nakon početnog',
		},
		content: 'sadržaj',
		contents: 'sadržaji',
	},
	targetsTab: {
		title: 'Izaberite nano učenike',
		subtitle: 'Odaberite sve ili postavite uslove za prisustvovanje ovoj kampanji',
		allPeople: 'Svi zaposleni',
		customGroup: 'Poručena grupa',
		tags: {
			label: 'Oznake',
			placeholder: 'Izaberite oznake da biste dodali učesnike',
		},
	},
	settingsTab: {
		title: 'Podešavanja kampanje',
		subtitle: 'Konfigurišite podešavanja kampanje',
		nameDesc: {
			title: 'Ime i opis',
			subtitle: 'Ime i opis su takođe vidljivi učenicima',
			name: {
				title: 'Ime kampanje',
				placeholder: 'Unesite ime',
			},
			description: {
				title: 'Opis',
				placeholder: 'Dodajte opis',
			},
		},
		time: {
			title: 'Vremenska zona i vreme',
			subtitle: 'Podesite vremensku zonu kampanje i vreme',
			selectZone: 'Izaberite vremensku zonu',
			start: 'Početak',
			end: 'Kraj',
		},
		businessDays: {
			title: 'Obuhvat kampanje',
			subtitle: 'Izaberite dane u nedelji kada je kampanja dozvoljena',
			[BusinessDays.Monday]: 'Ponedeljak',
			[BusinessDays.Tuesday]: 'Utorak',
			[BusinessDays.Wednesday]: 'Sreda',
			[BusinessDays.Thursday]: 'Četvrtak',
			[BusinessDays.Friday]: 'Petak',
			[BusinessDays.Saturday]: 'Subota',
			[BusinessDays.Sunday]: 'Nedelja',
			error: 'Najamnje jedan radni dan je obavezan',
		},
		alertComponent: {
			subtitlePlural: `Stranice: "{{pages}}" ne podržavaju trenutno izabran jezik`,
			subtitleOne: `Stranica: "{{page}}" ne podrzava trenutno izabran jezik`,
		},
		language: {
			title: 'Jezik',
			subtitle: 'Podesite jezik kampanje',
			selectLang: 'Izaberite jezik',
		},
	},
	successfullyCreatedCampaign: {
		title: '{{campaignName}} je spremna!',
		subtitle:
			'Ako želite da napravite promene na svojoj kampanji, možete to uraditi na glavnoj stranici!',
		buttonProceed: 'Nastavite na stranicu Nano Learning kampanje',
	},
	campaignList: {
		title: 'Seif Nano učenje',
		subtitle:
			'Ovde možete pregledati sve kampanje za nano učenje koje su u toku, kao i one koje dolaze.',
		section1: 'Kampanje u toku',
		section2: 'Predstojeće kampanje',
		noResultMsg: 'Trenutno nema predstojećih kampanji!',
		notEnabled: {
			title: 'Vaša organizacija nema pristup ovoj funkciji platforme.',
			content1: 'Kako bi pokrenuli nano learning kampanju,',
			content2: 'kontaktirajte Seif tim za prodaju!',
			contactBtn: 'Kontaktirajte nas',
		},
	},
	campaignResult: {
		learners: 'Učenici',
		startedOn: 'Početak',
		startingOn: 'Počinje',
		totalDates: 'Ukupno termina',
		remainingDates: 'Preostalo termina',
		engageRate: 'Stopa angažovanja',
		stopped: 'Stopirano',
	},
	noCampaignPage: {
		title1: 'Pokrenite vašu prvu nano kampanju',
		title2: `da biste pratili angažovanostu u vašoj organizaciji!`,
		buttonCreate: 'Nova kampanja',
	},
	viewCampaign: {
		subtitle:
			'Ovde možete pregledati rezultate i sadržaj kampanje nano učenja, upravljati polaznicima, i odrediti druge postavke.',
		tab1: 'PREGLED',
		tab2: 'POLAZNICI',
		tab3: 'SADRŽAJ',
		tab4: 'PODEŠAVANJA',
	},
	chart: {
		title: 'Tok kampanje',
		results: {
			totalContentProjected: 'Ukupno planiran sadržaj',
			delivered: { label: 'Dostavljeno', tooltip: 'Broj dostavljenih nano sadržaja do sada.' },
			opened: { label: 'Otvoreno', tooltip: 'Broj otvorenih mejlova sa nano sadržajem do sada.' },
			quizContent: {
				label: 'Sadržaj kviza',
				tooltip: 'Ukupno broj planiranog sadržaja koji je tipa kviz.',
			},
			sentQuizContent: {
				label: 'Poslat sadržaj kviza',
				tooltip: 'Broj poslatih sadržaja tipa kviz do sada.',
			},
			answered: { label: 'Odgovoreno', tooltip: 'Broj odgovora na sadržajima tipa kviz.' },
			failed: {
				label: 'Nije položeno',
				tooltip: 'Broj sadržaja tipa kviz na kojima su polaznici odgovorili netačno barem jednom.',
			},
			repeated: {
				label: 'Ponovljeno',
				tooltip: 'Broj sadržaja tipa kviz na kojima su polaznici ponovili odgovor barem jednom.',
			},
		},
	},
	contentTable: {
		title: 'Sadržaj',
		name: 'Naziv',
		type: 'Tip',
		sentOpen: 'Poslato/Otvoreno',
		engageRate: 'Stopa angažovanja',
		score: 'Rezultat',
		tooltipAntd: {
			openedContents: 'Broj otvorenih sadržaja',
			correctContents: 'Broj tačnih odgovora',
			incorrectContents: 'Broj netačnih odgovora',
		},
	},
	learnersTable: {
		title: 'Polaznici',
		fullname: 'Ime i prezime',
		engageRate: 'Stopa angažovanja',
		score: 'Rezultat',
		timeToEngage: 'Vreme do angažovanja',
		tooltip: {
			incorrect: 'Broj netačnih odgovora',
			correct: 'Broj tačnih odgovora',
		},
	},
	learnersTab: {
		title: 'Svi polaznici',
		firstName: 'Ime',
		lastName: 'Prezime',
		groups: 'Grupe',
		score: 'Rezultat',
		engageRate: 'Stopa angažovanja',
		timeToEngage: 'Vreme do angažovanja',
		timeFromOpenToEngage: 'Vreme od otvaranja do angažovanja',
		actions: 'Akcije',
		remove: 'Ukloni',
		addBtn: '+ Dodaj polaznike',
		learnerDetails: {
			type: 'Type',
			scheduled: 'Zakazan',
			[NanoLearnerEventName.Sent]: 'Poslato',
			[NanoLearnerEventName.Delivered]: 'Dostavljeno',
			[NanoLearnerEventName.Opened]: 'Otvoreno',
			[NanoLearnerEventName.AnsweredCorrectly]: 'Tačan odgovor',
			[NanoLearnerEventName.AnsweredIncorrectly]: 'Netačan odgovor',
			[NanoLearnerEventName.OpenedVideoQuizPage]: 'Otvorena stranica sa video kvizom',
			[NanoLearnerEventName.VideoCompleted]: 'Video završen',
		},
		tooltip: {
			incorrect: 'Broj netačnih odgovora',
			correct: 'Broj tačnih odgovora',
		},
		searchPlaceholder: 'Pretrazi polaznike',
		modalConfirm: {
			title: 'Izbriši ovog učenika?',
			subtitle: 'Da li ste sigurni da želite da izbrišete <1>{{name}}</1> sa nano kampanje?',
		},
		modalAdd: {
			title: 'Odaberite svoje polaznike',
			subtitle: `Molimo Vas da imate na umu da odabirom opcije "Svi polaznici" ili "Poseban segment", dodajete samo osobe koje trenutno nisu dodeljene ovom programu.`,
		},
	},
	contentTab: {
		title: 'Zakazani sadržaj',
		name: 'Naziv',
		type: 'Tip',
		time: 'Vreme',
		score: 'Rezultat',
		actions: 'Akcije',
		addBtn: '+ Dodaj sadržaj',
		edit: 'Izmeni',
		remove: 'Ukloni',
		tooltipAntd: {
			openedContents: 'Broj otvorenih sadržaja',
			correctContents: 'Broj tačnih odgovora',
			incorrectContents: 'Broj netačnih odgovora',
		},
		modalConfirm: {
			title: 'Ukloniti ovaj sadržaj?',
			subtitle: 'Da li ste sigurni da želite ukloniti ovaj sadržaj iz nano kampanje?',
		},
		useRemoveContent: {
			success: 'Sadržaj uspešno uklonjen',
			error: 'Dogodila se greška',
			alertMessage: 'Sadržaj ne može biti obrisan jer je u toku.',
		},
		useAddContent: {
			success: 'Sadržaj uspešno dodat',
		},
		useUpdateContent: {
			success: 'Sadržaj uspešno ažuriran',
			alertMessage: 'Sadržaj ne može biti ažuriran jer je u toku.',
		},
	},
	headerView: {
		subtitle:
			'Ovde možete zakazati, dodati polaznike i konfigurisati postavke za vašu kampanju za nano učenje.',
		start: 'Početak',
		end: 'Kraj',
		status: 'Status',
		actionButton: 'Zaustavi kampanju',
		modalTitle: 'Da li želite da zaustavite ovu kampanju?',
		modalTxt1: 'Da li ste sigurni da želite da zaustavite kampanju',
		modalTxt2: '* Imajte na umu da nećete moći nastaviti ovu kampanju.',
		successMsg: 'Status kampanje uspešno ažuriran!',
	},
	status: {
		stopped: 'Zaustavljeno',
		running: 'U toku',
		upcoming: 'Predstojeće',
	},
}

export default sr
