import styled from 'styled-components'

import { SPACES } from '../styles/spaces'
import { Colors } from '../styles/colors'

export type BorderCss = Partial<
	Record<'border' | 'borderTop' | 'borderBottom' | 'borderLeft' | 'borderRight', string>
>

const ExpandableTableRowContent = styled.div<{ backgroundColor?: string; borderCss?: BorderCss }>`
	padding: ${SPACES.DEFAULT};
	background: ${(props) => props.backgroundColor || Colors.GrayLighter};
	margin-top: -${SPACES.DEFAULT};
	border-bottom-right-radius: ${SPACES.EXTRA_SMALL};
	border-bottom-left-radius: ${SPACES.EXTRA_SMALL};
	${({ borderCss }) => `
    border: ${borderCss?.border};
    border-top: ${borderCss?.borderTop};
    border-bottom: ${borderCss?.borderBottom};
    border-left: ${borderCss?.borderLeft};
    border-right: ${borderCss?.borderRight};
  `}
`

export default ExpandableTableRowContent
