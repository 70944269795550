import { Button, Modal } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { SPACES } from '../styles/spaces'
import Title from './Title'

interface IProps {
	isVisible: boolean
	isConfirming?: boolean
	children: React.ReactNode | React.ReactNode[]
	title: string
	confirmText?: string
	onCancel(): void
	onConfirm(): void
	shouldDisableConfirm?: boolean
}

const Confirm: React.FC<IProps> = ({
	isVisible,
	isConfirming,
	children,
	title,
	confirmText,
	onCancel,
	onConfirm,
	shouldDisableConfirm,
}) => {
	const { t } = useTranslation()

	const confirmTextTranslate = confirmText || t('common.confirm')

	return (
		<Modal open={isVisible} onCancel={onCancel} footer={false} destroyOnClose={true}>
			<ConfirmWrapper>
				<Title level={4}>{title}</Title>
				{children}
				<Actions>
					<Button type="link" onClick={onCancel}>
						{t('common.cancel')}
					</Button>
					<Button
						type="primary"
						onClick={onConfirm}
						loading={isConfirming}
						disabled={shouldDisableConfirm}
					>
						{confirmTextTranslate}
					</Button>
				</Actions>
			</ConfirmWrapper>
		</Modal>
	)
}

Confirm.defaultProps = {
	isConfirming: false,
}

const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: ${SPACES.EXTRA_LARGE};
`

const ConfirmWrapper = styled.div`
	h4 {
		margin-bottom: ${SPACES.LARGE};
	}
`

export default Confirm
