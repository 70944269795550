import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd'
import { lighten } from 'polished'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import classNames from 'classnames'

import { Colors } from '../styles/colors'

export interface IButtonProps extends AntButtonProps {
	to?: string
	children?: React.ReactElement | React.ReactElement[] | string
}

const Button = (props: IButtonProps) => {
	if (props.to) {
		return (
			<StyledLink
				{...(props as any)}
				className={classNames({
					[props.className as string]: !!props.className,
					'ant-btn': true,
					'ant-btn-primary': props.type === 'primary',
					'ant-btn-link': props.type === 'link',
					'ant-btn-block': !!props.block,
				})}
			/>
		)
	}
	return <StyledButton {...props} />
}

const getDisabledBgColor = (props: IButtonProps) => {
	if (props.type === 'primary') {
		return Colors.Blue
	}
	if (props.type === 'link') {
		return 'transparent'
	}
	return Colors.GrayLighter
}

const getDisabledColor = (props: IButtonProps) => {
	if (props.type === 'primary') {
		return Colors.White
	}
	return Colors.Blue
}

const getHoverBgColor = (props: IButtonProps) => {
	if (props.type === 'default') {
		return Colors.GrayHover
	}
	if (props.type === 'primary') {
		return lighten(0.0255)(Colors.Blue)
	}
	if (props.type === 'link') {
		return 'transparent'
	}
}

const styledBtn = css`
	&&& {
		${(props: IButtonProps) =>
			props.disabled &&
			`
		background: ${getDisabledBgColor(props)};
		opacity: 0.4;
		color: ${getDisabledColor(props)};
	`}
		&:hover {
			background: ${getHoverBgColor};
		}
	}
`

const StyledButton = styled(AntButton)`
	${styledBtn};
`

const StyledLink = styled(Link)`
	${styledBtn};
`

Button.defaultProps = {
	type: 'default',
}

export default Button
