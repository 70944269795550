import { useContext } from 'react'

import { IAuthContext, AuthContext } from '../context/context'

const useAuth = () => {
	const context = useContext<IAuthContext>(AuthContext)

	return context
}

export default useAuth
