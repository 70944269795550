import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Colors } from '../../../../styles/colors'
import { SPACES } from '../../../../styles/spaces'
import TitleSubtitleWrapper from '../../../inventory/components/common/TitleSubtitleWrapper'

interface IProps {
	rate: string
	title: string
	subtitle: string
	icon?: React.ReactNode
}

const AwarenessRate: React.FC<IProps> = ({ rate, title, subtitle, icon }) => {
	const { t } = useTranslation()

	return (
		<Wrapper>
			<RateWrapper>
				<span>{rate}</span>
				{icon}
			</RateWrapper>
			<TitleSubtitleWrapper smallerFontTitle title={title} subtitle={subtitle} />
		</Wrapper>
	)
}

const Wrapper = styled.section`
	display: flex;
	padding: ${SPACES.SMALL};
	flex-direction: column;
	span {
		font-size: ${SPACES.LARGE};
		font-weight: 600;
		line-height: ${SPACES.LARGE};
		color: ${Colors.Black};
	}
`
const RateWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: ${SPACES.SMALL};
	padding-bottom: ${SPACES.MEDIUM};
	max-width: 200px;
`
export default AwarenessRate
