import { DownOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'

import { DropdownContent } from '../../components'
import { SPACES } from '../../styles/spaces'
import { capitalizeFirstLetter } from '../../utils/formatters'
import i18n from './../../translate/i18n'
import { DropDownItem } from './StyledComponents'
import { useToggleVisibility } from '../../hooks'

const LanguageChanger = () => {
	const {
		isVisible: isDropdownVisible,
		hide: hideDropdown,
		show: showDropdown,
	} = useToggleVisibility()

	const [currentLanguage, setCurrentLanguage] = useState(i18n.language)

	const handleChangeLanguage = (lng: string) => {
		i18n.changeLanguage(lng)
		setCurrentLanguage(lng)
	}

	return (
		<Dropdown
			open={isDropdownVisible}
			onOpenChange={(visible: boolean) => (!visible ? hideDropdown() : showDropdown())}
			dropdownRender={() => (
				<DropdownContent onClick={isDropdownVisible ? hideDropdown : showDropdown}>
					<DropDownItem align="center" width="50px" onClick={() => handleChangeLanguage('en')}>
						En
					</DropDownItem>
					<DropDownItem align="center" width="50px" onClick={() => handleChangeLanguage('sr')}>
						Sr
					</DropDownItem>
				</DropdownContent>
			)}
			trigger={['click']}
			placement="bottom"
		>
			<CurrentLanguage>
				{capitalizeFirstLetter(currentLanguage)} <DownOutlined />
			</CurrentLanguage>
		</Dropdown>
	)
}

const CurrentLanguage = styled.div`
	margin: 0 ${SPACES.EXTRA_SMALL};
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	svg {
		margin-left: ${SPACES.XXS};
	}
	&:hover {
		cursor: pointer;
	}
`

export default LanguageChanger
