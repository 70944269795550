import { FC, useMemo } from 'react'
import { Form, Input } from 'formik-antd'
import { Formik } from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useTranslation, Trans } from 'react-i18next'

import { SPACES } from '../../../styles/spaces'
import { Button, Title, FormCheckbox, FormikInput } from '../../../components'
import { useAuth } from '../context'
import { FormWrapper, SmallActionsWrapper } from './StyledComponents'
import useOrganizationRedirect from '../hooks/useAddOrganizationRedirect'
import useDefaultEmail from '../hooks/useDefaultEmail'

interface IProps {
	goToLogin(): void
}

const SIGN_UP_BASE_KEY = 'auth.signUp'
const FIELDS_BASE_KEY = 'auth.fields'
const ERRORS_BASE_KEY = 'common.validation.errors'

const RegisterForm: FC<IProps> = ({ goToLogin }) => {
	const { t } = useTranslation()
	const { register, isRegistering } = useAuth()
	const { redirectUrl, organizationToken } = useOrganizationRedirect()
	const defaultEmail = useDefaultEmail()

	const schema = useMemo(() => {
		return Yup.object().shape({
			firstName: Yup.string()
				.trim()
				.required(t(`${ERRORS_BASE_KEY}.required`))
				.min(
					2,
					t(`${ERRORS_BASE_KEY}.min`, {
						field: 'First name',
						numberOfCharacters: 'two',
					}),
				)
				.max(
					100,
					t(`${ERRORS_BASE_KEY}.max`, {
						field: 'First name',
						numberOfCharacters: 'hundred',
					}),
				),
			lastName: Yup.string()
				.trim()
				.required(t(`${ERRORS_BASE_KEY}.required`))
				.min(
					2,
					t(`${ERRORS_BASE_KEY}.min`, {
						field: 'Last name',
						numberOfCharacters: 'two',
					}),
				)
				.max(
					100,
					t(`${ERRORS_BASE_KEY}.max`, {
						field: 'Last name',
						numberOfCharacters: 'hundred',
					}),
				),
			email: Yup.string()
				.email(t(`${ERRORS_BASE_KEY}.email`))
				.required(t(`${ERRORS_BASE_KEY}.required`)),
			password: Yup.string()
				.required(t(`${ERRORS_BASE_KEY}.required`))
				.matches(/^(?=.*[0-9])(?=.{6,})/, t(`${ERRORS_BASE_KEY}.password`)),
			privacyPolicy: Yup.bool().oneOf(
				[true],
				t(`${ERRORS_BASE_KEY}.checked`, { field: 'Privacy and Policy' }),
			),
		})
	}, [t])

	return (
		<FormWrapper>
			<Title level={1}>{t(`${SIGN_UP_BASE_KEY}.title`)}</Title>
			<p>
				{t(`${SIGN_UP_BASE_KEY}.subtitle`)}{' '}
				<Button type="link" onClick={goToLogin}>
					{t(`auth.signIn.button`)}
				</Button>
			</p>
			<Formik
				initialValues={{
					email: defaultEmail,
					organizationToken,
					password: '',
					firstName: '',
					lastName: '',
					rememberMe: false,
					privacyPolicy: false,
				}}
				onSubmit={(data) => register(data, redirectUrl)}
				validationSchema={schema}
			>
				{() => (
					<Form layout="vertical">
						<Form.Item name="firstName" label={t(`${FIELDS_BASE_KEY}.firstName.label`)}>
							<FormikInput
								name="firstName"
								placeholder={t(`${FIELDS_BASE_KEY}.firstName.placeholder`)}
								size="large"
							/>
						</Form.Item>
						<Form.Item name="lastName" label={t(`${FIELDS_BASE_KEY}.lastName.label`)}>
							<FormikInput
								name="lastName"
								placeholder={t(`${FIELDS_BASE_KEY}.lastName.placeholder`)}
								size="large"
							/>
						</Form.Item>
						<Form.Item name="email" label={t(`${FIELDS_BASE_KEY}.email.label`)}>
							<FormikInput
								// disable email input if user came to our site via invite
								disabled={!!organizationToken}
								name="email"
								placeholder={t(`${FIELDS_BASE_KEY}.email.placeholder`)}
								size="large"
							/>
						</Form.Item>
						<Form.Item name="password" label={t(`${FIELDS_BASE_KEY}.password.label`)}>
							<Input.Password
								name="password"
								placeholder={t(`${FIELDS_BASE_KEY}.password.placeholder`)}
								size="large"
							/>
						</Form.Item>
						<StyledSmallActions>
							<Form.Item name="privacyPolicy">
								<FormCheckbox name="privacyPolicy">
									<Trans i18nKey={`${SIGN_UP_BASE_KEY}.policy`}>
										I have read and approved Abstract’s <a href="#termsOfUse">Terms of Use</a> and{' '}
										<a href="#privacyPolicy">Privacy Policy</a>. I accept processing of my personal
										information according to the Privacy Policy.
									</Trans>
								</FormCheckbox>
							</Form.Item>
						</StyledSmallActions>
						<Button type="primary" htmlType="submit" size="large" block loading={isRegistering}>
							{t(`${SIGN_UP_BASE_KEY}.button`)}
						</Button>
					</Form>
				)}
			</Formik>
		</FormWrapper>
	)
}

const StyledSmallActions = styled(SmallActionsWrapper)`
	margin: 0 0 ${SPACES.SMALL} 0;
	label {
		max-width: 440px;
		font-size: 12px;
		line-height: 18px;
	}
`

export default RegisterForm
