import styled from 'styled-components'
import { Colors } from '../styles/colors'

import Button from './Button'

const ButtonSocial = styled(Button)`
	color: ${Colors.Dark};
`

export default ButtonSocial
