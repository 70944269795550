import { Badge } from 'antd'
import { ITenant } from 'blue-project-types'
import styled, { css } from 'styled-components'
import { Colors } from '../../styles/colors'
import { SPACES } from '../../styles/spaces'

interface IProps {
	tenant: ITenant
	isDemoData?: boolean
}

const CompanyAvatar: React.FC<IProps> = ({ tenant, isDemoData }) => {
	if (isDemoData) {
		return (
			<Badge offset={[-15, 0]} color={Colors.Violet} count="DEMO" size="small">
				<CompanyImagePlaceholder opacity={0.3} color={tenant.color} />
			</Badge>
		)
	}

	if (tenant.logo) {
		return <CompanyImage src={tenant.logo} alt={tenant.name} />
	}

	return <CompanyImagePlaceholder opacity={0.3} color={tenant.color} />
}

const companyImageCss = css`
	width: 32px;
	height: 32px;
	border-radius: 6px;
	margin-right: ${SPACES.SMALL};
`

const CompanyImagePlaceholder = styled.div<{
	opacity: number
	marginRight?: string
	color?: string
}>`
	${companyImageCss};
	background: ${({ color }) => color || Colors.Violet};
	opacity: ${({ opacity }) => opacity};
	margin-right: ${({ marginRight }) => marginRight || undefined};
`

const CompanyImage = styled.img`
	${companyImageCss};
	object-fit: cover;
`

export default CompanyAvatar
