import styled from 'styled-components'
import { SPACES } from '../../../styles/spaces'

export const FormWrapper = styled.div`
	min-width: 440px;
	p {
		margin-bottom: 30px;
		font-weight: 500;
		button {
			padding: 0;
			font-weight: 500;
		}
	}
`
export const SmallActionsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: -${SPACES.SMALL} 0 ${SPACES.LARGE} 0;
	button {
		padding: 0;
		font-size: 12px;
	}
`
