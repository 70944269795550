import styled from 'styled-components'
import { SPACES } from '../../styles/spaces'
import { Colors } from '../../styles/colors'
import { rgba } from 'polished'

export const DropDownItem = styled.div<{ width?: string; align: string }>`
	padding: ${SPACES.EXTRA_SMALL};
	color: ${Colors.Dark};
	font-size: 12px;
	width: ${({ width }) => width};

	display: flex;
	align-items: center;
	justify-content: ${({ align }) => align};
	&:hover {
		background: ${rgba(Colors.Blue, 0.05)};
		cursor: pointer;
	}
`
