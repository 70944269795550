import { Node } from '@tiptap/core'

import { SPACES } from '../styles/spaces'

export const CustomBlock = Node.create({
	name: 'customBlock',

	group: 'block',

	content: 'block+',

	parseHTML() {
		return [
			{
				tag: 'div[data-type="customBlock"]',
			},
		]
	},

	renderHTML({ HTMLAttributes }) {
		return [
			'div',
			{
				...HTMLAttributes,
				'data-type': 'customBlock',
				style: `
          background-color: #EDEFF2;
          padding: 24px 40px 1px;
          border-radius: 16px;
          margin-bottom: ${SPACES.LARGE}
        `,
			},
			0,
		]
	},
})
