import { LeakStatus, StrengthCode } from 'blue-project-types'

const sr = {
	credentialSearches: {
		pageTitle: 'Pretrage lozinki',
		table: {
			columns: {
				term: 'Član',
				status: 'Status',
				filesCount: 'Broj fajlova',
				leaksCount: 'Broj procurelih podataka',
				createdAt: 'Datum kreiranja',
				updatedAt: 'Datum izmene',
			},
		},
		search: {
			placeholder: 'Pretraži po članu',
		},
		addNewButton: 'Dodaj novu pretragu',
		addSearchMessage: {
			error: 'Došlo je do problema prilikom zahteva za novom pretragom',
			inProgress: 'Zahtevana pretraga je u toku',
			refreshInProgress: 'Osvežavanje pretrage je u toku',
			completed: 'Pretraga je završena',
		},
		orderOptionsValues: {
			dateAsc: 'Datum uzlazno',
			dateDesc: 'Datum silazno',
			noSort: 'Bez sortiranja',
			xScoreAsc: 'XScore uzlazno',
			xScoreDesc: 'XScore silazno',
		},
		alert: {
			title: 'Preostali krediti za pretragu',
		},
	},
	requestSearchModal: {
		title: 'Zahtevaj novu pretragu',
		termInputLabel: 'Email, domen ili poddomen',
		termInputPlaceholder: 'Unesite email, domen ili poddomen',
		limitInputLabel: 'Limit',
		limitInputPlaceholder: 'Unesite limit',
		orderSelectLabel: 'Redosled',
		orderSelectPlaceholder: 'Izaberite kako su rezultati sortirani',
		fromLabel: 'Od',
		toLabel: 'Do',
		endDateBeforeStartError: 'Datum završetka mora biti posle početnog datuma',
		startDateAfterEndError: 'Početni datum mora biti pre datuma završetka',
		searchTermRequiredError: 'Termin pretrage je obavezan',
	},
	credentialSearch: {
		alertText: 'Konfiguracija korišćena za pretragu',
		changeSearchConfigButton: 'Promeni konfiguraciju pretrage',
		tabs: {
			credentials: 'Kredencijali',
			files: 'Fajlovi',
		},
		credentials: {
			table: {
				columns: {
					url: 'URL',
					username: 'Korisničko ime',
					password: 'Lozinka',
					status: 'Status lozinke',
					tags: 'Oznake',
					weakness: 'Slabost',
					exposedAt: 'Izloženo',
					lastNotifiedAt: 'Poslednji datum notifikacije',
					actions: 'Akcije',
					menu: {
						editLeakStatus: 'Izmeni status procurelog podatka',
						notify: 'Obavesti o procureloj lozinki',
					},
				},
			},
			tooltip: {
				[LeakStatus.Deprecated]:
					'Ova lozinka je označena kao zastarela zbog mnogih faktora koji ukazuju na njenu ranjivost.',
				[LeakStatus.Discovered]:
					'Ova lozinka je otkrivena na internetu i predstavlja bezbednosnu pretnju.',
				[LeakStatus.Resolved]: 'Ova lozinka je promenjena i više ne predstavlja pretnju.',
				worst500: 'Ova lozinka je među najgorima prema listi 500 najgorih lozinki prema SecList.',
				mostCommon:
					'Ova lozinka je među najčešće korišćenim prema {{tagNumber}} najčešćih lozinki prema SecList.',
			},
			leakStatus: {
				[LeakStatus.Deprecated]: 'Zastarelo',
				[LeakStatus.Discovered]: 'Otkriveno',
				[LeakStatus.Resolved]: 'Rešeno',
			},
			strengthCode: {
				[StrengthCode.Reasonable]: 'Razumno',
				[StrengthCode.Strong]: 'Jako',
				[StrengthCode.VeryStrong]: 'Veoma jako',
				[StrengthCode.VeryWeak]: 'Veoma slabo',
				[StrengthCode.Weak]: 'Slabo',
			},
			metaData: {
				timeLeakedSingle: '1 put procurela',
				timesLeakedNumber: '{{timesLeakedNumber}} puta procurela',
				timesLeakedRange: '{{timesLeakedNumber}}+ puta procurela',
				worst500: '500 najgorih',
				mostCommon: '{{tagNumber}} najčešćih',
			},
			search: {
				placeholder: 'Pretraži po korisničkom imenu',
			},
			changeLeakStatusModal: {
				title: 'Promeni status procurelog podatka',
				leakStatusSelectLabel: 'Status procurelog podatka',
				leakStatusSelectPlaceholder: 'Izaberite status procurelog podatka',
				leakStatusOptionsLabels: {
					discovered: 'Otkriveno',
					deprecated: 'Zastarelo',
					resolved: 'Rešeno',
				},
				statusChangeSuccess: 'Status procurelog podatka je ažuriran',
			},
		},
		changeSearchConfigMessage: {
			error: 'Došlo je do problema prilikom zahteva za pretragu sa novom konfiguracijom',
			inProgress: 'Pretraga sa novom konfiguracijom je sada u toku',
			refreshInProgress: 'Osvežavanje pretrage sa novom konfiguracijom',
			completed: 'Pretraga sa ovom konfiguracijom je završena',
		},
		files: {
			table: {
				columns: {
					name: 'Ime fajla',
					score: 'Rezultat',
					status: 'Status',
					parsedBy: 'Analizirao',
					createdAt: 'Kreirano',
					updatedAt: 'Ažurirano',
					preview: 'Pregled',
					reattemptParse: 'Pokušaj ponovno citanje fajla',
				},
			},
			search: {
				placeholder: 'Pretraži po imenu fajla',
			},
			reattemptFileParseSuccess: 'Successfully requested file parsing',
		},
	},
	credentials: {
		pageTitle: 'Kredencijali',
		actions: {
			searchPlaceholder: 'Pretraži kredencijale',
			btn: 'Izvezi izveštaj',
			statusFilter: 'Status',
			strengthFilter: 'Slabost',
			notify: 'Obavesti sve',
		},
		domains: {
			title: 'Domeni',
			noDomains: 'Ne postoje dodati domeni',
			lastScannedAtTooltip: 'Vreme poslednje pretrage',
		},
		newCredentialsAlert: {
			title: 'Novo curenje podataka je otkriveno',
			subtitle:
				'Novi kredencijali su otkriveni i prikazani ispod. Ugasite upozorenje i označenu kolonu klikom na dugme Zatovri.',
			gotItButton: 'Zatvori',
		},
		notifyLeakedCredentialOwnersModal: {
			title: 'Posalji obavestenje o procurelim lozinkama',
			emailLanguageSelectLabel: 'Jezik email-a',
			emailLanguageSelectPlaceholder: 'Izaberite jezik koji se biti koriscen u email-u',
			displayAllLeakedPasswordsCheckboxLabel: 'Posalji sve procurele lozinke korisnika',
			emailLanguageOptions: {
				en: 'Engleski',
				sr: 'Srpski',
			},
		},
		notifyLeakedCredentialOwnersSuccess: 'Obaveštenja o procurelim lozinkama uspesno poslata',
	},
	feature: {
		pageTitle: 'Procureli podaci',
		title: 'Ova opcija nije omogućena za vašu organizaciju.',
		content1: 'Da biste pokrenuli opciju Procureli podaci,',
		content2: 'molimo kontaktirajte Seif prodajni tim!',
		contactBtn: 'Kontaktirajte nas',
	},
}

export default sr
