import { NavLink } from 'react-router-dom'
import { getItem } from '../utils/sidebarMenuItem'

export const navLinks = ({ listOfItems }) =>
	listOfItems.map(({ to, label, key, icon, dataTest }) => {
		return getItem(
			<NavLink
				className="nav-text"
				to={to}
				activeClassName="active-nav-link"
				data-test={dataTest ?? ''}
			>
				{label}
			</NavLink>,
			key,
			icon,
		)
	})
