import { DataExportType, Language, NanoPageType, PageType, VideoType } from 'blue-project-types'
import { PredefinedCourseLevel } from 'blue-project-types'

const sr = {
	common: {
		settings: {
			title: 'Jezik kursa',
			subtitle: 'Izaberite jezik kursa',
			training: {
				title: 'Imena i opisi',
				subtitle: 'Ime i opis kursa je vidljiv i polaznicima',
			},
			properties: {
				language: {
					label: 'Jezik kursa',
					placeholder: 'Odaberite jezik',
				},
				name: {
					label: 'Naziv kursa',
					placeholder: 'Naziv kursa',
				},
				description: {
					label: 'Opis kursa',
					placeholder: 'Opis',
				},
				enrollment: {
					label: 'Datum upisa kursa',
				},
				duration: {
					label: 'Trajanje',
					text: 'Nedelje',
				},
			},
			programDuration: {
				title: 'Datum početka i završetka kursa',
				content1: 'Zakažite datum i trajanje za dodelu zadataka i upis na kurs.',
				content2: 'Svaki novi polaznik će imati istu količinu vremena da završi kurs.',
				content3:
					'I datum upisa, i trajanje kursa može se naknadno prilagoditi individualnim polaznicima u listi polaznika.',
			},
		},
		courseItem: {
			title: 'Osnove Informacione bezbednosti',
			subtitle:
				'U dva reda opišite polaznicima kurs, objašnjavajući metodologiju, korist od učenja o informacionoj bezbednosti, kao i modernom pristupu kursa koji je pred njima.',
			list: {
				item1: 'Uvod u informacionu bezbednost',
				item2: 'Lozinke',
				item3: 'Phishing i Ransomware',
				item4: 'Socijalni inženjering',
				item5: 'Bezbedno pretraživanje',
			},
			desriptionMsg: 'Niste dodali opis',
		},
		feature: {
			pageTitle: 'Svest o informacionoj bezbednosti - Kursevi',
			title: 'Vaša organizacija nema pristup ovoj funkciji platforme.',
			content1: 'Kako bi pokrenuli kurs osnova informacione bezbednosti,',
			content2: `te unapredili i nadgledali informacionu bezbednost unutar vaše organizacije,`,
			content3: 'kontaktirajte Seif tim za prodaju!',
			contactBtn: 'Kontaktirajte nas',
		},
		messageSettings: {
			properties: {
				enrollment: {
					title: 'Upis',
					subtitle: 'Polaznici će primiti mejl nakon što budu uključeni u program kursa',
					label: 'Napišite poruku',
					placeholder: 'Poruka za upis polaznika kursa',
				},
				completed: {
					title: 'Kurs je završen',
					subtitle: 'Polaznici će primiti poruk nakon što završe kurs',
					label: 'Napišite poruku',
					placeholder: 'Poruka za završetak kursa',
				},
				reminder: {
					title: 'Podsetnik',
					subtitle: 'Polaznici će dobijati nedeljne podsetnike sa pozivom da nastave kurs',
					label: 'Napisite podsetnik',
					placeholder: 'Podsetnik za kurs',
				},
			},
		},
		courseResult: {
			rate: 'Procenat kursa koji je završen',
			average: 'Prosečan rezultat',
			start: 'Datum početka',
		},
		headerData: {
			createBtn: 'Kreirajte kurs',
		},
		courseLearners: {
			title: 'Dodajte polaznike',
			subtitle: 'Dodajte ili prilagodite uslove za prisustvovanje kursu',
			properties: {
				people: {
					radio1: 'Svi polaznici',
					radio2: 'Poseban segment',
					radio3: 'Pozivni link',
					radio4: 'Predefinisani polaznici',
				},
				tags: {
					label: 'Tagovi',
					placeholder: 'Odaberite tagove da dodate polaznike kursa',
				},
			},
			failedCourseAlert: {
				title: 'Polaznik je pao kurs',
				subtitle:
					'Samo polaznik koji je pao kurs je prikazan. Ugasite upozorenje i označenu kolonu klikom na dugme Zatovri.',
				gotItButton: 'Zatvori',
			},
		},
		courseMessages: {
			title: 'Poruke',
			subtitle: 'Pregledajte i po izboru prilagodite mejlove poslate polaznicima',
		},
		courseSettings: {
			title: 'Podešavanje kursa',
			subtitle: `Konfigurišite podešavanja kursa`,
		},
		courseReview: {
			column: {
				name1: 'Naziv kursa',
				name2: 'Prolazna ocena',
			},
			title: `Pregledajte sadržaj kursa`,
			subtitle: 'Pregledajte sadržaj kursa i detalje o lekcijama',
			courses: {
				basic: 'Security Awareness Basics',
				ASEEAdvanced: 'ASEE Advanced Security Training',
				advanced: 'Advanced Security Training',
				OWASPTop10: 'OWASP Top 10',
			},
			basics: 'Seif kursevi',
			custom: 'Prilagodjen',
			columns: {
				name: 'Naziv Lekcije',
				pages: 'Stranice',
				languages: 'Jezici',
				order: 'Redosled',
				description: 'Opis',
			},
			language: {
				[Language.En]: 'Engleski',
				[Language.Sr]: 'Srpski',
				[Language.De]: 'Nemački',
				[Language.Es]: 'Španski',
				[Language.Sl]: 'Slovenački',
				all: 'Svi',
			},
		},
		courseCreated: {
			title: 'je spreman!',
			subtitle: `Ako želite da napravite izmene u kursu, možete to učiniti u sekciji "Podešavanja" na stranici programa.`,
			buttonProceed: 'Nastavite do stranice programa',
		},
		ctaAwareness: {
			title1: 'Pokrenite Vaš prvi Kurs obuke',
			title2: `kako bi unapredili i nadgledali informacionu bezbednost vaše organizacije `,
			buttonCreate: 'Kreirajte kurs',
		},
		infoAwareness: {
			title: 'Svest o informacionoj bezbednosti',
			score: 'Sveukupan rezultat',
			scoreResult: 'NA',
			complete: 'Obuka završena',
			average: 'Prosečno vreme',
		},
		programAwareness: {
			title: 'Programi informacione bezbednosti',
			subtitle1: 'Osnovni kurs',
			subtitle2: 'Napredni nivo',
			content: 'Uskoro dostupan',
		},
		addLearners: {
			properties: {
				people: {
					radio1: 'Svi polaznici',
					radio2: 'Poseban segment',
				},
				tags: {
					label: 'Tagovi',
					placeholder: 'Odaberite tagove da dodate polaznike',
				},
			},
		},
		basicCourseSettings: {
			title: 'Podešavanja programa kursa',
			subtitle: `Konfigurišite podešavanja programa`,
		},
		content: {
			columns: {
				name: 'Ime lekcije',
				passingScore: 'Poeni za prolaz',
				type: 'Tip',
				pages: 'Broj strana',
				expectedCompletionTime: 'Vreme završavanja',
			},
			premium: 'Premium',
			standard: 'Standard',
			moreinfo: 'Vidite kurs',
		},
		courseOverview: {
			properties: {
				notCompleted: {
					title: 'Nije završeno',
					subtitle: 'niste završili pre isteka dogovorenog roka',
					actionText: 'Vidite polaznike',
				},
				toBeReminded: {
					title: 'Uskoro ističe rok za završetak kursa',
					subtitle: `niste još završili kurs`,
					actionText: 'Pošalji podsetnike',
					actionTooltip: 'Polaznici su već primili podsetnik danas',
				},
				completionRate: {
					title: 'Procenat kursa koji je završen',
					subtitle: 'Ukupni napredak svih polaznika',
				},
				completedLearners: {
					title: 'polaznici koji su završili kurs',
					subtitle: `odnos polaznika koji su završili naspram polaznika koji tek treba da završe`,
				},
				passedLearners: {
					title: 'stopa prolaznosti',
					subtitle: 'odnos polaznika koji su položili naspram polaznika koji su pali kurs.',
				},
				averageScore: {
					title: 'Prosečan rezultat',
					subTitle: 'polaznika koji su položili kurs',
				},
				invitationPending: {
					title: 'Očekuje se pozivnica',
					subtitle: 'polaznici koji čekaju pozivnicu za početak kursa',
				},
				invitationNoActivity: {
					title: 'Bez aktivnosti',
					subtitle: `polaznici koji su primili pozivnicu za početak kursa, ali je nisu još otvorili`,
				},
				invitationOpened: {
					title: 'Otvorena pozivnica',
					subtitle: `polaznici koji su otvorili pozivnicu, ali nisu još započeli kurs.`,
				},
				inProgress: {
					title: 'Aktivni polaznici',
					subtitle: 'polaznici koji su započeli kurs',
				},
			},
		},
		duration: {
			title: 'dana ostalo',
			subtitle: 'dana trajanje',
			tag: 'Probijen rok',
		},
		viewHeaderData: {
			title: 'Početak',
			pdfSuffix: 'detalji_kursa.pdf',
		},
		quizzesDetails: {
			success: 'uspeh',
			error: 'greška',
		},
		learners: {
			sendReminder: {
				message:
					'Primetili smo da neki polaznici nemaju aktivnosti na kursu, možda biste želeli da ih podsetite.',
				successMsg: 'Podsetnici uspešno poslati.',
			},
			properties: {
				firstName: {
					title: 'Ime',
				},
				lastName: {
					title: 'Prezime',
				},
				groups: {
					title: 'Grupe',
				},
				completionRate: {
					title: 'Status',
				},
				timeSpent: {
					title: 'Vreme provedeno na kursu',
				},
				score: {
					title: 'Rezultati kursa',
				},
				daysLeft: {
					title: 'Preostalo dana',
				},
			},
			actions: {
				edit: 'Uredite',
				remove: 'Uklonite',
				reminder: 'Pošaljite podsetnik',
				resend: 'Ponovo pošaljite pozivnicu',
			},
			search: {
				placeholder: 'Pretražite polaznike',
			},
			filters: {
				completionStatus: {
					name: 'Status',
					label1: 'Očekuje se pozivnica',
					label2: 'Bez aktivnosti',
					label3: 'Pozivnica otvorena',
					label4: 'Kurs započet',
					label5: 'Kurs završen',
				},
				courseResults: {
					name: 'Rezultati kursa',
					label1: 'Pao',
					label2: 'Prošao',
				},
				groups: {
					name: 'Grupe',
				},
			},
			crudActions: {
				send: 'Pošaljite podsetnike',
				add: 'Dodajte polaznike',
				invitationLink: 'Pozivni link',
			},
			modal1: {
				title: 'Odaberite svoje polaznike',
				subtitle:
					'Molimo Vas da imate na umu da odabirom opcije "Svi polaznici" ili "Poseban segment", dodajete samo osobe koje trenutno nisu dodeljene ovom programu.',
			},
			modal2: {
				title: 'Ažurirajte podatke polaznika',
			},
			modal3: {
				title: 'Poštaljite podsetnik',
			},
			linkModal: {
				title: 'Pozivni link',
				subtitle:
					'Kopirajte link, ili skenirajte QR kȏd koji će vas preusmeriti na stranicu za dodavanje polaznika kursa.',
				invitationLink: 'Pozivni link',
			},
			modalConfirm: {
				title: 'Uklonite profil ovog polaznika?',
				subtitle1: 'Da li ste sigurni da želite da uklonite?',
				subtitle2: 'profil polaznika iz kursa?',
			},
			export: {
				title: 'Export',
				[DataExportType.CSV]: 'Csv',
				[DataExportType.XLS]: 'Xls',
			},
			learnersScore: {
				courseAverage: {
					average: 'Rezultat je jednak prosečnom na kursu',
					bellow: 'Rezultat je ispod prosečnog na kursu',
					above: 'Rezultat je iznad prosečnog na kursu',
				},
				industryAverage: {
					average: 'Rezultat je jednak prosečnom u industriji',
					bellow: 'Rezultat je ispod prosečnog u industriji',
					above: 'Rezultat je iznad prosečnog u industriji',
					notSet: 'Prosečni rezultat u industriji nije definisan',
				},
				explanation:
					'Rezultat se izračunava dodelom poena za tačne odgovore i oduzimanjem poena za više netačnih odgovora',
			},
		},
		learnerStatus: {
			invitationPending: {
				title: 'Očekuje se pozivnica',
				subtitle: 'Kurs počinje u',
			},
			invitationSentNoActivity: {
				title: 'Pozivnica poslata',
				subtitle: 'Bez aktivnosti',
			},
			invitationOpened: {
				title: 'Pozivnica otvorena',
				subtitle: 'Kurs još nije započet',
			},
			learningInProgress: {
				title: 'U toku',
			},
			learningCompleted: {
				title: 'Završeno',
			},
		},
		messagesSettingsForm: {
			title: 'Poruke',
			subtitle: 'Pregledajte i po izboru prilagodite mejlove koji se šalju polaznicima',
		},
		quizProgress: {
			passed: 'Prošao',
			failed: 'Pao',
			notCompleted: 'Nije još završio',
		},
		securityContact: {
			title: 'Bezbednosna polisa i kontakt',
			subtitle: 'Pregledajte i po izboru prilagodite bezbednosnu polisu i kontakt podatke',
			properties: {
				policy: {
					title: 'Polisa',
					subtitle: 'Povežite bezbednosnu polisu organizacije.',
					label: 'Link ka polisi',
					placeholder: 'Link ka polisi',
				},
				security: {
					title: 'Bezbednosni kontakt',
					subtitle:
						'Dodajte kontakt podatke osobe zadužene za sprovođenje obuke za informacionu bezbednost u organizaciji.',
					label1: 'Ime',
					placeholder1: 'Ime',
					label2: 'Mejl adresa bezbednosnog kontakta',
					placeholder2: 'Mejl adresa',
					label3: 'Broj telefona bezbednosnog kontakta',
					placeholder3: 'Telefon(+3816512345678)',
				},
				errorMsg: {
					email: 'Nevalidna mejl adresa',
					url: 'Nevalidan URL',
				},
			},
		},
		sendReminders: {
			title: `Pregledajte i pošaljite podsetnike polazniicima koji još nisu završili kurs`,
			properties: {
				noActivity: {
					title: 'Bez aktivnosti',
					subtitle: `Polaznici koji još nisu otvorili pozivnicu za početak kursa`,
				},
				invitationOpened: {
					title: 'Pozivnica otvorena',
					subtitle: `Polaznici koji su otvorili svoju pozivnicu, ali još uvek nisu započeli kurs`,
				},
				learningStarted: {
					title: 'Kurs započet',
					subtitle: `Polaznici koji su započeli kurs, ali još uvek nisu završili`,
				},
			},
			actionsBtn: 'Pošaljite podsetnike putem mejla',
		},
		updateLearner: {
			label1: 'Datum upisa',
			label2: 'Trajanje',
			weeks: 'nedelja',
		},
		api: {
			useAddLearners: {
				success1: 'Uspešno dodat',
				success2: 'polaznika',
				info: 'Nema novih polaznika za dodavanje',
				error: 'Došlo je do greške',
			},
			useRemoveLearner: {
				success: 'Profil polaznika uspešno uklonjen',
				error: 'Došlo je do greške',
			},
			useResendInvitation: {
				success: 'Pozivnica je uspešno poslata',
			},
			useSendReminder: {
				reminders: 'Podsetnici',
				reminder: 'Podsetnik',
				info: 'uspešno poslat',
			},
			useUpdateCourse: {
				success: 'Kurs je uspešno ažuriran',
			},
			useUpdateLearner: {
				success: 'Profil polaznika je uspešno ažuriran',
				error: 'Došlo je do greške',
			},
			useUpdateLesson: {
				success: 'Lekcija je uspešno ažurirana',
				error: 'Došlo je do greške',
			},
			useUpdateLessonPageOrder: {
				success: 'Sortiranje strana u lekciji je uspešno ažurirano',
				error: 'Došlo je do greške',
			},
			useRemoveLessonPage: {
				success: 'Strana je uspešno uklonjena',
				error: 'Došlo je do greške',
			},
			useCopyLesson: {
				success: 'Lekcija je uspešno kopirana!',
				error: 'Došlo je do greške',
			},
			useRemovePredefinedCourse: {
				success: 'Predefinisani kurs je uspešno uklonjen',
				error: 'Došlo je do greške',
			},
			useUpdatePredefinedCourse: {
				success: 'Predefinisani kurs je uspešno ažuriran!',
				error: 'Došlo je do greške',
			},
		},
		pages: {
			courses: {
				title: 'Kursevi',
				subtitle: 'Ovde možete videti sve kurseve koji su u toku, kao i one koji dolaze.',
				section1: 'Aktivni kursevi',
				section2: 'Sledeći kursevi',
				name: 'Napredni kurs informacione bezbednosti',
				description:
					'Pružite zaposlenima kurs naprednih veština kako bi pravilno odgovorili na sofisticirane sajber napade.',
				text: 'Uskoro...',
			},
			createCourseWizard: {
				error: 'Molimo Vas popunite podatke',
				title: 'Pokrenite novi kurs',
				subtitle:
					'Ovde možete odabrati sadržaj za obuku, dodati polaznike, odrediti datume i druge postavke, kao i kreirati poruke dobrodošlice za Vaš novi kurs.',
				titleStep1: 'Sadržaj',
				descriptionStep1: 'Pregledaj sadržaj',
				titleStep2: 'Polaznici',
				descriptionStep2: 'Izaberite polaznike',
				titleStep3: 'Podešavanja',
				descriptionStep3: 'Konfigurišite podešavanja',
				titleStep4: 'Poruke',
				descriptionStep4: 'Prilagodite poruke',
			},
			learners: {
				text: 'Polaznici',
			},
			overview: {
				text: 'Pregled',
			},
			viewCourse: {
				subtitle:
					'Ovde možete pregledati rezultate i sadržaj kursa, upravljati polaznicima, i odrediti druge postavke.',
				tab1: 'PREGLED',
				tab2: 'SADRŽAJ',
				tab3: 'POLAZNICI',
				tab4: 'PODEŠAVANJA',
			},
			organizationContent: {
				pageTitle: 'Sadržaj za učenje organizacije',
				commonLessons: 'SEIF Lekcije',
				organizationLessons: 'Prilagođene Lekcije',
				commonNanoPages: 'SEIF Nano Stranice',
				organizationNanoPages: 'Prilagođene Nano Stranice',
			},
			commonContent: {
				pageTitle: 'Lekcije',
			},
		},
		lessons: {
			title: 'Lekcije',
			search: {
				placeholder: 'Pretraži lekcije',
			},
			premiumFilter: {
				name: 'Tip',
				premium: 'Standard',
				standard: 'Premium',
			},
			actions: {
				edit: 'Uredi',
			},
			columns: {
				name: 'Naziv',
				pages: 'Broj stranica',
				type: 'Tip',
				languages: 'Jezici',
				createdAt: 'Datum dodavanja',
				groups: 'Grupe biblioteka',
				expectedCompletionTime: 'Vreme završavanja',
			},
			isStandard: 'Standardna',
			isPremium: 'Premium',
			addNewButton: 'Kreiraj Novu Lekciju',
			createEditLesson: {
				create: 'Kreiraj lekciju',
				back: 'Nazad',
				createLesson: 'Kreiraj novu lekciju',
				editLesson: 'Izmeni lekciju',
				createOrganizationLesson: 'Kreiraj novu lekciju za organizaciju',
				editOrganizationLesson: 'Izmeni lekciju organizacije',
				firstSection: {
					title: 'Ime i tip lekcije',
					subtitle: 'Ime i tip se koriste interno',
				},
				secondSection: {
					title: 'Jezik',
					subtitle: 'Odaberite jezik i unesite naslov lekcije na odabranom jeziku',
				},
				properties: {
					name: {
						label: 'Naziv lekcije',
						placeholder: 'Unesite naziv',
					},
					expectedCompletionTime: {
						label: 'Vreme završavanja',
						placeholder: 'Unesite vreme završavanja',
					},
					premium: {
						label: 'Premium',
					},
					lessonContent: {
						title: {
							label: 'Naslov lekcije na odabranom jeziku',
							placeholder: 'Unesite naslov',
						},
						language: {
							[Language.En]: 'Engleski',
							[Language.Sr]: 'Srpski',
							[Language.De]: 'Nemački',
							[Language.Es]: 'Španski',
							[Language.Sl]: 'Slovenački',
						},
					},
					pages: {
						title: 'Strane lekcije',
						subtitle: 'Dodaj i sortiraj strane',
						name: 'Ime',
						type: 'Tip',
						actions: 'Akcije',
						addNewBtn: '+ Dodaj novu stranu',
						editBtn: 'Izmeni',
						viewBtn: 'Pogledaj',
						removeBtn: 'Obriši',

						removingLessonPage: {
							title: 'Ukloni ovu stranu?',
							subtitle1: 'Da li ste sigurni da želite da uklonite stranu',
							subtitle2: 'iz lekcije?',
							ongoingCoursesExist: 'Postoje tekući/nadolazeći kursevi koji sadrže ovu stranu!',
							error: 'Greška pri brisanju strane!',
						},
					},
					tags: {
						label: 'Grupe',
						placeholder: 'Unesite grupe',
					},
				},
			},
			removingLesson: {
				title: 'Ukloni ovu lekciju?',
				subtitle:
					'Da li ste sigurni da želite da uklonite lekciju <1>{{lessonName}}</1> iz sadržaja za učenje?',
				ongoingCoursesExist: 'Postoje tekući/nadolazeći kursevi koji sadrže ovu lekciju!',
				error: 'Greška pri brisanju lekcije!',
			},
			copy: 'Kopiraj',
			copyingLesson: {
				title: 'Kopiraj ovu lekciju?',
				subtitle:
					'Da li ste sigurni da želite da kopirate <1>{{lessonName}}</1> iz zajedničkih lekcija?',
			},
		},
		createPage: {
			pageTitle: 'Napravi Stranu',
			title: 'Ime i Tip',
			subtitle: 'Ime i tip koji se koriste interno',
			languageContent: 'Sadržaj po jezicima',
			options: {
				[PageType.Text]: 'Tekst',
				[PageType.Video]: 'Video',
				[PageType.Quiz]: 'Kviz',
				[PageType.Custom]: 'Custom',
			},
			lang: {
				[Language.Sr]: 'Srpski',
				[Language.En]: 'Engleski',
				[Language.De]: 'Nemački',
				[Language.Es]: 'Španski',
				[Language.Sl]: 'Slovenački',
			},
			success: 'Nova strana je uspešno napravljena!',
			properties: {
				type: {
					label: 'Tip',
					placeholder: 'Izaberite tip strane',
					errorMsg: 'Tip strane je obavezan',
				},
				name: {
					label: 'Ime strane (interno)',
					placeholder: 'Unesite ime strane',
					errorMsg: 'Ime strane je obavezano',
				},
				description: {
					label: 'Opis',
					placeholder: 'Unesite opis',
					errorMsg: 'Opis strane je obavezano',
				},
				title: {
					label: 'Naslov',
					placeholder: 'Unesite naslov',
					errorMsg: 'Naslov strane je obavezan',
				},
				sidebarTitle: {
					label: 'Sidebar Naslov',
					placeholder: 'Unesite sidebar naslov',
					errorMsg: 'Sidebar naslov je obavezan',
				},
				content: {
					label: 'Kontent',
					placeholder: 'Unesite opis',
					errorMsg: 'Kontent je obavezan',
				},
				videoLink: {
					label: 'Video Link',
					placeholder: 'Unesite link za video',
				},
				videoLinkTranslate: {
					label: 'Video Prevod Link',
					placeholder: 'Unesite link za prevod videa',
				},
				question: {
					label: 'Pitanje',
					placeholder: 'Unesite pitanje',
					errorMsg: 'Pitanje je obavezano',
				},
				answer: {
					label: 'Odgovori (izaberite samo jedan tacan odgovor)',
					placeholder: 'Unesite opciju odgovor',
					remove: 'Ukloni ovu odgovor opciju!',
					add: '+ Dodaj odgovor opciju',
					errorMsg:
						'Polja za odgovore su obavezna. Morate da popunite najmanje 2 odgovora i izaberete samo jedan tačan odgovor.',
				},
				explanationCorrect: {
					label: 'Objašnjenje kad je tačan',
					placeholder: 'Unesite objašnjenje za tačan odgovor',
					errorMsg: 'Objašnjenje za tačan odgovor je obavezno',
				},
				explanationIncorrect: {
					label: 'Objašnjenje kad je netačan',
					placeholder: 'Unesite objašnjenje za netačan odgovor',
					errorMsg: 'Objašnjenje za netačan odgovor je obavezno',
				},
				componentName: {
					label: 'Naziv Komponente',
					placeholder: 'Unesite custom naziv komponente',
					tooltip:
						'U dogovoru sa administratorom napišite odgovarajući naziv prilagođene komponente koja mora da se poklopi za front-end nazivom komponente',
					errorMsg: 'Naziv komponente je obavezan',
				},
			},
		},
		editPage: {
			pageTitle: 'Izmeni Stranu',
			success: 'Strana je uspešno izmenjena!',
		},
		predefinedCourses: {
			pageTitle: 'Predefinisani kursevi',
			addNewButton: '+ Kreiraj novi predefinisani kurs',
			errorTitle: 'Došlo je do greške',
			search: {
				placeholder: 'Pretraži predefinisane kurseve',
			},
			columns: {
				name: 'Ime kursa',
				description: 'Opis',
				level: 'Nivo težine',
				passingScore: 'Prolazna ocena',
				tenant: 'Organizacija',
				createdAt: 'Datum dodavanja',
				actions: {
					edit: 'Pogledaj/Izmeni',
					remove: 'Obriši',
				},
			},
			levelOptions: {
				[PredefinedCourseLevel.Beginner]: 'Početni',
				[PredefinedCourseLevel.Intermediate]: 'Srednji',
				[PredefinedCourseLevel.Advanced]: 'Napredni',
			},
			removingPredefined: {
				title: 'Ukloni ovu lekciju?',
				subtitle1: 'Da li ste sigurni da želite da uklonite kurs',
				subtitle2: 'iz predefinisanih kurseva?',
			},
		},
		predefinedCourse: {
			title: 'Kreirajte predefinisani kurs',
			editTitle: 'Pogledajte/Izmenite predefinisani kurs',
			form: {
				name: {
					label: 'Ime',
					placeholder: 'Unesite ime',
				},
				description: {
					label: 'Opis',
					placeholder: 'Unesite opis',
				},
				image: {
					label: 'Logo',
					dragAndDropText:
						'Aploudujte logo <1>Prevucite-i-otpustite ili kliknite da dodate sa računara</1>',
				},
				avatar: {
					label: 'Avatar',
					dragAndDropText:
						'Aploudujte avatar kursa <1>Prevucite-i-otpustite ili kliknite da dodate sa računara</1>',
				},
				level: {
					label: 'Nivo težine',
					placeholder: 'Molimo odaberite nivo težine',
					options: {
						[PredefinedCourseLevel.Beginner]: 'Početni',
						[PredefinedCourseLevel.Intermediate]: 'Srednji',
						[PredefinedCourseLevel.Advanced]: 'Napredni',
					},
				},
				passingScore: {
					label: 'Prolazna ocena',
					placeholder: 'Unesite prolaznu ocenu u procentima',
				},
				tenantId: {
					allTenants: 'Sve',
					label: 'Organizacija',
					placeholder: 'Odaberite organizaciju',
				},
				lessonIds: {
					label: 'Lekcije',
					placeholder: 'Odaberite lekcije za predefinisani kurs',
				},
				predefinedCourseContent: {
					language: {
						[Language.En]: 'Engleski',
						[Language.Sr]: 'Srpski',
						[Language.De]: 'Nemački',
						[Language.Es]: 'Španski',
						[Language.Sl]: 'Slovenački',
					},
					name: {
						label: 'Naziv predefinisanog kursa na odabranom jeziku',
						placeholder: 'Unesite naziv',
					},
				},
				premium: {
					label: 'Premium',
				},
			},
			courseInfoSection: {
				title: 'Informacije o kursu',
				subtitle: 'Popunite informacije o kursu',
			},
			courseLangSection: {
				title: 'Jezici kursa',
				subtitle: 'Popunite naziv i opis za svaki',
			},
			courseLessonsSection: {
				title: 'Lekcije kursa',
				subtitle: 'Izaberite lekcije',
				langMissingAlert: 'Postoji lekcija koja ne sadrži neki od izabranih jezika!',
			},
		},
		linkInvitationSettings: {
			disable: 'Isključi',
			enable: 'Uključi',
			disableModalSubTitle:
				'Da li ste sigurni da želite da isključite opciju zahtevanja kursa putem linka?',
			disableModalTitle: 'Isključi opciju zahtevanja pristupa putem linka',
			enableModalSubTitle:
				'Da li ste sigurni da hoćete da uključite opciju zahtevanja kursa putem linka?',
			enableModalTitle: 'Uključi opciju zahtevanja kursa putem linka',
			title: 'Zahtev putem linka',
			subtitle: 'Podesite opciju zahtevanja pristupa kursu putem linka',
			status: 'Status',
			active: 'Aktivan',
			inactive: 'Neaktivan',
			disableSuccess: 'Zahtevanje kursa putem linka uspešno isključeno',
			enableSuccess: 'Zahtevanje kursa putem linka uspešno uključeno',
		},
	},
	learningContent: {
		nanoPagesTitle: 'Nano Stranice',
		lessonsTitle: 'Lekcije',
	},
	nanoPages: {
		search: {
			placeholder: 'Pretraži nano stranice',
		},
		columns: {
			name: 'Naziv',
			type: 'Tip',
			languages: 'Jezici',
			groups: 'Grupe biblioteka',
			createdAt: 'Datum dodavanja',
		},
		createBtn: 'Kreiraj Novu Nano Stranicu',
		typeFilter: {
			name: 'Tip',
			[NanoPageType.SimpleQuiz]: 'Jednostavan kviz',
			[NanoPageType.VideoQuiz]: 'Video Kviz',
			[NanoPageType.CustomHTML]: 'Custom HTML',
		},
		actions: {
			edit: 'Uredi',
			remove: 'Ukloni',
		},
		createEditNanoPage: {
			create: 'Kreiraj Novu Nano Stranicu',
			createBtn: 'Kreiraj Nano Stranicu',
			editOrganizationNanoPage: 'Izmeni nano stranicu organizacije',
			editNanoPage: 'Izmeni nano stranicu',
			back: 'Nazad',
			createOrganization: 'Kreiraj novu nano stranicu za organizaciju',
			firstSection: {
				title: 'Ime i Tip',
				subtitle: 'Ime i tip koji se koriste interno',
			},
			secondSection: {
				title: 'Sadržaj',
				subtitle: 'Select language and title for each',
			},
			properties: {
				type: {
					label: 'Tip',
					placeholder: 'Izaberi tip nano stranice',
				},
				name: {
					label: 'Ime nano stranice (interno)',
					placeholder: 'Unesite naziv',
				},
				tags: {
					label: 'Grupa',
					placeholder: 'Unesite grupu',
				},
				languages: {
					label: 'Jezici',
					placeholder: 'Odaberite jezike',
					error: 'Najmanje jedan izabran jezik',
				},
				emailSubject: {
					label: 'Naslov mejl-a',
					placeholder: 'Unesite naslov mejl-a',
				},
				premium: {
					label: 'Premium',
				},
				languageContent: 'Sadržaj po jezicima',
			},
			customForm: {
				label: 'HTML editor',
				placeholder: 'Unesite opis',
			},
			videoForm: {
				videoLink: {
					label: 'Video Link',
					placeholder: 'Unesite link za video',
				},
				videoType: {
					label: 'Video tip',
					placeholder: 'Označite video tip',
					[VideoType.External]: 'Eksterni',
					[VideoType.InternalSimpleVideo]: 'Interni jednostavan video',
				},
				videoThumbnail: {
					label: 'Video sličica',
				},
			},
		},
		removingNanoPage: {
			title: 'Ukloni ovu nano stranicu?',
			subtitle:
				'Da li ste sigurni da želite da uklonite nano stranicu <1>{{nanoPageName}}</1> iz sadržaja za učenje?',
			ongoingCampaignsExist: 'Postoje aktivne nano kampanje koje sadrže ovu stranu!',
		},
	},
	uploadVideo: {
		label: 'Video Link',
		placeholder: 'Unesite video link',
		btn: 'Učitaj',
	},
	language: {
		name: 'Jezik',
		[Language.En]: 'Engleski',
		[Language.Sr]: 'Srpski',
		[Language.De]: 'Nemački',
		[Language.Es]: 'Španski',
		[Language.Sl]: 'Slovenački',
	},
	showListBtn: {
		seeMore: 'Vidi više',
		seeLess: 'Vidi manje',
	},
}

export default sr
