import { useCallback, useMemo, useState } from 'react'
import { FC, ReactNode } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { message } from 'antd'
import { ITenant, IUser } from 'blue-project-types'

import { AuthContext } from './context'
import {
	ILoginRequest,
	ILoginResponse,
	IRegisterRequest,
	IResetPasswordSaveRequest,
	IToken,
} from '../utils/types'
import {
	getAuthUser,
	getAuthUserPermissions,
	GET_AUTH_USER_KEY,
	GET_AUTH_USER_PERMISSIONS_KEY,
	login as loginApi,
	LOGIN_KEY,
	register as registerApi,
	REGISTER_KEY,
	resetPasswordSave as resetPasswordSaveApi,
	RESET_PASSWORD_SAVE_KEY,
	login2FA as login2FAApi,
	LOGIN_2_FA_KEY,
	login2FARecoveryKey as login2FARecoveryKeyApi,
	LOGIN_2_FA_RECOVERY_KEY,
	LOGOUT_USER_KEY,
	logoutUser as logoutUserApi,
} from '../utils/api'
import {
	setRefreshTokenInterceptor,
	setTenantId as setTenantIdToHeader,
	setToken as setTokenToAxios,
} from '../../../utils/axios'
import LocalStorage from '../../../utils/LocalStorage'
import { TOKEN_LOCAL_STORAGE_KEY } from '../utils'
import { useHistory } from 'react-router-dom'
import { Routes } from '../../../routes/config'
import { getTenantLocalStorageName, resolveTenantInfo } from '../utils/tenant'
import { useEffect } from 'react'

interface IProps {
	children: ReactNode | ReactNode[]
}

const AuthProvider: FC<IProps> = ({ children }) => {
	const [token, setToken] = useState<IToken | undefined>(
		() => LocalStorage.get(TOKEN_LOCAL_STORAGE_KEY) as IToken,
	)
	const [is2FAEnabled, setIs2FAEnabled] = useState(false)

	// Active tenant
	const [tenantId, setTenantId] = useState<string>()

	const queryClient = useQueryClient()

	const history = useHistory()

	// Set refresh token interceptor
	useEffect(() => {
		setRefreshTokenInterceptor({
			logout: handleLogout,
			setToken,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const { mutate: login, isLoading: isLogging } = useMutation(loginApi, {
		mutationKey: LOGIN_KEY,
	})

	const { mutate: login2FA, isLoading: isLogging2FA } = useMutation(login2FAApi, {
		mutationKey: LOGIN_2_FA_KEY,
	})

	const { mutate: login2FARecoveryKey, isLoading: isLogging2FARecoveryKey } = useMutation(
		login2FARecoveryKeyApi,
		{
			mutationKey: LOGIN_2_FA_RECOVERY_KEY,
		},
	)

	const { mutate: register, isLoading: isRegistering } = useMutation(registerApi, {
		mutationKey: REGISTER_KEY,
	})

	const { mutate: resetPasswordSave, isLoading: isSubmittingResetPasswordSave } = useMutation(
		resetPasswordSaveApi,
		{
			mutationKey: RESET_PASSWORD_SAVE_KEY,
		},
	)

	// Fetch user info (only if token exists)
	const { isLoading: isFetchingUser, data: user } = useQuery<IUser>(
		GET_AUTH_USER_KEY,
		getAuthUser,
		{
			enabled: !!token,
		},
	)
	const handleLogout = useCallback(
		(redirectUrl?: string) => {
			// Cancel all queries
			queryClient.cancelQueries()

			// Remove query cache
			queryClient.removeQueries()

			// Reset token
			setTokenToAxios('')
			setToken(undefined)
			LocalStorage.remove(TOKEN_LOCAL_STORAGE_KEY)

			// Reset 2FA
			setIs2FAEnabled(false)

			// Reset tenant id
			setTenantIdToHeader('')
			setTenantId('')

			const url = redirectUrl ? redirectUrl : Routes.Auth
			history.push(url)
		},
		[history, queryClient],
	)

	const { mutate: logoutUser, isLoading: isLoggingOut } = useMutation(logoutUserApi, {
		mutationKey: LOGOUT_USER_KEY,
		onSuccess: handleLogout,
	})

	const setTenant = useCallback(
		(tenantId?: string) => {
			setTenantIdToHeader(tenantId)
			setTenantId(tenantId)
			LocalStorage.set(getTenantLocalStorageName(user?.id!), tenantId)
		},
		[user?.id],
	)

	const switchTenant = useCallback(
		(tenantId: string) => {
			setTenant(tenantId)
			queryClient.invalidateQueries()
		},
		[queryClient, setTenant],
	)

	// Set tenant id to header and local state after user is loaded for the first time only
	useEffect(() => {
		if (user) {
			const newTenantId = resolveTenantInfo(user)
			if (newTenantId && !tenantId) {
				// Pass only if tenantId is not set
				setTenant(newTenantId)
			}
		}
	}, [user, tenantId, setTenant])

	// Fetch user permissions after user is loaded
	const { isLoading: isFetchingPermissions } = useQuery(
		GET_AUTH_USER_PERMISSIONS_KEY,
		getAuthUserPermissions,
		{
			enabled: !!user,
		},
	)

	const handleLogin = (data: ILoginRequest, redirectUrl?: string) => {
		login(data, {
			onSuccess: (loginResponse: ILoginResponse) => {
				if (!loginResponse.isTwoFactorAuthEnabled) {
					setUserToken(loginResponse.token!)
					if (redirectUrl) {
						history.push(redirectUrl)
					}
				} else {
					setIs2FAEnabled(true)
				}
			},
			onError: () => {
				message.error('Wrong credential')
			},
		})
	}

	const handleLogin2FA = (data: ILoginRequest, redirectUrl?: string) => {
		login2FA(data, {
			onSuccess: (loginResponse: ILoginResponse) => {
				setUserToken(loginResponse.token!)
				if (redirectUrl) {
					history.push(redirectUrl)
				}
			},
			onError: () => {
				message.error('Wrong credential')
			},
		})
	}

	const handleLogin2FARecoveryKey = (data: ILoginRequest, redirectUrl?: string) => {
		login2FARecoveryKey(data, {
			onSuccess: (loginResponse: ILoginResponse) => {
				setUserToken(loginResponse.token!)
				if (redirectUrl) {
					history.push(redirectUrl)
				} else {
					history.push(`${Routes.SettingsNewRecoveryKey}?recovery-key=${loginResponse.recoveryKey}`)
				}
			},
			onError: () => {
				message.error('Wrong credential')
			},
		})
	}

	const handleRegister = (data: IRegisterRequest, redirectUrl?: string) => {
		register(data, {
			onSuccess: (registerResponse) => {
				setUserToken(registerResponse.token)
				setTimeout(() => {
					history.push(redirectUrl || Routes.Dashboard)
				}, 100)
			},
			onError: (error: any) => {
				message.error(error?.response?.data?.message || 'Error happened')
			},
		})
	}

	const handleResetPasswordSave = (data: IResetPasswordSaveRequest) => {
		resetPasswordSave(data, {
			onSuccess: (resetPasswordResponse: ILoginResponse) => {
				if (!resetPasswordResponse.isTwoFactorAuthEnabled) {
					setUserToken(resetPasswordResponse.token!)
					history.push(Routes.Dashboard)
				} else {
					history.push(Routes.Auth)
					message.success('Password successfully reset, please Sign in.')
				}
			},
			onError: () => {
				// TODO: use real error
				message.error('Error happened')
			},
		})
	}

	const setUserToken = (token: IToken) => {
		setTokenToAxios(token.accessToken)
		setToken(token)
		LocalStorage.set(TOKEN_LOCAL_STORAGE_KEY, token)
	}

	// Active tenant info
	const activeTenant = useMemo<ITenant | undefined>(() => {
		if (!tenantId || !user) {
			return
		}
		return user.tenants?.find((tenant) => tenant.id === tenantId)
	}, [tenantId, user])

	return (
		<AuthContext.Provider
			value={{
				tenantId,
				activeTenant,
				token,
				user: queryClient.getQueryData(GET_AUTH_USER_KEY),
				isLogging2FA,
				isSubmittingResetPasswordSave: isSubmittingResetPasswordSave,
				isLogging,
				isLoggingOut,
				isAuthInitializing: isFetchingUser || isFetchingPermissions,
				isRegistering,
				is2FAEnabled,
				isLogging2FARecoveryKey,
				switchTenant,
				login: handleLogin,
				register: handleRegister,
				logout: handleLogout,
				logoutUser: logoutUser,
				resetPasswordSave: handleResetPasswordSave,
				login2FA: handleLogin2FA,
				login2FARecoveryKey: handleLogin2FARecoveryKey,
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthProvider
