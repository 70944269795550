const en = {
	common: {
		title: 'Overview',
		subtitle: 'Firewall events',
		eventsSummary: {
			title: 'Events summary',
			placeholder: 'Choose filter',
			total: 'Total',
			timeFilters: {
				0: 'Previous 24h',
				1: 'Previous 3 day',
				2: 'Previous 5 day',
			},
			tabs: {
				tab1: 'Action',
				tab2: 'Host',
				tab3: 'Country',
				tab4: 'ASN',
				tab5: 'IP',
				tab6: 'PATH',
			},
			chart: {
				title: 'Number of events',
			},
		},
		activityLog: {
			title: 'Activity Log',
			table: {
				date: 'Date',
				actionTaken: 'Action taken',
				country: 'Country',
				ip: 'IP address',
				service: 'Service',
			},
			log: {
				rayId: 'Ray ID',
				methods: 'Methods',
				http: 'HTTP Version',
				host: 'Host',
				path: 'Path',
				queryString: 'Empty query string',
				userAgent: 'User agent',
				ipAddress: 'IP address',
				asn: 'ASN',
				country: 'Country',
				service: 'Service',
				ruleId: 'Rule ID',
				ruleMessage: 'Rule Message',
				ruleGroup: 'Rule Group',
				actionTaken: 'Action taken',
			},
			services: {
				manage: '',
			},
			actionTaken: {
				deny: 'Block',
			},
			search: {
				placeholder: 'Search activity log',
			},
		},
		eventsServices: {
			title: 'Events by service',
			subtitle: 'Managed rules',
		},
		eventsTopSource: {
			title: 'Top events by source',
			log: {
				rayId: 'Ray ID',
				paths: 'Paths',
				hosts: 'Hosts',
				firewallRules: 'Firewall rules',
				httpDdoS: 'HTTP DdoS rules',
				httpMethods: 'HTTP Methods',
				service: 'Service',
				countries: 'Countries',
				asns: 'ASN',
				managedRules: 'Managed rules',
				rateLimiting: 'Rate limiting rules',
			},
		},
	},
	blockedIps: {
		title: 'Blocked IP addresses',
		addNewBlockedIp: 'Block Ip Address',
		table: {
			columns: {
				ip: 'IP Address',
				country: 'Country',
				blockedTimes: 'Number of blocked attempts',
				expireTime: 'Blocked until',
			},
		},
		blockIpModal: {
			title: 'Block IP Address',
			properties: {
				ip: {
					placeholder: 'Enter IP Address',
					label: 'IP Address',
				},
			},
		},
	},
}

export default en
