import { useContext } from 'react'

import { IUIContext, UIContext } from './context'

const useUI = () => {
	const context = useContext<IUIContext>(UIContext)

	return context
}

export default useUI
