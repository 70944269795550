import { Feature } from 'blue-project-types'
import { useMemo } from 'react'
import { useAuth } from '../features/auth'

const useFeature = (feature: Feature) => {
	const { activeTenant } = useAuth()

	return useMemo(() => {
		if (!activeTenant) return false

		return !!activeTenant.features?.find((f) => f.name === feature)
	}, [feature, activeTenant])
}

export default useFeature
