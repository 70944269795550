import styled from 'styled-components'
import Editor from '@monaco-editor/react'
import { EyeOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import Text from './Text'
import { SPACES } from '../styles/spaces'
import { Colors } from '../styles/colors'
import HTMLPreview from './HTMLPreview'
import { useToggleVisibility } from '../hooks'
import Modal from './Modal'

export interface IPreviewData {
	name: string
	html: string
}
interface IProps {
	error?: string
	label: string
	canEdit?: boolean
	isRequired: boolean
	withPreviewButton?: boolean
	previewData?: IPreviewData
	defaultValue: string
	height?: string
	onChange(value?: string): void
}

const HtmlEditor: React.FC<IProps> = ({
	error,
	canEdit,
	isRequired,
	withPreviewButton,
	previewData,
	label,
	defaultValue,
	height,
	onChange,
}) => {
	const { isVisible, show, hide } = useToggleVisibility(false)

	const handleToHtmlPreview = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		e.preventDefault()
		show()
	}
	const { t } = useTranslation()

	return (
		<Wrapper>
			<Text type="secondary" size="sm" color={Colors.Dark} zeroMargin>
				{isRequired && <Required />} {label}
			</Text>
			<EditorWrapper disabled={!canEdit} hasError={!!error}>
				<Editor
					height={height}
					defaultLanguage="html"
					defaultValue={defaultValue}
					onChange={(value) => onChange(value)}
					options={{ minimap: { enabled: false }, scrollbar: { verticalScrollbarSize: 0 } }}
				/>
			</EditorWrapper>
			{!!error && <Error>{error}</Error>}
			<WrapperPreview>
				{withPreviewButton && previewData && (
					<span>
						<Text
							color={Colors.Blue}
							zeroMargin
							data-test="previewTemplateIcon"
							onClick={(e) => handleToHtmlPreview(e as React.MouseEvent<HTMLDivElement>)}
						>
							<EyeOutlined style={{ marginRight: SPACES.EXTRA_SMALL }} />
							{t('common.previewHTML')}
						</Text>
					</span>
				)}
			</WrapperPreview>
			<Modal open={isVisible} title={previewData?.name} onCancel={hide} onOk={hide} width={700}>
				<HTMLPreview emailBody={previewData?.html} />
			</Modal>
		</Wrapper>
	)
}

HtmlEditor.defaultProps = {
	canEdit: true,
	height: '400px',
}

const Required = styled.span`
	display: inline-block;
	color: ${Colors.Error};
	font-size: 12px;
	font-family: SimSun, sans-serif;
	line-height: 1;
	&:before {
		content: '*';
	}
`

const EditorWrapper = styled.div<{ disabled: boolean; hasError: boolean }>`
	border: 1px solid;
	border-color: ${({ hasError }) => (hasError ? Colors.Error : Colors.Light)};
	border-radius: 8px;
	padding: ${SPACES.EXTRA_SMALL};
	margin-bottom: ${SPACES.XXS};
	.monaco-editor .view-overlays .current-line {
		border: none;
	}
	${({ disabled }) =>
		disabled &&
		`
		&&& {
			opacity: 0.8; 
			pointer-events: none;
			cursor: not-allowed;
			background-color: rgb(245, 245, 245);
			.monaco-editor {
				background-color: rgb(245, 245, 245);
				.line-numbers, .monaco-scrollable-element, span {
					color: rgba(0, 0, 0, 0.25);
				}
				.margin, .lines-content {
					background-color: rgb(245, 245, 245);
				}
			}
		}
		`};
`

const Wrapper = styled.div`
	position: relative;
	margin-bottom: ${SPACES.DEFAULT};
`

const Error = styled.div`
	color: ${Colors.Error};
	position: absolute;
	bottom: -22px;
`
const WrapperPreview = styled.div`
	display: flex;
	justify-content: flex-end;
	span {
		&:hover {
			cursor: pointer;
		}
	}
`

export default HtmlEditor
