import { SuggestionActionTimeFrame } from 'blue-project-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

// icons
import { ReactComponent as ClockIcon } from '../../../../assets/clock-fill.svg'
import { ReactComponent as RiskWarningIcon } from '../../../../assets/risk-warning.svg'
import { ReactComponent as SearchIcon } from '../../../../assets/search.svg'

import { Button } from '../../../../components'
import { Colors } from '../../../../styles/colors'
import { SPACES } from '../../../../styles/spaces'
import TitleSubtitleWrapper from '../../../inventory/components/common/TitleSubtitleWrapper'
import { IRiskScoreSuggestion } from '../../../inventory/hooks/memo/useCreateRiskCoreResolvers'

interface IProps {
	riskScoreSuggestions: IRiskScoreSuggestion[]
	actionTimeFrame: SuggestionActionTimeFrame
}

const TRANSLATE_RECOMMENDED_ACTIONS_BASE_KEY =
	'pdfExports.RiskManagementExport.recommendedActions.list'

const ActionListCard: React.FC<IProps> = ({ riskScoreSuggestions, actionTimeFrame }) => {
	const { t } = useTranslation()

	const iconMapper = useCallback(
		(actionTimeFrame: SuggestionActionTimeFrame) => {
			switch (actionTimeFrame) {
				case SuggestionActionTimeFrame.Immediate:
					return <RiskWarningIcon />
				case SuggestionActionTimeFrame.ShortTerm:
					return <ClockIcon />
				case SuggestionActionTimeFrame.LongTerm:
					return <SearchIcon />
				default:
					throw new Error(`No icon mapped for action time-frame: ${actionTimeFrame}`)
			}
		},
		[actionTimeFrame],
	)

	const titleColorMapper = useCallback(
		(actionTimeFrame: SuggestionActionTimeFrame) => {
			switch (actionTimeFrame) {
				case SuggestionActionTimeFrame.Immediate:
					return Colors.RedNew
				case SuggestionActionTimeFrame.ShortTerm:
					return Colors.OrangeNew
				case SuggestionActionTimeFrame.LongTerm:
					return Colors.GreenNew
				default:
					throw new Error(`No color mapped for action time-frame: ${actionTimeFrame}`)
			}
		},
		[actionTimeFrame],
	)

	return (
		<Wrapper>
			<TitleWrapper>
				{iconMapper(actionTimeFrame)}
				<TitleSubtitleWrapper
					title={t(`${TRANSLATE_RECOMMENDED_ACTIONS_BASE_KEY}.title.${actionTimeFrame}`)}
					subtitle={t(`${TRANSLATE_RECOMMENDED_ACTIONS_BASE_KEY}.subtitle.${actionTimeFrame}`)}
					titleColor={titleColorMapper(actionTimeFrame)}
				/>
			</TitleWrapper>
			{riskScoreSuggestions.map((suggestion) => (
				<ActionWrapper key={suggestion.name}>
					<TitleSubtitleWrapper
						smallerFontTitle={true}
						title={t(`${TRANSLATE_RECOMMENDED_ACTIONS_BASE_KEY}.action.${suggestion.name}.title`)}
						subtitle={t(
							`${TRANSLATE_RECOMMENDED_ACTIONS_BASE_KEY}.action.${suggestion.name}.subtitle`,
						)}
					/>
					<Button type="primary">{t(`${TRANSLATE_RECOMMENDED_ACTIONS_BASE_KEY}.button`)}</Button>
				</ActionWrapper>
			))}
		</Wrapper>
	)
}

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	border-radius: ${SPACES.DEFAULT};
	background: #f1f3f7;
`
const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: ${SPACES.SMALL};
	padding: ${SPACES.EXTRA_LARGE} ${SPACES.DEFAULT};
`
const ActionWrapper = styled.section`
	display: flex;
	align-items: center;
	padding: ${SPACES.SMALL} ${SPACES.DEFAULT};
	justify-content: space-between;
`
export default ActionListCard
