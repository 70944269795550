import { ALL_IMAGE_SUPPORTED_MIME_TYPES, ImageMimeTypes } from 'blue-project-types'

export enum ImageErrors {
	NotValidType = 'Not valid type',
	LargeSize = 'Large size',
}

export const validateImageFile = (file: File) => {
	const isAppropriateImage = ALL_IMAGE_SUPPORTED_MIME_TYPES.includes(file.type as ImageMimeTypes)
	if (!isAppropriateImage) {
		return {
			success: false,
			error: ImageErrors.NotValidType,
		}
	}
	const isLt5M = file.size / 1024 / 1024 < 5
	if (!isLt5M) {
		return {
			success: false,
			error: ImageErrors.LargeSize,
		}
	}

	return {
		success: true,
	}
}
