import { message } from 'antd'
import { parse } from 'query-string'
import { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import GlobalLoader from '../../../components/GlobalLoader'
import { Routes } from '../../../routes/config'

import { GET_AUTH_USER_KEY, verifyEmail as verifyEmailApi, VERIFY_EMAIL_KEY } from '../utils/api'

const VerifyEmail = () => {
	const location = useLocation()
	const history = useHistory()
	const queryClient = useQueryClient()

	const { mutate: verifyEmail, isLoading: isVerifyingEmail } = useMutation(verifyEmailApi, {
		mutationKey: VERIFY_EMAIL_KEY,
		onError: () => {
			history.push(Routes.Auth)
			message.error('Error happened')
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries([GET_AUTH_USER_KEY])
			history.push(Routes.Dashboard)
		},
	})

	useEffect(() => {
		const query = parse(location.search)
		verifyEmail({ token: query.token as string })
	}, [location.search, verifyEmail])

	if (isVerifyingEmail) {
		return <GlobalLoader text="Verifying..." />
	}

	return null
}

export default VerifyEmail
