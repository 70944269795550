import { AssetDiscoveryStatus, IAsset } from 'blue-project-types'
import { createContext } from 'react'

export interface IAssetDiscoveryData {
	asset: IAsset
	status: AssetDiscoveryStatus
	newlyDiscoveredAssets: string[]
}

// Context data
export interface IAssetDiscoveryContext {
	discoveringAssetsData: IAssetDiscoveryData[]
	onAssetAdded(assets: IAsset[]): void
}

// Context
export const AssetDiscoveryContext = createContext<IAssetDiscoveryContext>({
	discoveringAssetsData: [],
	onAssetAdded: () => ({}),
} as IAssetDiscoveryContext)

AssetDiscoveryContext.displayName = 'AssetDiscoveryContext'
