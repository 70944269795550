import { Checkbox as AntCheckbox, CheckboxProps as AntCheckboxProps } from 'antd'
import { Field } from 'formik-antd'
import { FormikFieldProps } from 'formik-antd/lib/FieldProps'
import { FieldProps } from 'formik'

export type CheckboxProps = FormikFieldProps & AntCheckboxProps

export const FormCheckbox = ({ name, validate, fast, onChange, ...restProps }: CheckboxProps) => (
	<Field name={name} validate={validate} fast={fast}>
		{({ field: { value }, form: { setFieldValue, setFieldTouched } }: FieldProps) => (
			<AntCheckbox
				name={name}
				checked={value}
				onChange={(event) => {
					setFieldValue(name, event.target.checked)
					setTimeout(() => setFieldTouched(name, true, false), 300)
					onChange && onChange(event)
				}}
				{...restProps}
			/>
		)}
	</Field>
)

export default FormCheckbox
