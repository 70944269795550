import { Switch } from 'react-router-dom'
import { lazy } from 'react'

import { ProtectedRoute } from '../../components'
import { Routes as RoutesConfig } from '../../routes/config'

const LicensesExports = lazy(() => import('./pages/LicencesExports'))
const SimulationOverviewExports = lazy(() => import('./pages/SimulationOverviewExport'))
const CourseOverviewExport = lazy(() => import('./pages/CourseOverviewExport'))
const GeneralOverviewExport = lazy(() => import('./pages/RiskManagementExport'))

// We are grouping routes per feature so we can lazy load js

const Routes = ({ token }) => {
	return (
		<Switch>
			<ProtectedRoute
				path={RoutesConfig.OrganizationOverview}
				isAuthenticated={token}
				component={LicensesExports}
			/>
			<ProtectedRoute
				path={RoutesConfig.SimulationOverview}
				isAuthenticated={token}
				component={SimulationOverviewExports}
			/>
			<ProtectedRoute
				path={RoutesConfig.CourseOverview}
				isAuthenticated={token}
				component={CourseOverviewExport}
			/>
			<ProtectedRoute
				path={RoutesConfig.RiskManagementOverview}
				isAuthenticated={token}
				component={GeneralOverviewExport}
			/>
		</Switch>
	)
}

export default Routes
