import { ModalProps, Modal as AntModal } from 'antd'
import Title from './Title'
import Text from './Text'
import styled from 'styled-components'
import { SPACES } from '../styles/spaces'

interface IModalProps extends ModalProps {
	title?: string | React.ReactNode
	subtitle?: string | React.ReactNode
	children: React.ReactNode | React.ReactNode[] | string
}

const Modal: React.FC<IModalProps> = ({ children, title, subtitle, ...props }) => {
	return (
		<AntModal footer={false} destroyOnClose={true} {...props}>
			{title ? (
				typeof title !== 'string' ? (
					title
				) : (
					<StyledTitle level={4} subtitleExists={!!subtitle}>
						{title}
					</StyledTitle>
				)
			) : null}
			{subtitle ? (
				typeof subtitle !== 'string' ? (
					subtitle
				) : (
					<Text zeroMargin>{subtitle}</Text>
				)
			) : null}
			<StyledContent titleExists={!!title}>{children}</StyledContent>
		</AntModal>
	)
}

export const ModalActions = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: ${SPACES.EXTRA_LARGE};
	button {
		margin-left: ${SPACES.XXS};
	}
`

const StyledContent = styled.div<{ titleExists: boolean }>`
	margin-top: ${({ titleExists }) => (titleExists ? SPACES.LARGE : 0)};
`

const StyledTitle = styled(({ subtitleExists, ...rest }) => <Title {...rest} />)`
	&&& {
		margin-bottom: ${({ subtitleExists }) => (subtitleExists ? SPACES.SMALL : 0)};
	}
`

export default Modal
