import { Progress } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { RiskScore } from '../../../../../utils/constants'
import { Tag, Text } from '../../../../../components'
import { Colors, RiskScoreColors } from '../../../../../styles/colors'
import { ReactComponent as ArrowDownIcon } from '../../../../../assets/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from '../../../../../assets/arrow-up.svg'
import { SPACES } from '../../../../../styles/spaces'

interface IProps {
	riskScore: number
	riskScoreEnum: RiskScore
}
const RISK_SCORE_COLOR = {
	'0%': '#25C7B6',
	'26%': '#EEFF2C',
	'51%': '#FFBA00',
	'76%': '#FB7604',
	'100%': '#FF1E2B',
}

const PEOPLE_RISK_SCORE_BASE_TRANSLATE = 'inventory.people.riskScore.tenantOverview'

const RiskScoreValueNew: React.FC<IProps> = ({ riskScore, riskScoreEnum }) => {
	const { t } = useTranslation()

	return (
		<SectionWrapper>
			<ScoreWrapper>
				<Text
					color={Colors.Black}
					zeroMargin
					style={{ fontSize: 24, fontWeight: 500, lineHeight: '24px' }}
				>
					{t(`${PEOPLE_RISK_SCORE_BASE_TRANSLATE}.riskScoreTitle`)}
				</Text>
				<Tag color={RiskScoreColors[riskScoreEnum]} style={{ margin: '0px' }}>
					{t(`common.riskScoreStatus.${riskScoreEnum}`)}
				</Tag>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<ArrowDownIcon width={24} height={24} />
					<Text zeroMargin color={Colors.RedNew}>
						{t(`${PEOPLE_RISK_SCORE_BASE_TRANSLATE}.riskScoreVsLastMonth`, { percent: '+23' })}
					</Text>
				</div>
			</ScoreWrapper>
			<ProgressWrapper>
				<Label className="start">0</Label>
				<Label className="end">100</Label>
				<Progress
					type="dashboard"
					percent={riskScore}
					format={(percent) => (
						<>
							<Text
								color={RiskScoreColors[riskScoreEnum]}
								zeroMargin
								style={{ fontSize: 64, fontWeight: 600 }}
							>
								{percent}
							</Text>
							<StyledText color={Colors.Black}>
								{/* {t(`common.riskScore.${riskScoreEnum}`)} */}
								{'High'}
							</StyledText>
						</>
					)}
					strokeColor={RISK_SCORE_COLOR}
					strokeWidth={8}
					width={225}
				/>
			</ProgressWrapper>
			<Description>{t(`${PEOPLE_RISK_SCORE_BASE_TRANSLATE}.riskScoreDescription`)}</Description>
		</SectionWrapper>
	)
}

const ScoreWrapper = styled.div`
	display: flex;
	gap: ${SPACES.SMALL};
	align-items: center;
	padding-bottom: ${SPACES.DEFAULT};
`

const ProgressWrapper = styled.div`
	position: relative;
	display: flex;
	justify-self: center;
	width: 225px;
	height: 225px;
	padding-block: ;
`
const StyledText = styled(Text)`
	font-size: ${SPACES.SMALL};
	padding-top: ${SPACES.EXTRA_SMALL};
	font-weight: 500;
`
const Description = styled(Text)`
	font-size: ${SPACES.SMALL};
	padding-top: ${SPACES.DEFAULT};
	margin: 0;
	font-weight: 400;
	color: ${Colors.Gray};
`

const SectionWrapper = styled.div`
	max-width: 35%;
	padding: ${SPACES.DEFAULT};
	border-right: 0.5px solid #e1e5eb;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`
const Label = styled.div`
	position: absolute;
	font-size: 14px;
	font-weight: 400;
	color: ${Colors.Gray};
	&.start {
		left: 50px;
		bottom: -10px;
	}
	&.end {
		right: 50px;
		bottom: -10px;
	}
`
export default RiskScoreValueNew
