import styled from 'styled-components'
import { Text } from '../../components'
import { Colors } from '../../styles/colors'

const NotVerifiedUserLabel = () => {
	return (
		<Wrapper>
			<Text color={Colors.White} zeroMargin>
				To benefit from all features, you must verify your email.
			</Text>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: ${Colors.Blue};
	height: 40px;
	width: 100%;
	z-index: 3;
	text-align: center;
	padding-top: 7px;
`

export default NotVerifiedUserLabel
