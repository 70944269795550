import { useEffect, useState } from 'react'
import { CaretDownOutlined } from '@ant-design/icons'
import { Checkbox, Dropdown } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { DropdownContent, Button } from '.'
import { useToggleVisibility } from '../hooks'
import { SPACES } from '../styles/spaces'
import { AwarenessStatus } from '../features/awareness/utils/types'

interface IProps<T> {
	selected: T[]
	onApply: (newSelection: T[]) => void
}

const TitleFilterStatus: React.FC<IProps<any>> = ({ selected, onApply }) => {
	const { t } = useTranslation()
	const { isVisible, show, hide } = useToggleVisibility()

	const [localSelected, setLocalSelected] = useState(selected)

	useEffect(() => {
		setLocalSelected(selected)
	}, [selected])

	const handleChangeFilter = (e: any) => {
		const { value, checked } = e.target
		const newSelection = checked
			? [...localSelected, value]
			: localSelected.filter((item) => item !== value)

		setLocalSelected(newSelection)
	}

	const handleApply = () => {
		onApply(localSelected)
		console.log(localSelected)
		hide()
	}

	const handleClear = () => {
		setLocalSelected([])
		onApply([])
		hide()
	}

	return (
		<Dropdown
			dropdownRender={() => (
				<StyledContent>
					<ItemsWrapper>
						{Object.values(AwarenessStatus).map((status) => {
							return (
								<Checkbox
									key={status}
									value={status}
									checked={localSelected.includes(status)}
									onChange={handleChangeFilter}
								>
									{t(`common.awarenessStatus.${status}`)}
								</Checkbox>
							)
						})}
					</ItemsWrapper>
					<ApplyButton size="small" type="link" onClick={handleClear}>
						{t('common.clear')}
					</ApplyButton>
					<ApplyButton size="small" type="primary" onClick={handleApply}>
						{t('common.apply')}
					</ApplyButton>
				</StyledContent>
			)}
			trigger={['click']}
			open={isVisible}
			onOpenChange={(visible) => {
				visible ? show() : hide()
			}}
		>
			<CaretDownOutlined style={{ fontSize: SPACES.DEFAULT, marginLeft: SPACES.XXS }} />
		</Dropdown>
	)
}

const StyledContent = styled(DropdownContent)`
	padding: ${SPACES.SMALL};
	width: fit-content;
`

const ApplyButton = styled(Button)`
	margin-top: ${SPACES.EXTRA_SMALL};
	height: 24px;
	padding: 2px 24px;
	font-size: 12px;
`

const ItemsWrapper = styled.div`
	max-height: 150px;
	overflow: auto;
	display: flex;
	flex-direction: column;
	.ant-checkbox-wrapper + .ant-checkbox-wrapper {
		margin-left: 0px;
	}
`

export default TitleFilterStatus
