import styled from 'styled-components'

import { Button, Title, Text, Editor } from './../../components'
import { Input, Radio, Checkbox, Select, Switch, InputNumber, Table, Modal } from 'antd'
import { Form as FormikForm, Input as FormikInput } from 'formik-antd'
import { Formik } from 'formik'
import { UserOutlined } from '@ant-design/icons'
import { Colors } from '../../styles/colors'
import { useState } from 'react'

const data = [
	{
		key: '1',
		name: 'John Brown',
		age: 32,
		address: 'New York No. 1 Lake Park',
	},
	{
		key: '2',
		name: 'Jim Green',
		age: 42,
		address: 'London No. 1 Lake Park',
	},
	{
		key: '3',
		name: 'Joe Black',
		age: 32,
		address: 'Sidney No. 1 Lake Park',
	},
	{
		key: '4',
		name: 'Jim Red',
		age: 32,
		address: 'London No. 2 Lake Park',
	},
]

const columns: any = [
	{
		title: 'Name',
		dataIndex: 'name',
		sorter: (a: any, b: any) => a.name.length - b.name.length,
		// sortDirections: ['descend'],
	},
	{
		title: 'Age',
		dataIndex: 'age',
		defaultSortOrder: 'descend',
		sorter: (a: any, b: any) => a.age - b.age,
	},
	{
		title: 'Address',
		dataIndex: 'address',
	},
]

const ComponentWrapper = styled.div`
	margin-bottom: 10px;
`

const Theme = () => {
	const [isModalVisible, setIsModalVisible] = useState(false)

	const showModal = () => {
		setIsModalVisible(true)
	}

	const handleCancel = () => {
		setIsModalVisible(false)
	}
	return (
		<section style={{ margin: '0px 50px' }}>
			<Title>Buttons</Title>

			<ComponentWrapper>
				<Button size="small" type="primary">
					Primary Small
				</Button>
			</ComponentWrapper>
			<ComponentWrapper>
				<Button type="primary">Primary default</Button>
			</ComponentWrapper>
			<ComponentWrapper>
				<Button type="primary" loading={true}>
					Primary Loading
				</Button>
			</ComponentWrapper>
			<ComponentWrapper>
				<Button type="primary" disabled={true}>
					Primary Disabled
				</Button>
			</ComponentWrapper>
			<ComponentWrapper>
				<Button size="large" type="primary">
					Primary Large
				</Button>
			</ComponentWrapper>

			<ComponentWrapper>
				<Button size="small" type="default">
					Default Small
				</Button>
			</ComponentWrapper>
			<ComponentWrapper>
				<Button type="default">Default</Button>
			</ComponentWrapper>
			<ComponentWrapper>
				<Button type="default" loading={true}>
					Default Loading
				</Button>
			</ComponentWrapper>
			<ComponentWrapper>
				<Button type="default" disabled={true}>
					Default Disabled
				</Button>
			</ComponentWrapper>
			<ComponentWrapper>
				<Button size="large" type="default">
					Default Large
				</Button>
			</ComponentWrapper>

			<ComponentWrapper>
				<Button size="small" type="link">
					Link Small
				</Button>
			</ComponentWrapper>
			<ComponentWrapper>
				<Button type="link">Link Default</Button>
			</ComponentWrapper>
			<ComponentWrapper>
				<Button size="large" type="link">
					Link Large
				</Button>
			</ComponentWrapper>

			<div style={{ width: 500, marginBottom: 32 }}>
				<Title>Form without formik</Title>

				<Title level={3}>Input</Title>
				<ComponentWrapper>
					<label htmlFor="test">Test</label>
					<Input placeholder="Input Small" size="small" id="test" />
				</ComponentWrapper>
				<ComponentWrapper>
					<Input placeholder="Input Default" size="middle" />
				</ComponentWrapper>
				<ComponentWrapper>
					<Input placeholder="Input Large" size="large" />
				</ComponentWrapper>
				<ComponentWrapper>
					<Input placeholder="Input Default" prefix={<UserOutlined />} />
				</ComponentWrapper>
				<ComponentWrapper>
					<InputNumber min={1} max={10} defaultValue={3} />
				</ComponentWrapper>

				<Title level={3}>Checkbox</Title>
				<ComponentWrapper>
					<Checkbox />
				</ComponentWrapper>

				<Title level={3}>Radio</Title>
				<ComponentWrapper>
					<Radio />
				</ComponentWrapper>
				<ComponentWrapper>
					<Radio.Group onChange={console.log} value={1}>
						<Radio value={1}>A</Radio>
						<Radio value={2}>B</Radio>
						<Radio value={3}>C</Radio>
						<Radio value={4}>D</Radio>
					</Radio.Group>
				</ComponentWrapper>

				<Title level={3}>Select</Title>
				<ComponentWrapper>
					<Select style={{ marginBottom: 12, minWidth: 200 }} placeholder="Select one">
						<Select.Option value="item1">Item1</Select.Option>
						<Select.Option value="item2">Item2</Select.Option>
					</Select>
					<ComponentWrapper>
						<Select mode="tags" style={{ minWidth: 200 }} placeholder="Select multiple">
							<Select.Option value="item1">Item1</Select.Option>
							<Select.Option value="item2">Item2</Select.Option>
							<Select.Option value="item3">Item3</Select.Option>
							<Select.Option value="item4">Item4</Select.Option>
						</Select>
					</ComponentWrapper>
				</ComponentWrapper>

				<Title level={3}>Switch</Title>
				<ComponentWrapper>
					<Switch defaultChecked onChange={console.log} />
				</ComponentWrapper>
			</div>

			<div style={{ width: 500 }}>
				<Title>Formik Fields</Title>
				<ComponentWrapper>
					<Formik initialValues={{}} onSubmit={console.log}>
						{() => (
							<FormikForm layout="vertical">
								<FormikForm.Item
									name="email"
									label="Email"
									validateStatus="error"
									help="Some error"
								>
									<FormikInput name="email" placeholder="Email" />
								</FormikForm.Item>
								<FormikForm.Item name="password" label="Email" validateStatus="success">
									<FormikInput.Password name="password" placeholder="Password" />
								</FormikForm.Item>
							</FormikForm>
						)}
					</Formik>
				</ComponentWrapper>
			</div>
			<Title>Modal</Title>
			<ComponentWrapper>
				<Button type="primary" onClick={showModal}>
					Open modal
				</Button>
			</ComponentWrapper>
			<Modal open={isModalVisible} onCancel={handleCancel} footer={false} destroyOnClose={true}>
				<div className="ant-modal-info-group">
					<Title level={3}>Manage Tip 1</Title>
					<p>Some content info</p>
					<p>Some content info1</p>
					<p>Some content info2</p>
				</div>
				<Formik initialValues={{}} onSubmit={console.log}>
					{() => (
						<FormikForm layout="vertical">
							<FormikForm.Item name="email" label="Email" validateStatus="error" help="Some error">
								<FormikInput name="email" placeholder="Email" />
							</FormikForm.Item>
							<FormikForm.Item name="password" label="Password" validateStatus="success">
								<FormikInput.Password name="password" placeholder="Password" />
							</FormikForm.Item>
						</FormikForm>
					)}
				</Formik>
			</Modal>

			<div style={{ width: 500 }}>
				<Title>Titles</Title>
				<ComponentWrapper>
					<Title>h1. Title</Title>
					<Title level={2}>h2. Title</Title>
					<Title level={3}>h3. Title</Title>
					<Title level={4}>h4. Title</Title>
					<Title level={4} color={Colors.Blue}>
						h4. Title
					</Title>
				</ComponentWrapper>

				<Title>Text</Title>
				<ComponentWrapper>
					<Text>Normal text</Text>
				</ComponentWrapper>
				<ComponentWrapper>
					<Text type="secondary">Secondary text</Text>
				</ComponentWrapper>
				<ComponentWrapper>
					<Text type="success">Success text</Text>
				</ComponentWrapper>
				<ComponentWrapper>
					<Text type="warning">Warning text</Text>
				</ComponentWrapper>
				<ComponentWrapper>
					<Text type="danger">Danger text</Text>
				</ComponentWrapper>
				<ComponentWrapper>
					<Text color={Colors.Blue}>Any color text</Text>
				</ComponentWrapper>
				<ComponentWrapper>
					<Text disabled={true}>Disabled text</Text>
				</ComponentWrapper>
				<ComponentWrapper>
					<Text strong={true}>Strong text</Text>
				</ComponentWrapper>
				<ComponentWrapper>
					<Text>Normal text</Text>
					<Text size="sm">Normal sm text</Text>
					<Text size="xs">Normal xs text</Text>
					<Text size="xss">Normal xss text</Text>
				</ComponentWrapper>
			</div>

			<div>
				<Title>Table</Title>
				<ComponentWrapper>
					<Table pagination={false} columns={columns} dataSource={data} />
				</ComponentWrapper>
				<ComponentWrapper>
					<Table pagination={false} columns={columns} dataSource={data} size="small" />
				</ComponentWrapper>
				<ComponentWrapper style={{ background: '#fff', padding: '24px', borderRadius: '8px' }}>
					<Table pagination={false} columns={columns} dataSource={data} size="small" />
				</ComponentWrapper>
			</div>

			<div>
				<Title>Editor</Title>
				<ComponentWrapper>
					<Editor />
				</ComponentWrapper>
			</div>
		</section>
	)
}

export default Theme
