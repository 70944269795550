import React from 'react'
import styled from 'styled-components'
import { INotification } from 'blue-project-types'
import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'

import { Colors } from '../../../styles/colors'
import { Title } from '../../../components'
import { SPACES } from '../../../styles/spaces'
import { DateFormat, formatDate } from '../../../utils/dates'
import NotificationText from './NotificationText'

interface IProps {
	notification: INotification
}

const NotificationDetails: React.FC<IProps> = ({ notification }) => {
	const { t } = useTranslation()

	return (
		<NotificationDetailsWrapper>
			<Title level={5}>
				{t(`notifications.common.type.${notification.type}`)}
				{!notification?.isRead && <NotificationNew />}
			</Title>
			<NotificationText notification={notification} />
			<Typography.Text>
				{formatDate(notification?.createdAt, DateFormat.DateAndTime)} <br />
			</Typography.Text>
		</NotificationDetailsWrapper>
	)
}

const NotificationDetailsWrapper = styled.div`
	h5 {
		font-size: 12px;
		text-transform: capitalize;
		color: ${Colors.Gray};
	}
`

const NotificationNew = styled.span`
	display: inline-block;
	width: ${SPACES.EXTRA_SMALL};
	height: ${SPACES.EXTRA_SMALL};
	margin-left: ${SPACES.XXS};
	border-radius: 100%;
	background-color: ${Colors.Blue};
`

export default NotificationDetails
