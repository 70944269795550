import {
	Discovered,
	ExploitAvailable,
	Severity,
	VulnerabilityProviders,
	VulnerabilityStatus,
} from 'blue-project-types'

const en = {
	common: {
		days: 'days',
		day: 'day',
		severity: {
			[Severity.Critical]: 'Critical',
			[Severity.High]: 'High',
			[Severity.Info]: 'Info',
			[Severity.Low]: 'Low',
			[Severity.Medium]: 'Medium',
		},
		status: {
			[VulnerabilityStatus.AutoDiscovered]: 'Auto Discovered',
			[VulnerabilityStatus.Draft]: 'Draft',
			[VulnerabilityStatus.Fixing]: 'Fixing',
			[VulnerabilityStatus.ReTest]: 'Re Test',
			[VulnerabilityStatus.Reported]: 'Reported',
			[VulnerabilityStatus.Resolved]: 'Resolved',
		},
		exploitAvailable: {
			[ExploitAvailable.False]: 'False',
			[ExploitAvailable.NoNApplicable]: 'Non Applicable',
			[ExploitAvailable.True]: 'True',
		},
		discovered: {
			[Discovered.Automatically]: 'Auto-Discovered',
			[Discovered.Manually]: 'Manually',
		},
		properties: {
			title: {
				label: 'Title of vulnerability',
				placeholder: 'Enter vulnerability title',
			},
			description: {
				placeholder: 'Enter vulnerability description',
			},
			type: {
				label: 'Vulnerability type',
				placeholder: 'Enter vulnerability type',
			},
			severity: {
				label: 'Severity',
				placeholder: 'Select severity',
			},
			severityScore: {
				label: 'Severity score',
				placeholder: 'Enter severity score',
			},
			discovered: {
				label: 'Discovered',
			},
			exploitAvailable: {
				label: 'Exploit available',
				placeholder: 'Select exploit available',
			},
			lob: {
				label: 'Likelihood of breach',
				placeholder: 'Enter likelihood of breach',
			},
			businessImpact: {
				label: 'Business impact',
				placeholder: 'Enter business impact',
			},
			assetsAffected: {
				label: 'Assets affected',
				placeholder: 'Select assets affected',
			},
			cvs: {
				label: 'CVS references',
				placeholder: 'Enter CVS references',
			},
			OSVDB: {
				label: 'OSVDB',
				placeholder: 'Enter OSVDB',
			},
			BID: {
				label: 'BID',
				placeholder: 'Enter BID',
			},
			id: {
				label: 'Vulnerability ID',
			},
		},
	},
	dashboard: {
		title: 'Vulnerabilities',
		addNewButton: 'Add New Vulnerability',
		draftsButton: 'Drafts',
		tabs: {
			all: 'ALL',
			reported: 'REPORTED',
			fixing: 'FIXING',
			reTest: 'RE TEST',
			resolved: 'RESOLVED',
		},
		table: {
			columns: {
				title: 'Title',
				type: 'Type',
				assetsAffected: 'Assets affected',
				severityScore: 'Severity score',
				severity: 'Severity',
				discoveredOn: 'Discovered on',
				status: 'Status',
			},
		},
		search: {
			placeholder: 'Search for vulnerability',
		},
		discoveredOnFilter: {
			startPlaceholder: 'Discovered on start',
			endPlaceholder: 'Discovered on end',
		},
		severityFilter: {
			button: 'Severity',
		},
		typeFilter: {
			button: 'Type',
		},
		assetsFilter: {
			button: 'Assets',
		},
		criticalVulnerabilityAlert: {
			titleOneVulnerability: '1 critical vulnerability discovered',
			titleMultipleVulnerability:
				'{{numberOfCriticalVulnerabilities}} critical vulnerabilities discovered',
			subtitle: 'Review and submit for report.',
			button: 'Review now',
		},
	},
	drafts: {
		title: 'Drafts - Vulnerabilities',
		tabs: {
			all: 'ALL',
			drafts: 'DRAFTS',
		},
		removeDraft: {
			title: 'Remove draft',
			text: 'Are you sure you want to remove draft?',
		},
	},
	create: {
		title: 'Create Vulnerability',
		saveDraftButton: 'Save Draft',
		reportButton: 'Report',
		titleAndDescription: 'Title & Description',
		vulnerabilityDetails: 'Vulnerability Details',
	},
	details: {
		editButton: 'Edit Vulnerability',
		gotIt: 'Got it',
		headerInfo: {
			lastStatus: 'Last status on',
			status: 'Status',
		},
		description: 'Description',
		vulnerabilityDetails: 'Vulnerability Details',
		activity: 'Activity',
		noActivityFound: 'No activity found',
		systemActivity: 'System',
		graph: {
			lob: 'LIKELIHOOD',
			bi: 'BUSINESS IMPACT',
		},
		activityUpdater: {
			text: {
				placeholder: 'Enter activity description',
			},
			status: {
				label: 'New Status',
				placeholder: 'Select new status',
			},
		},
		systemActivities: {
			reported: 'Vulnerability is reported.',
			edited: 'Vulnerability is edited.',
		},
	},
	statistics: {
		title: 'Vulnerability management',
		header: {
			score: 'Risk Score',
			criticalVulnerabilities: 'Critical Vulnerabilities',
		},
		atTheMoment: 'At the moment',
		inTheLast: 'In the last',
		days: 'days',
		activity: {
			title: 'Activity',
		},
		riskMatrix: {
			title: 'Risk matrix',
			likelihood: 'LIKELIHOOD',
			businessImpact: 'BUSINESS IMPACT',
		},
		vulnerabilityBySeverity: {
			title: 'Vulnerabilities by Severity',
		},
		assetsAtRisk: {
			title: 'Assets at Risk',
		},
		riskScore: {
			title: 'Current Risk Score',
		},
		timeToRemediate: {
			title: 'Median time to remediate',
			days: 'days',
			forCriticalDescription: 'For critical vulnerabilities must be less then 5 days',
			infoDescription: 'vs <1>10</1> days of Industry average',
		},
		newDiscovery: {
			title: 'New discoveries every',
			median: 'median',
			onAverage: 'on average',
		},
		vulnerabilitiesBySeverity: {
			title: 'Vulnerabilities by Severity',
		},
		distributionOfVulnerabilities: {
			title: 'Distribution of vulnerabilities',
			vulnerability: 'vulnerability',
			vulnerabilities: 'vulnerabilities',
		},
		vulnerabilitiesBySeverityGraph: {
			title: 'Vulnerabilities by Severity',
		},
		vulnerabilitiesByAgeAndSeverity: {
			title: 'Vulnerabilities by Age and Severity',
			dates: {
				oneWeek: '0 - 7 days',
				oneMonth: '8 - 30 days',
				twoMonths: '31 - 60 days',
				threeMonths: '61 - 91 days',
				moreThenThreeMonths: '> 91 days',
			},
		},
		activities: {
			today: 'TODAY',
			thisWeek: 'THIS WEEK',
			noActivitiesToday: 'No activities today',
			noActivitiesThisWeek: 'No activities this week',
			loadMore: 'Load more activity logs',
		},
	},
	enrichers: {
		title: 'Vulnerability enrichers',
		addNewButton: 'Add New Enricher',
		tabs: {
			enrichers: 'Enrichers',
			discovers: 'Discovers without description',
		},
		actions: {
			search: 'Search enrichers',
		},
		table: {
			columns: {
				title: 'Title',
				cve: 'Cve',
				createdAt: 'Created at',
				providers: 'Providers',
			},
			actions: {
				edit: 'Edit',
				remove: 'Remove',
			},
		},
		remove: {
			removingSuccess: 'Vulnerability enricher successfully removed',
			modalTitle: 'Remove vulnerability enricher',
			modalText:
				'Are you sure you want to remove <1>{{title}}</1> from the vulnerability enrichers?',
		},
		createEdit: {
			createTitle: 'Create Vulnerability Enricher',
			editTitle: 'Edit Vulnerability Enricher',
			successfullyCreated: 'Vulnerability enricher successfully created',
			successfullyUpdated: 'Vulnerability enricher successfully updated',
			properties: {
				title: {
					label: 'Title',
					placeholder: 'Enter title',
				},
				cve: {
					label: 'CVE',
					placeholder: 'Enter CVE',
				},
				description: {
					label: 'Description',
					placeholder: 'Enter Description',
				},
				providers: {
					label: 'Providers',
					addButton: 'Add provider',
					noProviders: 'No providers added',
				},
				providersType: {
					placeholder: 'Select provider type',
					alreadyAddedError: 'Provider with the same type already added',
					options: {
						[VulnerabilityProviders.Nuclei]: 'Nuclei',
						[VulnerabilityProviders.Shodan]: 'Shodan',
					},
				},
				providerId: {
					placeholder: 'Enter provider id',
				},
				useCve: {
					label: 'Use CVE as id',
				},
			},
		},
		discoverWithoutEnrichers: {
			table: {
				columns: {
					providerType: 'Provider',
					providerId: 'Provider id',
				},
				actions: {
					addEnricher: 'Add enricher',
				},
			},
		},
	},
}

export default en
