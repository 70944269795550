import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Routes as RoutesConfig } from './config'
import Theme from '../features/theme'
import { ProtectedRoute } from '../components'
import { useAuth } from '../features/auth'
import OverviewNew from '../features/dashboard/pages/OverviewNew'

// We are grouping routes per feature so we can lazy load js
const SettingsRoutes = React.lazy(() => import('../features/settings/Routes'))
const Dashboard = React.lazy(() => import('../features/dashboard/pages/DashboardV2'))
const AuthRoutes = React.lazy(() => import('../features/auth/Routes'))
const InventoryRoutes = React.lazy(() => import('../features/inventory/Routes'))
const OrganizationsRoutes = React.lazy(() => import('../features/organizations/Routes'))
const OrganizationAcceptInvitation = React.lazy(
	() => import('../features/organizations/pages/AcceptInvitation'),
)
const AwarenessRoutes = React.lazy(() => import('../features/awareness/Routes'))
const SimulationRoutes = React.lazy(() => import('../features/simulations/Routes'))
const AdminRoutes = React.lazy(() => import('../features/admin/Routes'))
const ContinuesMonitoringRoutes = React.lazy(() => import('../features/vulnerabilities/Routes'))
const WafRoutes = React.lazy(() => import('../features/waf/Routes'))
const NotificationsRoutes = React.lazy(() => import('../features/notifications/Routes'))
const GroupAdministrationRoutes = React.lazy(() => import('../features/group-admin/Routes'))
const NanoLearnRoutes = React.lazy(() => import('../features/nano-learn/Routes'))
const AdminLeaksRoutes = React.lazy(() => import('../features/leaks/AdminRoutes'))
const LeaksRoutes = React.lazy(() => import('../features/leaks/Routes'))
const ExtensionReportsRoutes = React.lazy(() => import('../features/extension-reports/Routes'))
const OrganizationLibraryRoutes = React.lazy(
	() => import('../features/awareness/OrganizationLibraryRoutes'),
)
const AdminLibraryRoutes = React.lazy(() => import('../features/awareness/AdminLibraryRoutes'))
const AdminVulnerabilitiesRoutes = React.lazy(
	() => import('../features/vulnerabilities/AdminRoutes'),
)
const OrganizationPhishingLibraryRoutes = React.lazy(
	() => import('../features/simulations/OrganizationPhishingLibraryRoutes'),
)

const AppRoutes = () => {
	const { token } = useAuth()

	return (
		<Switch>
			<Route path={RoutesConfig.Theme} component={Theme} />
			<ProtectedRoute
				path={RoutesConfig.Dashboard}
				isAuthenticated={!!token}
				exact={true}
				component={OverviewNew}
			/>
			<Route path={RoutesConfig.Auth} component={AuthRoutes} />
			<ProtectedRoute
				path={RoutesConfig.Settings}
				component={SettingsRoutes}
				isGuestRoute={false}
				isAuthenticated={!!token}
			/>
			<ProtectedRoute
				path={RoutesConfig.Inventory}
				component={InventoryRoutes}
				isGuestRoute={false}
				isAuthenticated={!!token}
			/>
			<Route
				path={RoutesConfig.OrganizationsAcceptInvitation}
				component={OrganizationAcceptInvitation}
			/>
			<ProtectedRoute
				path={RoutesConfig.Organizations}
				component={OrganizationsRoutes}
				isGuestRoute={false}
				isAuthenticated={!!token}
			/>
			<ProtectedRoute
				path={RoutesConfig.Awareness}
				component={AwarenessRoutes}
				isGuestRoute={false}
				isAuthenticated={!!token}
			/>
			<ProtectedRoute
				path={RoutesConfig.OrganizationTemplates}
				component={OrganizationPhishingLibraryRoutes}
				isGuestRoute={false}
				isAuthenticated={!!token}
			/>
			<ProtectedRoute
				path={RoutesConfig.OrganizationLibrary}
				component={OrganizationLibraryRoutes}
				isGuestRoute={false}
				isAuthenticated={!!token}
			/>
			<ProtectedRoute
				path={RoutesConfig.Simulations}
				component={SimulationRoutes}
				isGuestRoute={false}
				isAuthenticated={!!token}
			/>
			<ProtectedRoute
				path={RoutesConfig.ContinuesMonitoring}
				component={ContinuesMonitoringRoutes}
				isGuestRoute={false}
				isAuthenticated={!!token}
			/>
			<ProtectedRoute
				path={RoutesConfig.Waf}
				component={WafRoutes}
				isGuestRoute={false}
				isAuthenticated={!!token}
			/>
			<ProtectedRoute
				path={RoutesConfig.Notifications}
				component={NotificationsRoutes}
				isGuestRoute={false}
				isAuthenticated={!!token}
			/>
			<ProtectedRoute
				path={RoutesConfig.GroupAdministration}
				component={GroupAdministrationRoutes}
				isGuestRoute={false}
				isAuthenticated={!!token}
			/>
			<ProtectedRoute
				path={RoutesConfig.NanoLearn}
				component={NanoLearnRoutes}
				isGuestRoute={false}
				isAuthenticated={!!token}
			/>
			<ProtectedRoute
				path={RoutesConfig.Leaks}
				component={LeaksRoutes}
				isGuestRoute={false}
				isAuthenticated={!!token}
			/>
			{/* Admin routes */}
			<ProtectedRoute
				path={RoutesConfig.AdminLeaks}
				component={AdminLeaksRoutes}
				isGuestRoute={false}
				isAuthenticated={!!token}
			/>
			<ProtectedRoute
				path={RoutesConfig.AdminVulnerabilities}
				component={AdminVulnerabilitiesRoutes}
				isGuestRoute={false}
				isAuthenticated={!!token}
			/>
			<ProtectedRoute
				path={RoutesConfig.AdminAwareness}
				component={AdminLibraryRoutes}
				isGuestRoute={false}
				isAuthenticated={!!token}
			/>
			<ProtectedRoute
				path={RoutesConfig.ExtensionReports}
				component={ExtensionReportsRoutes}
				isGuestRoute={false}
				isAuthenticated={!!token}
			/>
			<ProtectedRoute
				path={RoutesConfig.Administration}
				component={AdminRoutes}
				isGuestRoute={false}
				isAuthenticated={!!token}
			/>
			{/* TODO: real 404 */}
			<Route render={() => <div>404</div>} />
		</Switch>
	)
}

export default AppRoutes
