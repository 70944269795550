export const MISSING_INFO_PLACEHOLDER = '-'

export const printOptionalInfoDetails = (details?: string | number) => {
	return details || MISSING_INFO_PLACEHOLDER
}

export const getFullName = (value: { firstName?: string; lastName?: string }) => {
	if (!value) {
		console.warn('Missing info for user/person')
		return MISSING_INFO_PLACEHOLDER
	}

	return `${value.firstName || ''} ${value.lastName || ''}`
}
