const en = {
	orgMenu: {
		orgSettings: 'Organization settings',
		manageOrg: 'Manage Organizations',
		orgActivity: 'Organization activity',
		userSettings: 'User settings',
		signOut: 'Sign out',
		createNewOrganization: 'Create new Organization',
	},
	manageOrgModal: {
		title: 'Manage Organizations',
		subtitle: 'Switch between organizations',
		smallExplanationAddNewOrg: 'Adding new Organization? Start here',
		addOrgButton: 'Create new Organization',
	},
	menuChanger: {
		platform: 'Platform',
		admin: 'Administration',
	},
	sidebar: {
		overview: 'Overview',
		inventory: 'Inventory',
		people: 'People',
		assets: 'Assets',
		awareness: 'Awareness',
		library: 'Library',
		organizationLessons: 'Lessons',
		organizationNanoPages: 'Nano Pages',
		courses: 'Courses',
		simulations: 'Phishing Simulations',
		continuesMonitoring: 'Monitoring',
		vulnerabilities: 'Vulnerabilities',
		reporting: 'Reporting',
		vulnerabilityEnrichers: 'Enrichers',
		drafts: 'Drafts',
		admin: 'Admin',
		users: 'Users',
		organizations: 'Organizations',
		templates: 'Phishing Templates',
		organizationTemplates: 'Phishing Templates',
		'3rdParty': '3rd parties',
		allAssets: 'Assets',
		lessons: 'Lessons',
		nanoPages: 'Nano Pages',
		dataLeaks: 'Data Leaks',
		waf: 'Waf',
		organizationLearningContent: 'Org. Content',
		blockedIps: 'Blocked Ip Addresses',
		predefinedCourses: 'Predefined Courses',
		groupAdministration: 'Group Administration',
		inventoryAdministration: 'Inventory',
		awarenessAdministration: 'Awareness',
		continuesMonitoringAdministration: 'Monitoring',
		theme: 'Theme',
		nanoLearn: 'Seif Nano Learning',
		leaksAdministration: 'Leaks',
		credentialsAdministration: 'Credentials',
		dataLeaksCredentials: 'Credentials',
		extensionReports: 'Extension Reports',
		courseIndustryStandards: 'Industry Standards',
		peopleOverview: 'People Overview',
	},
}

export default en
