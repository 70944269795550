import { useEffect, useMemo, useState } from 'react'
import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons'
import { Checkbox, Dropdown, Input, Tree } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { DataNode } from 'antd/lib/tree'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { DropdownContent, Button, Text } from '.'
import { useToggleVisibility } from '../hooks'
import { Colors } from '../styles/colors'
import { SPACES } from '../styles/spaces'

type IStringOrNumber = number | string

export interface IFilter {
	label: string
	value: IStringOrNumber
}

interface IProps<T> {
	name: string
	filters?: IFilter[]
	filtersGroup?: DataNode[]
	selected: T[]
	placement?: 'bottomLeft' | 'bottomRight'
	onApply: (newSelection: T[]) => void
	showDropdownSearch?: boolean
}

const FilterDropdown: React.FC<IProps<any>> = ({
	placement,
	name,
	filters,
	filtersGroup,
	selected,
	onApply,
	showDropdownSearch,
}) => {
	const { t } = useTranslation()

	const { isVisible, show, hide } = useToggleVisibility()

	const [localSelected, setLocalSelected] = useState(selected)
	const [searchInput, setSearchInput] = useState<string>('')

	const handleCheckTreeItem = (checkedKeysValue: string[]) => {
		const parentKeys = filtersGroup?.map((group) => group.key)
		setLocalSelected(checkedKeysValue.filter((item) => !parentKeys?.includes(item)))
	}

	const searchedFilters = useMemo(() => {
		if (!filters) {
			return []
		}

		return filters.filter((item) => item.label.toLowerCase().includes(searchInput.toLowerCase()))
	}, [searchInput, filters])

	const searchedFiltersGroup = useMemo(() => {
		if (!filtersGroup) {
			return []
		}

		return filtersGroup.map((item) => {
			const children = item.children?.map((child) => {
				if (!(child.title as string).toLowerCase().includes(searchInput.toLowerCase())) {
					return { ...child, disabled: true }
				}

				return child
			})

			if (!children?.length) {
				return { ...item, disabled: true, children }
			}

			return { ...item, children }
		})
	}, [searchInput, filtersGroup])

	useEffect(() => {
		setLocalSelected(selected)
	}, [selected])

	const handleChangeFilter = (e: CheckboxChangeEvent) => {
		const { value, checked } = e.target
		const newSelection = localSelected.filter((item) => item !== value)
		if (checked) {
			newSelection.push(value)
		}
		setLocalSelected(newSelection)
	}

	const handleApply = () => {
		onApply(localSelected)
		hide()
	}

	const handleClear = () => {
		setLocalSelected([])
		setSearchInput('')
		onApply([])
		hide()
	}

	const handleSearch = (e: any) => {
		setSearchInput(e.target.value)
	}

	return (
		<Dropdown
			dropdownRender={() => (
				<StyledContent>
					{showDropdownSearch && (
						<Input
							value={searchInput}
							onChange={handleSearch}
							placeholder={t(`search`)}
							prefix={<SearchOutlined size={10} />}
							allowClear={true}
							size="small"
							style={{ marginBottom: '8px' }}
						/>
					)}
					<ItemsWrapper>
						{filtersGroup ? (
							<Tree
								checkable
								onCheck={(data) => handleCheckTreeItem(data as string[])}
								checkedKeys={localSelected}
								treeData={searchedFiltersGroup}
							/>
						) : (
							<>
								{searchedFilters?.map((filter) => (
									<div key={filter.value}>
										<Checkbox
											name={filter.label}
											value={filter.value}
											checked={localSelected.includes(filter.value)}
											onChange={handleChangeFilter}
										>
											{filter.label}
										</Checkbox>
									</div>
								))}
								{filters?.length === 0 && (
									<Text zeroMargin size="sm" color={Colors.Dark}>
										{t('common.messages.noOptions')}
									</Text>
								)}
							</>
						)}
					</ItemsWrapper>
					<ApplyButton size="small" type="link" onClick={handleClear}>
						{t('common.clear')}
					</ApplyButton>
					<ApplyButton size="small" type="primary" onClick={handleApply}>
						{t('common.apply')}
					</ApplyButton>
				</StyledContent>
			)}
			trigger={['click']}
			open={isVisible}
			onOpenChange={(visible) => {
				visible ? show() : hide()
			}}
			placement={placement}
		>
			<Trigger hasSelected={selected.length}>
				<span>{name}</span>{' '}
				{selected.length > 0 ? (
					<ActiveFilters>{selected.length}</ActiveFilters>
				) : (
					<CaretDownOutlined />
				)}
			</Trigger>
		</Dropdown>
	)
}

FilterDropdown.defaultProps = {
	placement: 'bottomLeft',
}

const Trigger = styled.div<{ hasSelected }>`
	border: 1px solid ${Colors.Light};
	height: 40px;
	align-items: center;
	border-radius: 6px;
	padding: ${SPACES.EXTRA_SMALL} ${SPACES.SMALL};
	background: ${Colors.Light};
	margin-right: ${SPACES.SMALL};
	&:last-child {
		margin-right: 0;
	}
	span {
		color: ${({ hasSelected }) => (hasSelected ? Colors.Blue : Colors.Dark)};
		line-height: 22px;
	}
	&:hover {
		cursor: pointer;
	}
`

const ActiveFilters = styled.span`
	&&& {
		color: ${Colors.White};
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: ${Colors.Blue};
		display: inline-block;
		padding: 0 6px;
		line-height: 20px;
		text-align: center;
		margin: 0 ${SPACES.EXTRA_SMALL} 0 ${SPACES.XXS};
	}
`

const StyledContent = styled(DropdownContent)`
	padding: ${SPACES.SMALL};
	.ant-checkbox-wrapper {
		color: ${Colors.Dark};
		font-size: 12px;
	}
	.ant-input-affix-wrapper-sm {
		padding: 2px;
	}
`

const ApplyButton = styled(Button)`
	&&& {
		margin-top: ${SPACES.EXTRA_SMALL};
		height: 24px;
		padding: 2px 24px;
		font-size: 12px;
	}
`
const ItemsWrapper = styled.div`
	max-height: 150px;
	overflow: auto;
	.ant-tree-treenode-disabled {
		display: none;
	}
	.ant-tree-list {
		margin-left: -8px;
	}
`
export default FilterDropdown
