import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { RiskScore } from '../../../../../utils/constants'
import { Text } from '../../../../../components'
import { Colors } from '../../../../../styles/colors'
import { SPACES } from '../../../../../styles/spaces'

interface IProps {
	riskScoreEnum: RiskScore
}

const PEOPLE_RISK_SCORE_BASE_TRANSLATE = 'inventory.people.riskScore.tenantOverview'

const RiskScoreSummaryTextNew: React.FC<IProps> = ({ riskScoreEnum }) => {
	const { t } = useTranslation()

	return (
		<TextWrapper>
			<Text zeroMargin color={Colors.Dark}>
				{t(`${PEOPLE_RISK_SCORE_BASE_TRANSLATE}.riskScoreText.${riskScoreEnum}`)}
			</Text>
			<Text zeroMargin color={Colors.Dark}>
				{t(`${PEOPLE_RISK_SCORE_BASE_TRANSLATE}.summaryText.${riskScoreEnum}`)}
			</Text>
		</TextWrapper>
	)
}

const TextWrapper = styled.div`
	font-size: 24px;
	display: flex;
	flex-direction: column;
	font-weight: 500;
	max-width: 680px;
	gap: ${SPACES.DEFAULT};
	padding-left: ${SPACES.DEFAULT};
`

export default RiskScoreSummaryTextNew
