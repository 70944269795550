import React, { useRef } from 'react'
import { MsalProvider, useMsal } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'

const msalConfig = {
	auth: {
		clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID as string,
		authority: 'https://login.microsoftonline.com/common',
	},
	cache: {
		cacheLocation: '', // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
}

interface IProps {
	scopes: string[]
	children: React.ReactElement
	onSuccess(data: any): void
	onFailure(e: Error): void
}

const MicrosoftLogin: React.FC<IProps> = ({ onSuccess, onFailure, scopes, children }) => {
	const { instance } = useMsal()
	const handleLogin = () => {
		instance
			.loginPopup({
				scopes,
			})
			.then(onSuccess)
			.catch(onFailure)
	}

	return React.cloneElement(children, { onClick: handleLogin })
}

const LoginWrapped: React.FC<IProps> = (props: IProps) => {
	const msalInstance = useRef(new PublicClientApplication(msalConfig)).current
	return (
		<MsalProvider instance={msalInstance}>
			<MicrosoftLogin {...props} />
		</MsalProvider>
	)
}

export default LoginWrapped
