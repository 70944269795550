import { DataExportType, Language, NanoPageType, PageType, VideoType } from 'blue-project-types'
import { PredefinedCourseLevel } from 'blue-project-types'
import { AwarenessStatus } from '../utils/types'

const en = {
	common: {
		settings: {
			title: 'Course language',
			subtitle: 'Chose language of instruction',
			training: {
				title: 'Name & Description',
				subtitle: 'The name and description are also visible to learners',
			},
			properties: {
				language: {
					label: 'Course language',
					placeholder: 'Select language',
				},
				name: {
					label: 'Training course name',
					placeholder: 'Training course name',
				},
				description: {
					label: 'Training course description',
					placeholder: 'Description',
				},
				enrollment: {
					label: 'Enrolment date',
				},
				duration: {
					label: 'Durations',
					text: 'Weeks',
				},
			},
			programDuration: {
				title: 'Course  start and end date',
				content1: 'Set up course enrolment date and duration.',
				content2:
					'Whenever a new learner is added, they will have equal amount of time to complete the course.',
				content3:
					'Both the enrolment date and duration can be adjusted individually, from the learners list.',
			},
		},
		courseItem: {
			title: 'Security Awareness Training Program Foundations',
			subtitle:
				'Course description in 2 lines of text, explaining benefits, methodology, modern approach and other amazing stuff about this course',
			list: {
				item1: 'Introduction to information Security1',
				item2: 'Passwords',
				item3: 'Phishing and Ransomware',
				item4: 'Social engineering',
				item5: 'Safe browsing',
			},
			desriptionMsg: 'No description added',
		},
		feature: {
			pageTitle: 'Security Awareness - Courses',
			title: 'This feature is not enabled for your organization.',
			content1: 'To launch your first Security Awareness Course,',
			content2: `improve & monitor your Organization's security,`,
			content3: 'please contact Seif sales team!',
			contactBtn: 'Contact Us',
		},
		messageSettings: {
			properties: {
				enrollment: {
					title: 'Enrolment',
					subtitle: 'Learners receive emails when they are added to course',
					label: 'Include custom message',
					placeholder: 'Course enrolment message',
				},
				completed: {
					title: 'Course completed',
					subtitle: 'Learners receive emails when they complete the course',
					label: 'Include custom message',
					placeholder: 'Course completed message',
				},
				reminder: {
					title: 'Reminder messages',
					subtitle:
						'Learners receive weekly emails for all the courses in the program that are still incomplete',
					label: 'Include custom message',
					placeholder: 'Course reminder message',
				},
			},
		},
		courseResult: {
			rate: 'Completion rate',
			average: 'Average score',
			start: 'Start',
		},
		headerData: {
			createBtn: 'Create Course',
		},
		courseLearners: {
			title: 'Chose your learners',
			subtitle: 'Add all or adjust condition for this Course attendance',
			properties: {
				people: {
					radio1: 'All People',
					radio2: 'New and untrained People',
					radio3: 'Custom segment',
					radio4: 'Invitation link',
					radio5: 'Predefined people',
				},
				tags: {
					label: 'Tags',
					placeholder: 'Select tags to add learners',
				},
			},
			failedCourseAlert: {
				title: 'Target failed course',
				subtitle:
					'Only the learner which failed course is shown. Close alert & highlighting by clicking on got it button.',
				gotItButton: 'Got it',
			},
		},
		courseMessages: {
			title: 'Messages',
			subtitle: 'Preview and optionally configure emails sent to learners',
		},
		courseSettings: {
			title: 'Course settings',
			subtitle: `Configure the course's settings`,
		},
		courseReview: {
			column: {
				name1: 'Course name',
				name2: 'Passing mark',
			},
			title: `Review course content`,
			subtitle: 'Review course content and lesson details',
			courses: {
				basic: 'Security Awareness Basics',
				ASEEAdvanced: 'ASEE Advanced Security Training',
				advanced: 'Advanced Security Training',
				OWASPTop10: 'OWASP Top 10',
			},
			basics: 'Seif Courses',
			custom: 'Custom',
			columns: {
				name: 'Name',
				description: 'Description',
				pages: 'Pages',
				languages: 'Languages',
				order: 'Order',
			},
			language: {
				[Language.En]: 'English',
				[Language.Sr]: 'Serbian',
				[Language.De]: 'German',
				[Language.Es]: 'Spanish',
				[Language.Sl]: 'Slovenian',
				all: 'All',
			},
		},
		courseCreated: {
			title: 'is ready!',
			subtitle: `If you wish to make changes to your course you can do it in the Settings Section of the
			Program Page.`,
			buttonProceed: 'Proceed to Course Page',
		},
		ctaAwareness: {
			title1: 'Launch your first Training Program',
			title2: `to improve & monitor your Organization’s security`,
			buttonCreate: 'Create Course',
		},
		infoAwareness: {
			title: 'Security Awareness',
			score: 'Overall score',
			scoreResult: 'NA',
			complete: 'Training complete',
			average: 'Average time',
		},
		programAwareness: {
			title: 'Awareness Programs',
			subtitle1: 'Awareness',
			subtitle2: 'Advanced',
			content: 'Coming soon',
		},
		addLearners: {
			properties: {
				people: {
					radio1: 'All People',
					radio2: 'New and untrained People',
					radio3: 'Custom segment',
				},
				tags: {
					label: 'Tags',
					placeholder: 'Select tags to add learners',
				},
			},
		},
		basicCourseSettings: {
			title: 'Program settings',
			subtitle: `Configure the program's settings`,
		},
		content: {
			columns: {
				name: 'Lesson name',
				passingScore: 'Passing score',
				type: 'Type',
				pages: 'Pages',
				expectedCompletionTime: 'Completion time',
			},
			premium: 'Premium',
			standard: 'Standard',
			moreinfo: 'See course',
		},
		courseOverview: {
			properties: {
				notCompleted: {
					title: 'Not completed',
					subtitle: "haven't completed before due date",
					actionText: 'See learners',
				},
				toBeReminded: {
					title: 'Upcoming deadline',
					subtitle: `haven't yet completed required e-learning course`,
					actionText: 'Send reminders',
					actionTooltip: 'Learners are already reminded today',
				},
				completionRate: {
					title: 'Course completion rate',
					subtitle: 'All learner progress',
				},
				completedLearners: {
					title: 'learner completion',
					subtitle: `learners who completed the course compared to those who didn't yet`,
				},
				passedLearners: {
					title: 'passing rate',
					subtitle: 'learners who passed compared to whose who failed',
				},
				averageScore: {
					title: 'Average score',
					subTitle: 'for learners that completed the course',
				},
				invitationPending: {
					title: 'Pending invitation',
					subtitle: 'learners who are waiting for invitation',
				},
				invitationNoActivity: {
					title: 'No activity',
					subtitle: `learners who received invitation but didn't open it`,
				},
				invitationOpened: {
					title: 'Invitation open',
					subtitle: `learners who opened invitation but didn't started the course`,
				},
				inProgress: {
					title: 'Learners active',
					subtitle: 'learners who started the course',
				},
			},
		},
		duration: {
			title: 'days left',
			subtitle: 'days duration',
			tag: 'Overdue',
		},
		viewHeaderData: {
			title: 'Start',
			pdfSuffix: 'course_details.pdf',
		},
		quizzesDetails: {
			success: 'success',
			error: 'error',
		},
		learners: {
			sendReminder: {
				message:
					'We noticed there’s no course activity on some learners, you might want to remind them.',
				successMsg: 'Reminders successfully sent',
			},
			properties: {
				firstName: {
					title: 'First name',
				},
				lastName: {
					title: 'Last name',
				},
				groups: {
					title: 'Groups',
				},
				completionRate: {
					title: 'Completion status',
				},
				timeSpent: {
					title: 'Time on the course',
				},
				score: {
					title: 'Course results',
				},
				daysLeft: {
					title: 'Time to cut off',
				},
			},
			actions: {
				edit: 'Edit',
				remove: 'Remove',
				reminder: 'Send Reminder',
				resend: 'Resend Invitation',
			},
			search: {
				placeholder: 'Search for specific learner',
			},
			filters: {
				completionStatus: {
					name: 'Completion status',
					label1: 'Invitation Pending',
					label2: 'No Activity',
					label3: 'Invitation opened',
					label4: 'Learning started',
					label5: 'Learning completed',
				},
				courseResults: {
					name: 'Course results',
					label1: 'Failed',
					label2: 'Passed',
				},
				groups: {
					name: 'Groups',
				},
			},
			crudActions: {
				send: 'Send Reminders',
				add: 'Add Learners',
				invitationLink: 'Invitation link',
			},
			modal1: {
				title: 'Chose your learners',
				subtitle:
					"Please be aware that by selecting the 'All People' or 'Custom Segment' options, you will only add individuals who are not currently assigned to this program.",
			},
			modal2: {
				title: 'Update learner',
			},
			modal3: {
				title: 'Send reminders',
			},
			linkModal: {
				title: 'Invitation link',
				subtitle:
					'Copy the link or scan the QR code to be redirected to the page for adding learner.',
				invitationLink: 'Invitation link',
			},
			modalConfirm: {
				title: 'Remove this learner?',
				subtitle1: 'Are you sure you want to remove',
				subtitle2: 'from this course?',
			},
			export: {
				title: 'Export',
				[DataExportType.CSV]: 'CSV export',
				[DataExportType.XLS]: 'XLS export',
			},
			learnersScore: {
				courseAverage: {
					average: 'Score is equal to course average',
					bellow: 'Score is bellow course average',
					above: 'Score is above course average',
				},
				industryAverage: {
					average: 'Score is equal to industry average',
					bellow: 'Score is bellow industry average',
					above: 'Score is above industry average',
					notSet: 'Industry average score is not set',
				},
				explanation:
					'Score is calculated by assigning points for correct answers and deducting points for multiple incorrect answers',
			},
		},
		learnerStatus: {
			invitationPending: {
				title: 'Pending invitation',
				subtitle: 'Course starts at',
			},
			invitationSentNoActivity: {
				title: 'Invitation sent',
				subtitle: 'No Activity',
			},
			invitationOpened: {
				title: 'Invitation opened',
				subtitle: 'Course not started',
			},
			learningInProgress: {
				title: 'In progress',
			},
			learningCompleted: {
				title: 'Completed',
			},
		},
		messagesSettingsForm: {
			title: 'Messages',
			subtitle: 'Preview and optionally configure emails sent to learners',
		},
		quizProgress: {
			passed: 'Passed',
			failed: 'Failed',
			notCompleted: 'Not completed yet',
		},
		securityContact: {
			title: 'Security Policy & Contact',
			subtitle: 'Preview and optionally configure security policy and contact information',
			properties: {
				policy: {
					title: 'Policy',
					subtitle: 'Policy wording...',
					label: 'Policy link',
					placeholder: 'Policy link',
				},
				security: {
					title: 'Security contact',
					subtitle: 'Contact wording...',
					label1: 'Name',
					placeholder1: 'Name',
					label2: 'Security contact email',
					placeholder2: 'Email',
					label3: 'Security contact phone number',
					placeholder3: 'Phone (+3816512345678)',
				},
				errorMsg: {
					email: 'Invalid email',
					url: 'Invalid url',
				},
			},
		},
		sendReminders: {
			title: `Review and send reminders to users who still haven't finished the course`,
			properties: {
				noActivity: {
					title: 'No activity',
					subtitle: `Users that still haven't opened enrolment email`,
				},
				invitationOpened: {
					title: 'Invitation opened',
					subtitle: `Users that opened their enrolment email, but still haven't started the course`,
				},
				learningStarted: {
					title: 'Learning started',
					subtitle: `Users that started the course but haven't completed yet`,
				},
			},
			actionsBtn: 'Send Email Reminders',
		},
		updateLearner: {
			label1: 'Enrolment date',
			label2: 'Durations',
			weeks: 'Weeks',
		},
		api: {
			useAddLearners: {
				success1: 'Successfully added',
				success2: 'learners',
				info: 'No new learners to be added',
				error: 'Error happened',
			},
			useRemoveLearner: {
				success: 'Learner successfully removed',
				error: 'Error happened',
			},
			useResendInvitation: {
				success: 'Invitation successfully sent',
			},
			useSendReminder: {
				reminders: 'Reminders',
				reminder: 'Reminder',
				info: 'successfully sent',
			},
			useUpdateCourse: {
				success: 'Course successfully updated',
			},
			useUpdateLearner: {
				success: 'Learner successfully updated',
				error: 'Error happened',
			},
			useUpdateLesson: {
				success: 'Lesson successfully updated',
				error: 'Error happened',
			},
			useUpdateLessonPageOrder: {
				success: 'Lesson page order successfully updated',
				error: 'Error happened',
			},
			useRemoveLessonPage: {
				success: 'Page successfully removed',
				error: 'Error happened',
			},
			useCopyLesson: {
				success: 'Lesson successfully copied!',
				error: 'Error happened',
			},
			useRemovePredefinedCourse: {
				success: 'Predefined course removed',
				error: 'Error happened',
			},
			useUpdatePredefinedCourse: {
				success: 'Predefined course successfully updated!',
				error: 'Error happened',
			},
		},
		pages: {
			courses: {
				title: 'Courses',
				subtitle: 'Here you can see all active, and upcoming courses.',
				section1: 'Active Courses',
				section2: 'Upcoming Courses',
				section3: 'Finished Courses',
				course: 'Course',
				titleNoItemCard: 'No {{status}} {{awarenessName}}',
				descriptionNoItemCard: {
					[AwarenessStatus.InProgress]: 'Running one will keep your team`s guard up.',
					[AwarenessStatus.Upcoming]: 'Create one now to boost your team`s skills.',
					[AwarenessStatus.Finished]:
						'Your company is just getting started on cybersecurity training.',
				},
			},
			createCourseWizard: {
				error: 'Please populate current data.',
				title: 'Launch New Course',
				subtitle:
					'Here you can choose training content, add learners, set dates, and other settings, as well as create customized messages to launch your course.',
				titleStep1: 'Content',
				descriptionStep1: 'Review content',
				titleStep2: 'Learners',
				descriptionStep2: 'Choose learners',
				titleStep3: 'Settings',
				descriptionStep3: 'Configure settings',
				titleStep4: 'Messages',
				descriptionStep4: 'Customize messages',
			},
			learners: {
				text: 'Learners',
			},
			overview: {
				text: 'Overview',
			},
			viewCourse: {
				subtitle:
					'Here you can review course results and content, manage learners, as well as configure settings.',
				tab1: 'OVERVIEW',
				tab2: 'CONTENT',
				tab3: 'LEARNERS',
				tab4: 'SETTINGS',
			},
			organizationContent: {
				pageTitle: 'Organization Content',
				commonLessons: 'SEIF LESSONS',
				organizationLessons: 'CUSTOM LESSONS',
				commonNanoPages: 'SEIF NANO PAGES',
				organizationNanoPages: 'CUSTOM NANO PAGES',
			},
			commonContent: {
				pageTitle: 'Lessons',
			},
		},
		lessons: {
			title: 'Lessons',
			search: {
				placeholder: 'Search lessons',
			},
			premiumFilter: {
				name: 'Type',
				premium: 'Premium',
				standard: 'Standard',
			},
			actions: {
				edit: 'Edit',
			},
			columns: {
				name: 'Name',
				pages: 'Pages',
				type: 'Type',
				languages: 'Languages',
				createdAt: 'Date added',
				groups: 'Library Groups',
				expectedCompletionTime: 'Completion time',
			},
			isStandard: 'Standard',
			isPremium: 'Premium',
			addNewButton: 'Create New Lesson',
			createEditLesson: {
				create: 'Create Lesson',
				back: 'Back',
				info: 'INFO',
				pages: 'PAGES',
				createLesson: 'Create New Lesson',
				editLesson: 'Edit Lesson',
				createOrganizationLesson: 'Create New Organization Lesson',
				editOrganizationLesson: 'Edit Organization Lesson',
				firstSection: {
					title: 'Name And Type',
					subtitle: 'Name and type are used internally',
				},
				secondSection: {
					title: 'Language',
					subtitle: 'Select language and title for each',
				},
				properties: {
					name: {
						label: 'Lesson name',
						placeholder: 'Enter name',
					},
					premium: {
						label: 'Premium',
					},
					expectedCompletionTime: {
						label: 'Completion time',
						placeholder: 'Enter completion time',
					},
					lessonContent: {
						title: {
							label: 'Lesson title for this language',
							placeholder: 'Enter title',
						},
						language: {
							[Language.En]: 'English',
							[Language.Sr]: 'Serbian',
							[Language.De]: 'German',
							[Language.Es]: 'Spanish',
							[Language.Sl]: 'Slovenian',
						},
					},
					pages: {
						title: 'Lesson Pages',
						subtitle: 'Add and reorder pages',
						name: 'Name',
						type: 'Type',
						actions: 'Actions',
						addNewBtn: '+ Add New Page',
						editBtn: 'Edit',
						viewBtn: 'View',
						removeBtn: 'Remove',

						removingLessonPage: {
							title: 'Remove this page?',
							subtitle1: 'Are you sure you want to remove',
							subtitle2: 'from lesson?',
							ongoingCoursesExist: 'There are ongoing/upcoming courses containing this page!',
							error: 'Error removing page!',
						},
					},
					tags: {
						label: 'Library Groups',
						placeholder: 'Enter library groups',
					},
				},
			},
			removingLesson: {
				title: 'Remove this lesson?',
				subtitle: 'Are you sure you want to remove <1>{{lessonName}}</1> from learning content?',
				ongoingCoursesExist: 'There are ongoing/upcoming courses containing this lesson!',
				error: 'Error removing lesson!',
			},
			copy: 'Copy',
			copyingLesson: {
				title: 'Copy this lesson?',
				subtitle: 'Are you sure you want to copy <1>{{lessonName}}</1> from common lessons?',
			},
		},
		createPage: {
			pageTitle: 'Create Page',
			title: 'Name and Type',
			subtitle: 'Name and type are used internally',
			languageContent: 'Content language',
			options: {
				[PageType.Text]: 'Text',
				[PageType.Video]: 'Video',
				[PageType.Quiz]: 'Quiz',
				[PageType.Custom]: 'Custom',
			},
			lang: {
				[Language.En]: 'English',
				[Language.Sr]: 'Serbian',
				[Language.De]: 'German',
				[Language.Es]: 'Spanish',
				[Language.Sl]: 'Slovenian',
			},
			success: 'New Page successfully created!',
			properties: {
				type: {
					label: 'Type',
					placeholder: 'Select page type',
					errorMsg: 'Page type is required',
				},
				name: {
					label: 'Page name (internal)',
					placeholder: 'Enter page name',
					errorMsg: 'Page name is required',
				},
				description: {
					label: 'Description',
					placeholder: 'Add description',
					errorMsg: 'Page description is required',
				},
				lang: {
					errorMsg: 'Language is required',
				},
				title: {
					label: 'Title',
					placeholder: 'Enter title',
					errorMsg: 'Title is required',
				},
				sidebarTitle: {
					label: 'Sidebar Title',
					placeholder: 'Enter sidebar title',
					errorMsg: 'Sidebar title is required',
				},
				content: {
					label: 'Content',
					placeholder: 'Enter Content',
					errorMsg: 'Content is required',
				},
				videoLink: {
					label: 'Video Link',
					placeholder: 'Enter link for video',
				},
				videoLinkTranslate: {
					label: 'Video Translate Link',
					placeholder: 'Enter link for video translate',
				},
				explanationCorrect: {
					label: 'Explanation correct',
					placeholder: 'Enter explanation correct answer',
					errorMsg: 'Explanation correct is required',
				},
				explanationIncorrect: {
					label: 'Explanation incorrect',
					placeholder: 'Enter explanation incorrect answer',
					errorMsg: 'Explanation in correct is required',
				},
				question: {
					label: 'Question',
					placeholder: 'Enter question',
					errorMsg: 'Question is required',
				},
				answer: {
					label: 'Answers (select only one correct answer)',
					placeholder: 'Enter answer option',
					remove: 'Remove this answer option!',
					add: '+ Add answer option',
					errorMsg:
						'Answer fields is required. You must fill in a minimum of 2 answers & select only one correct answer.',
				},
				componentName: {
					label: 'Component Name',
					placeholder: 'Enter custom component name',
					tooltip:
						'In agreement with the administrator, write the appropriate name of the custom component, which must match the front-end name of the component',
					errorMsg: 'Component name is required',
				},
			},
		},
		editPage: {
			pageTitle: 'Edit Page',
			success: 'Page is successfully updated!',
		},
		predefinedCourses: {
			pageTitle: 'Predefined courses',
			addNewButton: '+ Create new predefined course',
			errorTitle: 'Error happened',
			search: {
				placeholder: 'Search predefined courses',
			},
			columns: {
				name: 'Name',
				description: 'Description',
				level: 'Difficulty level',
				passingScore: 'Passing mark',
				tenant: 'Organization',
				createdAt: 'Date added',
				actions: {
					edit: 'View/Edit',
					remove: 'Remove',
				},
			},
			levelOptions: {
				[PredefinedCourseLevel.Beginner]: 'Beginner',
				[PredefinedCourseLevel.Intermediate]: 'Intermediate',
				[PredefinedCourseLevel.Advanced]: 'Advanced',
			},
			removingPredefined: {
				title: 'Remove this predefined course?',
				subtitle1: 'Are you sure you want to remove',
				subtitle2: 'from predefined courses?',
			},
		},
		predefinedCourse: {
			createTitle: 'Create Predefined course',
			editTitle: 'View/Edit Predefined course',
			form: {
				name: {
					label: 'Name',
					placeholder: 'Enter name',
				},
				description: {
					label: 'Description',
					placeholder: 'Enter description',
				},
				image: {
					label: 'Logo',
					dragAndDropText:
						"Upload predefined course logo <br/> <1>Drag'n'drop or click to add from computer</1>",
				},
				avatar: {
					label: 'Avatar',
					dragAndDropText:
						"Upload predefined course avatar <br/> <1>Drag'n'drop or click to add from computer</1>",
				},
				level: {
					label: 'Difficulty level',
					placeholder: 'Please select level',
					options: {
						[PredefinedCourseLevel.Beginner]: 'Beginner',
						[PredefinedCourseLevel.Intermediate]: 'Intermediate',
						[PredefinedCourseLevel.Advanced]: 'Advanced',
					},
				},
				passingScore: {
					label: 'Passing score',
					placeholder: 'Enter passing score in percent',
				},
				tenantId: {
					allTenants: 'All',
					label: 'Organization',
					placeholder: 'Select organization',
				},
				lessonIds: {
					label: 'Lessons',
					placeholder: 'Select lessons',
				},
				predefinedCourseContent: {
					language: {
						[Language.En]: 'English',
						[Language.Sr]: 'Serbian',
						[Language.De]: 'German',
						[Language.Es]: 'Spanish',
						[Language.Sl]: 'Slovenian',
					},
					name: {
						label: 'Predefined course name for this language',
						placeholder: 'Enter name',
					},
				},
				premium: {
					label: 'Premium',
				},
			},
			courseInfoSection: {
				title: 'Course information',
				subtitle: 'Fill course information',
			},
			courseLangSection: {
				title: 'Course languages',
				subtitle: 'Fill name and description for each',
			},
			courseLessonsSection: {
				title: 'Course lessons',
				subtitle: 'Select lessons',
				langMissingAlert: "There is lesson that doesn't have some of the selected languages!",
			},
		},
		linkInvitationSettings: {
			disable: 'Disable',
			enable: 'Enable',
			disableModalSubTitle: 'Are you sure you want to disable link invitation?',
			disableModalTitle: 'Disable link invitation',
			enableModalSubTitle: 'Are you sure you want to enable link invitation?',
			enableModalTitle: 'Enable link invitation',
			title: 'Link Invitation',
			subtitle: 'Configure if course is available via invitation link',
			status: 'Status',
			active: 'Active',
			inactive: 'Inactive',
			disableSuccess: 'Invitation via link successfully disabled',
			enableSuccess: 'Invitation via link successfully enabled',
		},
	},
	learningContent: {
		nanoPagesTitle: 'Nano Pages',
		lessonsTitle: 'Lessons',
	},
	nanoPages: {
		search: {
			placeholder: 'Search nano pages',
		},
		columns: {
			name: 'Name',
			type: 'Type',
			languages: 'Languages',
			groups: 'Library Groups',
			createdAt: 'Date added',
		},
		createBtn: 'Create New Nano Page',
		typeFilter: {
			name: 'Type',
			[NanoPageType.SimpleQuiz]: 'Basic Quiz',
			[NanoPageType.VideoQuiz]: 'Video Quiz',
			[NanoPageType.CustomHTML]: 'Custom HTML',
		},
		actions: {
			edit: 'Edit',
			remove: 'Remove',
		},
		createEditNanoPage: {
			create: 'Create New Nano Page',
			createBtn: 'Create Nano Page',
			editOrganizationNanoPage: 'Edit Organization Nano Page',
			editNanoPage: 'Edit Nano Page',
			back: 'Back',
			createOrganization: 'Create New Orgnanization Nano Page',
			firstSection: {
				title: 'Name and Type',
				subtitle: 'Name and type are used internally',
			},
			secondSection: {
				title: 'Content',
				subtitle: 'Select language and title for each',
			},
			properties: {
				type: {
					label: 'Type',
					placeholder: 'Select type',
				},
				name: {
					label: 'Nano page name(internal)',
					placeholder: 'Enter name',
				},
				tags: {
					label: 'Library Groups',
					placeholder: 'Enter library groups',
				},
				languages: {
					label: 'Languages',
					placeholder: 'Select languages',
					error: 'Minimum one selected language',
				},
				emailSubject: {
					label: 'Email subject',
					placeholder: 'Enter email subject',
				},
				premium: {
					label: 'Premium',
				},
				languageContent: 'Language Content',
			},
			customForm: {
				label: 'HTML editor',
				placeholder: 'Enter description',
			},
			videoForm: {
				videoLink: {
					label: 'Video Link',
					placeholder: 'Enter link for video',
				},
				videoType: {
					label: 'Video Type',
					placeholder: 'Select Video Type',
					[VideoType.External]: 'External',
					[VideoType.InternalSimpleVideo]: 'Internal Simple Video',
				},
				videoThumbnail: {
					label: 'Video Thumbnail',
				},
			},
		},
		removingNanoPage: {
			title: 'Remove this nano page?',
			subtitle: 'Are you sure you want to remove <1>{{nanoPageName}}</1> from learning content?',
			ongoingCampaignsExist: 'There are active nano campaigns containing this page!',
		},
	},
	uploadVideo: {
		label: 'Video Link',
		placeholder: 'Enter video link',
		btn: 'Upload',
	},
	language: {
		name: 'Language',
		[Language.En]: 'English',
		[Language.Sr]: 'Serbian',
		[Language.De]: 'German',
		[Language.Es]: 'Spanish',
		[Language.Sl]: 'Slovenian',
	},
	showListBtn: {
		seeMore: 'See more',
		seeLess: 'See less',
	},
}

export default en
