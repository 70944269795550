import { Layout } from 'antd'
import styled from 'styled-components'
import { IUser, SuperAdminPermissions } from 'blue-project-types'

import { Button } from '../../components'
import { useUI } from '../../context/ui'
import { useAuth } from '../../features/auth'
import VerifyEmailModal from '../../features/auth/components/VerifyEmailModal'
import { Routes } from '../../routes/config'
import { SPACES } from '../../styles/spaces'
import { adoptMarginToTheSidebar, adoptWidthToTheSidebar } from './../utils/styleHelpers'
import CompanyUserMenu from './CompanyUserMenu'
import LanguageChanger from './LanguageChanger'
import NotificationsHeader from '../../features/notifications/components/NotificationsHeader'
import { useCan } from '../../hooks'
import MenuChanger from './MenuChanger'

// const UserWithTenantsHeaderActions = () => {
// 	// 	const { coursesCount } = useCountCourses()

// 	// if (coursesCount === 0) {
// 	// 	return (
// 	// 		<Button type="primary" to={Routes.Awareness}>
// 	// 			Preview Awareness Training
// 	// 		</Button>
// 	// 	)
// 	// }

// 	return null
// }

const Actions = ({ user, onShowVerifyModal }: { user: IUser; onShowVerifyModal(): void }) => {
	if (!user?.isVerified) {
		return (
			<ButtonActionsWrapper>
				<Button type="primary" onClick={onShowVerifyModal}>
					Verify Email
				</Button>
			</ButtonActionsWrapper>
		)
	}

	if (user?.tenants?.length === 0) {
		return (
			<ButtonActionsWrapper>
				<Button type="primary" to={Routes.OrganizationsSetup}>
					Get Started
				</Button>
				<Button to={Routes.Courses}>Preview Awareness Training</Button>
			</ButtonActionsWrapper>
		)
	}

	// Commented for now - Check later if needed
	// if (user?.tenants?.length) {
	// 	return <UserWithTenantsHeaderActions />
	// }

	return null
}

const Header = () => {
	const { isSidebarOpen, isVerifyModalVisible, toggleIsVerifyModalVisible } = useUI()
	const { user } = useAuth()

	const canReadTenants = useCan(SuperAdminPermissions.ReadTenants)
	const canManageLearningContent = useCan(SuperAdminPermissions.ManageLearningContent)
	const canMangeLeaks = useCan(SuperAdminPermissions.ManageLeaks)
	const canMangePhishing = useCan(SuperAdminPermissions.ManagePhishing)
	const canMangePhishingContent = useCan(SuperAdminPermissions.ManageTemplatesLibrary)
	const canManageInventory = useCan(SuperAdminPermissions.ManageInventory)
	const canManageVulnerability = useCan(SuperAdminPermissions.ManageInventory)

	const canSeeSwitcher =
		canReadTenants ||
		canManageLearningContent ||
		canMangeLeaks ||
		canMangePhishing ||
		canMangePhishingContent ||
		canManageInventory ||
		canManageVulnerability

	return (
		<StyledHeader isSidebarOpen={isSidebarOpen} showNotVerifiedLabel={user && !user.isVerified}>
			{canSeeSwitcher && (
				<LeftHeader>
					<MenuChanger />
				</LeftHeader>
			)}
			<RightHeader>
				<NotificationsHeader />
				{user && <Actions user={user} onShowVerifyModal={toggleIsVerifyModalVisible} />}
				<LanguageChanger />
				{user && <CompanyUserMenu />}
			</RightHeader>
			{user && (
				<VerifyEmailModal
					open={isVerifyModalVisible}
					onHide={toggleIsVerifyModalVisible}
					user={user}
				/>
			)}
		</StyledHeader>
	)
}

const RightHeader = styled.div`
	height: 100%;
	float: right;
	display: flex;
	align-items: center;
`

const LeftHeader = styled.div`
	width: 100px;
	height: 100%;
	display: inline-flex;
`

const StyledHeader = styled(({ isSidebarOpen, showNotVerifiedLabel, ...rest }) => (
	<Layout.Header {...rest} />
))`
	position: fixed;
	box-shadow: 0 0.5px 2px 0 rgba(0, 21, 41, 0.12);
	top: 0;
	z-index: 2;
	width: ${(props) => adoptWidthToTheSidebar(props.isSidebarOpen)};
	margin-left: ${(props) => adoptMarginToTheSidebar(props.isSidebarOpen)};
	background: #fff;
	padding: 0 ${SPACES.LARGE};
	transition: all 0.2s ease-out;
	margin-top: ${(props) => (props.showNotVerifiedLabel ? '40px' : 0)};
`

const ButtonActionsWrapper = styled.div`
	margin: 0 ${SPACES.EXTRA_SMALL};
	a {
		margin-right: ${SPACES.SMALL};
		&:last-child {
			margin-right: 0;
		}
	}
`

export default Header
