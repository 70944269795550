import { ITenant } from 'blue-project-types'
import moment from 'moment'
import { Colors } from '../styles/colors'

const DEMO_COMPANY = {
	id: 'demo-company-id',
	name: 'Cool Start-up',
	logo: undefined,
	webUrl: undefined,
	color: Colors.Violet,
} as ITenant

const DASHBOARD_DEMO = {
	organizationInfo: {
		people: 24,
		organizations: 3,
		activeAssignments: 1,
		peopleLearning: 7,
	},
	assignmentsInfo: {
		totalLearners: 20,
		completedLearners: 10,
		learningLearners: 7,
		learnerCompletionPercentValue: 50,
		courseCompletionRatePercentValue: 75,
		averageScore: 80,
		passedLearners: 10,
		passedLearnersPercentValue: 100,
	},
	learningAssignmentsInfo: [
		{
			statistic: {
				completionRate: 75,
				averageScore: 80,
			},
			enrollmentDate: moment().subtract(1, 'd').toString(),
			description: 'Demo course description',
			name: 'Demo course',
		},
	] as any,
}

export const DEMO_DATA = {
	company: DEMO_COMPANY,
	dashboard: DASHBOARD_DEMO,
}
