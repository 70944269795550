import { ExportRequestStatus } from 'blue-project-types'
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'

import { Colors } from '../../styles/colors'
import { IPdfExportsData, PdfDownloadStatus } from './context'

interface IProps {
	pdf: IPdfExportsData
}

const BASE_TRANSLATE_KEY = 'common.pdfDownload'

const OneFile: React.FC<IProps> = ({ pdf }) => {
	const { t } = useTranslation()

	if (
		[
			PdfDownloadStatus.Downloading,
			PdfDownloadStatus.PendingToBeCreated,
			PdfDownloadStatus.ReadyToBeDownloaded,
		].includes(pdf.downloadStatus)
	) {
		return (
			<div>
				{pdf.fileName} - {t(`${BASE_TRANSLATE_KEY}.${pdf.downloadStatus}`)}
				<Spin
					indicator={
						<LoadingOutlined style={{ marginLeft: 8, fontSize: 16, color: Colors.White }} spin />
					}
					size="default"
				/>
			</div>
		)
	}
	if (pdf.exportRequest?.status === ExportRequestStatus.Completed) {
		return (
			<div>
				{pdf.fileName}{' '}
				<CheckOutlined style={{ fontSize: 16, color: Colors.White, marginLeft: 8 }} />
			</div>
		)
	}
	if (pdf.exportRequest?.status === ExportRequestStatus.Failed) {
		return (
			<div>
				{pdf.fileName} - {t(`${BASE_TRANSLATE_KEY}.error`)}
			</div>
		)
	}

	return null
}

export default OneFile
