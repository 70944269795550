import { ModalActions, Button, Text } from '.'
import { message, Upload, Select, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { UploadChangeParam } from 'antd/lib/upload'
import styled from 'styled-components'
import { CloseCircleOutlined } from '@ant-design/icons'
import { uniqueId } from 'lodash'
import { LoadingOutlined } from '@ant-design/icons'
import axios from 'axios'

import { validateImageFile } from '../utils/images'
import { Colors } from '../styles/colors'
import { SPACES } from '../styles/spaces'

interface IProps {
	uploadImageUrl?: string
	onCancel(): void
	onSave(urls: IUploadResponse[]): void
}

interface IUploadStatus {
	src?: string
	isUploading: boolean
	uid: string
	viaLink?: boolean
	title?: string
}

interface IUploadStatusData {
	[id: string]: IUploadStatus
}

export interface IUploadResponse {
	title?: string
	src: string
}

export const uploadImage = async (url: string, file: File) => {
	const formData = new FormData()
	formData.set('image', file)

	const response = await axios.post(url, formData)
	return response.data
}

const AddImageForm: React.FC<IProps> = ({ onCancel, onSave, uploadImageUrl }) => {
	const [uploadingImageStatus, setUploadingImageStatus] = useState<IUploadStatusData>({})

	const { t } = useTranslation()

	const handleValidateImage = (file: any) => {
		const { success, error } = validateImageFile(file)
		if (error) {
			setUploadingImageStatus((current) => ({
				...current,
				[file.uid]: { isUploading: false, uid: file.uid },
			}))
			message.error(error)
		}

		return success
	}

	const handleUploadImage = (info: UploadChangeParam) => {
		const { file } = info
		const updateImageStatus = (isUploading, additionalData = {}) => {
			setUploadingImageStatus((current) => ({
				...current,
				[file.uid]: { isUploading, uid: file.uid, ...additionalData },
			}))
		}

		if (file.percent !== 100) {
			if (file.status === 'error') {
				message.error('Error happened!')
				updateImageStatus(false)
			} else if (file.status === 'uploading') {
				updateImageStatus(true)
			}
		}

		if (file.response) {
			updateImageStatus(false, file.response)
		}
	}

	const handleUploadRequest = async ({ file, onSuccess, onError }: any) => {
		if (!uploadImageUrl) return
		try {
			const response = await uploadImage(uploadImageUrl, file)
			onSuccess(response)
		} catch (e) {
			onError(e)
		}
	}

	const handleRemoveImage = (uid: string) => {
		setUploadingImageStatus((current) => {
			const newData = { ...current }
			delete newData[uid]
			return newData
		})
	}

	const handleAddLink = (links: string[]) => {
		const newLinksToBeAdded: IUploadStatusData = {}
		links.forEach((link) => {
			const alreadyExists = Object.values(uploadingImageStatus).find((image) => image.src === link)
			if (!alreadyExists) {
				const id = uniqueId()
				newLinksToBeAdded[id] = {
					uid: id,
					src: link,
					isUploading: false,
					viaLink: true,
				}
			}
		})
		if (Object.keys(newLinksToBeAdded).length) {
			setUploadingImageStatus((current) => ({
				...current,
				...newLinksToBeAdded,
			}))
		}
	}

	const handleDeleteLink = (url: string) => {
		setUploadingImageStatus((current) => {
			const imageToBeDeleted = Object.values(current).find(
				(image) => image.src === url && image.viaLink === true,
			)
			if (imageToBeDeleted) {
				const newImages = { ...current }
				delete newImages[imageToBeDeleted.uid]
				return newImages
			}
			return current
		})
	}

	const handleSave = () => {
		const urlData = Object.values(uploadingImageStatus).map((image) => ({
			src: image.src,
			title: image.title,
		})) as IUploadResponse[]

		onSave(urlData)
	}

	const isAnyImageUploaded = Object.values(uploadingImageStatus).find(
		(imageStatus) => !!imageStatus.src,
	)

	const isAnyUploading = Object.values(uploadingImageStatus).find(
		(imageStatus) => imageStatus.isUploading,
	)
	return (
		<div>
			<AddImageMultiSelectWrapper>
				<Select
					value={Object.values(uploadingImageStatus)
						.filter((image) => image.viaLink)
						.map((image) => image.src!)}
					mode="tags"
					style={{ width: '100%' }}
					tokenSeparators={[',', ' ']}
					id="imageUrls"
					placeholder="Add image urls"
					open={false}
					onChange={handleAddLink}
					onDeselect={handleDeleteLink}
				/>
			</AddImageMultiSelectWrapper>
			<Or>or</Or>
			<Upload.Dragger
				customRequest={handleUploadRequest}
				onChange={handleUploadImage}
				beforeUpload={handleValidateImage}
				multiple={true}
				showUploadList={false}
			>
				<UploadText>
					<Text zeroMargin>
						Upload image <span>Drag'n'drop or click to add from computer</span>
					</Text>
					{isAnyUploading && <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />}
				</UploadText>
			</Upload.Dragger>

			{isAnyImageUploaded && (
				<ImageWrapper>
					{Object.values(uploadingImageStatus)
						.filter((image) => !image.isUploading && image.src)
						.map((image) => (
							<li key={image.uid}>
								<img src={image.src} alt={image.title} />
								<CloseCircleOutlined onClick={() => handleRemoveImage(image.uid)} />
							</li>
						))}
				</ImageWrapper>
			)}
			<ModalActions>
				<Button type="link" onClick={onCancel}>
					{t('common.cancel')}
				</Button>
				<Button type="primary" disabled={!isAnyImageUploaded} onClick={handleSave}>
					{t('common.imageUpload.addImages')}
				</Button>
			</ModalActions>
		</div>
	)
}

const UploadText = styled.div`
	padding: ${SPACES.DEFAULT} 0;
	position: relative;
	.ant-spin {
		position: absolute;
		right: ${SPACES.XXS};
		bottom: 0;
	}
	span {
		color: ${Colors.Gray};
	}
`

const ImageWrapper = styled.ul`
	padding: 0;
	margin: ${SPACES.SMALL} 0;
	display: flex;
	flex-wrap: wrap;
	li {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 136px;
		margin-right: ${SPACES.SMALL};
		margin-bottom: ${SPACES.SMALL};
		list-style: none;
		position: relative;
		padding: ${SPACES.SMALL};
		border: 1px solid ${Colors.Blue};
		border-radius: 4px;
		transition: all 0.3s;
		&:last-child {
			margin-right: 0;
		}
		.anticon-close-circle {
			position: absolute;
			right: 4px;
			top: 4px;
			svg {
				transition: all 0.3s;
				fill: ${Colors.Gray};
				&:hover {
					fill: rgba(0, 0, 0, 0.75);
				}
			}
			&:hover {
				cursor: pointer;
			}
		}
	}
	img {
		width: 100%;
	}
`

const AddImageMultiSelectWrapper = styled.div`
	margin-bottom: ${SPACES.DEFAULT};
`

const Or = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: ${SPACES.DEFAULT};
`

export default AddImageForm
