import { NotificationType } from 'blue-project-types'

const en = {
	common: {
		title: 'NOTIFICATIONS',
		markAll: 'Mark all as read',
		loadMore: 'Load more notifications',
		type: {
			[NotificationType.VulnerabilityCreated]: 'Vulnerability',
			[NotificationType.TargetFailed]: 'Phishing simulations',
			[NotificationType.CredentialLeaked]: 'Credential leaks',
			[NotificationType.VulnerabilityActivityCreated]: 'Vulnerability activity',
			[NotificationType.LearnerFailedCourse]: 'Courses',
		},
		text: {
			[NotificationType.VulnerabilityCreated]: '{{severity}} vulnerability has been discovered',
			[NotificationType.VulnerabilityActivityCreated]:
				'New activity created with status: {{vulnerabilityActivityStatus}} for vulnerability: {{vulnerabilityTitle}}',
			[NotificationType.TargetFailed]:
				'{{personFirstName}} {{personLastName}} has failed the simulation {{simulationName}}',
			[`${NotificationType.CredentialLeaked}_one`]:
				'New leak has been discovered for {{usernames}}',
			[`${NotificationType.CredentialLeaked}_multiple`]:
				'{{numberOfLeaks}} new leaks has been discovered for {{domain}}',
			[`${NotificationType.LearnerFailedCourse}`]:
				'{{courseName}}: {{learnerName}} has failed the course',
		},
	},
}

export default en
