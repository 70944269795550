import { IApiError } from 'blue-project-types'
import { useQuery } from 'react-query'
import {
	fetchNumberOfUnreadNotifications,
	FETCH_NUMBER_OF_UNREAD_NOTIFICATIONS,
} from '../utils/api'

const useFetchNumberOfUnreadNotifications = () => {
	const {
		isLoading: isFetchingNumberOfUnreadNotifications,
		error: errorFetchingNumberOfUnreadNotifications,
		data: numberOfUnreadNotifications,
	} = useQuery<number, IApiError>([FETCH_NUMBER_OF_UNREAD_NOTIFICATIONS], () =>
		fetchNumberOfUnreadNotifications(),
	)

	return {
		isFetchingNumberOfUnreadNotifications,
		errorFetchingNumberOfUnreadNotifications,
		numberOfUnreadNotifications,
	}
}

export default useFetchNumberOfUnreadNotifications
