import { ITenant, IUser } from 'blue-project-types'
import { parse } from 'query-string'

import LocalStorage from '../../../utils/LocalStorage'
import { LAST_TENANT_STORAGE_KEY } from './constants'

export const getTenantLocalStorageName = (userId: string) => `${userId}_${LAST_TENANT_STORAGE_KEY}`

export const resolveTenantInfo = (user: IUser) => {
	const { tenants, id } = user

	const tenantIdFromQuery = parse(window.location.search).organizationId
	const tenantIdFromLocalStorage = LocalStorage.get(getTenantLocalStorageName(id))

	const tenantId = tenantIdFromQuery || tenantIdFromLocalStorage

	if (tenants && tenants.length) {
		const tenant = tenants.find((tenant: ITenant) => tenant.id === tenantId) || tenants[0]
		return tenant.id
	}

	return
}
