import { AxiosError } from 'axios'
import { IApiError } from 'blue-project-types'

export const getApiError = (error: AxiosError) =>
	error?.response?.data || { statusCode: 500, message: 'Something went wrong' }

export const getApiErrorStatus = (error: AxiosError) => error?.response?.status || 500

export const getTextError = (error: IApiError, defaultMessage?: string) => {
	if (error.statusCode !== 422 && typeof error.message === 'string') {
		return error.message
	} else {
		return defaultMessage ? defaultMessage : 'Ups, something went wrong!'
	}
}
