import { rgba } from 'polished'
import styled from 'styled-components'
import { Colors } from '../styles/colors'
import { SPACES } from '../styles/spaces'

export const StatisticBox = styled.div`
	padding: ${SPACES.EXTRA_SMALL} ${SPACES.SMALL};
	background-color: ${rgba(Colors.GrayLighter, 0.7)};
	margin-left: ${SPACES.EXTRA_SMALL};
	border-radius: 6px;
	&:first-child {
		margin-left: 0;
	}
`

export const StatisticsName = styled.span<{ error?: boolean }>`
	display: block;
	font-size: 10px;
	color: ${(props) => (props.error ? Colors.Red : Colors.Gray)};
`

export const StatisticsResult = styled.span<{ error?: boolean }>`
	display: block;
	font-size: 18px;
	font-weight: 500;
	color: ${(props) => (props.error ? Colors.Red : Colors.Dark)};
	line-height: normal;
	text-align: center;
	margin-bottom: ${SPACES.XXS};
	/* span {
		position: relative;
		margin: 0 ${SPACES.EXTRA_SMALL};
		&:last-child {
			color: ${Colors.Gray};
			&:before {
				content: '/';
				position: absolute;
				top: 50%;
				left: -12px;
				transform: translateY(-50%);
			}
		}
	} */
`
