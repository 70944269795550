import { message } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { useMutation } from 'react-query'
import { IUser } from 'blue-project-types'

import { Text, Button, Modal } from '../../../components'
import { resendVerification as resendVerificationApi, RESEND_VERIFICATION_KEY } from '../utils/api'

interface IProps {
	open: boolean
	user: IUser
	onHide(): void
}

const VerifyEmailModal: React.FC<IProps> = ({ user, open, onHide }) => {
	const { mutate: resendVerification } = useMutation(resendVerificationApi, {
		mutationKey: RESEND_VERIFICATION_KEY,
		onError: () => {
			// TODO: add real error
			message.error('Error happened')
		},
		onSuccess: () => {
			onHide()
			message.success('Email sent')
		},
	})

	return (
		<Modal
			open={open}
			onCancel={onHide}
			closeIcon={null}
			title="Just one more step to better security"
		>
			<Text>
				In order to access all security benefits of Seif, we need you to confirm your email first.
			</Text>
			<Text>
				Please check your email <strong>{user.email} </strong> and click on the confirmation link to
				complete the sign up process.
			</Text>
			<Text>
				If you can't find our mail please check the spam folder or{' '}
				<TextButton type="link" onClick={() => resendVerification()}>
					click here
				</TextButton>{' '}
				to have it sent again.
			</Text>
		</Modal>
	)
}

const TextButton = styled(Button)`
	padding: 0;
	height: 20px;
`

export default VerifyEmailModal
