export const Routes = {
	// Auth routes
	Auth: '/auth',
	ResetPasswordSave: '/auth/reset-password',
	VerifyEmail: '/auth/verify-email',

	// Theme route
	Theme: '/theme',

	// Account settings routes
	Settings: '/account-settings',
	SettingsTwoFactorAuth: '/account-settings/two-factor-authentication',
	SettingsCreateNewRecoveryKey: '/account-settings/new-recovery-key/create',
	SettingsNewRecoveryKey: '/account-settings/new-recovery-key',

	// Organization routes
	Organizations: '/organizations',
	OrganizationsSetup: '/organizations/setup',
	OrganizationsActive: '/organizations/active',
	OrganizationsActivityLogs: '/organizations/activity-logs/:tenantId',
	OrganizationsAcceptInvitation: '/organizations/invitation/accept',
	OrganizationsSwitchingOrganization: '/organizations/switching-organization',

	// Dashboard routes
	Dashboard: '/',

	// Notifications routes
	Notifications: '/notifications',
	Notification: '/notifications/:id',

	// Inventory routes
	Inventory: '/inventory',
	People: '/inventory/people',
	Assets: '/inventory/assets',
	AssetDetails: '/inventory/assets/:id',

	// Course learning routes
	Awareness: '/awareness',
	CreateCourse: '/awareness/assignments/create',
	ViewCourse: '/awareness/assignments/:id',
	Courses: '/awareness/assignments',

	// Nano learning routes
	NanoLearn: '/nano-learning',
	NanoLearningCampaigns: '/nano-learning/campaigns',
	ViewCampaign: '/nano-learning/campaigns/:id',
	CreateNanoLearningCampaign: '/nano-learning/create-campaign',

	// Organization library
	OrganizationLibrary: '/library',

	// Organization library nano learning content
	OrganizationNanoPages: '/library/organization-nano-pages',
	CreateOrganizationNanoPage: '/library/organization-nano-pages/create',
	ViewEditOrganizationNanoPage: '/library/organization-nano-pages/:id',
	ViewEditOrganizationCommonNanoPage: '/library/organization-nano-pages/common/:id',

	// Organization library course content
	OrganizationLessons: '/library/organization-lessons',
	CreateOrganizationPage: '/library/organization-lessons/:id/pages/create',
	ViewEditOrganizationPage: '/library/organization-lessons/:lessonId/pages/:pageId',
	ViewEditOrganizationLesson: '/library/organization-lessons/:id',
	ViewEditOrganizationCommonLesson: '/library/organization-lessons/common/:id',
	ViewEditOrganizationCommonPage: '/library/organization-lessons/common/:lessonId/pages/:pageId',
	CreateOrganizationLesson: '/library/organization-lessons/create',
	CreateCommonLesson: '/library/common-lessons/create',

	// Organization library templates content
	OrganizationTemplates: '/library/organization-templates',
	ViewEditOrganizationTemplate: '/library/organization-templates/:id',

	// Continues Monitoring
	ContinuesMonitoring: '/continues-monitoring',
	ContinuesMonitoringVulnerabilities: '/continues-monitoring/vulnerabilities',
	ContinuesMonitoringDraftVulnerabilities: '/continues-monitoring/drafts-vulnerabilities',
	ContinuesMonitoringStatistics: '/continues-monitoring/statistics',
	ContinuesMonitoringEditDraftVulnerability:
		'/continues-monitoring/drafts-vulnerabilities/:id/edit',
	ContinuesMonitoringCreateVulnerability: '/continues-monitoring/vulnerabilities/create',
	ContinuesMonitoringVulnerabilityDetails: '/continues-monitoring/vulnerabilities/:id',

	// WAF
	Waf: '/waf',
	WafOverview: '/waf/overview',
	BlockedIps: '/waf/blocked-ips',

	// Simulations routes
	Simulations: '/simulations',
	CreateSimulation: '/simulations/create',
	ViewSimulation: '/simulations/:id',

	// Group admin routes
	GroupAdministration: '/group-administration',
	GroupAdministrationOrganizations: '/group-administration/organizations',
	GroupAdministrationOrganizationDetails: '/group-administration/organizations/:id',

	// Pdf exports
	PdfIndex: '/pdf-exports',
	SimulationOverview: '/pdf-exports/simulations/:id',
	OrganizationOverview: '/pdf-exports/tenants/:id/licences',
	CourseOverview: '/pdf-exports/courses/:id',
	RiskManagementOverview: '/pdf-exports/risk-management',

	// Data leaks
	Leaks: '/leaks',
	LeaksCredentials: '/leaks/credentials',

	/**
	 * Admin routs start here
	 */
	Administration: '/administration',

	AdministrationOverview: '/administration/overview',

	// Admin users
	AdminUsers: '/administration/users',

	// Admin organizations
	AdminOrganizations: '/administration/organizations',
	AdminOrganizationDetailsPage: '/administration/organizations/:id',

	// Admin inventory
	AdminAllAssets: '/administration/all-assets',

	// Admin library
	AdminAwareness: '/administration/awareness',

	// Admin library predefined courses
	ViewEditPredefinedCourse: '/administration/awareness/predefined-courses/:predefinedCourseId',
	CreatePredefinedCourse: '/administration/awareness/predefined-courses/create',
	PredefinedCourses: '/administration/awareness/predefined-courses',

	// Admin library nano learning
	NanoPages: '/administration/awareness/nano-pages',
	CreateNanoPage: '/administration/awareness/nano-pages/create',
	ViewEditNanoPage: '/administration/awareness/nano-pages/:id',

	// Admin library course content
	Lessons: '/administration/awareness/lessons',
	CreateLesson: '/administration/awareness/lessons/create',
	ViewEditLesson: '/administration/awareness/lessons/:id',
	CreatePage: '/administration/awareness/lessons/:id/pages/create',
	ViewEditPage: '/administration/awareness/lessons/:lessonId/pages/:pageId',

	// Admin library phishing content
	AdminTemplates: '/administration/templates',
	AdminCreateTemplate: '/administration/create-template',
	AdminEditTemplate: '/administration/edit-template/:id',

	// Admin data leaks
	AdminLeaks: '/administration/leaks',
	AdminCredentialSearches: '/administration/leaks/credential-searches',
	AdminCredentialSearch: '/administration/leaks/credential-searches/:term',

	// Admin extension reports
	ExtensionReports: '/administration/extension-reports',

	// Admin enrichers
	AdminVulnerabilities: '/administration/continues-monitoring',
	ContinuesMonitoringVulnerabilityEnrichers:
		'/administration/continues-monitoring/vulnerability-enrichers',
	ContinuesMonitoringCreateVulnerabilityEnricher:
		'/administration/continues-monitoring/vulnerability-enrichers/create',
	ContinuesMonitoringEditVulnerabilityEnricher:
		'/administration/continues-monitoring/vulnerability-enrichers/:id/edit',

	// Admin course industry standards
	CourseIndustryStandards: '/administration/course-industry-standards',

	/**
	 * Admin routs end here
	 */

	Public: '/public',
	DataLeaksPersonCredentials: '/public/leaks/person-credentials/:token',
}
