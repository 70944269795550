import React, { FC, useMemo } from 'react'
import { Form, Input, Checkbox } from 'formik-antd'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, FormikInput, Title } from '../../../components'
import { useAuth } from '../context'
import TwoFactorAuthTokenForm from './TwoFactorAuthTokenForm'
import { ILoginRequest, ITwoFactorAuthToken, ITwoFactorRecoveryKey } from '../utils/types'
import TwoFactorAuthRecoveryKeyForm from './TwoFactorAuthRecoveryKeyForm'
import useOrganizationRedirect from '../hooks/useAddOrganizationRedirect'
import { FormWrapper, SmallActionsWrapper } from './StyledComponents'
import useDefaultEmail from '../hooks/useDefaultEmail'
import useRedirectBack from '../hooks/useRedirectBack'

enum TwoFAForm {
	Code = 'code',
	RecoveryKey = 'recoveryKey',
}

interface IProps {
	goToRegister(): void
	goToPassRecover(): void
}

const SIGN_IN_BASE_KEY = 'auth.signIn'
const SIGN_UP_BASE_KEY = 'auth.signUp'
const FIELDS_BASE_KEY = 'auth.fields'
const ERRORS_BASE_KEY = 'common.validation.errors'

const LoginForm: FC<IProps> = ({ goToRegister, goToPassRecover }) => {
	const { t } = useTranslation()
	const {
		login,
		isLogging,
		isLogging2FA,
		login2FA,
		is2FAEnabled,
		login2FARecoveryKey,
		isLogging2FARecoveryKey,
	} = useAuth()

	const [twoFAForm, setTwoFAForm] = useState(TwoFAForm.Code)

	const [credentialsCache, setCredentialsCache] = useState({
		email: '',
		password: '',
		rememberMe: false,
	})

	const { redirectUrl } = useOrganizationRedirect()
	const { redirectPath } = useRedirectBack()

	const defaultEmail = useDefaultEmail()

	const redirect = redirectUrl || redirectPath

	const handleSubmit2FA = ({ token }: ITwoFactorAuthToken) => {
		login2FA(
			{
				...credentialsCache,
				token,
			},
			redirect,
		)
	}

	const handleSubmitLogin = (data: ILoginRequest) => {
		setCredentialsCache(data)
		login(data, redirect)
	}

	const handleSubmit2FARecoveryKey = ({ recoveryKey }: ITwoFactorRecoveryKey) => {
		login2FARecoveryKey(
			{
				...credentialsCache,
				recoveryKey,
			},
			redirect,
		)
	}

	const schema = useMemo(() => {
		return Yup.object().shape({
			email: Yup.string()
				.email(t(`${ERRORS_BASE_KEY}.email`))
				.required(t(`${ERRORS_BASE_KEY}.required`)),
			password: Yup.string()
				.required(t(`${ERRORS_BASE_KEY}.required`))
				.matches(/^(?=.*[0-9])(?=.{6,})/, t(`${ERRORS_BASE_KEY}.password`)),
		})
	}, [t])

	if (is2FAEnabled) {
		return (
			<FormWrapper>
				<Title level={1}>{t(`${SIGN_IN_BASE_KEY}.title`)}</Title>
				{twoFAForm === TwoFAForm.Code && (
					<React.Fragment>
						<p>
							{t(`${SIGN_IN_BASE_KEY}.dontHaveAccessToPhone`)}{' '}
							<Button type="link" onClick={() => setTwoFAForm(TwoFAForm.RecoveryKey)}>
								{t(`${SIGN_IN_BASE_KEY}.useRecoveryKey`)}
							</Button>
						</p>
						<TwoFactorAuthTokenForm
							submitText={t(`${SIGN_IN_BASE_KEY}.button`)}
							formSize="large"
							submitBlock={true}
							onSubmit={handleSubmit2FA}
							isSubmitting={isLogging2FA}
						/>
					</React.Fragment>
				)}
				{twoFAForm === TwoFAForm.RecoveryKey && (
					<React.Fragment>
						<p>
							{t(`${SIGN_IN_BASE_KEY}.backTo`)}{' '}
							<Button type="link" onClick={() => setTwoFAForm(TwoFAForm.Code)}>
								{t(`${SIGN_IN_BASE_KEY}.code`)}
							</Button>
						</p>
						<TwoFactorAuthRecoveryKeyForm
							onSubmit={handleSubmit2FARecoveryKey}
							isSubmitting={isLogging2FARecoveryKey}
						/>
					</React.Fragment>
				)}
			</FormWrapper>
		)
	}

	return (
		<FormWrapper>
			<Title level={1}>{t(`${SIGN_IN_BASE_KEY}.title`)}</Title>
			<p>
				{t(`${SIGN_IN_BASE_KEY}.subtitle`)}{' '}
				<Button type="link" onClick={goToRegister}>
					{t(`${SIGN_UP_BASE_KEY}.button`)}
				</Button>
			</p>
			<Formik
				initialValues={{ email: defaultEmail, password: '', rememberMe: false }}
				onSubmit={(data) => handleSubmitLogin(data)}
				validationSchema={schema}
			>
				{() => (
					<Form layout="vertical">
						<Form.Item name="email" label={t(`${FIELDS_BASE_KEY}.email.label`)}>
							<FormikInput
								name="email"
								placeholder={t(`${FIELDS_BASE_KEY}.email.placeholder`)}
								size="large"
								data-test="loginEmail"
							/>
						</Form.Item>
						<Form.Item name="password" label={t(`${FIELDS_BASE_KEY}.password.label`)}>
							<Input.Password
								name="password"
								placeholder={t(`${FIELDS_BASE_KEY}.password.placeholder`)}
								size="large"
								data-test="loginPassword"
							/>
						</Form.Item>
						<SmallActionsWrapper>
							<Checkbox name="rememberMe">{t(`${SIGN_IN_BASE_KEY}.rememberMe`)}</Checkbox>
							<Button type="link" onClick={goToPassRecover}>
								{t(`${SIGN_IN_BASE_KEY}.forgetPassword`)}
							</Button>
						</SmallActionsWrapper>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							block
							loading={isLogging}
							data-test="loginButton"
						>
							{t(`${SIGN_IN_BASE_KEY}.button`)}
						</Button>
					</Form>
				)}
			</Formik>
		</FormWrapper>
	)
}

export default LoginForm
