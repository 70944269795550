import OneFile from './OneFile'
import { IPdfExportsData } from './context'

interface IProps {
	pdfData: IPdfExportsData[]
}

const DownloadPdfMessage: React.FC<IProps> = ({ pdfData }) => {
	if (!pdfData.length) {
		return null
	}

	return (
		<div>
			{pdfData.map((item, index) => (
				<OneFile key={item.exportRequest?.id || index} pdf={item} />
			))}
		</div>
	)
}

export default DownloadPdfMessage
