import {
	IPeopleOverviewResponse,
	IPerson,
	Suggestion,
	SuggestionActionTimeFrame,
	getSuggestionTimeFrame,
} from 'blue-project-types'
import { uniq } from 'lodash'
import { useMemo } from 'react'

const getPersonDetails = (personId: string, people: IPerson[]) => {
	const person = people.find((person) => person.id === personId)
	if (!person) {
		return null
	}

	return person
}

const findItemWithMostPeople = <T extends { people: string[] }>(item: Array<T>): T => {
	const withMostPeople = item.reduce((prev, curr) => {
		if (curr.people.length > prev.people.length) {
			return curr
		}
		return prev
	}, item[0])

	return withMostPeople
}

export interface IRiskScoreSuggestion {
	name: Suggestion
	meta: ISuggestionMeta
}

export interface ISuggestionMeta {
	people: IPerson[] | string[]
	actionTimeFrame: SuggestionActionTimeFrame
	courseId?: string
	courseName?: string
	recreatePeople?: IPerson[]
	extendPeople?: IPerson[]
	lessonId?: string
	lessonName?: string
}

const useCreateRiskCoreResolvers = ({
	peopleSummary,
	people,
}: {
	peopleSummary: IPeopleOverviewResponse
	people: IPerson[]
}) => {
	return useMemo(() => {
		const suggestions: IRiskScoreSuggestion[] = []

		if (!peopleSummary || !people) return suggestions

		if (
			peopleSummary.suggestions.createFirstCourse?.length &&
			peopleSummary.suggestions.createFirstCourse[0].people.length
		) {
			suggestions.push({
				name: Suggestion.CreateFirstCourse,
				meta: {
					people: peopleSummary.suggestions.createFirstCourse[0].people
						.map((personId: string) => getPersonDetails(personId, people))
						.filter((person) => !!person) as IPerson[],
					actionTimeFrame: getSuggestionTimeFrame(Suggestion.CreateFirstCourse),
				},
			})
		}

		if (
			peopleSummary.suggestions.createFirstSimulation?.length &&
			peopleSummary.suggestions.createFirstSimulation[0].people.length
		) {
			suggestions.push({
				name: Suggestion.CreateFirstSimulation,
				meta: {
					people: peopleSummary.suggestions.createFirstSimulation[0].people
						.map((personId: string) => getPersonDetails(personId, people))
						.filter((person) => !!person) as IPerson[],
					actionTimeFrame: getSuggestionTimeFrame(Suggestion.CreateFirstSimulation),
				},
			})
		}

		if (
			peopleSummary.suggestions.notifyPeopleAboutLeak?.length &&
			peopleSummary.suggestions.notifyPeopleAboutLeak[0].people.length
		) {
			suggestions.push({
				name: Suggestion.NotifyPeopleAboutLeak,
				meta: {
					people: peopleSummary.suggestions.notifyPeopleAboutLeak[0].people,
					actionTimeFrame: getSuggestionTimeFrame(Suggestion.NotifyPeopleAboutLeak),
				},
			})
		}

		if (
			peopleSummary.suggestions.extendExpiredCourse?.length ||
			peopleSummary.suggestions.recreateExpiredCourse?.length
		) {
			const recreateCourseWithMostPeople = findItemWithMostPeople(
				peopleSummary.suggestions.recreateExpiredCourse,
			)

			const extendCourse = peopleSummary.suggestions.extendExpiredCourse.find(
				(item) => item.courseId,
			)

			suggestions.push({
				name: Suggestion.ExtendExpiredCourse,
				meta: {
					courseId: recreateCourseWithMostPeople.courseId,
					courseName: recreateCourseWithMostPeople.courseName,
					recreatePeople: recreateCourseWithMostPeople.people
						.map((personId: string) => getPersonDetails(personId, people))
						.filter((person) => !!person) as IPerson[],
					extendPeople: (extendCourse?.people || [])
						.map((personId: string) => getPersonDetails(personId, people))
						.filter((person) => !!person) as IPerson[],
					people: uniq([...recreateCourseWithMostPeople.people, ...(extendCourse?.people || [])]),
					actionTimeFrame: getSuggestionTimeFrame(Suggestion.ExtendExpiredCourse),
				},
			})
		}

		if (peopleSummary.suggestions.recreateFailedCourse?.length) {
			const recreateCourseWithMostPeople = findItemWithMostPeople(
				peopleSummary.suggestions.recreateFailedCourse,
			)

			suggestions.push({
				name: Suggestion.RecreateFailedCourse,
				meta: {
					courseId: recreateCourseWithMostPeople.courseId,
					courseName: recreateCourseWithMostPeople.courseName,
					people: recreateCourseWithMostPeople.people
						.map((personId: string) => getPersonDetails(personId, people))
						.filter((person) => person) as IPerson[],
					actionTimeFrame: getSuggestionTimeFrame(Suggestion.RecreateFailedCourse),
				},
			})
		}

		if (peopleSummary.suggestions.improveLesson?.length) {
			const improveLessonWithMostPeople = findItemWithMostPeople(
				peopleSummary.suggestions.improveLesson,
			)

			suggestions.push({
				name: Suggestion.ImproveLesson,
				meta: {
					lessonId: improveLessonWithMostPeople.lessonId,
					lessonName: improveLessonWithMostPeople.lessonName,
					people: improveLessonWithMostPeople.people
						.map((personId: string) => getPersonDetails(personId, people))
						.filter((person) => person) as IPerson[],
					actionTimeFrame: getSuggestionTimeFrame(Suggestion.ImproveLesson),
				},
			})
		}

		return suggestions
	}, [peopleSummary, people])
}

export default useCreateRiskCoreResolvers
