import { LeakStatus } from 'blue-project-types'

const sr = {
	personalCredentials: {
		title: 'Procureli kredencijali za {{email}}',
		subtitle: `Promenite lozinke povezane sa ovim nalogom što je pre moguće zatim potvrdite promenu kliknom na dugme "Izmenjeno"`,
		resolveButton: 'Izmenjeno',
		leakStatus: {
			[LeakStatus.Deprecated]: 'Zastarelo',
			[LeakStatus.Discovered]: 'Otkriveno',
			[LeakStatus.Resolved]: 'Rešeno',
		},
		resolveLeakModal: {
			title: 'Potvrdite da ste rešili curenje lozinke',
			subtitle: `Da li ste sigurni da ste izmenili lozinku?`,
		},
		leakResolving: {
			success: 'Curenje lozinke uspešno rešeno',
			error: 'Greška prilikom rešavanja curenja',
		},
	},
}

export default sr
