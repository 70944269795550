import styled from 'styled-components'
import { Colors } from '../styles/colors'
import { SPACES } from '../styles/spaces'

const DropdownContent = styled.div`
	box-shadow: 0 0 12px 0 rgba(34, 48, 92, 0.08);
	background: ${Colors.White};
	border-radius: 6px;
	padding: ${SPACES.SMALL};
`

export default DropdownContent
