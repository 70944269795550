import axios from 'axios'
import { stringify } from 'query-string'

import { API_USERS_URL } from '../config/urls'
import { IToken, TOKEN_LOCAL_STORAGE_KEY } from '../features/auth'
import { Routes } from '../routes/config'
import LocalStorage from './LocalStorage'

const TENANT_ID_HEADER = 'X-Tenant-Id'
const REFRESH_TOKEN_URL = `${API_USERS_URL}/auth/refresh-token`

export const setBaseUrl = (url: string): void => {
	axios.defaults.baseURL = url
}

export const setToken = (token?: string): void => {
	if (token) {
		axios.defaults.headers.common.Authorization = `Bearer ${token}`
	} else {
		delete axios.defaults.headers.common.Authorization
	}
}

export const setTenantId = (tenantId?: string): void => {
	if (tenantId) {
		axios.defaults.headers.common[TENANT_ID_HEADER] = tenantId
	} else {
		delete axios.defaults.headers.common[TENANT_ID_HEADER]
	}
}

interface IRefreshTokenInterceptorConfig {
	logout(redirectUrl?: string): void
	setToken(token?: IToken): void
}

interface IQueueItem {
	resolve: any
	reject: any
}

export const setRefreshTokenInterceptor = ({
	logout,
	setToken: setNewToken,
}: IRefreshTokenInterceptorConfig) => {
	let isRefreshing = false
	let failedQueue: IQueueItem[] = []

	const processQueue = (error: Error | null, token = null) => {
		failedQueue.forEach((prom: IQueueItem) => {
			if (error) {
				prom.reject(error)
			} else {
				prom.resolve(token)
			}
		})

		failedQueue = []
	}

	axios.interceptors.response.use(
		(response) => response,
		async (error: any) => {
			const { config: originalRequest } = error

			// Get token from local storage
			const token = LocalStorage.get(TOKEN_LOCAL_STORAGE_KEY)
			if (!token) {
				// If token does not exists => we don't have to refresh it => throw error immediately
				return Promise.reject(error)
			}

			// If error response exists and status is 401 and it is not retry
			if (error.response && error.response.status === 401 && !originalRequest._retry) {
				// If 401 on refresh token => Logout
				if (originalRequest.url === REFRESH_TOKEN_URL) {
					logout(
						`${Routes.Auth}?${stringify({
							from: `${window.location.pathname}${window.location.search}`,
						})}`,
					)
					return Promise.reject(error)
				}

				if (isRefreshing) {
					// If we are refreshing we should put in the queue
					return new Promise((resolve, reject) => {
						failedQueue.push({ resolve, reject })
					})
						.then((token) => {
							originalRequest.headers.Authorization = `Bearer ${token}`
							return axios(originalRequest)
						})
						.catch((err) => {
							return Promise.reject(err)
						})
				}

				// If we are not refreshing start refreshing
				originalRequest._retry = true
				isRefreshing = true
				// Return promise for refreshing token
				setToken()
				return new Promise((resolve, reject) => {
					axios
						.post(REFRESH_TOKEN_URL, { refreshToken: token.refreshToken })
						.then(({ data }) => {
							LocalStorage.set(TOKEN_LOCAL_STORAGE_KEY, data)
							setNewToken(data)
							setToken(data.accessToken)
							originalRequest.headers.Authorization = `Bearer ${data.accessToken}`
							processQueue(null, data.accessToken)
							resolve(axios(originalRequest))
						})
						.catch((err) => {
							processQueue(err, null)
							reject(err)
						})
						.then(() => {
							isRefreshing = false
						})
				})
			}

			// If it is not 401 just return error
			return Promise.reject(error)
		},
	)
}
