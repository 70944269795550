import { Route, Switch } from 'react-router-dom'
import { lazy } from 'react'

import { Routes as RoutesConfig } from '../../routes/config'

const DataLeaksPersonCredentials = lazy(() => import('./pages/DataLeaksPersonCredentials'))

const Routes = () => {
	return (
		<Switch>
			<Route
				path={RoutesConfig.DataLeaksPersonCredentials}
				component={DataLeaksPersonCredentials}
			/>
		</Switch>
	)
}

export default Routes
