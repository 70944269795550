import { Formik } from 'formik'
import { Form } from 'formik-antd'
import * as Yup from 'yup'

import { ModalActions, Button, FormikInput } from '.'

interface IProps {
	initialLink?: string
	onCancel(): void
	onSubmit(link: string): void
}

const AddLinkForm: React.FC<IProps> = ({ initialLink, onSubmit, onCancel }) => {
	return (
		<Formik<{ link: string }>
			initialValues={{
				link: initialLink || '',
			}}
			onSubmit={(data) => onSubmit(data.link)}
			validationSchema={LinkSchema}
		>
			{(formik) => (
				<Form layout="vertical">
					<Form.Item name="link" label="Link">
						<FormikInput name="link" placeholder="Add Link" />
					</Form.Item>
					<ModalActions>
						<Button type="link" onClick={onCancel}>
							Cancel
						</Button>
						<Button type="primary" htmlType="button" onClick={() => formik.submitForm()}>
							Add Link
						</Button>
					</ModalActions>
				</Form>
			)}
		</Formik>
	)
}

export default AddLinkForm

const LinkSchema = Yup.object().shape({
	link: Yup.string().required('Required').url('Invalid url'),
})
