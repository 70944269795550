import { AxiosError } from 'axios'
import { useQuery } from 'react-query'

import { FETCH_PEOPLE_SUMMARY_KEY, fetchPeopleSummary } from '../../utils/api'

const useFetchPeopleSummary = () => {
	const {
		data: peopleSummary,
		isLoading: isFetchingPeopleSummary,
		error: errorFetchingPeopleSummary,
	} = useQuery<any, AxiosError>([FETCH_PEOPLE_SUMMARY_KEY], () => fetchPeopleSummary())

	return {
		peopleSummary,
		isFetchingPeopleSummary,
		errorFetchingPeopleSummary,
	}
}

export default useFetchPeopleSummary
