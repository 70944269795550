import { Tabs } from 'antd'
import styled from 'styled-components'
import { Colors } from '../styles/colors'
import { SPACES } from '../styles/spaces'

const PageTabs = styled(Tabs)`
	.ant-tabs-nav {
		background: ${Colors.White};
		padding: 0 ${SPACES.LARGE};
		&::before {
			content: none;
		}
	}
	.ant-tabs-tab-btn {
		font-size: 14px;
		font-weight: 600;
		color: ${Colors.Gray};
		padding: 0 ${SPACES.DEFAULT};
	}
	.ant-tabs-tab-active {
		.ant-tabs-tab-btn {
			color: ${Colors.Dark};
		}
	}
	.ant-tabs-content {
		padding: 0 ${SPACES.LARGE};
	}
`

export default PageTabs
