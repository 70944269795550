import styled from 'styled-components'
import { largeSteps, steps } from '.'
import { Colors } from '../styles/colors'
import { SPACES } from '../styles/spaces'

export const StepsWrapper = styled.div<{ maxWidth?: string }>`
	padding: ${SPACES.EXTRA_LARGE} 0;
	border-bottom: 1px solid ${Colors.BackgroundGray};
	.ant-steps {
		max-width: ${(props) => props.maxWidth || '1100px'};
		margin: auto;
		.ant-steps-item-content,
		.ant-steps-item-icon {
			vertical-align: middle;
		}
	}
	${steps};
	${largeSteps};
	.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
		max-width: 180px;
	}
`

export const StepsWrapperSmall = styled(StepsWrapper)`
	.ant-steps {
		max-width: 440px;
	}
`
