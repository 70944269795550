const sr = {
	description: {
		title: 'Dobro došli na',
		subtitle1: 'interaktivni kurs informacione bezbednosti',
		subtitle2: ' platforma za učenje koja će vam pomoći da:',
		item1: 'Svedete rizik ljudske greške skoro na nulu',
		item2: 'Analizirate spremnost vaših zaposlenih da odgovore na sajber pretnje',
		item3: 'Pratite njihov napredak',
		item4: 'Donosite odluke na osnovu proverenih podataka',
	},
	fields: {
		firstName: {
			label: 'Ime',
			placeholder: 'Unesite ime',
		},
		lastName: {
			label: 'Prezime',
			placeholder: 'Unesite prezime',
		},
		email: {
			label: 'Mejl adresa',
			placeholder: 'Unesite mejl adresu',
		},
		password: {
			label: 'Lozinka',
			placeholder: 'Unesite lozinku',
		},
		twoFactor: {
			label: 'Šestocifreni kod',
			placeholder: 'Unesite šestocifreni kod',
		},
		recoveryKey: {
			label: 'Ključ za povraćaj naloga',
			placeholder: 'Unesite ključ za povraćaj naloga',
		},
	},
	signIn: {
		title: 'Prijavite se',
		subtitle: 'Još uvek nemate nalog?',
		rememberMe: 'Zapamtite me',
		forgetPassword: 'Zaboravio/la lozinku',
		button: 'Upišite se',
		dontHaveAccessToPhone: 'Nemate pristup telefonu?',
		useRecoveryKey: 'Iskoristite ključ za povraćaj naloga',
		backTo: 'Nazad u',
		code: 'kod',
		submit: 'Predaj',
	},
	signUp: {
		title: 'Kreiraj nalog',
		subtitle: 'Već imate nalog?',
		button: 'Prijavite se',
		policy:
			'Pročitao sam i prihvatam Abstract <1>Terms of Use</1> i <1>Privacy Policy</1>. Prihvatam obradu mojih ličnih informacija u skladu sa Politikom privatnosti.',
	},
	forgetPassword: {
		title: 'Resetujte lozinku',
		subtitle: 'Predomislili ste se?',
		goBack: 'Idite nazad',
		button: 'Resetujte lozinku',
	},
	setPassword: {
		title: 'Postavite lozinku',
		button: 'Postavite lozinku',
	},
}

export default sr
