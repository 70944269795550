import styled, { css } from 'styled-components'
import { ITenant, SuperAdminPermissions } from 'blue-project-types'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import { Text, Button, Modal } from '../../../components'
import { Routes } from '../../../routes/config'
import { Colors } from '../../../styles/colors'
import { SPACES } from '../../../styles/spaces'
import { useCan } from '../../../hooks'

interface IProps {
	isVisible: boolean
	onHide(): void
	setTenantId(id: string): void
	tenants: ITenant[]
	activeTenant: ITenant
}

const TRANSLATE_SIDEBAR_BASE_KEY = 'layout.manageOrgModal'

const ManageOrganizationsModal: React.FC<IProps> = ({
	isVisible,
	onHide,
	setTenantId,
	tenants,
	activeTenant,
}) => {
	const canCreateOrganization = useCan(SuperAdminPermissions.ManageTenants)

	const history = useHistory()

	const { t } = useTranslation()

	const handleSwitchOrganization = (id: string) => {
		if (activeTenant.id === id) {
			return
		}
		onHide()
		setTenantId(id)
		history.push(Routes.OrganizationsSwitchingOrganization, { id })
	}

	return (
		<Modal
			open={isVisible}
			onCancel={onHide}
			title={t(`${TRANSLATE_SIDEBAR_BASE_KEY}.title`)}
			subtitle={t(`${TRANSLATE_SIDEBAR_BASE_KEY}.subtitle`)}
		>
			<OrganizationsWrapper>
				{tenants.map((tenant) => (
					<Organization
						key={tenant.id}
						onClick={() => handleSwitchOrganization(tenant.id)}
						active={activeTenant.id === tenant.id}
					>
						{tenant.logo && <CompanyImage src={tenant.logo} alt={tenant.name} />}
						{!tenant.logo && <CompanyImagePlaceholder color={tenant.color} />}
						<Text zeroMargin>{tenant.name}</Text>
					</Organization>
				))}
			</OrganizationsWrapper>
			<Text size="xs" color={Colors.Gray}>
				{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.smallExplanationAddNewOrg`)}
			</Text>
			{canCreateOrganization && (
				<Button block onClick={onHide} to={Routes.OrganizationsSetup}>
					{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.addOrgButton`)}
				</Button>
			)}
		</Modal>
	)
}

const OrganizationsWrapper = styled.div`
	max-height: 250px;
	overflow-y: auto;
	margin: ${SPACES.LARGE} 0;
`

const Organization = styled.div<{ active: boolean }>`
	padding: ${SPACES.EXTRA_SMALL};
	border: ${({ active }) => `1px solid ${active ? Colors.Blue : Colors.GrayLighter}`};
	border-radius: 6px;
	margin-bottom: ${SPACES.SMALL};
	display: flex;
	align-items: center;
	transition: border-color 0.3s;
	&:last-of-type {
		margin-bottom: 0;
	}
	&:hover {
		cursor: pointer;
		border-color: ${Colors.Blue};
	}
`

const companyImageCss = css`
	width: 48px;
	height: 48px;
	border-radius: 6px;
	margin-right: ${SPACES.SMALL};
`

const CompanyImagePlaceholder = styled.div<{
	color?: string
}>`
	${companyImageCss};
	background: ${({ color }) => color || Colors.Violet};
`

const CompanyImage = styled.img`
	${companyImageCss};
	object-fit: cover;
`

export default ManageOrganizationsModal
