import React from 'react'
import styled from 'styled-components'

import { Title, Text, StyledList } from './'
import { SPACES } from '../styles/spaces'
import { Colors } from '../styles/colors'

interface IProps {
	name: string
	description?: string
	icon?: React.ReactNode
	children?: React.ReactNode
	grayed?: boolean
}

const AwarenessListItem: React.FC<IProps> = ({
	name,
	description,
	icon,
	children,
	grayed,
	...props
}) => {
	return (
		<Standard grayed={grayed} {...props}>
			<Details>
				<div>
					<TitleWrapper>
						<Title level={5} zeroMargin>
							{name}
						</Title>
					</TitleWrapper>
					<Text size="sm">{description}</Text>
				</div>
				{children}
			</Details>
			<Item>
				{icon ? (
					icon
				) : (
					<img src="/images/programs-icon.png" alt="Nano Learn Campaign" style={{ width: 90 }} />
				)}
			</Item>
		</Standard>
	)
}

const Standard = styled.div<{ grayed?: boolean }>`
	position: relative;
	padding: ${SPACES.DEFAULT};
	background-color: ${(props) => (props.grayed ? Colors.GrayHover : Colors.White)};
	border-radius: ${SPACES.SMALL};
	min-height: 180px;
	margin-bottom: ${SPACES.DEFAULT};
	box-shadow: 0px 3px 2.2px rgba(161, 170, 184, 0.035), 0px 7px 5px rgba(161, 170, 184, 0.05),
		0px 13px 10px rgba(161, 170, 184, 0.06), 0px 24px 17px rgba(161, 170, 184, 0.07),
		0px 55px 33px rgba(161, 170, 184, 0.075), 0px 109px 80px rgba(161, 170, 184, 0.08);
`

const Details = styled.div`
	max-width: 64.2%;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	min-height: calc(180px - 48px);
	${StyledList} {
		li {
			margin-bottom: ${SPACES.XXS};
		}
	}
`

const Item = styled.div`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 55px;
`

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: ${SPACES.EXTRA_SMALL};
`

export default AwarenessListItem
