import { Dropdown } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'
import { NotificationIcon } from '../../../components/Icons'
import { Colors } from '../../../styles/colors'
import { SPACES } from '../../../styles/spaces'
import useFetchNumberOfUnreadNotifications from '../hooks/useFetchNumberOfUnreadNotifications'
import NotificationsBar from './NotificationsBar'

const NotificationsHeader = () => {
	const [notificationBarVisible, setNotificationBarVisible] = useState(false)

	const { numberOfUnreadNotifications, isFetchingNumberOfUnreadNotifications } =
		useFetchNumberOfUnreadNotifications()

	const existUnreadNotifications = numberOfUnreadNotifications !== 0

	if (isFetchingNumberOfUnreadNotifications) {
		return null
	}

	return (
		<Dropdown
			dropdownRender={() => <NotificationsBar onHide={() => setNotificationBarVisible(false)} />}
			open={notificationBarVisible}
			onOpenChange={(open) => setNotificationBarVisible(open)}
			trigger={['click']}
			placement="bottomRight"
		>
			<NotificationWrapper>
				<NotificationAlert>
					<NotificationIcon />
					{existUnreadNotifications && (
						<NotificationCount>{numberOfUnreadNotifications}</NotificationCount>
					)}
				</NotificationAlert>
			</NotificationWrapper>
		</Dropdown>
	)
}

const NotificationWrapper = styled.div`
	cursor: pointer;
	margin: 0 ${SPACES.EXTRA_SMALL};
`

const NotificationAlert = styled.div`
	position: relative;
	width: ${SPACES.EXTRA_LARGE};
	height: ${SPACES.EXTRA_LARGE};
	border-radius: 100%;
	background-color: ${Colors.GrayLighter};
	display: inline-flex;
	justify-content: center;
	align-items: center;
	top: ${SPACES.XXS};
	transition: all 0.3s ease-in-out;
	&:hover {
		background-color: ${Colors.Light};
	}
`

const NotificationCount = styled.span`
	position: absolute;
	top: -${SPACES.XXS};
	right: -${SPACES.XXS};
	width: ${SPACES.SMALL};
	height: ${SPACES.SMALL};
	background-color: ${Colors.Red};
	border-radius: 100%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 10px;
	font-weight: 500;
	color: ${Colors.White};
`

export default NotificationsHeader
