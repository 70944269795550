import axios from 'axios'
import { API_NOTIFICATIONS_URL } from '../../../config/urls'

export const FETCH_NOTIFICATION_KEY = 'fetchNotification'
export const fetchNotification = async (id: string) => {
	const response = await axios.get(`${API_NOTIFICATIONS_URL}/notifications/${id}`)

	return response.data
}

export const FETCH_NOTIFICATIONS_KEY = 'fetchNotifications'
export const fetchNotifications = async (params) => {
	const response = await axios.get(`${API_NOTIFICATIONS_URL}/notifications/`, {
		params,
	})

	return response.data
}

export const FETCH_NUMBER_OF_UNREAD_NOTIFICATIONS = 'fetchNumberOfUnreadNotifications'
export const fetchNumberOfUnreadNotifications = async () => {
	const response = await axios.get(`${API_NOTIFICATIONS_URL}/notifications/not-read-count`)
	return response.data
}

export const UPDATE_NOTIFICATION_MARK_READ_KEY = 'updateNotificationMarkRead'
export const updateNotificationMarkRead = async (id: string) => {
	const response = await axios.put(`${API_NOTIFICATIONS_URL}/notifications/${id}/mark-read`)

	return response.data
}

export const UPDATE_NOTIFICATIONS_MARK_ALL_READ_KEY = 'updateNotificationsMarkAllRead'
export const updateNotificationsMarkAllRead = async () => {
	const response = await axios.post(`${API_NOTIFICATIONS_URL}/notifications/mark-all-read`)

	return response.data
}
