import { Typography } from 'antd'
import { TitleProps } from 'antd/lib/typography/Title'
import { FC } from 'react'
import styled from 'styled-components'

interface ITitleProps extends TitleProps {
	color?: string
	zeroMargin?: boolean
}

const Title: FC<ITitleProps> = (props: ITitleProps) => {
	return <StyledTitle {...props} />
}

const StyledTitle = styled(({ color, size, zeroMargin, ...rest }) => (
	<Typography.Title {...rest} />
))`
	&&& {
		line-height: 1.4;
		margin-top: 0;
		${(props: ITitleProps) => props.color && `color:${props.color}`};
		font-weight: ${(props: ITitleProps) => (props.level! < 4 ? 600 : 500)};
		${(props: ITitleProps) => props.zeroMargin && `margin-bottom:0;`}
	}
`

Title.defaultProps = {
	level: 1,
}

export default Title
