import { AclEvents, Industry, LicenceEvents, TenantEvents, UserEvents } from 'blue-project-types'

const sr = {
	common: {
		organizationFields: {
			name: {
				label: 'Naziv organizacije',
				placeholder: 'Unesite naziv organizacije',
			},
			webUrl: {
				label: 'Vebsajt organizacije',
				placeholder: 'Unesite vebsajt organizacije',
			},
			logo: {
				label: 'Logo organizacije',
				placeholder: 'Unesite logo organizacije',
				dragAndDropText:
					'Aploudujte logo <1>Prevucite-i-otpustite ili kliknite da dodate sa računara</1>',
			},
			group: {
				label: 'Grupa organizacije',
				placeholder: 'Unesite grupu organizacije',
			},
			industry: {
				label: 'Industrija organizacije',
				placeholder: 'Unesite industriju organizacije',
				[Industry.Agriculture]: 'Poljoprivreda',
				[Industry.Automotive]: 'Automobilska industrija',
				[Industry.Banking]: 'Bankarstvo',
				[Industry.Chemicals]: 'Hemijska industrija',
				[Industry.Construction]: 'Građevinarstvo',
				[Industry.Education]: 'Obrazovanje',
				[Industry.Energy]: 'Energetika',
				[Industry.Entertainment]: 'Zabava',
				[Industry.Finance]: 'Finansije',
				[Industry.FoodAndBeverage]: 'Hrana i piće',
				[Industry.Healthcare]: 'Zdravstvo',
				[Industry.InformationTechnology]: 'Informacione tehnologije',
				[Industry.Manufacturing]: 'Proizvodnja',
				[Industry.Media]: 'Mediji',
				[Industry.Pharmaceuticals]: 'Farmaceutska industrija',
				[Industry.RealEstate]: 'Nekretnine',
				[Industry.Retail]: 'Trgovina',
				[Industry.Telecommunications]: 'Telekomunikacije',
				[Industry.Transportation]: 'Transport',
				[Industry.TravelAndTourism]: 'Putovanja i turizam',
				[Industry.Utilities]: 'Komunalne usluge',
				[Industry.Other]: 'Druge',
			},
		},
	},
	active: {
		title: 'Upravljajte organizacijom',
		changeOrganizationDetailsButton: 'Izmenite detalje organizacije',
		changeOrganizationModal: {
			title: 'Izmenite detalje organizacije',
			confirm: 'Sačuvajte izmene',
		},
		activeUsers: {
			title: 'Korisnici',
			addUsersButton: '+ Dodajte nove korisnike',
			addUsersModal: {
				title: 'Dodajte nove korisnike',
				subtitle: 'Dodajte mejl adrese novih korisnika',
				description:
					'Obavestićemo vas kada novi korisnici uspostave naloge. Njihovi podaci postaće vidljivi u sekciji "Korisnici", gde možete da konfigurišete uloge za pristup.',
				fields: {
					emails: {
						label: 'Mejl adrese',
						placeholder: 'Unesite mejl adrese',
					},
				},
				confirm: 'Dodajte nove korisnike',
				actionMessage: {
					success: 'Zahtev je poslat!',
					alreadyPartOfOrganization:
						'Korisnik je već deo organizacije ili se čeka njegova potvrda!',
				},
			},
			removeConfirm: {
				title: 'Uklonite ovog korisnika?',
				text: 'Da li ste sigurni da želite da uklonite <1>{{fullName}}</1> iz organizacije?',
			},
			table: {
				columns: {
					firstName: 'Ime',
					lastName: 'Prezime',
					email: 'Mejl adresa',
					roles: 'Uloge',
				},
				messages: {
					roleRequired: 'Jedna uloga je obavezna!',
					rolesChanged: 'Uloge su izmenjene',
				},
				actions: {
					remove: 'Ukloni',
					saveRoles: 'Sačuvaj',
				},
			},
		},
		pendingInvitations: {
			title: 'Čeka se pozivnica',
			table: {
				columns: {
					email: 'Mejl adresa',
					invitedByUser: 'Pozvan od strane',
					createdAt: 'Pozvan u',
				},
				actions: {
					resendInvitation: 'Ponovo pošaljite pozivnicu',
				},
			},
			resendInvitationConfirm: {
				title: 'Ponovo pošaljite pozivnicu na ovaj e-mail?',
				text: 'Da li ste sigurni da želite ponovo da pošaljete pozivnicu na e-mail <1>{{email}}</1> ?',
				success: 'Pozivnica poslata!',
				alreadyPartOfOrganization: 'Korisnik je već deo organizacije ili čeka odgovor!',
			},
		},
	},
	add: {
		title: 'Dodajte organizaciju',
		steps: {
			details: {
				title: 'Detalji organizacije',
				description: 'Dodajte podatke organizacije',
			},
			people: {
				title: 'Zaposleni',
				description: 'Dodajte podatke o zaposlenima',
			},
			domains: {
				title: 'Domeni',
				description: 'Dodajte onlajn resurse',
			},
		},
		detailsStep: {
			title: 'Unesite detalje o vašoj organizaciji',
		},
		finishStep: {
			title: '{{name}} je spremna!',
			description1:
				'Sada možete da kreirate sopstvene kurseve, dodajete zaposlene i resurse, kao i da pokrenete Seif.ai kontinuiranu sajber bezbednosnu procenu.',
			description2:
				'Svi dodati resursi su dostupni za pregled i podešavanja u sekciji "Inventar" na platformi.',
			goToButton: 'Idite u inventar',
		},
		errors: {
			mustSetupOrganization: 'Morate prvo da uspostavite organizaciju',
		},
	},
	activityLogs: {
		title: 'Aktivnosti',
		column: {
			eventName: 'Događaj',
			eventDescription: 'Opis događaja',
			userName: 'Korisnik',
			ipAdress: 'IP adresa',
			data: 'Podaci',
			createdAt: 'Datum kreiranja',
			description: {
				[UserEvents.UserCreated]: '{{actionUserFullName}} je kreirao/la nalog na platformi.',
				[UserEvents.UserLoggedIn]: '{{actionUserFullName}} se prijavio/la na platformu.',
				[UserEvents.UserLoggedOut]: '{{actionUserFullName}} se odjavio/la sa platforme.',
				[UserEvents.UserLoginFailed]:
					'{{actionUserFullName}} nije uspeo/la da se prijavi na platformu.',
				[UserEvents.UserVerifiedAccount]: '{{actionUserFullName}} je verifikovao/la nalog',
				[UserEvents.UserRequestedVerificationEmail]:
					'{{actionUserFullName}} je zatrazio/la novi verifikacioni e-email.',
				[UserEvents.UserRequestedResetPassword]:
					'{{actionUserFullName}} je zatrazio/la resetovanje lozinke.',
				[UserEvents.UserResetPassword]: '{{actionUserFullName}} je uspešno resetovao/la lozinku.',
				[UserEvents.UserTurnedOnTwoFactorAuth]:
					'{{actionUserFullName}} je uključio/la dvo-faktorsku autentifikaciju.',
				[UserEvents.UserTurnedOffTwoFactorAuth]:
					'{{actionUserFullName}} je isključio/la dvo-faktorsku autentifikaciju.',
				[UserEvents.UserRequestedNewTwoFactorAuthRecoveryKeys]:
					'{{actionUserFullName}} je zatrazio/la nove kljućeve za oporavak dvo-faktorske autentifikacije.',
				[UserEvents.UserUpdatedPassword]: '{{actionUserFullName}} je ažurirao/la lozinku.',
				[UserEvents.UserFailedToUpdatePassword]:
					'{{actionUserFullName}} nije uspeo/la da ažurira lozinku.',
				[UserEvents.UserUpdatedProfile]: '{{actionUserFullName}} je ažurirao/la lične informacije.',
				[UserEvents.AdminRemovedUser]:
					'{{actionUserFullName}} je obrisao/la {{removedUserFullName}} sa platforme.',
				[UserEvents.AdminRestoredUser]:
					'{{actionUserFullName}} je vratio/la {{restoredUserFullName}} na platforme.',
				[TenantEvents.AdminRemovedTenant]:
					'{{actionUserFullName}} je obrisao/la organizaciju sa platforme.',
				[TenantEvents.AdminRestoredTenant]:
					'{{actionUserFullName}} je vratio/la organizaciju na platformu.',
				[TenantEvents.TenantCreated]:
					'{{actionUserFullName}} je kreirao/la organizaciju na platformi.',
				[TenantEvents.FeatureChangedForTenant]:
					'{{actionUserFullName}} je promenio/la funkcionalnosti koje ima organizacija.',
				[TenantEvents.UsersInvited]:
					'{{actionUserFullName}} je pozvao/la {{invitationUsers}} u organizaciju.',
				[TenantEvents.UserAcceptedInvitation]: '{{actionUserFullName}} je prihvatio/la poziv.',
				[TenantEvents.TenantUpdated]:
					'{{actionUserFullName}} je ažurirao/la informacije o organizaciji.',
				[TenantEvents.AdminUpdatedTenant]:
					'{{actionUserFullName}} je ažurirao/la informacije o organizaciji.',
				[LicenceEvents.LicenceCreated]: '{{actionUserFullName}} je kreirao/la licencu.',
				[LicenceEvents.LicenceUpdated]: '{{actionUserFullName}} je ažurirao/la licencu.',
				[LicenceEvents.LicenceDeleted]: '{{actionUserFullName}} je obrisao/la licencu.',
				[AclEvents.UserRolesChanged]:
					'{{actionUserFullName}} je ažurirao/la uloge {{updatedUserFullName}}.',
				[AclEvents.AdminRolesChanged]:
					'{{actionUserFullName}} je ažurirao/la uloge {{updatedUserFullName}}.',
			},
		},
		filter: {
			search: {
				placeholder: 'Pretražite po korisniku',
			},
			group: {
				title: 'Grupa',
			},
			eventName: {
				[UserEvents.UserCreated]: 'Kreiranje korisnika',
				[UserEvents.UserLoggedIn]: 'Prijavljivanje korisnika',
				[UserEvents.UserLoggedOut]: 'Odjavljivanje korisnika',
				[UserEvents.UserLoginFailed]: 'Neuspela prijava korisnika',
				[UserEvents.UserVerifiedAccount]: 'Verifikacija korisničkog naloga',
				[UserEvents.UserRequestedVerificationEmail]: 'Zahtev za email verifikaciju korisnika',
				[UserEvents.UserRequestedResetPassword]: 'Zahtev za resetovanje lozinke',
				[UserEvents.UserResetPassword]: 'Resetovanje lozinke',
				[UserEvents.UserTurnedOnTwoFactorAuth]: 'Uključivanje dvofaktorske autentifikacija',
				[UserEvents.UserTurnedOffTwoFactorAuth]: 'Isključivanje dvofaktorske autentifikacija',
				[UserEvents.UserRequestedNewTwoFactorAuthRecoveryKeys]:
					'Zahtev za novim ključem za povraćaj pristupa dvofaktorske autentifikacije',
				[UserEvents.UserUpdatedPassword]: 'Promena lozinke',
				[UserEvents.UserFailedToUpdatePassword]: 'Neuspešna promena lozinke',
				[UserEvents.UserUpdatedProfile]: 'Ažuriranje profila',
				[UserEvents.AdminRemovedUser]: 'Brisanje korisnika od strane administratora',
				[UserEvents.AdminRestoredUser]: 'Vraćanje korisnika od strane administratora',
				[AclEvents.UserRolesChanged]: 'Promena korisničke uloge',
				[AclEvents.AdminRolesChanged]: 'Promena administratorske uloge',
				[TenantEvents.AdminRemovedTenant]: 'Brisanje organizacije od strane administratora',
				[TenantEvents.AdminRestoredTenant]: 'Vraćanje organizacije od strane administratora',
				[TenantEvents.TenantCreated]: 'Kreiranje organizacije',
				[TenantEvents.FeatureChangedForTenant]: 'Promena funkcionalnosti organizacije',
				[TenantEvents.AdminUpdatedTenant]: 'Ažuriranje organizacije od strane administratora',
				[LicenceEvents.LicenceCreated]: 'Kreiranje licence',
				[LicenceEvents.LicenceUpdated]: 'Ažuriranje licence',
				[LicenceEvents.LicenceDeleted]: 'Brisanje licence',
				[TenantEvents.UsersInvited]: 'Slanje pozivnice korisnicima',
				[TenantEvents.UserAcceptedInvitation]: 'Korisnik je prihvatio poziv',
				[TenantEvents.TenantUpdated]: 'Ažuriranje organizacije',
			},
			groupNames: {
				UserManagement: 'Upravljanje korisnicima',
				UserAdministration: 'Administracija korisnika',
				AclManagement: 'Upravljanje ACL-om',
				TenantAdministration: 'Administracija organizacija',
				TenantManagement: 'Upravljanje organizacijama',
			},
		},
	},
	acceptInvitation: {
		errors: {
			notValid: 'Pozivnica je nevažeća',
			generic: 'Došlo je do greške',
		},
	},
	switching: {
		messageWithName: 'Prebacujemo na {{name}}...',
		messageWithoutName: 'Prebacujemo...',
	},
}

export default sr
