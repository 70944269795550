import { Spin } from 'antd'
import { FC } from 'react'
import { CSSProperties } from 'styled-components'

interface IGlobalLoaderProps {
	text?: string
	style?: CSSProperties
}

const GlobalLoader: FC<IGlobalLoaderProps> = ({ text, style }) => {
	return (
		<Spin
			size="large"
			tip={text}
			style={{ position: 'absolute', left: '50%', top: '200px', ...style }}
		/>
	)
}

GlobalLoader.defaultProps = {
	text: 'Loading...',
	style: {},
}

export default GlobalLoader
