import { ReactNode, FC } from 'react'
import styled from 'styled-components'
import { Title } from '../../components'
import { useUI } from '../../context/ui'
import { SPACES } from '../../styles/spaces'
import Breadcrumb, { IBreadcrumbItem } from './../components/Breadcrumb'
import { adoptMarginToTheSidebar } from './../utils/styleHelpers'

interface ISubHeaderProps {
	title: string
	breadcrumbItems?: IBreadcrumbItem[]
	children?: ReactNode | ReactNode[]
}

const SubHeader: FC<ISubHeaderProps> = ({ title, children, breadcrumbItems }) => {
	const { isSidebarOpen } = useUI()
	return (
		<StyledSubHeader isSidebarOpen={isSidebarOpen}>
			<Title>{title}</Title>
			{breadcrumbItems?.length! > 0 && <Breadcrumb items={breadcrumbItems as IBreadcrumbItem[]} />}
			{children}
		</StyledSubHeader>
	)
}

const StyledSubHeader = styled.section<{ isSidebarOpen: boolean }>`
	display: flex;
	align-items: center;
	background-color: white;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
	padding: 0 ${SPACES.DEFAULT};
	position: fixed;
	top: 64px;
	right: 0;
	left: ${(props) => adoptMarginToTheSidebar(props.isSidebarOpen)};
	transition: all 0.2s ease-out;
	min-height: 54px;
	z-index: 1;
	h1 {
		font-size: 24px;
		margin-bottom: 0;
	}
`

export default SubHeader
