import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { SPACES } from '../../../../../styles/spaces'
import { Text } from '../../../../../components'
import { Colors } from '../../../../../styles/colors'
import RiskScoreSummaryIcon from './RiskScoreSummaryIcon'
import { RiskScore } from '../../../../../utils/constants'
import { IRiskScoreSuggestion } from '../../../hooks/memo/useCreateRiskCoreResolvers'
import RiskScoreSummaryTextNew from './RiskSummaryTextNew'

const PEOPLE_RISK_SCORE_BASE_TRANSLATE = 'inventory.people.riskScore.tenantOverview'

interface IProps {
	riskScoreEnum: RiskScore
	riskScoreResolvers: IRiskScoreSuggestion[]
}

const RiskScoreSummaryNew: React.FC<IProps> = ({ riskScoreEnum, riskScoreResolvers }) => {
	const { t } = useTranslation()

	return (
		<Wrapper>
			<Text color={Colors.Black} style={{ fontSize: 24, fontWeight: 500 }}>
				{t(`${PEOPLE_RISK_SCORE_BASE_TRANSLATE}.riskScoreSummary`)}
			</Text>
			<DescriptionWrapper>
				<RiskScoreSummaryIcon riskScoreEnum={riskScoreEnum} />
				<RiskScoreSummaryTextNew riskScoreEnum={riskScoreEnum} />
			</DescriptionWrapper>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: ${SPACES.DEFAULT};
`

const DescriptionWrapper = styled.div`
	display: flex;
	flex-grow: 2;
	justify-content: start;
	align-items: center;
	padding: ${SPACES.LARGE} 0;
`

export default RiskScoreSummaryNew
