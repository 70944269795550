import { IPerson } from 'blue-project-types'
import { useQuery, useQueryClient } from 'react-query'
import { fetchPeople, FETCH_PEOPLE_KEY } from '../utils/api'
import { IFetchPeopleQueryData } from '../utils/types'

const useFetchPeople = (params?: IFetchPeopleQueryData) => {
	const { data, isFetching, error } = useQuery<IPerson[]>(FETCH_PEOPLE_KEY, () =>
		fetchPeople(params),
	)

	return {
		people: data || [],
		isFetchingPeople: isFetching,
		fetchingPeopleError: error,
	}
}

export default useFetchPeople
