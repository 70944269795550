import { Badge, Dropdown } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { rgba } from 'polished'
import styled, { css } from 'styled-components'
import { IUser, ITenant, SuperAdminPermissions } from 'blue-project-types'
import { useTranslation } from 'react-i18next'

import { Text, DropdownContent, UserAvatar } from '../../components'
import { DEMO_DATA } from '../../demo/data'
import { useAuth } from '../../features/auth'
import ManageOrganizationsModal from '../../features/organizations/components/ManageOrganizationsModal'
import { useCan, useToggleVisibility } from '../../hooks'
import { Routes } from '../../routes/config'
import { Colors } from '../../styles/colors'
import { SPACES } from '../../styles/spaces'
import { useUI } from '../../context/ui'
import CompanyAvatar from './CompanyAvatar'

interface IMenuProps {
	tenant: any
	isDemo: boolean
	user: IUser
	tenants: ITenant[]
	onClick(): void
	logoutUser(): void
	showManageOrgModal(): void
}

const TRANSLATE_SIDEBAR_BASE_KEY = 'layout.orgMenu'

const Menu: React.FC<IMenuProps> = ({
	tenant,
	tenants,
	isDemo,
	user,
	logoutUser,
	showManageOrgModal,
	onClick,
}) => {
	const isMultiTenant = tenants.length > 1
	const { showIsVerifyModalVisible } = useUI()

	const canCreateOrganization = useCan(SuperAdminPermissions.ManageTenants)
	// const canReadActivityLogs = useCan(ActivityLogPermissions.ReadActivityLogs) TODO: return back once we add activity log

	const { t } = useTranslation()

	const onClickWithVerificationCheck = () => {
		onClick()
		if (!user.isVerified) {
			showIsVerifyModalVisible()
		}
	}

	return (
		<DropdownContent>
			<DropdownContentCompanyWrapper>
				<CompanyInfoWrapper>
					{tenant.logo && (
						<CompanyImage
							src={tenant.logo}
							alt={tenant.name}
							style={{ marginRight: SPACES.EXTRA_SMALL }}
						/>
					)}
					{!tenant.logo && <CompanyImagePlaceholder opacity={1} marginRight={SPACES.EXTRA_SMALL} />}
					<Text size="sm" strong zeroMargin>
						{tenant.name}
					</Text>
					{isDemo && (
						<Badge offset={[-25, 0]} color={Colors.Violet} count="DEMO">
							<div style={{ width: 65 }} />
						</Badge>
					)}
				</CompanyInfoWrapper>
				{isDemo && (
					<React.Fragment>
						{user.isVerified && canCreateOrganization && (
							<MenuItem onClick={onClickWithVerificationCheck}>
								<Link to={Routes.OrganizationsSetup}>
									{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.createNewOrganization`)}
								</Link>
							</MenuItem>
						)}
						{!user.isVerified && canCreateOrganization && (
							<MenuItem onClick={onClickWithVerificationCheck}>
								{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.createNewOrganization`)}
							</MenuItem>
						)}
					</React.Fragment>
				)}
				{!isDemo && (
					<React.Fragment>
						<MenuItem onClick={onClickWithVerificationCheck}>
							<Link to={Routes.OrganizationsActive}>
								{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.orgSettings`)}
							</Link>
						</MenuItem>
						{isMultiTenant && (
							<MenuItem
								onClick={() => {
									onClickWithVerificationCheck()
									showManageOrgModal()
								}}
							>
								{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.manageOrg`)}
							</MenuItem>
						)}
						{/* {canReadActivityLogs && (
							<MenuItem onClick={onClickWithVerificationCheck}>
								<Link to={Routes.OrganizationsActivityLogs.replace(':tenantId', tenant.id)}>
									{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.orgActivity`)}
								</Link>
							</MenuItem>
						)} */}
						{!isMultiTenant && canCreateOrganization && (
							<MenuItem onClick={onClickWithVerificationCheck}>
								<Link to={Routes.OrganizationsSetup}>
									{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.createNewOrganization`)}
								</Link>
							</MenuItem>
						)}
					</React.Fragment>
				)}
			</DropdownContentCompanyWrapper>
			<DropdownUserWrapper>
				<UserInfoWrapper>
					<UserAvatar user={user} />
					<UserInfo>
						<Text strong color={Colors.Dark} size="sm" zeroMargin>
							{user.firstName} {user.lastName}
						</Text>
						<Text type="secondary" size="sm" zeroMargin>
							{user.email}
						</Text>
					</UserInfo>
				</UserInfoWrapper>
				<MenuItem onClick={onClickWithVerificationCheck}>
					<Link to={Routes.Settings}>{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.userSettings`)}</Link>
				</MenuItem>
				<MenuItem
					onClick={() => {
						logoutUser()
					}}
				>
					{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.signOut`)}
				</MenuItem>
			</DropdownUserWrapper>
		</DropdownContent>
	)
}

const CompanyUserMenu = () => {
	const { activeTenant, user, logoutUser, switchTenant } = useAuth()
	const tenant = activeTenant || DEMO_DATA.company
	const isDemoData = !activeTenant

	const {
		isVisible: isManageOrgModalVisible,
		hide: hideManageOrgModal,
		show: showManageOrgModal,
	} = useToggleVisibility()

	const {
		isVisible: isDropdownVisible,
		hide: hideDropdown,
		show: showDropdown,
	} = useToggleVisibility()

	return (
		<React.Fragment>
			<Dropdown
				dropdownRender={() => (
					<Menu
						tenants={user?.tenants!}
						tenant={tenant}
						isDemo={isDemoData}
						user={user!}
						logoutUser={logoutUser}
						showManageOrgModal={showManageOrgModal}
						onClick={hideDropdown}
					/>
				)}
				trigger={['click']}
				placement="bottomRight"
				open={isDropdownVisible}
				onOpenChange={(visible: boolean) => (!visible ? hideDropdown() : showDropdown())}
			>
				<DropdownCompanyWrapper onClick={isDropdownVisible ? hideDropdown : showDropdown}>
					<CompanyAvatar tenant={tenant} isDemoData={isDemoData} />
					<Text size="sm" zeroMargin>
						{tenant.name}
					</Text>
				</DropdownCompanyWrapper>
			</Dropdown>
			<ManageOrganizationsModal
				isVisible={isManageOrgModalVisible}
				tenants={user?.tenants!}
				setTenantId={switchTenant}
				onHide={hideManageOrgModal}
				activeTenant={tenant}
			/>
		</React.Fragment>
	)
}

const companyImageCss = css`
	width: 32px;
	height: 32px;
	border-radius: 6px;
	margin-right: ${SPACES.SMALL};
`

const CompanyImagePlaceholder = styled.div<{
	opacity: number
	marginRight?: string
	color?: string
}>`
	${companyImageCss};
	background: ${({ color }) => color || Colors.Violet};
	opacity: ${({ opacity }) => opacity};
	margin-right: ${({ marginRight }) => marginRight || undefined};
`

const CompanyImage = styled.img`
	${companyImageCss};
	object-fit: cover;
`

const DropdownCompanyWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: ${SPACES.EXTRA_SMALL};
	&:hover {
		cursor: pointer;
	}
`

const CompanyInfoWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: ${SPACES.EXTRA_SMALL};
`

const DropdownContentCompanyWrapper = styled.div`
	border-bottom: 1px solid ${Colors.GrayLighter};
	margin: 0 -${SPACES.SMALL};
	padding: 0 ${SPACES.SMALL} ${SPACES.EXTRA_SMALL} ${SPACES.SMALL};
`

const MenuItem = styled.div`
	padding: ${SPACES.EXTRA_SMALL};
	&:hover {
		background: ${rgba(Colors.Blue, 0.05)};
	}
	a,
	& {
		color: ${Colors.Dark};
		font-size: 12px;
		&:hover {
			cursor: pointer;
		}
	}
`

const UserInfoWrapper = styled.div`
	display: flex;
	margin-bottom: ${SPACES.EXTRA_SMALL};
`

const UserInfo = styled.div`
	margin-left: ${SPACES.EXTRA_SMALL};
`

const DropdownUserWrapper = styled.div`
	margin-top: ${SPACES.SMALL};
`

export default CompanyUserMenu
