import styled from 'styled-components'

import Title from '../../../components/Title'
import { Colors } from '../../../styles/colors'
import { SPACES } from '../../../styles/spaces'
import { useTranslation } from 'react-i18next'
import AwarenessSummary from './training-campaigns-overview/AwarenessSummary'
import SimulationsResult from './training-campaigns-overview/SimulationsResult'

const TRANSLATE_CAMPAIGNS_OVERVIEW_BASE_KEY =
	'pdfExports.RiskManagementExport.trainingCampaignsOverview'

const CampaignsOverview = () => {
	const { t } = useTranslation()

	return (
		<Wrapper>
			<Title color={Colors.Dark} style={{ marginBottom: SPACES.DEFAULT }}>
				{t(`${TRANSLATE_CAMPAIGNS_OVERVIEW_BASE_KEY}.title`)}
			</Title>
			<AwarenessSummary icon={undefined} titleColor={''} title={''} subtitle={''} />
			<SimulationsResult totalAttacks={50} opened={10} clicked={4} failed={3} />
		</Wrapper>
	)
}

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	padding-block: ${SPACES.EXTRA_LARGE};
`
const ActionWrapper = styled.section`
	display: flex;
	justify-content: space-between;
`

export default CampaignsOverview
