import { Layout, Menu } from 'antd'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
	BankOutlined,
	FlagOutlined,
	HighlightOutlined,
	UserOutlined,
	PlayCircleOutlined,
	BookOutlined,
	DatabaseOutlined,
	ExceptionOutlined,
	LineChartOutlined,
	MailOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import {
	DataLeaksPermissions,
	Feature,
	GroupAdminPermissions,
	InventoryPermissions,
	LearningPermissions,
	PhishingPermissions,
	SuperAdminPermissions,
	VulnerabilityPermissions,
	WafPermissions,
} from 'blue-project-types'
import React from 'react'

import { useUI } from '../../context/ui'
import { Groups, SidebarRoutes } from './../utils/sidebarRoutes'
import { SPACES } from '../../styles/spaces'
import { useCan } from '../../hooks'
import {
	AssetsIcon,
	AssignmentsIcon,
	OverviewIcon,
	PeopleIcon,
	SimulationIcon,
	StatisticIcon,
	NanoLearnIcon,
	DataLeaksIcon,
} from '../../components/Icons'
import { Routes } from '../../routes/config'
import { useAuth } from '../../features/auth'
import { ReactComponent as SeifSVG } from '../../assets/seif-light.svg'
import { FeatureToggleNames, useFeatureToggle } from '../../components/FeatureToggle'
import { getItem, MenuItem } from '../utils/sidebarMenuItem'
import useFeature from '../../hooks/useFeature'
import { navLinks } from '../utils/navLinksItems'

export interface INavLinkData {
	to: string
	label: string
	key: React.Key
	icon?: React.ReactNode
	dateTest?: string
}

const GROUPS = [
	Groups.Awareness,
	Groups.Inventory,
	Groups.Admin,
	Groups.ContinuesMonitoring,
	Groups.Waf,
	Groups.Leaks,
	Groups.GroupAdministration,
	Groups.AwarenessAdministration,
	Groups.ContinuesMonitoringAdministration,
	Groups.InventoryAdministration,
	Groups.LeaksAdministration,
	Groups.Library,
]

const TRANSLATE_SIDEBAR_BASE_KEY = 'layout.sidebar'

const Sidebar = () => {
	const { isSidebarOpen, toggleIsSidebarOpen } = useUI()
	const { user } = useAuth()

	const { t } = useTranslation()

	const canManageUsers = useCan(SuperAdminPermissions.ManageUsers)
	const canManageOrganizations = useCan(SuperAdminPermissions.ManageTenants)
	const canReadLearning = useCan(LearningPermissions.ReadLearning)
	const canReadVulnerabilities = useCan(VulnerabilityPermissions.ReadVulnerability)
	const canReadInventory = useCan(InventoryPermissions.ReadInventory)
	const canManageTemplates = useCan(SuperAdminPermissions.ManageTemplatesLibrary)
	const canReadPhishing = useCan(PhishingPermissions.ReadPhishing)
	const canManageVulnerabilities = useCan(SuperAdminPermissions.ManageVulnerabilities)
	const canManageInventory = useCan(SuperAdminPermissions.ManageInventory)
	const canManageLearningContent = useCan(SuperAdminPermissions.ManageLearningContent)
	const canCrudLearning = useCan(LearningPermissions.CrudLearning)
	const canReadWaf = useCan(WafPermissions.ReadWaf)
	const canManageGroupOrganizations = useCan(GroupAdminPermissions.ManageTenants)
	const canManageDataLeaks = useCan(SuperAdminPermissions.ManageLeaks)
	const canReadDataLeaks = useCan(DataLeaksPermissions.ReadLeaks)
	const canManagePhishing = useCan(SuperAdminPermissions.ManagePhishing)
	const canCrudPhishing = useCan(PhishingPermissions.CrudPhishing)
	const canManageLearning = useCan(SuperAdminPermissions.ManageLearning)

	const isInventoryEnabled = useFeatureToggle(FeatureToggleNames.Inventory)
	const isLearningEnabled = useFeatureToggle(FeatureToggleNames.Learning)
	const isPhishingEnabled = useFeatureToggle(FeatureToggleNames.Phishing)
	const isMonitoringEnabled = useFeatureToggle(FeatureToggleNames.Monitoring)
	const isWafEnabled = useFeatureToggle(FeatureToggleNames.Waf)

	const hasGroupAdministrationFeature = useFeature(Feature.GroupOrganization)

	const { isAdminRoute, currentSidebarRoute } = useUI()

	const defaultSelectedKeys = currentSidebarRoute ? [currentSidebarRoute.key as string] : []
	const showNotVerifiedLabel = !!(user && !user.isVerified)

	const navItemsInventory: INavLinkData[] = [
		{
			to: SidebarRoutes.People.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.people`),
			key: SidebarRoutes.People.key,
			icon: <PeopleIcon />,
		},
		{
			to: SidebarRoutes.Assets.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.assets`),
			key: SidebarRoutes.Assets.key,
			icon: <AssetsIcon />,
		},
	].filter(Boolean) as INavLinkData[]

	const navItemsMonitoring: INavLinkData[] = [
		canReadVulnerabilities && {
			to: SidebarRoutes.ContinuesMonitoringVulnerabilitiesStatistics.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.reporting`),
			key: SidebarRoutes.ContinuesMonitoringVulnerabilitiesStatistics.key,
			icon: <StatisticIcon />,
		},
		canReadVulnerabilities && {
			to: SidebarRoutes.ContinuesMonitoringVulnerabilities.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.vulnerabilities`),
			key: SidebarRoutes.ContinuesMonitoringVulnerabilities.key,
			icon: <FlagOutlined />,
		},
	].filter(Boolean) as INavLinkData[]

	const navItemsAwareness: INavLinkData[] = [
		canReadLearning &&
			isLearningEnabled && {
				to: SidebarRoutes.Assignments.route,
				label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.courses`),
				key: SidebarRoutes.Assignments.key,
				icon: <AssignmentsIcon />,
			},
		canReadLearning &&
			isLearningEnabled && {
				to: SidebarRoutes.NanoLearn.route,
				label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.nanoLearn`),
				key: SidebarRoutes.NanoLearn.key,
				icon: <NanoLearnIcon />,
			},
		canReadPhishing &&
			isPhishingEnabled && {
				to: SidebarRoutes.Simulations.route,
				label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.simulations`),
				key: SidebarRoutes.Simulations.key,
				icon: <SimulationIcon />,
				dateTest: 'phishingSimulationsSidebarMenuItem',
			},
		((isLearningEnabled && canCrudLearning) || (canCrudPhishing && isPhishingEnabled)) && {
			to: SidebarRoutes.Library.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.library`),
			key: SidebarRoutes.Library.key,
			icon: <BookOutlined />,
		},
	].filter(Boolean) as INavLinkData[]

	const navItemsLeaksOrganization: INavLinkData[] = [
		{
			to: SidebarRoutes.DataLeaksCredentials.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.dataLeaksCredentials`),
			key: SidebarRoutes.DataLeaksCredentials.key,
			icon: <DataLeaksIcon />,
		},
	]

	const navItemsGroupAdministration: INavLinkData[] = [
		canManageGroupOrganizations && {
			to: SidebarRoutes.GroupAdministrationOrganizations.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.organizations`),
			key: SidebarRoutes.GroupAdministrationOrganizations.key,
			icon: <BankOutlined />,
		},
	].filter(Boolean) as INavLinkData[]

	const navItemsWaf: INavLinkData[] = [
		{
			to: SidebarRoutes.WafOverview.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.overview`),
			key: SidebarRoutes.WafOverview.key,
		},
		{
			to: SidebarRoutes.WafBlockedIps.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.blockedIps`),
			key: SidebarRoutes.WafBlockedIps.key,
		},
	].filter(Boolean) as INavLinkData[]

	const navItemsAdmin: INavLinkData[] = [
		canManageUsers && {
			to: SidebarRoutes.AdminUsers.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.users`),
			key: SidebarRoutes.AdminUsers.key,
			icon: <UserOutlined />,
		},
		canManageOrganizations && {
			to: SidebarRoutes.AdminOrganizations.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.organizations`),
			key: SidebarRoutes.AdminOrganizations.key,
			icon: <BankOutlined />,
		},
		process.env.REACT_APP_ENV === 'local' && {
			to: SidebarRoutes.Theme.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.theme`),
			key: SidebarRoutes.Theme.key,
			icon: <HighlightOutlined />,
		},
	].filter(Boolean) as INavLinkData[]

	const navItemsInventoryAdministration: INavLinkData[] = [
		{
			to: SidebarRoutes.AdminAllAssets.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.allAssets`),
			key: SidebarRoutes.AdminAllAssets.key,
			icon: <AssetsIcon />,
		},
	]

	const navItemsLeaksAdministration: INavLinkData[] = [
		{
			to: SidebarRoutes.LeaksCredentialsAdministration.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.credentialsAdministration`),
			key: SidebarRoutes.LeaksCredentialsAdministration.key,
			icon: <DatabaseOutlined />,
		},
	]

	const navItemsAwarenessAdministration: INavLinkData[] = [
		canManageLearningContent && {
			to: SidebarRoutes.Lessons.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.lessons`),
			key: SidebarRoutes.Lessons.key,
			icon: <BookOutlined />,
			dateTest: 'learningContentSidebarMenuItem',
		},
		canManageLearningContent && {
			to: SidebarRoutes.NanoPages.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.nanoPages`),
			key: SidebarRoutes.NanoPages.key,
			icon: <BookOutlined />,
			dateTest: 'nanoPagesSidebarMenuItem',
		},
		canManageLearningContent && {
			to: SidebarRoutes.PredefinedCourses.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.predefinedCourses`),
			key: SidebarRoutes.PredefinedCourses.key,
			icon: <PlayCircleOutlined />,
			dateTest: 'predefinedCoursesSidebarMenuItem',
		},
		canManageTemplates && {
			to: SidebarRoutes.AdminTemplates.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.templates`),
			key: SidebarRoutes.AdminTemplates.key,
			icon: <MailOutlined />,
			dateTest: 'templatesSidebarMenuItem',
		},
		canManagePhishing && {
			to: SidebarRoutes.ExtensionReports.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.extensionReports`),
			key: SidebarRoutes.ExtensionReports.key,
			icon: <ExceptionOutlined />,
			dateTest: 'extensionReportsSidebarMenuItem',
		},
		canManageLearning && {
			to: SidebarRoutes.CourseIndustryStandards.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.courseIndustryStandards`),
			key: SidebarRoutes.CourseIndustryStandards.key,
			icon: <LineChartOutlined />,
			dateTest: 'courseIndustryStandardsSidebarMenuItem',
		},
	].filter(Boolean) as INavLinkData[]

	const navItemsContinuesMonitoringAdministration: INavLinkData[] = [
		{
			to: SidebarRoutes.ContinuesMonitoringVulnerabilityEnrichers.route,
			label: t(`${TRANSLATE_SIDEBAR_BASE_KEY}.vulnerabilityEnrichers`),
			key: SidebarRoutes.ContinuesMonitoringVulnerabilityEnrichers.key,
			icon: <AssetsIcon />,
		},
	]

	const renderPlatformItems = () => {
		const overviewMenu = getItem(
			<NavLink
				className="nav-text"
				to={SidebarRoutes.Overview.route}
				activeClassName="active-nav-link"
			>
				{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.overview`)}
			</NavLink>,
			SidebarRoutes.Overview.key,
			<OverviewIcon />,
		)

		const inventoryMenu = getItem(
			<>{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.inventory`)}</>,
			SidebarRoutes.People.group,
			null,
			navLinks({ listOfItems: navItemsInventory }),
		)

		const continuesMonitoringMenu = getItem(
			<>{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.continuesMonitoring`)}</>,
			SidebarRoutes.ContinuesMonitoring.group,
			null,
			navLinks({ listOfItems: navItemsMonitoring }),
		)

		const awarenessMenu = getItem(
			<> {t(`${TRANSLATE_SIDEBAR_BASE_KEY}.awareness`)}</>,
			SidebarRoutes.Assignments.group,
			null,
			navLinks({ listOfItems: navItemsAwareness }),
		)

		const dataLeaksMenu = getItem(
			<>{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.dataLeaks`)}</>,
			SidebarRoutes.Leaks.group,
			null,
			navLinks({ listOfItems: navItemsLeaksOrganization }),
		)

		const wafMenu = getItem(
			<>{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.waf`)}</>,
			SidebarRoutes.WafOverview.group,
			null,
			navLinks({ listOfItems: navItemsWaf }),
		)

		const groupAdministrationMenu = getItem(
			<>{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.groupAdministration`)}</>,
			SidebarRoutes.GroupAdministrationOrganizations.group,
			null,
			navLinks({ listOfItems: navItemsGroupAdministration }),
		)

		const items: MenuItem[] = [overviewMenu]

		// Inventory submenu
		if (canReadInventory && isInventoryEnabled && !isAdminRoute) {
			items.push(inventoryMenu)
		}

		// Awareness submenus
		if (canReadLearning && isLearningEnabled && !isAdminRoute) {
			items.push(awarenessMenu)
		}
		// Vulnerability submenu
		if (canReadVulnerabilities && isMonitoringEnabled && !isAdminRoute) {
			items.push(continuesMonitoringMenu)
		}

		// Leaks submenu
		if (canReadDataLeaks && !isAdminRoute) {
			items.push(dataLeaksMenu)
		}

		// Waf submenu
		if (isWafEnabled && canReadWaf && !isAdminRoute) {
			items.push(wafMenu)
		}

		// Group administration
		if (hasGroupAdministrationFeature && canManageGroupOrganizations && !isAdminRoute) {
			items.push(groupAdministrationMenu)
		}

		return items
	}

	const renderAdminItems = () => {
		const items: MenuItem[] = []

		const adminMenu = getItem(
			<>{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.admin`)}</>,
			SidebarRoutes.AdminUsers.group,
			null,
			navLinks({ listOfItems: navItemsAdmin }),
		)

		const inventoryAdministrationMenu = getItem(
			<>{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.inventoryAdministration`)}</>,
			SidebarRoutes.InventoryAdministration.group,
			null,
			navLinks({ listOfItems: navItemsInventoryAdministration }),
		)

		const awarenessAdministrationMenu = getItem(
			<>{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.awarenessAdministration`)}</>,
			SidebarRoutes.AwarenessAdministration.group,
			null,
			navLinks({ listOfItems: navItemsAwarenessAdministration }),
		)

		const continuesMonitoringAdministrationMenu = getItem(
			<>{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.continuesMonitoringAdministration`)}</>,
			SidebarRoutes.ContinuesMonitoringAdministration.group,
			null,
			navLinks({ listOfItems: navItemsContinuesMonitoringAdministration }),
		)

		const dataLeaksAdministrationMenu = getItem(
			<>{t(`${TRANSLATE_SIDEBAR_BASE_KEY}.leaksAdministration`)}</>,
			SidebarRoutes.LeaksAdministration.group,
			null,
			navLinks({ listOfItems: navItemsLeaksAdministration }),
		)

		if (canManageInventory && isInventoryEnabled && isAdminRoute) {
			items.push(inventoryAdministrationMenu)
		}

		if (
			((canManageLearningContent && isLearningEnabled) ||
				(canManageTemplates && isPhishingEnabled)) &&
			isAdminRoute
		) {
			items.push(awarenessAdministrationMenu)
		}

		if (canManageVulnerabilities && isMonitoringEnabled && isAdminRoute) {
			items.push(continuesMonitoringAdministrationMenu)
		}

		if (canManageDataLeaks && isAdminRoute) {
			items.push(dataLeaksAdministrationMenu)
		}

		if ((canManageOrganizations || canManageUsers) && isAdminRoute) {
			items.push(adminMenu)
		}

		return items
	}

	return (
		<SidebarWrapper showNotVerifiedLabel={showNotVerifiedLabel}>
			<Layout>
				<Layout.Sider
					collapsible={false}
					breakpoint="sm"
					style={LAYOUT_CSS_SETTINGS}
					collapsed={!isSidebarOpen}
					onCollapse={toggleIsSidebarOpen}
					theme="dark"
					width="210px"
				>
					<Logo>
						<Link to={Routes.Dashboard}>
							<SeifSVG />
						</Link>
					</Logo>
					{isAdminRoute && (
						<Menu
							theme="dark"
							mode="inline"
							defaultSelectedKeys={defaultSelectedKeys}
							defaultOpenKeys={GROUPS as any}
							selectedKeys={defaultSelectedKeys}
							style={{ borderRight: 0 }}
							items={renderAdminItems()}
						/>
					)}
					{!isAdminRoute && (
						<Menu
							theme="dark"
							mode="inline"
							defaultSelectedKeys={defaultSelectedKeys}
							defaultOpenKeys={GROUPS as any}
							selectedKeys={defaultSelectedKeys}
							style={{ borderRight: 0 }}
							items={renderPlatformItems()}
						/>
					)}
				</Layout.Sider>
			</Layout>
		</SidebarWrapper>
	)
}

const LAYOUT_CSS_SETTINGS: React.CSSProperties = {
	overflow: 'auto',
	height: '100vh',
	position: 'fixed',
	left: 0,
	top: 0,
}

const Logo = styled.div`
	padding: ${SPACES.SMALL} ${SPACES.SMALL} ${SPACES.DEFAULT} ${SPACES.SMALL};
	display: flex;
	justify-content: flex-start;
	svg {
		width: 120px;
	}
`

const SidebarWrapper = styled.div<{
	showNotVerifiedLabel: boolean
}>`
	.ant-menu-item-icon {
		width: 14px;
		svg {
			width: 14px;
		}
	}
	.ant-layout-sider {
		padding-top: ${(props) => (props.showNotVerifiedLabel ? '40px' : 0)};
	}
	.ant-menu.ant-menu-dark {
		background: #001433;
	}
	.ant-menu-dark .ant-menu-inline.ant-menu-sub {
		background: #001433;
		padding: 0 8px;
		border-radius: 6px;
		margin-bottom: 24px;
	}
	.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
		height: 28px;
		line-height: 28px;
		opacity: 0.6;
		padding-left: 16px !important;
		background-color: #162747;
		margin-bottom: 12px;
		span {
			font-size: 12px;
			font-weight: 500;
			text-transform: uppercase;
		}
	}
	.ant-menu-dark .ant-menu-item {
		border-radius: 6px;
	}
	.ant-menu-dark .ant-menu-item:hover {
		background-color: #162747;
	}
	.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background-color: #162747;
		padding-left: 16px !important;
	}
	.ant-menu-dark.ant-menu-inline .ant-menu-item {
		padding-left: 16px !important;
	}
	.ant-menu-dark .ant-menu-sub .ant-menu-item {
		padding-left: 8px !important;
	}
	.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-sub .ant-menu-item-selected {
		padding-left: 8px !important;
	}
	.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
		line-height: 28px;
		height: 28px;
	}
`

export default Sidebar
