const sr = {
	libraryPage: {
		title: 'Biblioteka',
		subtitle:
			'Ovde možete odabrati različite edukativne formate, od kojih svaki ima specifičnu ulogu u poboljšanju informacione bezbednosti Vaših polaznika. Možete iskoristiti unapred pripremljeni sadržaj ili kreirati sopstveni.',
	},
	cards: {
		lessons: {
			title: 'Lekcije',
			description:
				'Koristite već pripremljen obrazovni sadržaj i primenite ga kako biste brzo obučili polaznike o osnovama informacione bezbednosti.',
			btnLabel: 'Upravljajte lekcijama',
			featureDisabledTooltip:
				'Ova opcija nije omogućena za vašu organizaciju. Kako bi pokrenuli kurs osnova informacione bezbednosti, kontaktirajte Seif tim za prodaju!',
		},
		nanoPages: {
			title: 'Nano stranice',
			description:
				'Izaberite materijal za nano-učenje i koristite ga kako biste pomogli polaznicima da se podsete važnih lekcije o informacionoj bezbednosti i utvrde svoje znanje.',
			btnLabel: 'Upravljajte nano stranicama',
			featureDisabledTooltip:
				'Ova opcija nije omogućena za vašu organizaciju. Kako bi pokrenuli nano learning kampanju, kontaktirajte Seif tim za prodaju!',
		},
		phishingTemplates: {
			title: 'Phishing šabloni',
			description:
				'Pronađite šablon za phishing koji odgovara vašim potrebama. Koristite šablone kako biste testirali sposobnost vaših učenika da prepoznaju i izbegnu phishing pretnje.',
			btnLabel: 'Upravljajte phishing šablonima',
			featureDisabledTooltip:
				'Ova opcija nije omogućena za vašu organizaciju. Da lansirate vašu prvu bezbednosnu simulaciju, kontaktirajte Seif prodajni tim!',
		},
		customContent: {
			title: 'Prilagođeni sadržaj',
			description: `Izaberite između Lekcija, Nano stranica i Šablona phishing-a kako biste kreirali sadržaj koji nabolje odgovara specifičnim potrebama Vaše kompanije.`,
			btnLabel: 'Upravljajte prilagodjenim sadrzajima',
			featureDisabledTooltip: 'Ova opcija nije omogućena za vašu organizaciju.',
		},
	},
	customModal: {
		title: 'Prilagođeni sadržaj',
		subtitle: 'Izaberite tip prilagodjenog sadržaja:',
		radioGroup: {
			radio1: 'Lekcije',
			radio2: 'Nano stranice',
			radio3: 'Phishing šabloni',
		},
	},
}

export default sr
