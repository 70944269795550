import { useQueryClient } from 'react-query'
import { useMemo } from 'react'
import { GET_AUTH_USER_PERMISSIONS_KEY } from '../features/auth/utils/api'
import { IPermission } from 'blue-project-types'

const useCan = (permission?: string, checks?: () => boolean) => {
	const queryClient = useQueryClient()
	const permissions = queryClient.getQueryData<IPermission[]>(GET_AUTH_USER_PERMISSIONS_KEY)
	const allowed = useMemo(() => {
		if (!permissions) return false

		let isAllowed = true
		if (permission) {
			isAllowed = isAllowed && !!permissions.find((p) => p.code === permission)
		}
		if (checks) {
			isAllowed = isAllowed && checks()
		}
		return isAllowed
	}, [permission, permissions, checks])

	return allowed
}

export default useCan
