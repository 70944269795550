import { Routes } from '../routes/config'

export const isPdfExportRoute = () => {
	return window.location.pathname.includes(Routes.PdfIndex)
}

export const isPublicRoute = () => {
	return window.location.pathname.includes(Routes.Public)
}

export const isAdminRoute = (route?: string) => {
	return route?.startsWith(Routes.Administration) || false
}
