import {
	BusinessDays,
	PhishedFeedbackType,
	SimulationStatus,
	TargetStatus,
} from 'blue-project-types'

const en = {
	common: {
		templateDeleted: 'Phishing template is deleted',
		settings: {
			simulation: {
				title: 'Name & Description',
				subtitle: 'The name and description are also visible to targets',
			},
			properties: {
				name: {
					label: 'Simulation name',
					placeholder: 'Add name',
				},
				description: {
					label: 'Simulation description',
					placeholder: 'Add description',
				},
				startDate: {
					label: 'Start',
				},
				timeZone: {
					label: 'Time zone',
					placeholder: 'Please select time zone',
				},
				duration: {
					label: 'Durations',
					text: 'weeks',
				},
				businessDaysName: {
					[BusinessDays.Monday]: 'Monday',
					[BusinessDays.Tuesday]: 'Tuesday',
					[BusinessDays.Wednesday]: 'Wednesday',
					[BusinessDays.Thursday]: 'Thursday',
					[BusinessDays.Friday]: 'Friday',
					[BusinessDays.Saturday]: 'Saturday',
					[BusinessDays.Sunday]: 'Sunday',
				},
				businessDays: {
					monday: 'Monday',
					tuesday: 'Tuesday',
					wednesday: 'Wednesday',
					thursday: 'Thursday',
					friday: 'Friday',
					saturday: 'Saturday',
					sunday: 'Sunday',
				},
				businessHours: {
					label: 'Business hours',
				},
				maxAtacks: {
					label: 'Max atacks per target name',
				},
			},
			feedbackMessage: {
				label: 'Feedback message',
				subtitle:
					'Choose predefined or write your own feedback message that the target will receive after being phished',
				radioGroup: {
					[PhishedFeedbackType.GenericMsg]: 'Generic confirmation message',
					[PhishedFeedbackType.Error404Page]: 'Error 404 page',
					[PhishedFeedbackType.PhishedDisplay]: '"You got phished" display',
					[PhishedFeedbackType.Other]: 'Other',
				},
				preview: 'Preview',
				message:
					'Your request has been received, it will be processed and we will get back to you with further steps.',
				placeholder: 'Enter your custom message',
				labelCustomMsg: 'Custom message',
			},
			simulationDuration: {
				title: 'Simulation start & duration',
				content1: 'Set up campaign date and duration.',
				content2:
					'Both the campaign start date and duration can be adjusted individually, from the participants list?',
			},
			simulationScoping: {
				title: 'Simulation scoping',
				content: 'Select days of the week that simulations are allowed on',
			},
		},
		feature: {
			pageTitle: 'Security Phishing Simulations',
			title: 'This feature is not enabled for your organization.',
			content1: 'To launch your first Security Phishing Simulations, and',
			content2: `improve & monitor your Organization's security,`,
			content3: 'please contact Seif sales team!',
			contactBtn: 'Contact Us',
		},
		simulationResult: {
			targets: 'Targets',
			startedOn: 'Started on',
			startingOn: 'Starting on',
			failed: 'Failed',
			duration: 'Duration',
			status: 'Status',
			timeLeft: 'Time left',
			days: 'days',
			statusType: {
				[SimulationStatus.InProgress]: 'Active',
				[SimulationStatus.Upcoming]: 'Upcoming',
				[SimulationStatus.Stopped]: 'Stopped',
				[SimulationStatus.Ended]: 'Ended',
			},
		},
		headerData: {
			createBtn: 'Create Simulation',
		},
		simulationTargets: {
			title: 'Choose simulation targets',
			subtitle: 'Add all or adjust condition for this campaign attendance',
			messageNoResults: 'There are no events for this target!',
			properties: {
				people: {
					radio1: 'All People',
					radio2: 'Custom group',
					radio3: 'Predefined people',
				},
				tags: {
					label: 'Tags',
					placeholder: 'Select tags to add participants',
				},
			},
			details: {
				sent: 'Sent',
				delivered: 'Delivered',
				opened: 'Opened',
				clicked: 'Clicked',
				replied: 'Replied',
				attachmentOpened: 'Attachment opened',
				dataEntered: 'Data entered',
				receivedTraining: 'Received training invitation',
				reportedPhishing: 'Reported phishing',
				scheduled: 'Scheduled',
			},
			statusType: {
				[TargetStatus.Created]: 'Created',
				[TargetStatus.InProgress]: 'In Progress',
				[TargetStatus.Passed]: 'Passed',
				[TargetStatus.Failed]: 'Failed',
				[TargetStatus.NoReaction]: 'No Reaction',
				[TargetStatus.ReportedSome]: 'Reported some',
			},
			sendManually: 'Send manually',
		},
		simulationSettings: {
			title: 'Simulation settings',
			subtitle: `Configure simulation settings`,
		},
		simulationTemplatesReview: {
			title: `Select Technique`,
			subtitle: 'Review campaign content and phishing mail details',
			filter: {
				title: 'Filter list',
				lang: 'Please select language',
			},
			templateTest: 'Test template',
			templatePreview: 'Preview',
			templateRequiredMsg:
				'* Please select & transfer minimum one phishing template in the right column',
		},
		simulationAddEmails: {
			title: `Test Phishing Template`,
			subtitle: '',
			properties: {
				emails: {
					label: 'Enter one or more email adress',
					placeholder: 'example@domain.com',
				},
			},
			noEmail: 'No email has been sent!',
			emailsSended: 'Emails have been sent successfully!',
		},
		simulationCreated: {
			title: '{{simulationName}} is ready!',
			subtitle: `If you wish to make changes to your simulation you can do it in the Settings Section of the
			Overview Simulation Page.`,
			buttonProceed: 'Proceed to Simulation Page',
		},
		ctaSimulation: {
			title1: 'Launch your first Phishing Simulation Campaign',
			title2: `to improve & monitor your Organization’s security`,
			buttonCreate: 'Create Simulation',
		},
		basicSimulationSettings: {
			title: 'Simulation settings',
			subtitle: `Configure simulation settings`,
		},
		simulationOverview: {
			funnel: {
				title: 'Simulation funnel',
				details: {
					totalTargets: 'Total Attacks',
					totalTargetsInfo: 'Total number of planned phishing simulation emails in this campaign.',
					delivered: 'Delivered',
					deliveredInfo: 'The number of delivered simulation emails so far.',
					opened: 'Opened',
					openedInfo: 'The number of phishing simulation emails opened so far.',
					clicked: 'Clicked',
					clickedInfo:
						'The number of targets who clicked on the phishing link embedded in the body of the email.',
					replied: 'Replied',
					repliedInfo: 'The number of targets who sent a reply via the phishing simulation email.',
					attachmentOpen: 'Attachment open',
					attachmentOpenInfo: 'The number of targets who opened the simulation malware attachment.',
					dataEntered: 'Data entered',
					dataEnteredInfo:
						'The number of targets who clicked on the malicious link and entered their data in the simulation login page.',
					receivedTraining: 'Received training',
					reportedPhishing: 'Reported phishing',
					receivedTrainingInfo:
						'The number of targets who received additional training after failing the simulations.',
					reportedPhishingInfo: 'The number of targets who successfully reported phishing attempt.',
				},
			},
			recentlyFailedTargets: {
				title: 'Most recently failed targets',
			},
			deletedTemplatesAlert: {
				title: 'Simulation contains deleted templates',
				subtitle:
					'Currently displayed simulation contains templates which have been deleted. Close alert by clicking on got it button.',
				gotIt: 'Got it',
			},
		},
		duration: {
			title: 'days left',
			subtitle: 'days duration',
			tag: 'Overdue',
		},
		viewHeaderData: {
			title: 'Start',
			duration: 'Duration',
			timeleft: 'Timeleft',
			status: 'Status',
			days: 'days',
			actionButton: 'Stop Simulation',
			modalTitle: 'Stop this simulation?',
			modalTxt1: 'Are you sure you want to stop simulation',
			modalTxt2: `* Be aware that you won't be able to continue this simulation any more.`,
			successMsg: 'Simulation status successfully updated!',
			exportedPdfSuffix: 'simulation_details',
		},
		targets: {
			messageSuccess: 'Reminders successfully sent',
			messageNoResults: 'There are no events for this target!',
			properties: {
				firstName: {
					title: 'First name',
				},
				lastName: {
					title: 'Last name',
				},
				groups: {
					title: 'Groups',
				},
				status: {
					title: 'Simulation status',
				},
				timeToFail: {
					title: 'Time to fail',
					info: 'The time period between the target’s receiving of the simulation mail to the moment of his failure. Time to fail is calculated on average if there is more than one template per target.',
				},
				timeToFailReaction: {
					title: 'Time to fail reaction',
					info: 'The time period between the opening of the simulation mail by the target, to the moment of his failure. Time to fail react is calculated on average if there is more than one template per target.',
				},
			},
			actions: {
				edit: 'Edit',
				remove: 'Remove',
				markPassed: 'Update status to passed',
			},
			modalConfirm: {
				title: 'Remove this target?',
				subtitle1: 'Are you sure you want to remove',
				subtitle2: 'from this simulation?',
			},
			updateStatusModal: {
				title: "Update this target's status to passed?",
				text: 'Are you sure you want to update target <1>{{fullName}}</1> status to passed?',
			},
			update: {
				success: 'Target status successfully updated!',
				error: 'Update not allowed!',
			},
			search: {
				placeholder: 'Search for specific target',
			},
			filters: {
				targetStatus: {
					name: 'Target status',
					[TargetStatus.Created]: 'Created',
					[TargetStatus.InProgress]: 'In progress',
					[TargetStatus.Passed]: 'Passed',
					[TargetStatus.Failed]: 'Failed',
					[TargetStatus.NoReaction]: 'No Reaction',
					[TargetStatus.ReportedSome]: 'Reported some',
				},
				groups: {
					name: 'Groups',
				},
			},
			alert: {
				title: 'Target failed simulation',
				subtitle:
					'Only the target which failed simulation is shown. Close alert & highlighting by clicking on got it button.',
				gotItButton: 'Got it',
			},
			sendTargetTemplate: { successMsg: 'Template successfully sent to the target!' },
		},
		api: {
			useRemoveLearner: {
				success: 'Target successfully removed',
				error: 'Error happened',
			},
		},
		pages: {
			simulation: {
				title: 'Phishing Simulations',
				subtitle: 'Here you can see all active and upcoming phishing simulations.',
				section1: 'Active Simulations',
				section2: 'Upcoming Simulations',
				section3: 'Finished Simulations',
				simulation: 'Phishing Simulation',
			},
			createSimulationWizard: {
				error: 'Please populate current data.',
				title: 'Launch New Simulation',
				subtitle:
					'Here you can choose phishing templates, add targets, and configure additional settings for your phishing simulation campaign.',
				titleStep1: 'Mail phishing templates',
				descriptionStep1: 'Preview & add phishing mails',
				titleStep2: 'Targets',
				descriptionStep2: 'Manage participants',
				titleStep3: 'Settings',
				descriptionStep3: 'Configure settings',
			},
			targets: {
				text: 'Targets',
			},
			overview: {
				text: 'Overview',
			},
			viewSimulation: {
				subtitle:
					'Here you can review phishing simulation campaign results and content, manage learners, as well as configure settings.',
				tab1: 'OVERVIEW',
				tab2: 'TARGETS',
				tab3: 'PHISHING TEMPLATES',
				tab4: 'SETTINGS',
				timeZoneAlert: {
					title: 'Time zone warning',
					subtitle:
						'Please note that your overview shows simulation tracking in simulation time zone.',
				},
			},
		},
	},
	templates: {
		title: 'Phishing templates',
		commonTemplates: 'SEIF PHISHING TEMPLATES',
		organizationTemplates: 'CUSTOM PHISHING TEMPLATES',
		copyTemplate: {
			title: 'Copy Phishing Template',
			subtitle:
				'Are you sure you want to copy <1>{{template}}</1>phishing template to organization library?',
		},
	},
}

export default en
