import {
	IPeopleOverviewResponse,
	IPerson,
	Suggestion,
	SuggestionActionTimeFrame,
} from 'blue-project-types'
import styled from 'styled-components'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Colors } from '../../../styles/colors'
import { SPACES } from '../../../styles/spaces'
import TitleSubtitleWrapper from '../../inventory/components/common/TitleSubtitleWrapper'
import useCreateRiskCoreResolvers, {
	IRiskScoreSuggestion,
} from '../../inventory/hooks/memo/useCreateRiskCoreResolvers'
import ActionListCard from './recommended-actions/ActionListCard'

interface IProps {
	peopleSummary: IPeopleOverviewResponse
	people: IPerson[]
}

const TRANSLATE_RECOMMENDED_ACTIONS_BASE_KEY = 'pdfExports.RiskManagementExport.recommendedActions'

const RecommendedActions: React.FC<IProps> = ({ people, peopleSummary }) => {
	const { t } = useTranslation()

	// const riskScoreSuggestions = useCreateRiskCoreResolvers({ peopleSummary, people })

	const riskScoreSuggestions: IRiskScoreSuggestion[] = [
		{
			name: Suggestion.CreateFirstCourse,
			meta: {
				people: [],
				actionTimeFrame: SuggestionActionTimeFrame.ShortTerm,
				courseId: undefined,
				courseName: undefined,
				recreatePeople: [],
				extendPeople: [],
				lessonId: undefined,
				lessonName: undefined,
			},
		},
		{
			name: Suggestion.CreateFirstSimulation,
			meta: {
				people: [],
				actionTimeFrame: SuggestionActionTimeFrame.ShortTerm,
				courseId: undefined,
				courseName: undefined,
				recreatePeople: [],
				extendPeople: [],
				lessonId: undefined,
				lessonName: undefined,
			},
		},
		{
			name: Suggestion.NotifyPeopleAboutLeak,
			meta: {
				people: [],
				actionTimeFrame: SuggestionActionTimeFrame.Immediate,
				courseId: undefined,
				courseName: undefined,
				recreatePeople: [],
				extendPeople: [],
				lessonId: undefined,
				lessonName: undefined,
			},
		},
		{
			name: Suggestion.ExtendExpiredCourse,
			meta: {
				people: [],
				actionTimeFrame: SuggestionActionTimeFrame.LongTerm,
				courseId: undefined,
				courseName: 'Math 101',
				recreatePeople: [],
				extendPeople: [],
				lessonId: undefined,
				lessonName: undefined,
			},
		},
		{
			name: Suggestion.RecreateExpiredCourse,
			meta: {
				people: [],
				actionTimeFrame: SuggestionActionTimeFrame.LongTerm,
				courseId: undefined,
				courseName: 'Science 101',
				recreatePeople: [],
				extendPeople: [],
				lessonId: undefined,
				lessonName: undefined,
			},
		},
		{
			name: Suggestion.RecreateFailedCourse,
			meta: {
				people: [],
				actionTimeFrame: SuggestionActionTimeFrame.ShortTerm,
				courseId: undefined,
				courseName: 'History 101',
				recreatePeople: [],
				extendPeople: [],
				lessonId: undefined,
				lessonName: undefined,
			},
		},
		{
			name: Suggestion.ImproveLesson,
			meta: {
				people: [],
				actionTimeFrame: SuggestionActionTimeFrame.LongTerm,
				courseId: undefined,
				courseName: undefined,
				recreatePeople: [],
				extendPeople: [],
				lessonId: 'lesson_001',
				lessonName: 'Introduction to Programming',
			},
		},
	]

	const riskScoreActionTimeFrameGroups = useMemo(() => {
		return riskScoreSuggestions.reduce<Record<SuggestionActionTimeFrame, IRiskScoreSuggestion[]>>(
			(acc, suggestion) => {
				const { actionTimeFrame } = suggestion.meta
				if (!acc[actionTimeFrame]) {
					acc[actionTimeFrame] = []
				}
				acc[actionTimeFrame].push(suggestion)
				return acc
			},
			{
				[SuggestionActionTimeFrame.Immediate]: [],
				[SuggestionActionTimeFrame.ShortTerm]: [],
				[SuggestionActionTimeFrame.LongTerm]: [],
			},
		)
	}, [riskScoreSuggestions])

	// TODO svaki red ima svoj resolve, to cmeo iskopirati na osnovu ovog sto imamo od pre
	// TODO skloniti red nakon resolvovanja ili oznaciti da je resolved nekako pa kad refresh mora da nestane
	// TODO translate za resolve akcije
	// TODO kill myself and i

	return (
		<Wrapper>
			<div style={{ paddingBlock: '16px', marginBottom: '16px' }}>
				<TitleSubtitleWrapper
					title={t(`${TRANSLATE_RECOMMENDED_ACTIONS_BASE_KEY}.title`)}
					subtitle={t(`${TRANSLATE_RECOMMENDED_ACTIONS_BASE_KEY}.subtitle`)}
				/>
			</div>
			{Object.entries(riskScoreActionTimeFrameGroups).map(([timeFrame, groupedSuggestions]) => {
				if (groupedSuggestions.length === 0) {
					return
				}

				return (
					<ActionListCard
						key={timeFrame}
						riskScoreSuggestions={groupedSuggestions}
						actionTimeFrame={timeFrame as SuggestionActionTimeFrame}
					/>
				)
			})}
		</Wrapper>
	)
}

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	gap: ${SPACES.DEFAULT};
	background: ${Colors.White};
	border-bottom-left-radius: ${SPACES.DEFAULT};
	border-bottom-right-radius: ${SPACES.DEFAULT};
	padding: ${SPACES.EXTRA_LARGE} 48px;
`
export default RecommendedActions
