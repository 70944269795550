import { FC, useMemo } from 'react'
import { Form } from 'formik-antd'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import { Button, FormikInput, Title } from '../../../components'
import { IResetEmailRequest } from '../utils/types'
import { useMutation } from 'react-query'
import {
	resetPasswordRequest as resetPasswordRequestApi,
	RESET_PASSWORD_REQUEST_KEY,
} from '../utils/api'
import { FormWrapper } from './StyledComponents'
import { useHistory } from 'react-router'
import { Routes } from '../../../routes/config'

interface IProps {
	goToLogin(): void
}

const RESET_PASS_BASE_KEY = 'auth.forgetPassword'
const FIELDS_BASE_KEY = 'auth.fields'
const ERRORS_BASE_KEY = 'common.validation.errors'

const ResetPasswordForm: FC<IProps> = ({ goToLogin }) => {
	const history = useHistory()
	const { t } = useTranslation()

	const { mutate: resetPasswordRequest, isLoading: isSubmitting } = useMutation(
		resetPasswordRequestApi,
		{
			mutationKey: RESET_PASSWORD_REQUEST_KEY,
		},
	)

	const handleResetEmail = (data: IResetEmailRequest) => {
		resetPasswordRequest(data, {
			onSuccess: () => {
				history.push(Routes.Auth)
				message.success('Email sent')
			},
			onError: () => {
				// TODO: use real error
				message.error('Error happened')
			},
		})
	}

	const schema = useMemo(() => {
		return Yup.object().shape({
			email: Yup.string()
				.email(t(`${ERRORS_BASE_KEY}.email`))
				.required(t(`${ERRORS_BASE_KEY}.required`)),
		})
	}, [t])

	return (
		<FormWrapper>
			<Title level={1}>{t(`${RESET_PASS_BASE_KEY}.title`)}</Title>
			<p>
				{t(`${RESET_PASS_BASE_KEY}.subtitle`)}{' '}
				<Button type="link" onClick={goToLogin}>
					{t(`${RESET_PASS_BASE_KEY}.goBack`)}
				</Button>
			</p>
			<Formik initialValues={{ email: '' }} onSubmit={handleResetEmail} validationSchema={schema}>
				{() => (
					<Form layout="vertical">
						<Form.Item name="email" label={t(`${FIELDS_BASE_KEY}.email.label`)}>
							<FormikInput
								name="email"
								placeholder={t(`${FIELDS_BASE_KEY}.email.placeholder`)}
								size="large"
							/>
						</Form.Item>
						<Button type="primary" htmlType="submit" size="large" block loading={isSubmitting}>
							{t(`${RESET_PASS_BASE_KEY}.button`)}
						</Button>
					</Form>
				)}
			</Formik>
		</FormWrapper>
	)
}

export default ResetPasswordForm
