import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ReactComponent as CourseRateIcon } from '../../../../assets/courses-completion-rate.svg'
import { ReactComponent as NanoRateIcon } from '../../../../assets/nano-rate.svg'
import { Colors } from '../../../../styles/colors'
import { SPACES } from '../../../../styles/spaces'
import TitleSubtitleWrapper from '../../../inventory/components/common/TitleSubtitleWrapper'
import AwarenessRate from './AwarenessRate'

interface IProps {
	icon: React.ReactNode
	titleColor: string
	title: string
	subtitle: string
}

const TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY =
	'pdfExports.RiskManagementExport.trainingCampaignsOverview'

const AwarenessSummary: React.FC<IProps> = () => {
	const { t } = useTranslation()

	return (
		<Wrapper>
			<SectionWrapper
				style={{
					borderRight: `0.5px solid ${Colors.GrayHover}`,
				}}
			>
				<TitleSubtitleWrapper
					title={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.courses.title`)}
					subtitle={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.courses.subtitle`)}
				/>
				<StatisticWrapper>
					<AwarenessRate
						rate={'95%'}
						title={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.courses.completionRate.title`)}
						subtitle={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.courses.completionRate.subtitle`)}
						icon={<CourseRateIcon />}
					/>
					<AwarenessRate
						rate={'23/38'}
						title={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.courses.passingRate.title`)}
						subtitle={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.courses.passingRate.subtitle`)}
						icon={<CourseRateIcon />}
					/>
				</StatisticWrapper>
			</SectionWrapper>
			<SectionWrapper>
				<TitleSubtitleWrapper
					title={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.nanoQuiz.title`)}
					subtitle={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.nanoQuiz.subtitle`)}
				/>
				<StatisticWrapper>
					<AwarenessRate
						rate={'36/42'}
						title={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.nanoQuiz.completionRate.title`)}
						subtitle={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.nanoQuiz.completionRate.subtitle`)}
						icon={<NanoRateIcon />}
					/>
					<AwarenessRate
						rate={'45%'}
						title={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.nanoQuiz.passingRate.title`)}
						subtitle={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.nanoQuiz.passingRate.subtitle`)}
						icon={<NanoRateIcon />}
					/>
				</StatisticWrapper>
			</SectionWrapper>
		</Wrapper>
	)
}

const Wrapper = styled.section`
	display: flex;
	border-top-left-radius: ${SPACES.DEFAULT};
	border-top-right-radius: ${SPACES.DEFAULT};
	background: ${Colors.White};
`
const StatisticWrapper = styled.div`
	display: flex;
	align-items: flex-top;
	gap: ${SPACES.EXTRA_LARGE};
	padding-top: ${SPACES.DEFAULT};
`

const SectionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	padding: 40px 48px;
`
export default AwarenessSummary
