const sr = {
	common: {
		title: 'Prijave ekstenzije',
		properties: {
			fullName: { title: 'Ime' },
			email: { title: 'Email' },
			createdAt: { title: 'Prijavljeno' },
		},
		actions: {
			previewContent: 'Pregled sadržaja',
			previewHTML: 'Pregled HTML-a',
		},
		modal: {
			previewContent: {
				title: 'Pregled sadržaja',
			},
			previewHTML: {
				title: 'Pregled HTML-a',
			},
		},
	},
}

export default sr
