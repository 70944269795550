export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string
export const API_USERS_URL = '/api/v1/users'
export const API_INVENTORY_URL = '/api/v1/inventory'
export const API_LEARNING_URL = '/api/v1/learning'
export const API_VULNERABILITY_URL = '/api/v1/vulnerabilities'
export const API_PHISHING_URL = '/api/v1/phishing'
export const API_NOTIFICATIONS_URL = '/api/v1/notifications'
export const API_WAF_URL = '/api/v1/waf'
export const API_ACTIVITY_LOGS_URL = '/api/v1/activity-logs'
export const API_LEAKS_URL = '/api/v1/leaks'
