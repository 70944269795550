import styled from 'styled-components'
import React from 'react'

import { Colors } from '../styles/colors'
import { SPACES } from '../styles/spaces'
import { Title } from '.'
import TitleFilterStatus from './TitleFilterStatus'
import { AwarenessStatus } from '../features/awareness/utils/types'

interface IProps {
	title: string
	subtitle?: string
	leftActions?: React.ReactNode
	children?: React.ReactNode
	isGrayHeader?: boolean
	selected?: AwarenessStatus[]
	setSelected?(selectedStatuses: AwarenessStatus[]): void
}

const PageTitle: React.FC<IProps> = ({
	title,
	subtitle,
	children,
	leftActions,
	isGrayHeader,
	selected,
	setSelected,
}) => {
	return (
		<WrapperInfo isGrayHeader={isGrayHeader}>
			<Name>
				<StyledGroup>
					<StyledTitle>
						<Title level={2}>{title}</Title>
						{selected && <TitleFilterStatus selected={selected} onApply={setSelected!} />}
					</StyledTitle>
					<Description>{subtitle}</Description>
				</StyledGroup>
				{leftActions && leftActions}
			</Name>
			<Actions>{children}</Actions>
		</WrapperInfo>
	)
}

const WrapperInfo = styled.div<{ isGrayHeader }>`
	padding: ${(props) =>
		props.isGrayHeader ? `0px 0px 10px 0px` : `${SPACES.SMALL} ${SPACES.LARGE}`};
	background-color: ${(props) => (props.isGrayHeader ? null : Colors.White)};
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const Name = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	h2 {
		margin-bottom: 0;
	}
`

const Actions = styled.div`
	display: flex;
	align-items: center;
`

const StyledTitle = styled.div`
	margin-right: ${SPACES.SMALL};
	display: flex;
	align-items: center;
`
const StyledGroup = styled.div`
	display: flex;
	flex-direction: column;
`

const Description = styled.div`
	display: flex;
	align-items: left;
	flex-wrap: wrap;
	padding-top: ${SPACES.XXS};
	font-size: 14px;
	width: 600px;
`

export default PageTitle
