import styled from 'styled-components'
import { useMemo } from 'react'
import { IPeopleOverviewResponse, IPerson } from 'blue-project-types'

import { mapRiskScoreValueToEnum } from '../../../../../utils/risk-score'
import useCreateRiskCoreResolvers from '../../../hooks/memo/useCreateRiskCoreResolvers'
import { RiskScore } from '../../../../../utils/constants'
import RiskScoreSummaryNew from './RiskScoreSummaryNew'
import { Colors } from '../../../../../styles/colors'
import { SPACES } from '../../../../../styles/spaces'
import RiskScoreValueNew from './RiskScoreValueNew'

interface IProps {
	peopleSummary: IPeopleOverviewResponse
	people: IPerson[]
}

const PeopleRiskScoreNew: React.FC<IProps> = ({ peopleSummary, people }) => {
	const riskScoreResolvers = useCreateRiskCoreResolvers({ peopleSummary, people })

	const riskScoreEnum = useMemo(() => {
		if (!peopleSummary) return null
		return mapRiskScoreValueToEnum(peopleSummary.riskScore)
	}, [peopleSummary])

	return (
		<Wrapper>
			<RiskScoreValueNew riskScore={63} riskScoreEnum={RiskScore.High!} />
			<RiskScoreSummaryNew
				riskScoreEnum={RiskScore.High!}
				riskScoreResolvers={riskScoreResolvers}
			/>
		</Wrapper>
	)
}

const Wrapper = styled.section`
	display: flex;
	gap: ${SPACES.DEFAULT};
	background: ${Colors.White};
	border-top-left-radius: ${SPACES.DEFAULT};
	border-top-right-radius: ${SPACES.DEFAULT};
`

export default PeopleRiskScoreNew
