import {
	Discovered,
	ExploitAvailable,
	Severity,
	VulnerabilityProviders,
	VulnerabilityStatus,
} from 'blue-project-types'

const sr = {
	common: {
		days: 'dani',
		day: 'dan',
		severity: {
			[Severity.Critical]: 'Kritično',
			[Severity.High]: 'Visoko',
			[Severity.Info]: 'Info',
			[Severity.Low]: 'Nisko',
			[Severity.Medium]: 'Srednje',
		},
		status: {
			[VulnerabilityStatus.AutoDiscovered]: 'Automatski otkriven',
			[VulnerabilityStatus.Draft]: 'Nacrt',
			[VulnerabilityStatus.Fixing]: 'Popravka',
			[VulnerabilityStatus.ReTest]: 'Ponovo testiranje',
			[VulnerabilityStatus.Reported]: 'Prijavljen',
			[VulnerabilityStatus.Resolved]: 'Rešen',
		},
		exploitAvailable: {
			[ExploitAvailable.False]: 'Ne',
			[ExploitAvailable.NoNApplicable]: 'Nije validno',
			[ExploitAvailable.True]: 'Da',
		},
		discovered: {
			[Discovered.Automatically]: 'Automatski',
			[Discovered.Manually]: 'Manuelno',
		},
		properties: {
			title: {
				label: 'Naziv propusta',
				placeholder: 'Unesite naziv propusta',
			},
			description: {
				placeholder: 'Unesite opis propusta',
			},
			type: {
				label: 'Tip propusta',
				placeholder: 'Unesite tip propusta',
			},
			severity: {
				label: 'Ugroženost',
				placeholder: 'Unesite nivo ugroženosti',
			},
			severityScore: {
				label: 'Stepen ugroženosti',
				placeholder: 'Unesite stepen ugroženosti',
			},
			discovered: {
				label: 'Otkriveno',
			},
			exploitAvailable: {
				label: 'Moguća zloupotreba',
				placeholder: 'Odaberite moguću zloupotrebu',
			},
			lob: {
				label: 'Verovatnoća uspešnog napada',
				placeholder: 'Unesite verovatnoću uspešnog napada',
			},
			businessImpact: {
				label: 'Posledice zloupotrebe po biznis',
				placeholder: 'Unesite nivo posledice zloupotrebe po biznis',
			},
			assetsAffected: {
				label: 'Ugroženi resursi',
				placeholder: 'Odaberite ugrožene resurse',
			},
			cvs: {
				label: 'CVS reference',
				placeholder: 'Unesite CVS reference',
			},
			OSVDB: {
				label: 'OSVDB',
				placeholder: 'Unesite OSVDB',
			},
			BID: {
				label: 'BID',
				placeholder: 'Unesite BID',
			},
			id: {
				label: 'ID Propusta',
			},
		},
	},
	dashboard: {
		title: 'Propusti',
		addNewButton: 'Prijavite novi propust',
		draftsButton: 'Nacrti',
		tabs: {
			all: 'SVI',
			reported: 'PRIJAVLJENI',
			fixing: 'POPRAVKA',
			reTest: 'PONOVO TESTIRAJ',
			resolved: 'REŠENI',
		},
		table: {
			columns: {
				title: 'Naziv',
				type: 'Tip',
				assetsAffected: 'Ugroženi resursi',
				severityScore: 'Stepen ugroženosti',
				severity: 'Ugroženost',
				discoveredOn: 'Otkriveno dana',
				status: 'Status',
			},
		},
		search: {
			placeholder: 'Pretražite propuste',
		},
		discoveredOnFilter: {
			startPlaceholder: 'Otkriveno početak',
			endPlaceholder: 'Otkriveno kraj',
		},
		severityFilter: {
			button: 'Ugroženost',
		},
		typeFilter: {
			button: 'Tip',
		},
		assetsFilter: {
			button: 'Resursi',
		},
		criticalVulnerabilityAlert: {
			titleOneVulnerability: '1 kritičan propust otkriven',
			titleMultipleVulnerability:
				'Broj otkrivenih kritičnih propusta je {{numberOfCriticalVulnerabilities}}',
			subtitle: '',
			button: 'Pogledaj sada',
		},
	},
	drafts: {
		title: 'Nacrti - Propusti',
		tabs: {
			all: 'SVI',
			drafts: 'NACRTI',
		},
		removeDraft: {
			title: 'Obriši nacrt',
			text: 'Da li ste sigurni da želite da obrišete nacrt?',
		},
	},
	create: {
		title: 'Prijavite propust',
		saveDraftButton: 'Sačuvajte nacrt',
		reportButton: 'Prijavite',
		titleAndDescription: 'Naziv i opis',
		vulnerabilityDetails: 'Detalji propusta',
	},
	details: {
		editButton: 'Izmenite Propust',
		gotIt: 'Zatvori',
		headerInfo: {
			lastStatus: 'Status ažuriran dana',
			status: 'Status',
		},
		description: 'Opis',
		vulnerabilityDetails: 'Detalji propusta',
		activity: 'Aktivnosti',
		noActivityFound: 'Ne postoje aktivnosti',
		systemActivity: 'Sistem',
		graph: {
			lob: 'VEROVATNOĆA USPEŠNOG NAPADA',
			bi: 'POSLEDICE ZLOUPOTREBE PO BIZNIS',
		},
		activityUpdater: {
			text: {
				placeholder: 'Unesite opis aktivnosti',
			},
			status: {
				label: 'Novi Status',
				placeholder: 'Odaberite novi status',
			},
		},
		systemActivities: {
			reported: 'Propust je prijavljen.',
			edited: 'Propust je izmenjen.',
		},
	},
	statistics: {
		title: 'Upravljanje propustima',
		header: {
			score: 'Procena rizika',
			criticalVulnerabilities: 'Kritični propusti',
		},
		atTheMoment: 'U ovom trenutku',
		inTheLast: 'U poslednjih',
		days: 'dana',
		activity: {
			title: 'Aktivnost',
		},
		riskMatrix: {
			title: 'Matrica rizika',
			likelihood: 'VEROVATNOĆA',
			businessImpact: 'POTENCIJALNA ŠTETA',
		},
		vulnerabilityBySeverity: {
			title: 'Propusti po ugroženosti',
		},
		assetsAtRisk: {
			title: 'Ugroženi resursi',
		},
		riskScore: {
			title: 'Trenutna procena rizika',
		},
		timeToRemediate: {
			title: 'Srednja vrednost vremena potrebnog za popravku',
			days: 'dana',
			forCriticalDescription:
				'U slučaju kritičnog propusta, popravka mora uslediti u manje od pet dana',
			infoDescription: 'vs <1>10</1> dana po industrijskom proseku',
		},
		newDiscovery: {
			title: 'Nova otkrića',
			median: 'srednja vrednost',
			onAverage: 'u proseku',
		},
		vulnerabilitiesBySeverity: {
			title: 'Propusti po ugroženosti',
		},
		distributionOfVulnerabilities: {
			title: 'Raspodela propusta',
			vulnerability: 'propust',
			vulnerabilities: 'propusti',
		},
		vulnerabilitiesBySeverityGraph: {
			title: 'Propusti po ugroženosti',
		},
		vulnerabilitiesByAgeAndSeverity: {
			title: 'Propusti sortirani po starosti i ugroženosti',
			dates: {
				oneWeek: '0 - 7 dana',
				oneMonth: '8 - 30 dana',
				twoMonths: '31 - 60 dana',
				threeMonths: '61 - 91 dana',
				moreThenThreeMonths: '> 91 dana',
			},
		},
		activities: {
			today: 'DANAS',
			thisWeek: 'OVE NEDELJE',
			noActivitiesToday: 'Bez aktivnosti danas',
			noActivitiesThisWeek: 'Bez aktivnosti ove nedelje',
			loadMore: 'Učitaj još unosa aktivnosti',
		},
	},
	enrichers: {
		title: 'Opisi propusta',
		addNewButton: 'Dodaj novi opis',
		tabs: {
			enrichers: 'Opisi',
			discovers: 'Otkiveni propusti bez opisa',
		},
		actions: {
			search: 'Pretraži opise',
		},
		table: {
			columns: {
				title: 'Naslov',
				cve: 'Cve',
				createdAt: 'Kreiran',
				providers: 'Provajderi',
			},
			actions: {
				edit: 'Izmeni',
				remove: 'Obrši',
			},
		},
		remove: {
			removingSuccess: 'Opis propusta uspešno obrisan',
			modalTitle: 'Obriši opis propusta',
			modalText: 'Da li ste sigurni da želite obrisati <1>{{title}}</1> iz list opisa propusta?',
		},
		createEdit: {
			createTitle: 'Kreiraj opis propusta',
			editTitle: 'Edituj opis propusta',
			successfullyCreated: 'Opis propusta uspešno obrisan',
			successfullyUpdated: 'Opis propusta uspešno ažuriran',
			properties: {
				title: {
					label: 'Naslov',
					placeholder: 'Uneti naslov',
				},
				cve: {
					label: 'CVE',
					placeholder: 'Uneti CVE',
				},
				description: {
					label: 'Opis',
					placeholder: 'Enter opis',
				},
				providers: {
					label: 'Provajder',
					addButton: 'Dodaj provajder',
					noProviders: 'Nisu pronađeni provajderi',
				},
				providersType: {
					placeholder: 'Izabrati tip provajdera',
					alreadyAddedError: 'Provajder istog tipa je već dodat',
					options: {
						[VulnerabilityProviders.Nuclei]: 'Nuclei',
						[VulnerabilityProviders.Shodan]: 'Shodan',
					},
				},
				providerId: {
					placeholder: 'Provajder id',
				},
				useCve: {
					label: 'Koristi CVE kao id',
				},
			},
		},
		discoverWithoutEnrichers: {
			table: {
				columns: {
					providerType: 'Provajder',
					providerId: 'Provajder id',
				},
				actions: {
					addEnricher: 'Dodaj',
				},
			},
		},
	},
}

export default sr
