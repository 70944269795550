import { LeakStatus, StrengthCode } from 'blue-project-types'

const en = {
	credentialSearches: {
		pageTitle: 'Credential searches',
		table: {
			columns: {
				term: 'Term',
				status: 'Status',
				filesCount: 'Num. of files',
				leaksCount: 'Num. of leaks',
				createdAt: 'Created at',
				updatedAt: 'Updated at',
			},
		},
		search: {
			placeholder: 'Search by term',
		},
		addNewButton: 'Add new search',
		addSearchMessage: {
			error: 'There was an issue while requesting new search',
			inProgress: 'Requested search is now in progress',
			refreshInProgress: 'Search refresh is now in progress',
			completed: 'Search is already completed',
		},
		orderOptionsValues: {
			dateAsc: 'Date Ascending',
			dateDesc: 'Date Descending',
			noSort: 'No Sort',
			xScoreAsc: 'XScore Ascending',
			xScoreDesc: 'XScore Descending',
		},
		alert: {
			title: 'Credential search credits',
		},
	},
	requestSearchModal: {
		title: 'Request new search',
		termInputLabel: 'Email, domain or subdomain',
		termInputPlaceholder: 'Enter email, domain or subdomain',
		limitInputLabel: 'Limit',
		limitInputPlaceholder: 'Enter limit',
		orderSelectLabel: 'Order',
		orderSelectPlaceholder: 'Select how results is ordered',
		fromLabel: 'From',
		toLabel: 'To',
		endDateBeforeStartError: 'End date must be after start date',
		startDateAfterEndError: 'Start date must be before end date',
		searchTermRequiredError: 'Search term is required',
	},
	credentialSearch: {
		alertText: 'Config used for search',
		changeSearchConfigButton: 'Change search config',
		tabs: {
			credentials: 'Credentials',
			files: 'Files',
		},
		credentials: {
			table: {
				columns: {
					url: 'Url',
					username: 'Username',
					password: 'Password',
					status: 'Password status',
					tags: 'Tags',
					weakness: 'Weakness',
					exposedAt: 'Exposed at',
					lastNotifiedAt: 'Last notified at',
					actions: 'Actions',
					menu: {
						editLeakStatus: 'Edit leak status',
						notify: 'Send leak notification',
					},
				},
			},
			tooltip: {
				[LeakStatus.Deprecated]:
					'This password has been flagged as deprecated due to many factors that indicate its vulnerability.',
				[LeakStatus.Discovered]:
					'This password has been discovered on the internet and it represents a security threat.',
				[LeakStatus.Resolved]:
					'This password has been changed, and it no longer represents a threat.',
				worst500:
					"This password is among the worst  used, according to SecList's 500 worst passwords.",
				mostCommon:
					"This password is among the most commonly used, according to SecList's  {{tagNumber}}  most common passwords.",
			},
			leakStatus: {
				[LeakStatus.Deprecated]: 'Deprecated',
				[LeakStatus.Discovered]: 'Discovered',
				[LeakStatus.Resolved]: 'Resolved',
			},
			strengthCode: {
				[StrengthCode.Reasonable]: 'Reasonable',
				[StrengthCode.Strong]: 'Strong',
				[StrengthCode.VeryStrong]: 'Very strong',
				[StrengthCode.VeryWeak]: 'Very weak',
				[StrengthCode.Weak]: 'Weak',
			},
			metaData: {
				timeLeakedSingle: '1 time leaked',
				timesLeakedNumber: '{{timesLeakedNumber}} times leaked ',
				timesLeakedRange: '{{timesLeakedNumber}}+ times leaked ',
				worst500: '500 worst',
				mostCommon: '{{tagNumber}} most common',
			},
			search: {
				placeholder: 'Search by username',
			},
			changeLeakStatusModal: {
				title: 'Change leak status',
				leakStatusSelectLabel: 'Leak status',
				leakStatusSelectPlaceholder: 'Select leak status',
				leakStatusOptionsLabels: {
					discovered: 'Discovered',
					deprecated: 'Deprecated',
					resolved: 'Resolved',
				},
				statusChangeSuccess: 'Updated leak status',
			},
		},
		changeSearchConfigMessage: {
			error: 'There was an issue while requesting search with new config',
			inProgress: 'Search with new configuration is now in progress',
			refreshInProgress: 'Refreshing search with new config',
			completed: 'Search with this config  is already completed',
		},
		files: {
			table: {
				columns: {
					name: 'File name',
					score: 'Score',
					status: 'Status',
					parsedBy: 'Parsed By',
					createdAt: 'Created at',
					updatedAt: 'Updated at',
					preview: 'Preview',
					reattemptParse: 'Reattempt file parse',
				},
			},
			search: {
				placeholder: 'Search by file name',
			},
			reattemptFileParseSuccess: 'Successfully requested file parsing',
		},
	},
	credentials: {
		pageTitle: 'Credentials',
		actions: {
			searchPlaceholder: 'Search credentials',
			btn: 'Export report',
			notify: 'Notify all',
			statusFilter: 'Status',
			strengthFilter: 'Weakness',
		},
		domains: {
			title: 'Domains',
			noDomains: 'No domains added',
			lastScannedAtTooltip: 'Last scanned on',
		},
		newCredentialsAlert: {
			title: 'New leaks discovered',
			subtitle:
				'New credential leaks has been discovered and shown bellow. Close alert & highlighting by clicking on got it button.',
			button: 'Got it',
		},
		notifyLeakedCredentialOwnersModal: {
			title: 'Send leaked credential notifications',
			emailLanguageSelectLabel: 'Email language',
			emailLanguageSelectPlaceholder: 'Chose language used in email',
			displayAllLeakedPasswordsCheckboxLabel: 'Send all leaked passwords',
			emailLanguageOptions: {
				en: 'English',
				sr: 'Serbian',
			},
		},
		notifyLeakedCredentialOwnersSuccess: 'Successfully sent notifications',
	},
	feature: {
		pageTitle: 'Data leaks',
		title: 'This feature is not enabled for your organization.',
		content1: 'To launch Data leaks feature,',
		content2: 'please contact Seif sales team!',
		contactBtn: 'Contact Us',
	},
}

export default en
