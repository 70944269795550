import { css } from 'styled-components'

export const breakpoints: IBreakpointsDefinitions = {
	xlg: '1670px',
	xl: '1430px',
	xls: '1330px',
	lg: '1200px',
	md: '991px',
	sm: '767px',
	xs: '575px',
	xss: '400px',
}

interface IBreakpointsDefinitions {
	[breakpoint: string]: string
}

interface IBreakpoints {
	[breakpoint: string]: any
}

export const respondTo = Object.keys(breakpoints).reduce((accumulator, label) => {
	accumulator[label] = (literals: TemplateStringsArray, ...args: any) => css`
		@media (max-width: ${breakpoints[label]}) {
			${css(literals, ...args)};
		}
	`
	return accumulator
}, {} as IBreakpoints)
