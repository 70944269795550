const en = {
	accountSettings: {
		title: 'Account settings',
		userInfo: {
			editButton: 'Edit',
			editModal: {
				title: 'Change account details',
				fields: {
					firstName: {
						label: 'First name',
						placeholder: 'Enter first name',
					},
					lastName: {
						label: 'Last name',
						placeholder: 'Enter last name',
					},
				},
				messages: {
					success: 'Profile successfully updated',
					error: 'Error happened!',
				},
				confirmButton: 'Save changes',
			},
		},
		updatePassword: {
			title: 'Change Password',
			messages: {
				success: 'Password successfully updated',
				error: 'Error happened!',
			},
			fields: {
				oldPassword: {
					label: 'Old password',
					placeholder: 'Enter old password',
				},
				newPassword: {
					label: 'New password',
					placeholder: 'Enter new password',
				},
			},
			confirmButton: 'Save changes',
		},
		twoStepAuth: {
			title: 'Set up two-factor authentication',
			description:
				'Protect your account by adding an extra layer of security. A second login step can keep your account secure, even if your password is compromised. To enable it, all you need is a smart phone.',
			button: 'Set Up Two Factor Authentication',
		},
	},
	twoFactorAuth: {
		title: 'Two-factor authentication',
		steps: {
			confirmIdentity: 'Confirm identity',
			getApp: 'Get app',
			connectPhone: 'Connect phone',
			saveKey: 'Save recovery key',
		},
		verifyPassword: {
			title: 'Enter your password',
			subtitle:
				"Start by entering your password so that we know it's you. Then we'll walk you through three more simple steps.",
			fields: {
				password: {
					label: 'Password',
					placeholder: 'Enter password',
				},
			},
			submit: "Let's Go",
		},
		getApp: {
			title: 'Install verification app on your phone',
			subtitle:
				'You will need to use a verification app such as Google Authenticator, Authy or Duo. Install from your store.',
			question: 'Do you have app ready?',
			scan: '	Yes, Ready To Scan Code',
		},
		connectPhone: {
			section1: {
				title: 'Scan this QR code',
				subtitlePart1: "Once your app reads the QR code you'll get a 6-digit code.",
				subtitlePart2: 'or use this code:',
			},
			section2: {
				title: 'Enter the 6-digit code here',
				subtitle:
					"Once connected we'll remember your phone, so you can use it each time you log in",
			},
		},
		recoveryKeys: {
			title: 'Save emergency recovery key',
			subtitle:
				"If you lose access to your phone you won't be able to log in to your account without this key. Print, copy or write down this key, without letting anyone see it.",
			saveButton: 'Save it!',
			savedButton: "Saved it, let's finish",
			copyToClipboard: 'Copy to clipboard',
		},
	},
	activeTwoFactorAuth: {
		createNewKeyTitle: 'Create a new emergency recovery key',
		createNewKeySubtitle:
			'Once you create a new key, your existing emergency recovery key will expire.',
		createNewKeyRoute: 'Create New Key',
		twoFactorAuth: 'Two-factor authentication',
		enabled: 'enabled',
		yourAccountIsProtected: 'Your account is protected with second step of verification since ',
		ifYouDisable: 'If you disable 2FA, you will no longer have protection of a second login step',
		disableButton: 'Disable 2FA',
		disableModal: {
			title: 'Disable two-factor authentication',
			subtitle: 'If you proceed you will no longer have the protection of a second login step.',
			fields: {
				password: {
					label: 'Password',
					placeholder: 'Enter password',
				},
			},
			proceed: 'Proceed',
		},
		messages: {
			successfullyDisabled: 'Successfully disabled',
		},
	},
	newRecoveryKey: {
		title: 'Two-factor authentication',
		steps: {
			createNewKey: 'Create new key',
			saveNewKey: 'Save new key',
		},
		createNewKey: {
			title: 'Create a new emergency recovery key',
			subtitle:
				'If you continue, your new key will be valid right away, and existing one will no longer work.',
			create: 'Create New Key',
			fields: {
				password: {
					label: 'Password',
					placeholder: 'Enter password',
				},
			},
		},
	},
	mySessions: {
		table: {
			title: 'My sessions',
			columns: {
				ip: 'IP',
				userAgent: 'User Agent',
				createdAt: 'Created at',
				validUntil: 'Valid until',
			},
			action: {
				logout: 'Logout',
			},
			loggedOut: {
				title: 'Logged out this session?',
				subtitle: 'Are you sure you want to logged out this session?',
			},
		},
		useLogoutSession: {
			success: 'Session successfully logged out',
			error: 'Error happened',
		},
	},
}

export default en
