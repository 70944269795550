import {
	ShieldErrorIcon,
	ShieldSuccessIcon,
	ShieldWarningIcon,
} from '../../../../../components/Icons'
import { RiskScoreColors } from '../../../../../styles/colors'
import { SPACES } from '../../../../../styles/spaces'
import { RiskScore } from '../../../../../utils/constants'

interface IProps {
	riskScoreEnum: RiskScore
}

const MAP_RISK_SCORE_TO_ICON = {
	[RiskScore.Critical]: ShieldErrorIcon,
	[RiskScore.High]: ShieldErrorIcon,
	[RiskScore.Medium]: ShieldWarningIcon,
	[RiskScore.Low]: ShieldSuccessIcon,
	[RiskScore.VeryLow]: ShieldSuccessIcon,
}

const RiskScoreSummaryIcon: React.FC<IProps> = ({ riskScoreEnum }) => {
	const Component = MAP_RISK_SCORE_TO_ICON[riskScoreEnum]

	return (
		<div style={{ marginTop: 6, marginRight: SPACES.SMALL }}>
			<Component style={{ fontSize: 48, color: RiskScoreColors[riskScoreEnum] }} />
		</div>
	)
}

export default RiskScoreSummaryIcon
