import styled from 'styled-components'
import { BellOutlined, InfoOutlined, WarningOutlined, CheckOutlined } from '@ant-design/icons'
import { lighten } from 'polished'
import { Title, Text } from './'
import { Colors } from '../styles/colors'
import { SPACES } from '../styles/spaces'

export enum AlertType {
	Warning = 'warning',
	Error = 'error',
	Info = 'info',
	Success = 'success',
}

interface IProps {
	title?: string
	subtitle?: string
	type: 'warning' | 'error' | 'info' | 'success'
	action?: React.ReactNode
}

const MAP_COLOR_TO_ALERT_TYPE = {
	[AlertType.Error]: Colors.Red,
	[AlertType.Warning]: Colors.Orange,
	[AlertType.Info]: Colors.Blue,
	[AlertType.Success]: Colors.Green,
}

const MAP_ICON_TO_ALERT_TYPE = {
	[AlertType.Error]: WarningOutlined,
	[AlertType.Warning]: BellOutlined,
	[AlertType.Info]: InfoOutlined,
	[AlertType.Success]: CheckOutlined,
}

const MAP_BACKGROUND_TO_ALERT_TYPE = {
	[AlertType.Error]: Colors.RedLight,
	[AlertType.Warning]: Colors.OrangeLight,
	[AlertType.Info]: lighten('0.4', Colors.Blue),
	[AlertType.Success]: lighten('0.4', Colors.Green),
}

const Alert: React.FC<IProps> = ({ title, subtitle, type, action }) => {
	const Icon = MAP_ICON_TO_ALERT_TYPE[type]
	const color = MAP_COLOR_TO_ALERT_TYPE[type]
	const backgroundColor = MAP_BACKGROUND_TO_ALERT_TYPE[type]

	return (
		<Wrapper color={color} backgroundColor={backgroundColor}>
			<LeftSection>
				<BellWrapper color={color}>{<Icon />}</BellWrapper>
				<TextWrapper>
					<Title level={5} color={color} zeroMargin>
						{title}
					</Title>
					{subtitle && <Text zeroMargin>{subtitle}</Text>}
				</TextWrapper>
			</LeftSection>
			{action ? action : null}
		</Wrapper>
	)
}

const Wrapper = styled.div<{ color: Colors; backgroundColor: string }>`
	border: ${({ color }) => `1px solid ${color}`};
	background: ${({ backgroundColor }) => backgroundColor};
	padding: ${SPACES.SMALL};
	border-radius: 6px;
	margin-bottom: ${SPACES.DEFAULT};
	display: flex;
	align-items: center;
	justify-content: space-between;
	button.ant-btn.ant-btn-default {
		color: ${Colors.Dark};
		background: #fff;
		&:hover {
			background: #fff;
			color: ${Colors.Dark};
		}
	}
`

const BellWrapper = styled.div<{ color: Colors }>`
	background: ${({ color }) => color};
	min-width: ${SPACES.EXTRA_LARGE};
	min-height: ${SPACES.EXTRA_LARGE};
	width: ${SPACES.EXTRA_LARGE};
	height: ${SPACES.EXTRA_LARGE};
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	svg {
		font-size: 16px;
		color: white;
	}
`

const TextWrapper = styled.div`
	margin-left: ${SPACES.DEFAULT};
	white-space: pre-line;
`

const LeftSection = styled.div`
	display: flex;
	align-items: center;
`

export default Alert
