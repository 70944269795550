import { Typography } from 'antd'
import { INotification, NotificationType } from 'blue-project-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Colors } from '../../../styles/colors'
import { getFullName } from '../../../utils/info'

interface IProps {
	notification: INotification
}

const NotificationText: React.FC<IProps> = ({ notification }) => {
	const { t } = useTranslation()

	const notificationText = useMemo(() => {
		if (notification?.type === NotificationType.VulnerabilityCreated) {
			return t(`notifications.common.text.${notification.type}`, {
				severity: t(`vulnerabilities.common.severity.${notification.meta?.vulnerabilitySeverity}`),
			})
		}

		if (notification?.type === NotificationType.CredentialLeaked) {
			const suffix = notification.meta.credentials.length < 2 ? '_one' : '_multiple'
			return t(`notifications.common.text.${notification.type}${suffix}`, {
				numberOfLeaks: notification.meta.credentials.length,
				usernames: notification.meta.credentials.map((credential) => credential.username).join(','),
				domain: notification.meta.domain,
			})
		}

		if (notification?.type === NotificationType.VulnerabilityActivityCreated) {
			return t(`notifications.common.text.${notification.type}`, {
				vulnerabilityTitle: notification.meta.vulnerabilityTitle,
				vulnerabilityActivityStatus: t(
					`vulnerabilities.common.status.${notification.meta.activityStatus}`,
				),
			})
		}

		if (notification?.type === NotificationType.LearnerFailedCourse) {
			return t(`notifications.common.text.${notification.type}`, {
				learnerName: getFullName({
					firstName: notification.meta.learnerFirstName,
					lastName: notification.meta.learnerLastName,
				}),
				courseName: notification.meta.courseName,
			})
		}

		return t(`notifications.common.text.${notification.type}`, notification.meta)
	}, [notification, t])

	return (
		<Typography.Paragraph style={{ color: Colors.Dark, fontSize: '12px' }}>
			{notificationText}
		</Typography.Paragraph>
	)
}

export default NotificationText
