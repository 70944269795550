import styled from 'styled-components'
import { useEditor, EditorContent } from '@tiptap/react'
import { Editor as TipTapEditor, Content } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import Image from '@tiptap/extension-image'
import { rgba } from 'polished'
import React, { useEffect, useState } from 'react'

import { Colors } from '../styles/colors'
import EditorToolbar from './EditorToolbar'
import { SPACES } from '../styles/spaces'
import { CustomBlock } from './CustomBlock'

export enum EditorMode {
	Disabled = 'disabled',
	View = 'view',
	Edit = 'edit',
}

export enum EditorToolbarPosition {
	Top = 'top',
	Bottom = 'bottom',
}

interface IProps {
	toolbarPosition?: EditorToolbarPosition
	mode?: EditorMode
	initialData?: Content
	placeholder?: string
	withSubmitButton?: boolean
	isSubmitting?: boolean
	uploadImageUrl?: string
	error?: string
	toolbarOptionsHidden?: string[]
	onSubmit?(e: TipTapEditor): void
	onChange?(data: TipTapEditor): void
}

const Editor: React.FC<IProps> = ({
	initialData,
	placeholder,
	mode,
	toolbarPosition,
	withSubmitButton,
	isSubmitting,
	error,
	toolbarOptionsHidden,
	uploadImageUrl,
	onSubmit,
	onChange,
}) => {
	const [isFocused, setIsFocused] = useState(false)

	const editor = useEditor({
		editable: mode === EditorMode.Edit,
		content: initialData,
		extensions: [
			StarterKit,
			Underline,
			Link,
			Placeholder.configure({
				placeholder,
			}),
			Image,
			CustomBlock,
		],
		onFocus: () => setIsFocused(true),
		onBlur: () => setIsFocused(false),
		onUpdate: ({ editor }) => {
			if (onChange) {
				onChange(editor)
			}
		},
	})

	useEffect(() => {
		if (!mode || !editor) return

		if ([EditorMode.Disabled, EditorMode.View].includes(mode) && editor.isEditable) {
			editor.setEditable(false)
		}

		if (EditorMode.Edit === mode && !editor.isEditable) {
			editor.setEditable(true)
		}
	}, [mode, editor])

	return (
		<Wrapper>
			<StyleWrapper isFocused={isFocused} mode={mode} error={!!error}>
				{mode !== EditorMode.View && toolbarPosition === EditorToolbarPosition.Top && (
					<EditorToolbar
						editor={editor}
						position={toolbarPosition}
						withSubmitButton={withSubmitButton!}
						onSubmit={onSubmit}
						isSubmitting={isSubmitting}
						uploadImageUrl={uploadImageUrl}
						optionsHidden={toolbarOptionsHidden}
					/>
				)}
				<EditorContent editor={editor} data-test="EditorReactComponentIdentifier" />
				{mode !== EditorMode.View && toolbarPosition === EditorToolbarPosition.Bottom && (
					<EditorToolbar
						editor={editor}
						position={toolbarPosition}
						withSubmitButton={withSubmitButton!}
						onSubmit={onSubmit}
						isSubmitting={isSubmitting}
						uploadImageUrl={uploadImageUrl}
						optionsHidden={toolbarOptionsHidden}
					/>
				)}
			</StyleWrapper>
			{error && (
				<Error className="ant-form-item-explain ant-form-item-explain-error">{error}</Error>
			)}
		</Wrapper>
	)
}

Editor.defaultProps = {
	mode: EditorMode.Edit,
	toolbarPosition: EditorToolbarPosition.Top,
	withSubmitButton: false,
}

const Wrapper = styled.div`
	position: relative;
`

const StyleWrapper = styled.div<{
	isFocused: boolean
	mode?: EditorMode
	error?: boolean
}>`
	border: 1px solid ${Colors.Light};
	outline-color: ${rgba(Colors.Blue, 0.1)};
	outline-width: 2px;
	${({ isFocused, mode }) =>
		isFocused &&
		mode === EditorMode.Edit &&
		`
		border-color: ${Colors.Blue};
		outline-style: solid;
	`}
	${({ error }) =>
		error &&
		`
		border-color: ${Colors.Red};
		outline-color: ${rgba(Colors.Red, 0.1)};
		margin-bottom: 0;
	`}
	border-radius: 4px;
	background: ${Colors.White};
	transition: all 0.3s;

	.ProseMirror {
		&:focus {
			outline: none;
		}
		padding: ${SPACES.SMALL} ${SPACES.SMALL} 0 ${SPACES.SMALL};
		> * + * {
			margin-top: 0.75em;
		}
		ul,
		ol {
			padding: 0 1rem;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			line-height: 1.1;
		}

		code {
			background-color: ${rgba('#eeeef3', 0.6)};
			color: #808fad;
		}

		pre {
			background: ${rgba('#eeeef3', 0.6)};
			color: #808fad;
			font-family: 'ibm-plex-mono', sans-serif;
			font-weight: 400;
			font-style: normal;
			padding: 0.75rem 1rem;
			border-radius: 0.5rem;

			code {
				color: inherit;
				padding: 0;
				background: none;
				font-size: 0.8rem;
			}
		}

		img {
			max-width: 100%;
			height: auto;
		}

		blockquote {
			padding-left: 1rem;
			border-left: 2px solid rgba(#0d0d0d, 0.1);
		}

		hr {
			border: none;
			border-top: 2px solid ${rgba('#332d2d', 0.1)};
			margin: 2rem 0;
		}

		p.is-editor-empty:first-child::before {
			color: ${Colors.Gray};
			content: attr(data-placeholder);
			float: left;
			height: 0;
			pointer-events: none;
		}
	}

	${({ mode }) =>
		mode === EditorMode.View &&
		`
		border: none;
		.ProseMirror {
			padding: 0;
		}
	`}
	${({ mode }) =>
		mode === EditorMode.Edit &&
		`
		margin-bottom: 8px;
	`}
`

const Error = styled.div`
	&&& {
		min-height: 16px;
		position: absolute;
		bottom: -16px;
	}
`

export default Editor
