export enum FeatureToggleNames {
	SignUp = 'REACT_APP_FT_SIGN_UP',
	Inventory = 'REACT_APP_FT_INVENTORY',
	Monitoring = 'REACT_APP_FT_MONITORING',
	Learning = 'REACT_APP_FT_LEARNING',
	Phishing = 'REACT_APP_FT_PHISHING',
	ThirdParty = 'REACT_APP_FT_THIRD_PARTY',
	Waf = 'REACT_APP_FT_WAF',
}

export const useFeatureToggle = (feature: string) => {
	const envValue = process.env[feature] === 'false' ? false : true

	return envValue
}

interface IProps {
	name: string
	fallbackComponent?: any
	children: React.ReactNode | React.ReactNode[]
}

const FeatureToggle: React.FC<IProps> = ({ name, children, fallbackComponent }) => {
	const isEnabled = useFeatureToggle(name)

	if (isEnabled) {
		return children
	}

	if (fallbackComponent) {
		return fallbackComponent()
	}

	return null
}

export default FeatureToggle
