import { matchPath, useLocation } from 'react-router-dom'
import { Routes } from '../../routes/config'

export enum Groups {
	Inventory = 'inventory',
	Awareness = 'awareness',
	Simulations = 'simulations',
	ContinuesMonitoring = 'continuesMonitoring',
	Grc = 'grc',
	Waf = 'waf',
	Leaks = 'dataLeaks',
	Admin = 'admin',
	GroupAdministration = 'groupAdministration',
	AwarenessAdministration = 'awarenessAdministration',
	InventoryAdministration = 'inventoryAdministration',
	ContinuesMonitoringAdministration = 'continuesMonitoringAdministration',
	NanoLearn = 'nanoLearn',
	LeaksAdministration = 'leaksAdministration',
	Library = 'library',
	NoGroup = 'noGroup',
}

export interface ISidebarRoute {
	route: string
	group: Groups
	key: string
}

export const useMatchSidebarRoute = (): ISidebarRoute | undefined => {
	const location = useLocation()

	const matchRoute = () =>
		Object.values(SidebarRoutes).find((route) => {
			const match = matchPath(location.pathname, route.route)
			if (match) {
				return match.isExact
			}
			return false
		})

	return matchRoute()
}

export const SidebarRoutes: { [key: string]: ISidebarRoute } = {
	Overview: { route: Routes.Dashboard, group: Groups.NoGroup, key: 'overview' },
	AdminOverview: {
		route: Routes.AdministrationOverview,
		group: Groups.NoGroup,
		key: 'admin-overview',
	},
	People: { route: Routes.People, group: Groups.Inventory, key: 'people' },
	Assets: { route: Routes.Assets, group: Groups.Inventory, key: 'assets' },
	AssetDetails: {
		route: Routes.AssetDetails,
		group: Groups.Inventory,
		key: 'assets',
	},
	Library: {
		route: Routes.OrganizationLibrary,
		group: Groups.Library,
		key: 'library',
	},
	OrganizationNanoPages: {
		route: Routes.OrganizationNanoPages,
		group: Groups.Library,
		key: 'library',
	},
	OrganizationNanoPage: {
		route: Routes.ViewEditOrganizationNanoPage,
		group: Groups.Library,
		key: 'library',
	},
	OrganizationCommonNanoPage: {
		route: Routes.ViewEditOrganizationCommonNanoPage,
		group: Groups.Library,
		key: 'library',
	},
	OrganizationLessons: {
		route: Routes.OrganizationLessons,
		group: Groups.Library,
		key: 'library',
	},
	OrganizationLesson: {
		route: Routes.ViewEditOrganizationLesson,
		group: Groups.Library,
		key: 'library',
	},
	CreateCommonLesson: {
		route: Routes.CreateCommonLesson,
		group: Groups.Library,
		key: 'library',
	},
	OrganizationLessonCommon: {
		route: Routes.ViewEditOrganizationCommonLesson,
		group: Groups.Library,
		key: 'library',
	},
	OrganizationTemplates: {
		route: Routes.OrganizationTemplates,
		group: Groups.Library,
		key: 'library',
	},
	OrganizationTemplate: {
		route: Routes.ViewEditOrganizationTemplate,
		group: Groups.Library,
		key: 'library',
	},
	OrganizationPage: {
		route: Routes.ViewEditOrganizationPage,
		group: Groups.Library,
		key: 'library',
	},
	Assignments: {
		route: Routes.Courses,
		group: Groups.Awareness,
		key: 'assignments',
	},
	CreateAssignments: {
		route: Routes.CreateCourse,
		group: Groups.Awareness,
		key: 'assignments',
	},
	AssignmentDetails: {
		route: Routes.ViewCourse,
		group: Groups.Awareness,
		key: 'assignments',
	},
	Lessons: {
		route: Routes.Lessons,
		group: Groups.Awareness,
		key: 'learning-content',
	},
	CreateLesson: {
		route: Routes.CreateLesson,
		group: Groups.Awareness,
		key: 'learning-content',
	},
	ViewEditLesson: {
		route: Routes.ViewEditLesson,
		group: Groups.Awareness,
		key: 'learning-content',
	},
	CreatePage: {
		route: Routes.CreatePage,
		group: Groups.Awareness,
		key: 'learning-content',
	},
	ViewEditPage: {
		route: Routes.ViewEditPage,
		group: Groups.Awareness,
		key: 'learning-content',
	},
	NanoPages: {
		route: Routes.NanoPages,
		group: Groups.Awareness,
		key: 'nano-pages',
	},
	CreateNanoPage: {
		route: Routes.CreateNanoPage,
		group: Groups.Awareness,
		key: 'nano-pages',
	},
	ViewEditNanoPage: {
		route: Routes.ViewEditNanoPage,
		group: Groups.Awareness,
		key: 'nano-pages',
	},
	ViewCampaign: {
		route: Routes.ViewCampaign,
		group: Groups.Awareness,
		key: 'nano-learn',
	},
	Simulations: {
		route: Routes.Simulations,
		group: Groups.Simulations,
		key: 'simulations',
	},
	SimulationDetails: {
		route: Routes.ViewSimulation,
		group: Groups.Simulations,
		key: 'simulations',
	},
	CreateSimulation: {
		route: Routes.CreateSimulation,
		group: Groups.Simulations,
		key: 'simulations',
	},
	ContinuesMonitoring: {
		route: Routes.ContinuesMonitoring,
		group: Groups.ContinuesMonitoring,
		key: 'monitoring',
	},
	ContinuesMonitoringVulnerabilities: {
		route: Routes.ContinuesMonitoringVulnerabilities,
		group: Groups.ContinuesMonitoring,
		key: 'monitoring',
	},
	ContinuesMonitoringVulnerabilitiesStatistics: {
		route: Routes.ContinuesMonitoringStatistics,
		group: Groups.ContinuesMonitoring,
		key: 'monitoring-statistics',
	},
	ContinuesMonitoringVulnerabilityDetails: {
		route: Routes.ContinuesMonitoringVulnerabilityDetails,
		group: Groups.ContinuesMonitoring,
		key: 'monitoring',
	},
	ContinuesMonitoringCreateVulnerability: {
		route: Routes.ContinuesMonitoringCreateVulnerability,
		group: Groups.ContinuesMonitoring,
		key: 'monitoring',
	},
	ContinuesMonitoringDraftVulnerabilities: {
		route: Routes.ContinuesMonitoringDraftVulnerabilities,
		group: Groups.ContinuesMonitoring,
		key: 'monitoring',
	},
	WafOverview: {
		route: Routes.WafOverview,
		group: Groups.Waf,
		key: 'waf-overview',
	},
	WafBlockedIps: {
		route: Routes.BlockedIps,
		group: Groups.Waf,
		key: 'waf-blocked-ips',
	},
	Theme: { route: Routes.Theme, group: Groups.NoGroup, key: 'theme' },
	AdminUsers: {
		route: Routes.AdminUsers,
		group: Groups.Admin,
		key: 'admin-users',
	},
	AdminOrganizations: {
		route: Routes.AdminOrganizations,
		group: Groups.Admin,
		key: 'admin-organizations',
	},
	GroupAdministrationOrganizations: {
		route: Routes.GroupAdministrationOrganizations,
		group: Groups.GroupAdministration,
		key: 'group-administration-organizations',
	},
	GroupAdministrationOrganizationDetails: {
		route: Routes.GroupAdministrationOrganizationDetails,
		group: Groups.GroupAdministration,
		key: 'group-administration-organizations',
	},
	InventoryAdministration: {
		route: Routes.AdminAllAssets,
		group: Groups.InventoryAdministration,
		key: 'admin-all-assets',
	},
	AdminAllAssets: {
		route: Routes.AdminAllAssets,
		group: Groups.Admin,
		key: 'admin-all-assets',
	},
	AwarenessAdministration: {
		route: Routes.Awareness,
		group: Groups.AwarenessAdministration,
		key: 'awareness-administration',
	},
	PredefinedCourses: {
		route: Routes.PredefinedCourses,
		group: Groups.AwarenessAdministration,
		key: 'predefined-courses',
	},
	CreatePredefinedCourse: {
		route: Routes.CreatePredefinedCourse,
		group: Groups.AwarenessAdministration,
		key: 'predefined-courses',
	},
	ViewEditPredefinedCourse: {
		route: Routes.ViewEditPredefinedCourse,
		group: Groups.AwarenessAdministration,
		key: 'predefined-courses',
	},
	AdminTemplates: {
		route: Routes.AdminTemplates,
		group: Groups.AwarenessAdministration,
		key: 'admin-templates',
	},
	EditTemplateTemplates: {
		route: Routes.AdminEditTemplate,
		group: Groups.AwarenessAdministration,
		key: 'admin-templates',
	},
	CreateTemplateTemplates: {
		route: Routes.AdminCreateTemplate,
		group: Groups.AwarenessAdministration,
		key: 'admin-templates',
	},
	ContinuesMonitoringAdministration: {
		route: Routes.ContinuesMonitoring,
		group: Groups.ContinuesMonitoringAdministration,
		key: 'continues-monitoring-administration',
	},
	ContinuesMonitoringVulnerabilityEnrichers: {
		route: Routes.ContinuesMonitoringVulnerabilityEnrichers,
		group: Groups.ContinuesMonitoringAdministration,
		key: 'enricher',
	},
	ContinuesMonitoringCreateVulnerabilityEnricher: {
		route: Routes.ContinuesMonitoringCreateVulnerabilityEnricher,
		group: Groups.ContinuesMonitoringAdministration,
		key: 'enricher',
	},
	ContinuesMonitoringEditVulnerabilityEnricher: {
		route: Routes.ContinuesMonitoringEditVulnerabilityEnricher,
		group: Groups.ContinuesMonitoringAdministration,
		key: 'enricher',
	},
	NanoLearn: {
		route: Routes.NanoLearningCampaigns,
		group: Groups.Awareness,
		key: 'nano-learn',
	},
	CreateNanoLearnCampaign: {
		route: Routes.CreateNanoLearningCampaign,
		group: Groups.Awareness,
		key: 'nano-learn',
	},
	Leaks: {
		route: Routes.Leaks,
		group: Groups.Leaks,
		key: 'leaks',
	},
	DataLeaksCredentials: {
		route: Routes.LeaksCredentials,
		group: Groups.Leaks,
		key: 'leaks',
	},
	LeaksAdministration: {
		route: Routes.AdminLeaks,
		group: Groups.LeaksAdministration,
		key: 'admin-leaks',
	},
	LeaksCredentialsAdministration: {
		route: Routes.AdminCredentialSearches,
		group: Groups.LeaksAdministration,
		key: 'admin-leaks',
	},
	LeaksCredentialAdministration: {
		route: Routes.AdminCredentialSearch,
		group: Groups.LeaksAdministration,
		key: 'admin-leaks',
	},
	ExtensionReports: {
		route: Routes.ExtensionReports,
		group: Groups.AwarenessAdministration,
		key: 'extension-reports',
	},
	CourseIndustryStandards: {
		route: Routes.CourseIndustryStandards,
		group: Groups.AwarenessAdministration,
		key: 'course-industry-standards',
	},
}
