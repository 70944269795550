import styled from 'styled-components'
import { Colors } from '../styles/colors'
import { SPACES } from '../styles/spaces'

const PageBox = styled.section`
	display: flex;
	flex-flow: column;
	background-color: ${Colors.White};
	padding: 0 ${SPACES.EXTRA_LARGE};
	border-radius: ${SPACES.XXS};
	min-height: calc(100vh - 210px);
	border-radius: ${SPACES.EXTRA_SMALL};
`

export default PageBox
