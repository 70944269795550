import { Typography } from 'antd'
import { ParagraphProps } from 'antd/lib/typography/Paragraph'
import { FC } from 'react'
import styled from 'styled-components'

interface ITextProps extends ParagraphProps {
	color?: string
	zeroMargin?: boolean
	size?: 'lg' | 'md' | 'sm' | 'xs' | 'xss'
}
// TODO: add all typography props here
const Text: FC<ITextProps> = (props: ITextProps) => {
	return <StyledText {...props} />
}

const sizeToFontSize = {
	lg: '18px',
	md: '14px',
	sm: '12px',
	xs: '11px',
	xss: '10px',
}

const getFontSizeProps = (props: ITextProps) => {
	return `
		font-size: ${sizeToFontSize[props.size!]};
	`
}

const StyledText = styled(({ color, size, zeroMargin, ...rest }) => (
	<Typography.Paragraph {...rest} />
))`
	&&& {
		${(props: ITextProps) => props.color && `color: ${props.color};`}
		${(props: ITextProps) => props.size && getFontSizeProps(props)}
		${(props: ITextProps) => props.zeroMargin && `margin-bottom:0;`}
	}
`

export default Text
