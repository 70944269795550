import { createContext } from 'react'
import { IUser, ITenant } from 'blue-project-types'

import { ILoginRequest, IRegisterRequest, IResetPasswordSaveRequest } from '../utils/types'
import { IToken } from '../utils/types'

// Context data
export interface IAuthContext {
	tenantId?: string
	token?: IToken
	user?: IUser
	isLogging: boolean
	isLoggingOut: boolean
	isAuthInitializing: boolean
	isRegistering: boolean
	isSubmittingResetPasswordSave: boolean
	isLogging2FA: boolean
	is2FAEnabled: boolean
	isLogging2FARecoveryKey: boolean
	activeTenant?: ITenant
	switchTenant(tenantId: string): void
	resetPasswordSave(data: IResetPasswordSaveRequest): void
	login(data: ILoginRequest, redirectUrl?: string): void
	login2FA(data: ILoginRequest, redirectUrl?: string): void
	register(data: IRegisterRequest, redirectUrl?: string): void
	logout(redirectUrl?: string): void
	logoutUser(): void
	login2FARecoveryKey(data: ILoginRequest, redirectUrl?: string): void
}

// Context
export const AuthContext = createContext<IAuthContext>({
	isLogging: false,
	isLoggingOut: false,
	isAuthInitializing: false,
	isRegistering: false,
	isSubmittingResetPasswordSave: false,
	isLogging2FA: false,
	is2FAEnabled: false,
	isLogging2FARecoveryKey: false,
} as IAuthContext)

AuthContext.displayName = 'AuthContext'
