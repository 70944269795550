import { Path, createBrowserHistory, LocationState } from 'history'

// Utils functions that can be used anywhere to change the route

const routeHistory = createBrowserHistory()

const push = (path: Path, state?: LocationState) => {
	routeHistory.push(path, state)
}

const back = () => routeHistory.goBack()

const replace = (path: Path, state?: LocationState) => routeHistory.replace(path, state)

const getLocation = () => routeHistory.location

export { push, back, replace, getLocation }

export default routeHistory
