import * as React from 'react'
import { Route, Redirect, RouteProps } from 'react-router'
import { stringify } from 'query-string'

import { Routes } from '../routes/config'

interface IProps extends RouteProps {
	isAuthenticated: boolean
	isGuestRoute?: boolean
	redirectTo?: string
}

const ProtectedRoute: React.FC<IProps> = ({
	isAuthenticated,
	isGuestRoute,
	redirectTo,
	...props
}: IProps) => {
	const redirectRoute = redirectTo || isGuestRoute ? Routes.Dashboard : Routes.Auth

	if ((isAuthenticated && !isGuestRoute) || (!isAuthenticated && isGuestRoute)) {
		return <Route {...props} />
	}

	return (
		<Redirect
			to={{
				pathname: redirectRoute,
				state: { from: props.location },
				search: !isAuthenticated
					? stringify({
							from: `${props.location?.pathname}${props.location?.search}`,
					  })
					: undefined,
			}}
		/>
	)
}

export default ProtectedRoute
