import { intervalToDuration } from 'date-fns'

export const capitalizeFirstLetter = (value: string) => {
	return `${value.charAt(0).toUpperCase()}${value.slice(1)}`
}

export const generatePdfName = (name: string) => name.replace(' ', '_')

export const toFlatPropertyMap = (obj: object, keySeparator = '.') => {
	const flattenRecursive = (
		obj: object,
		parentProperty?: string,
		propertyMap: Record<string, unknown> = {},
	) => {
		for (const [key, value] of Object.entries(obj)) {
			const property = parentProperty ? `${parentProperty}${keySeparator}${key}` : key
			if (value && typeof value === 'object') {
				flattenRecursive(value, property, propertyMap)
			} else {
				propertyMap[property] = value
			}
		}
		return propertyMap
	}
	return flattenRecursive(obj)
}

export const minutesToPrintData = (minutes: number) => {
	const timeObject = intervalToDuration({ start: 0, end: minutes * 60 * 1000 })

	const time: string[] = []

	if (timeObject.hours) {
		time.push(`${timeObject.hours} h`)
	}

	if (timeObject.minutes) {
		time.push(`${timeObject.minutes} min`)
	}

	const timeString = time.join(' ')

	if (!timeString.trim()) {
		return '-'
	}

	return timeString
}
