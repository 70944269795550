import { useQueryParam, StringParam } from 'use-query-params'
import { Routes } from '../../../routes/config'

const useOrganizationRedirect = () => {
	const [organizationToken] = useQueryParam('organizationToken', StringParam)

	if (organizationToken) {
		return {
			redirectUrl: `${Routes.OrganizationsAcceptInvitation}?token=${organizationToken}`,
			organizationToken,
		}
	}

	return {}
}

export default useOrganizationRedirect
