import {
	BusinessDays,
	PhishedFeedbackType,
	SimulationStatus,
	TargetStatus,
} from 'blue-project-types'

const sr = {
	common: {
		templateDeleted: 'Phishing šablon je obrisan',
		settings: {
			simulation: {
				title: 'Ime i opis',
				subtitle: 'Ime i opis su takođe vidljivi metama',
			},
			properties: {
				name: {
					label: 'Naziv simulacije',
					placeholder: 'Dodaj naziv',
				},
				description: {
					label: 'Opis simulacije',
					placeholder: 'Dodaj opis',
				},
				startDate: {
					label: 'Počni',
				},
				timeZone: {
					label: 'Vremenska zona',
					placeholder: 'Molimo vas da odaberete vremensku zonu',
				},
				duration: {
					label: 'Trajanje',
					text: 'nedelja',
				},
				businessDaysName: {
					[BusinessDays.Monday]: 'Ponedeljak',
					[BusinessDays.Tuesday]: 'Utorak',
					[BusinessDays.Wednesday]: 'Sreda',
					[BusinessDays.Thursday]: 'Četvrtak',
					[BusinessDays.Friday]: 'Petak',
					[BusinessDays.Saturday]: 'Subota',
					[BusinessDays.Sunday]: 'Nedelja',
				},
				businessDays: {
					monday: 'Ponedeljak',
					tuesday: 'Utorak',
					wednesday: 'Sreda',
					thursday: 'Četvrtak',
					friday: 'Petak',
					saturday: 'Subota',
					sunday: 'Nedelja',
				},
				businessHours: {
					label: 'Radni sati',
				},
				maxAtacks: {
					label: 'Maksimalni broj napada po nazivu mete',
				},
			},
			feedbackMessage: {
				label: 'Feedback poruka',
				subtitle:
					'Izaberite unapred definisanu ili napišite svoju feedback poruku koju će korisnik dobiti nakon što se upeca',
				radioGroup: {
					[PhishedFeedbackType.GenericMsg]: 'Generička poruka',
					[PhishedFeedbackType.Error404Page]: 'Stranica 404',
					[PhishedFeedbackType.PhishedDisplay]: '"Prevareni ste" prikaz',
					[PhishedFeedbackType.Other]: 'Drugo',
				},
				preview: 'Pregled',
				message: 'Vaš zahtev je primljen, biće obrađen i javićemo vam se sa daljim koracima.',
				placeholder: 'Unesite željenu poruku',
				labelCustomMsg: 'Prilagođena poruka',
			},
			simulationDuration: {
				title: 'Početak i trajanje simulacije',
				content1: 'Postavite datum i trajanje kampanje.',
				content2:
					'Datum početka i trajanje kampanje može se pojedinačno podesiti iz liste učesnika?',
			},
			simulationScoping: {
				title: 'Opseg simulacija',
				content: 'Odaberite dane kada su simulacije dozvoljene',
			},
		},
		feature: {
			pageTitle: 'Bezbednosne phishing simulacije',
			title: 'Ova opcija nije omogućena za vašu organizaciju.',
			content1: 'Da lansirate vašu prvu bezbednosnu simulaciju,',
			content2: `unapredite i nadgledate bezbednosne phishing simulacije vaše organizacije,`,
			content3: 'kontaktirajte Seif prodajni tim!',
			contactBtn: 'Kontaktirajte nas',
		},
		simulationResult: {
			targets: 'Mete',
			startedOn: 'Početak',
			startingOn: 'Počinje od',
			failed: 'Pali ste',
			duration: 'Trajanje',
			status: 'Status',
			timeLeft: 'Ostalo još',
			days: 'dana',
			statusType: {
				[SimulationStatus.InProgress]: 'Aktivan',
				[SimulationStatus.Upcoming]: 'Predstojeći',
				[SimulationStatus.Stopped]: 'Zaustavljen',
				[SimulationStatus.Ended]: 'Završen',
			},
		},
		headerData: {
			createBtn: 'Kreiraj simulacija',
		},
		simulationTargets: {
			title: 'Odaberite metu simualacije',
			subtitle: 'Odaberite sve ili postavite uslove za prisustvovanje ovoj kampanji',
			messageNoResults: 'Ne postoje dešavanja vezana za ovu metu!',
			properties: {
				people: {
					radio1: 'Svi zaposleni',
					radio2: 'Poručena grupa',
					radio3: 'Predefinisani zaposleni',
				},
				tags: {
					label: 'Oznake',
					placeholder: 'Odaberite oznake da biste dodali učesnike',
				},
			},
			details: {
				sent: 'Poslat',
				delivered: 'Dostavljen',
				opened: 'Otvoren',
				clicked: 'Kliknut',
				replied: 'Odgovoren',
				attachmentOpened: 'Otvoren prilog',
				dataEntered: 'Podaci unešeni',
				receivedTraining: 'Primljena pozivnica za trening',
				reportedPhishing: 'Prijavljen pokušaj phishing-a',
				scheduled: 'Zakazan',
			},
			statusType: {
				[TargetStatus.Created]: 'Kreiran',
				[TargetStatus.InProgress]: 'U toku',
				[TargetStatus.Passed]: 'Prošao/la',
				[TargetStatus.Failed]: 'Pao/la',
				[TargetStatus.NoReaction]: 'Nije reagovao/la',
				[TargetStatus.ReportedSome]: 'Prijavio/la jedan deo phishing mailova',
			},
			sendManually: 'Pošalji manuelno',
		},
		simulationSettings: {
			title: 'Podešavanja simulacije',
			subtitle: `Konfigurišite podešavanja simulacije`,
		},
		simulationTemplatesReview: {
			title: `Odaberite tehniku`,
			subtitle: 'Revidirajte sadržaj kampanje, kao i detalje phishing mejlova',
			filter: {
				title: 'Lista filtera',
				lang: 'Molimo vas, odaberite jezik',
			},
			templatePreview: 'Pregled',
			templateRequiredMsg:
				'* Molimo vas da odaberete i prebacite minimum jedan šablon u desnu kolonu',
		},
		simulationCreated: {
			title: '{{simulationName}} je spreman!',
			subtitle: `Ako želite da napravite izmene u simulaciji, možete to učiniti u sekciji Podešavanja koja se nalazi na stranici Pregled simulacije.`,
			buttonProceed: 'Nastavite do stranice Simulacije',
		},
		ctaSimulation: {
			title1: 'Pokrenite vašu prvu kampanju phishing simulacija',
			title2: `unapredite i nadgledajte bezbednost vaše organizacije`,
			buttonCreate: 'Kreirajte simulaciju',
		},
		basicSimulationSettings: {
			title: 'Podešavanja simulacije',
			subtitle: `Konfigurišite podešavanja simulacije`,
		},
		simulationOverview: {
			funnel: {
				title: 'Tok simulacije',
				details: {
					totalTargets: 'Predviđeni broj napada',
					totalTargetsInfo: 'Ukupan broj napada predviđen tokom ove kampanje.',
					delivered: 'Dostavljen',
					deliveredInfo: 'Broj simuliranih mejlova koji su do sada dostavljeni primaocima.',
					opened: 'Otvoren',
					openedInfo: 'Broj simuliranih mejlova otvoren od strane primalaca.',
					clicked: 'Kliknut',
					clickedInfo: 'Broj primalaca koji su kliknuli na maliciozni link u simuliranom mejlu.',
					replied: 'Odgovoren',
					repliedInfo: 'Broj primalaca koji su odgovorili na simulirani mejl.',
					attachmentOpen: 'Otvoren prilog',
					attachmentOpenInfo: 'Broj primalaca koji su otvorili prilog sa malware fajlom.',
					dataEntered: 'Podaci unešeni',
					dataEnteredInfo:
						'Broj primalaca koji su kliknuli na link i uneli svoje podatke na simuliranoj login stranici.',
					receivedTraining: 'Trening dostavljen',
					reportedPhishing: 'Prijavljen phishing',
					receivedTrainingInfo:
						'Broj primalaca koji su primili dodatni materijal za obuku, nakon što su pali na simulacijama.',
					reportedPhishingInfo: 'Broj primalaca koji su uspešno prijavili pokušaj phishing-a.',
				},
			},
			recentlyFailedTargets: {
				title: 'Najskorije pali polaznici',
			},
			deletedTemplatesAlert: {
				title: 'Simulaticija sadrži izbrisane obrasce',
				subtitle:
					'Trenutno prikazana simulacija sadrži obrisane obrasce. Ugasite upozorenje klikom na dugme Zatvori.',
				gotIt: 'Zatvori',
			},
		},
		duration: {
			title: 'dana ostalo',
			subtitle: 'trajanje u danima',
			tag: 'Istekao',
		},
		viewHeaderData: {
			title: 'Početak',
			duration: 'Trajanje',
			timeleft: 'Ostalo još',
			status: 'Status',
			days: 'dana',
			actionButton: 'Zaustavi Simulaciju',
			modalTitle: 'Zaustavi ovu simulaciju?',
			modalTxt1: 'Da li ste sigurni da želite da zaustavite simulaciju',
			modalTxt2: `* Znajte da više nećete moći da nastavite sa ovom simulacijom.`,
			successMsg: 'Status simulacije je uspešno ažuriran!',
			exportedPdfSuffix: 'detalji_simulacije',
		},
		targets: {
			messageSuccess: 'Podsetnici su uspešno poslati',
			messageNoResults: 'Ne postoje dešavanja vezana za ovu metu!',
			properties: {
				firstName: {
					title: 'Ime',
				},
				lastName: {
					title: 'Prezime',
				},
				groups: {
					title: 'Grupe',
				},
				status: {
					title: 'Status simulacije',
				},
				timeToFail: {
					title: 'Vreme do padanja',
					info: 'Vreme od kad je primalac dobio mejl do trenutka kada je pao na testu. Ukoliko je primaocu dodeljeno više od jednog šablona, vreme do padanja predstavlja prosek.',
				},
				timeToFailReaction: {
					title: 'Vreme od reakcije do padanja',
					info: 'Vreme od otvaranja mejla od strane primaoca do trenutka kada je primalac pao na testu. Ukoliko je primaocu dodeljeno više od jednog šablona, vreme do padanja je predstavljano u proseku.',
				},
			},
			actions: {
				edit: 'Uredi',
				remove: 'Ukloni',
				markPassed: 'Ažuriraj status u prošao/la',
			},
			modalConfirm: {
				title: 'Ukloni ovu metu?',
				subtitle1: 'Da li ste sigurni da želite da uklonite metu',
				subtitle2: 'iz ove simulacije?',
			},
			updateStatusModal: {
				title: 'Ažuriraj status ove mete u prošao/la?',
				text: 'Da li ste sigurni da želite da ažurirate status mete <1>{{fullName}}</1> u prošao/la?',
			},
			update: {
				success: 'Status mete je uspešno ažuriran!',
				error: 'Ažuriranje nije dozvoljeno!',
			},
			search: {
				placeholder: 'Pretraži mete',
			},
			filters: {
				targetStatus: {
					name: 'Status mete',
					[TargetStatus.Created]: 'Kreiran',
					[TargetStatus.InProgress]: 'U toku',
					[TargetStatus.Passed]: 'Prošao/la',
					[TargetStatus.Failed]: 'Pao/la',
					[TargetStatus.NoReaction]: 'Nije reagovao/la',
					[TargetStatus.ReportedSome]: 'Prijavio/la deo phishing mailova',
				},
				groups: {
					name: 'Grupe',
				},
			},
			alert: {
				title: 'Meta je pala simulaciju',
				subtitle:
					'Samo meta koja je pala simulaciju je prikazana. Ugasite upozorenje i označenu kolonu klikom na dugme Zatvori.',
				gotItButton: 'Zatvori',
			},
			sendTargetTemplate: { successMsg: 'Mejl je uspešno poslat meti!' },
		},
		api: {
			useRemoveLearner: {
				success: 'Meta je uspešno uklonjena',
				error: 'Došlo je do greške',
			},
		},
		pages: {
			simulation: {
				title: 'Phishing simulacije',
				subtitle:
					'Ovde možete pregledati sve phishing simulacije koje su u toku, kao i one koje dolaze.',
				section1: 'Simulacije u toku',
				section2: 'Predstojeće simulacije',
				noResultMsg: 'Trenutno nema predstojećih simulacija!',
			},
			createSimulationWizard: {
				error: 'Unesite aktuelne podatke',
				title: 'Pokreni novu simulaciju',
				subtitle:
					'Ovde možete odabrati phishing šablone, dodati mete, i konfigurisati dodatne postavke za vašu simulaciju phishing kampanje.',
				titleStep1: 'Mejl phishing šabloni',
				descriptionStep1: 'Pregledajte i dodajte phishing mejlove',
				titleStep2: 'Mete',
				descriptionStep2: 'Upravljajte učesnicima',
				titleStep3: 'Podešavanja',
				descriptionStep3: 'Konfigurišite podešavanja',
			},
			targets: {
				text: 'Mete',
			},
			overview: {
				text: 'Pregled',
			},
			viewSimulation: {
				subtitle:
					'Ovde možete pregledati rezultate i sadržaj simulacije phishing kampanje, upravljati polaznicima, i odrediti druge postavke.',
				tab1: 'PREGLED',
				tab2: 'METE',
				tab3: 'PHISHING ŠABLONI',
				tab4: 'PODEŠAVANJA',
				timeZoneAlert: {
					title: 'Upozorenje vezano za vremensku zonu',
					subtitle:
						'Imajte u vidu da je vreme u kom se simulacije odigravaju usklađeno sa vremenskom zonom simulacije.',
				},
			},
		},
	},
	templates: {
		title: 'Phishing obrasci',
		commonTemplates: 'SEIF phishing obrasci',
		organizationTemplates: 'Prilagođeni phishing obrasci',
		copyTemplate: {
			title: 'Kopiraj phishing obrazac',
			subtitle:
				'Da li ste sigurni da želite da kopirate <1>{{template}}</1> phishing obrzac u biblioteku organizacije?',
		},
	},
}

export default sr
