import styled from 'styled-components'

import Text from '../../../components/Text'
import Title from '../../../components/Title'
import { Colors } from '../../../styles/colors'
import { SPACES } from '../../../styles/spaces'
import { useTranslation } from 'react-i18next'
import { ReactComponent as DetectedLeaksIcon } from '../../../assets/detected-leaks.svg'
import { ReactComponent as ResolvedLeaksIcon } from '../../../assets/resolved-leaks.svg'
import { ReactComponent as ClockIcon } from '../../../assets/clock-fill.svg'
import TitleSubtitleWrapper from '../../inventory/components/common/TitleSubtitleWrapper'

const TRANSLATE_LEAKS_OVERVIEW_BASE_KEY = 'pdfExports.RiskManagementExport.credentialLeakMonitoring'

const CredentialLeakMonitoring = () => {
	const { t } = useTranslation()

	return (
		<Wrapper>
			<Title color={Colors.Dark} style={{ marginBottom: SPACES.DEFAULT }}>
				{t(`${TRANSLATE_LEAKS_OVERVIEW_BASE_KEY}.title`)}
			</Title>
			<LeakWrapper>
				<TitleSubtitleWrapper
					title={t(`${TRANSLATE_LEAKS_OVERVIEW_BASE_KEY}.title`)}
					subtitle={t(`${TRANSLATE_LEAKS_OVERVIEW_BASE_KEY}.subtitle`)}
				/>
				<Section>
					<Frame>
						<div>
							<DetectedLeaksIcon />
							<Title zeroMargin level={4}>
								{t(`${TRANSLATE_LEAKS_OVERVIEW_BASE_KEY}.detected`)}
							</Title>
							<span>345</span>
						</div>
					</Frame>
					<Frame>
						<div>
							<ResolvedLeaksIcon />
							<Title zeroMargin level={4}>
								{t(`${TRANSLATE_LEAKS_OVERVIEW_BASE_KEY}.resolved`)}
							</Title>
							<span>67</span>
						</div>
					</Frame>
				</Section>
				<Notification>
					<ClockIcon style={{ height: '32px', width: '32px' }} />
					{t(`${TRANSLATE_LEAKS_OVERVIEW_BASE_KEY}.notification`)}
				</Notification>
			</LeakWrapper>
		</Wrapper>
	)
}

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
`
const LeakWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${SPACES.EXTRA_LARGE} 48px;
	border-radius: ${SPACES.DEFAULT};
	background: ${Colors.White};
`
const Section = styled.div`
	display: flex;
	gap: 160px;
	justify-content: space-between;
	margin-block: ${SPACES.DEFAULT};
`
const Frame = styled.div`
	border: 0.5px solid #808fad;
	width: 100%;
	max-width: 40%;
	border-radius: ${SPACES.DEFAULT};
	padding: ${SPACES.DEFAULT};
	svg {
		margin-bottom: ${SPACES.SMALL};
	}
	span {
		font-size: ${SPACES.LARGE};
		font-weight: 600;
	}
	div {
		justify-items: center;
		padding: ${SPACES.DEFAULT};
		text-align: center;
	}
`
const Notification = styled.div`
	margin-inline: -24px;
	display: flex;
	padding: ${SPACES.SMALL} ${SPACES.DEFAULT};
	background: ${Colors.GrayNew};
	border-radius: ${SPACES.SMALL};
	font-size: ${SPACES.SMALL};
	color: ${Colors.OrangeNew};
	gap: ${SPACES.SMALL};
	align-items: center;
`
export default CredentialLeakMonitoring
